import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceAccount } from '@src/models/resource-account';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourceAccountsQueryKeys } from '../resourceAccountsQueryKeys';

export const useResourceAccountsUpdateMutation = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceAccount>,
    AxiosError<ApiErrorResponse>,
    {
      resourceAccountId: string;
      name: string;
      credential: any;
    }
  >({
    mutationFn: ({ resourceAccountId, name, credential }) => {
      const payload: {
        name?: string;
        credentials?: any;
      } = {};
      if (name) {
        payload.name = name;
      }
      if (credential) {
        payload.credentials = credential;
      }

      return makeRequest(
        'PATCH',
        `/orgs/${orgId}/resources/accounts/${resourceAccountId}`,
        payload
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: resourceAccountsQueryKeys.listResourceAccounts(orgId),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};
