import { Button, CardStyles, Tooltip, WalCard } from '@humanitec/ui-components';
import { rgba } from 'polished';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import { Dot } from '@src/components/shared/Dot';
import { WorkloadStatus } from '@src/components/shared/ViewWorkloadProfile/components/WorkloadsStatus/WorkloadStatus';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import { getWorkloadProfileIdAndOrgId } from '@src/hooks/react-query/workload-profiles/queries/useWorkloadProfileLatestQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { useGetReplicaSetRuntimeStatus } from '@src/hooks/useGetReplicaSetRuntimeStatus';
import { Workload } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { generateDeploymentURL } from '@src/utilities/navigation';
import { mapRuntimeStatusToAlertColor } from '@src/utilities/runtime-utils';

import DuplicateWorkloadModal from './DuplicateWorkloadModal';

const WorkloadLink = styled(Link)`
  ${ellipsisStyle(300)}
`;
const Card = styled(WalCard)<{ $deleted: boolean; $edited: boolean }>`
  margin-bottom: ${units.margin.md};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ $deleted, theme }) =>
    $deleted &&
    css`
      background-color: ${rgba(theme.color.baseBrighter, 0.42)};
    `}
`;

const WorkloadInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const Buttons = styled.div`
  display: flex;
  column-gap: ${units.margin.md};
`;

interface WorkloadCardProps {
  workloadId: string;
  workload: Workload;
  deleted: boolean;
  edited: boolean;
  /** Only pass this prop if displaying this card in the active deployment card  */
  deploymentId?: string;
  cardStyle?: CardStyles;
}

const WorkloadCard = ({
  workload,
  workloadId,
  deleted,
  edited,
  deploymentId,
  cardStyle,
}: WorkloadCardProps) => {
  // Component state
  const [deleteWorkloadModalOpen, setDeleteWorkloadModalOpen] = useState(false);
  const [duplicateWorkloadModalOpen, setDuplicateWorkloadModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const { t: tViewDeployment } = useTranslation('viewDeployment');
  const uiTranslations = t('UI');
  const workloadTranslations = tViewDeployment('WORKLOADS');

  // Context
  const { deleteWorkload, restoreDeletedWorkload } = useDeltaUtils(undefined, () => {
    setDeleteWorkloadModalOpen(false);
  });
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { hasWorkloadError } = useDeploymentErrorsQuery(deploymentId);

  const viewDetailsURL = deploymentId
    ? `${generateDeploymentURL(orgId, appId, envId, deploymentId, 'workloads')}/${workloadId}`
    : workloadId;

  const runtimeStatus = useGetReplicaSetRuntimeStatus(workloadId);

  const hasError = hasWorkloadError(workloadId) || mapRuntimeStatusToAlertColor(runtimeStatus);

  return (
    <>
      <ConfirmDeleteModal
        title={workloadTranslations.DELETE_WORKLOAD}
        state={[deleteWorkloadModalOpen, setDeleteWorkloadModalOpen]}
        deleteConfirmedCallback={() => deleteWorkload(workloadId)}
      />
      {duplicateWorkloadModalOpen && (
        <DuplicateWorkloadModal
          openState={[duplicateWorkloadModalOpen, setDuplicateWorkloadModalOpen]}
          workload={workload}
        />
      )}
      <Card
        key={workloadId}
        $deleted={deleted}
        error={hasError}
        $edited={edited}
        cardStyle={cardStyle}
        dataTestId={'workload-card'}>
        <WorkloadInfo>
          {edited && draftModeActive && <Dot color={'blue'} data-testid={'edited-dot'} />}
          <Tooltip
            text={workloadId}
            triggerComponent={
              <WorkloadLink to={viewDetailsURL} className={'title-link'}>
                {workloadId}
              </WorkloadLink>
            }
          />
          <span className={'txt-sm txt-translucent'}>
            {getWorkloadProfileIdAndOrgId(workload.profile).profileId}
          </span>
        </WorkloadInfo>

        <Buttons>
          <WorkloadStatus workloadId={workloadId} />
          {draftModeActive &&
            (deleted ? (
              <Button
                ariaLabel={`${uiTranslations.RESTORE} ${workloadId}`}
                variant={'secondary'}
                onClick={() => restoreDeletedWorkload(workloadId)}>
                {uiTranslations.RESTORE}
              </Button>
            ) : (
              <>
                <Button variant={'secondary'} onClick={() => setDuplicateWorkloadModalOpen(true)}>
                  {uiTranslations.DUPLICATE}
                </Button>
                <Button
                  variant={'secondary'}
                  iconLeft={'delete'}
                  ariaLabel={uiTranslations.DELETE}
                  onClick={() => setDeleteWorkloadModalOpen(true)}
                />
              </>
            ))}
        </Buttons>
      </Card>
    </>
  );
};

export default WorkloadCard;
