import { t } from 'i18next';

import { WalLabel } from '../Label';

export const TableCount = ({
  displayedCount = 0,
  totalCount,
  className,
}: {
  totalCount: number;
  displayedCount?: number;
  className?: string;
}) => {
  return displayedCount && totalCount > displayedCount ? (
    <div className={className}>
      <WalLabel>{[`${t('UI').SHOWING} ${displayedCount} ${t('UI').OF} ${totalCount}`]}</WalLabel>
    </div>
  ) : (
    Boolean(totalCount) && (
      <div className={className}>
        <WalLabel>{[`${totalCount} ${t('UI').TOTAL} `]}</WalLabel>
      </div>
    )
  );
};
