import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceType } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceTypesQuery = (): QueryResponse<ResourceType[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.listTypes(orgId),
    queryFn: () => makeRequest<ResourceType[]>('GET', `/orgs/${orgId}/resources/types`),
  });

  return { ...queryResult, data: data?.data.sort((a, b) => a.name.localeCompare(b.name)) };
};

export default useResourceTypesQuery;
