import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { Role, RoleType } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../../user/userQueryKeys';
import { generateRoleQueryKeyBasedOnRoleType, generateRolesURL } from '../rolesQueryKeys';

const useRoleDeleteMutation = (roleType?: RoleType, successCallback?: () => void) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<AxiosResponse<Role>, AxiosError, string>({
    mutationFn: (roleId) => makeRequest('DELETE', `${generateRolesURL(orgId, roleType)}/${roleId}`),
    onSuccess: (_, roleId) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: generateRoleQueryKeyBasedOnRoleType(orgId, roleType),
      });

      // Invalidate org user
      queryClient.invalidateQueries({
        queryKey: userQueryKeys.user(orgId, roleId),
      });

      if (successCallback) {
        successCallback();
      }
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useRoleDeleteMutation;
