/* eslint-disable no-restricted-imports */
import { useDecision as optimizelyUseDecision } from '@optimizely/react-sdk';

/**
 * This hook enforces the usage of autoUpdate.
 * Without autoUpdate, any flag relying on user data such as id or orgId will not show flags correctly.
 * e.g. when the user is on the login screen, the user is undefined. Loggining in will set the optimizely user to be defined, but the hook will be basing it's decision on the undefined state.
 */
export const useDecision: typeof optimizelyUseDecision = (featureKey, options, ...rest) =>
  optimizelyUseDecision(featureKey, { ...options, autoUpdate: true }, ...rest);
