import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useWalhallContext } from '@src/context/walhallContext';
import { BreadcrumbItem } from '@src/hooks/useBreadcrumbs';
import { PageContainer } from '@src/styles/layout/PageContainer';

import BreadcrumbSection from './BreadcrumbSection';

const BreadcrumbOrderedList = styled.ol`
  display: flex;
  margin: 0;
  padding: 0;
`;

interface NewBreadcrumbsProps {
  crumbs: BreadcrumbItem[];
  className?: string;
}

const NewBreadcrumbs = ({ crumbs, className }: NewBreadcrumbsProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const { navigateDialogState, nextRouteState, shouldConfirmOnNavigateState } = useWalhallContext();
  const [, setShowNavigateDialog] = navigateDialogState;
  const [shouldConfirmOnNavigate] = shouldConfirmOnNavigateState;
  const [, setNextRoute] = nextRouteState;

  return (
    <nav className={className} aria-label={uiTranslations.BREADCRUMBS}>
      <PageContainer disableOverflow>
        <BreadcrumbOrderedList>
          {crumbs.map((crumb, index) => (
            <BreadcrumbSection
              {...crumb}
              key={`${crumb.name}-${crumb.pathname}`}
              isLastItem={index === crumbs.length - 1}
              onClick={(event) => {
                if (shouldConfirmOnNavigate) {
                  event.preventDefault();
                  setShowNavigateDialog(true);
                  setNextRoute(crumb.pathname ? crumb.pathname : null);
                }
              }}
            />
          ))}
        </BreadcrumbOrderedList>
      </PageContainer>
    </nav>
  );
};

export default NewBreadcrumbs;
