import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';
import { ValueSet } from '../sharedValueVersionsTypes';

/**
 * Custom hook to fetch specific Value Set
 */
export const useGetEnvValueSetVersionQuery = (versionId?: string) => {
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: sharedValuesQueryKeys.deployedEnvValuesList(orgId, appId, envId, versionId),
    queryFn: () =>
      makeRequest<ValueSet>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/value-set-versions/${versionId}`
      ),
    enabled: Boolean(orgId && appId && envId && versionId),
  });

  return { ...queryResult, data: data?.data };
};
export default useGetEnvValueSetVersionQuery;
