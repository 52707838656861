import { create } from 'zustand';

type DeploymentSetStore = {
  currentDeploymentSetId: string;
  setCurrentDeploymentSetId: (id: string | undefined) => void;
};

export const useDeploymentSetStore = create<DeploymentSetStore>((set) => ({
  currentDeploymentSetId: '',
  setCurrentDeploymentSetId: (id) => {
    set(() => ({
      currentDeploymentSetId: id,
    }));
  },
}));
