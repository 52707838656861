import { MenuItem, WalCard, WalLabel, WalMenu } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { units } from '@src/styles/variables';

import { ServicePortEntry } from '../ServicePorts';

const Card = styled(WalCard)<{ first: boolean; last: boolean }>`
  position: relative;
  ${({ first }) =>
    first &&
    css`
      margin-top: ${units.margin.md};
    `}

  ${({ last }) =>
    !last &&
    css`
      margin-bottom: ${units.margin.md};
    `}
`;

const CardButtonWrapper = styled.div`
  position: absolute;
  right: ${units.margin.md};
`;

const ServicePortTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const ServicePortDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const ServicePortDetailColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServicePortTitle = styled.span`
  font-size: ${units.fontSize.base};
  font-weight: 700;
`;

interface ServicePortCardProps {
  servicePort: ServicePortEntry;
  menuItemClicked: (item: MenuItem<string>, servicePort: ServicePortEntry) => void;
  first: boolean;
  last: boolean;
}

const ServicePortCard = ({ servicePort, menuItemClicked, first, last }: ServicePortCardProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  const uiTranslations = t('UI');

  // context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  return (
    <Card
      first={first}
      last={last}
      key={servicePort.name}
      cardStyle={'base'}
      dataTestId={'servicePort-card'}>
      <ServicePortTitleWrapper>
        <ServicePortTitle>{servicePort.name}</ServicePortTitle>
        <CardButtonWrapper>
          {draftModeActive && (
            <WalMenu
              items={[
                { label: uiTranslations.EDIT, value: 'edit' },
                { label: uiTranslations.DELETE, value: 'delete' },
              ]}
              standardToggle={{
                type: 'dots',
                objectType: 'service port',
                objectName: servicePort.name,
              }}
              onItemClick={(item) => menuItemClicked(item, servicePort)}
            />
          )}
        </CardButtonWrapper>
      </ServicePortTitleWrapper>
      <ServicePortDetailsWrapper>
        <ServicePortDetailColumn>
          <WalLabel>{sectionsTranslations.SERVICE_PORT}</WalLabel>
          <span>{servicePort.service_port}</span>
        </ServicePortDetailColumn>
        <ServicePortDetailColumn>
          <WalLabel>{sectionsTranslations.CONTAINER_PORT}</WalLabel>
          <span>{servicePort.container_port}</span>
        </ServicePortDetailColumn>
        <ServicePortDetailColumn>
          <WalLabel>{sectionsTranslations.PROTOCOL}</WalLabel>
          <span>{servicePort.protocol}</span>
        </ServicePortDetailColumn>
      </ServicePortDetailsWrapper>
    </Card>
  );
};

export default ServicePortCard;
