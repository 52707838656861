import { DynamicFormSchema } from '@src/types/dynamic-form';

/**
 * Converts a Pipeline JSON schema to a Dynamic Form JSON schema.
 *
 * @param {any} object - The Pipeline JSON schema object.
 * @returns {DynamicFormSchema | undefined} - The converted Dynamic Form JSON schema.
 */
export const convertPipelineJSONSchemaToDynamicFormJsonSchema = (
  object: any
): DynamicFormSchema | undefined => {
  return !object
    ? undefined
    : Object.entries(object).reduce(
        (acc: any, [key, value]: any) => {
          if (typeof value === 'object') {
            if (!value.type) {
              if (!acc.properties[key]) {
                acc.properties[key] = {};
              }
              if (!Array.isArray(value)) {
                acc.properties[key] = {
                  ...acc.properties[key],
                  ...convertPipelineJSONSchemaToDynamicFormJsonSchema(value),
                };
              }
              acc.properties[key].type = 'object';
            } else {
              acc.properties[key] = value;
              acc.properties[key].title = key;
              if (value.required) {
                if (!acc.required) {
                  acc.required = [];
                }
                acc.required.push(key);
              }
            }
          }

          return acc;
        },
        { properties: {} }
      );
};
