import { CardStyles, WalCard, WalLabel } from '@humanitec/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import CloneModal from '@src/components/shared/CloneModal/CloneModal';
import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import DeploymentControls from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/DeploymentControls';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import usePipelineRunDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunDetailsQuery';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { DeploymentObject } from '@src/models/deployment-object';
import { ContainerErrorState } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import {
  generateDeploymentURL,
  generatePipelinesURL,
  generateWorkloadURL,
} from '@src/utilities/navigation';
import { getContainerErrorsInReplicaSet } from '@src/utilities/runtime-utils';

import { DisplayDeploymentStatus } from '../../CompareDeployments/components/DeploymentEntryRows/components/DeploymentEntryMetadata/DeploymentEntryMetadata';
import DeploymentCardErrorTable, { ErrorTableData } from './components/DeploymentCardErrorTable';
import ExportManifestModal from './components/ExportManifestModal/ExportManifestModal';

const Card = styled(WalCard)`
  margin-bottom: ${units.margin.md};
  ${({ isActive, theme, error }: { isActive: boolean; theme: DefaultTheme; error: boolean }) =>
    isActive && !error && `border-color: ${theme.color.outline}`};
`;

const TopRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill);
  column-gap: ${units.padding.xl};
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
  padding: ${units.padding.sm} 0;
`;

const DeploymentCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
`;

interface DeploymentCardProps {
  deploy: DeploymentObject;
  isActiveDeployment?: boolean;
  lastPastDeploymentId?: string;
  cardStyle?: CardStyles;
}

const DeploymentCard = ({
  deploy,
  isActiveDeployment,
  lastPastDeploymentId,
  cardStyle,
}: DeploymentCardProps) => {
  // Component state
  const [exportManifestModalOpen, setExportManifestModalOpen] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation('viewEnvironment');
  const { t: tCommon } = useTranslation();
  const translations = t('DEPLOYS').DEPLOYMENT_CARD;
  const uiTranslations = tCommon('UI');

  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery(isActiveDeployment);
  const { data: users = [] } = useOrgRolesQuery();
  const { data: deploymentErrors = [] } = useDeploymentErrorsQuery(deploy.id);
  const { data: environment } = useEnvironmentQuery();
  const { data: user } = useGetCurrentUserQuery();
  const { data: pipelineRun } = usePipelineRunDetailsQuery({
    id: deploy.pipeline?.run_id,
    customPipelineId: deploy.pipeline?.id,
  });
  const { data: environmentResources } = useEnvironmentResourcesQuery();

  const { environmentRuntime } = environmentRuntimeData || {};

  const hasRunningPods = Boolean(Object.keys(environmentRuntime?.modules || {}).length);

  const deploymentErrorsTableData: ErrorTableData[] = deploymentErrors.map(
    ({ object_id, scope, summary, message }) => ({
      summary: {
        url:
          scope === 'workload'
            ? generateWorkloadURL(orgId, appId, envId, deploy.id, object_id)
            : generateDeploymentURL(orgId, appId, envId, deploy.id, 'workloads'),
        text: `${summary}${message ? ` - ${message}` : ''}`,
      },
      source:
        scope === 'environment'
          ? translations.ENVIRONMENT
          : `${translations.WORKLOAD} ${object_id}`,
    })
  );

  const replicaSets = Object.entries(environmentRuntime?.modules ?? {}).flatMap(
    ([workloadId, workloadReplicaSet]) => {
      const errorsArr: ContainerErrorState[] = [];

      Object.values(workloadReplicaSet.controllers || {}).forEach((workloadController) => {
        Object.values(workloadController.pods).forEach((pod) => {
          getContainerErrorsInReplicaSet(pod, errorsArr, false);
        });
      });

      return errorsArr.map((error) => ({ workloadId, error }));
    }
  );

  const runtimeErrorsTableData: ErrorTableData[] = isActiveDeployment
    ? replicaSets.map(({ workloadId, error: { reason, message } }) => ({
        summary: {
          url: generateWorkloadURL(orgId, appId, envId, deploy.id, workloadId),
          text: `${reason}${message ? ` - ${message}` : ''}`,
        },
        source: `${translations.WORKLOAD} ${workloadId}`,
      }))
    : [];

  const hasError =
    Boolean(deploymentErrorsTableData.length || runtimeErrorsTableData.length) &&
    deploy.status !== 'in progress';

  const alertColor = Boolean(hasError && isActiveDeployment);

  const urls = environmentResources
    ?.filter((resource) => resource.type === 'dns' && (resource?.resource?.host as string))
    .map((res) => `https://${res?.resource?.host}`);

  return (
    <>
      <Card
        isActive={false}
        cardStyle={cardStyle || 'default'}
        dataTestId={'deployment-card'}
        error={alertColor}>
        <TopRow>
          <DeploymentCommentWrapper className={`${!isActiveDeployment ? 'txt-translucent' : ''} `}>
            {isActiveDeployment && <WalLabel>{translations.LAST_DEPLOYMENT}</WalLabel>}
            <Link
              to={generateDeploymentURL(orgId, appId, envId, deploy.id, 'workloads')}
              className={'title-link'}>
              {deploy.comment || uiTranslations.NO_COMMENT}
            </Link>
          </DeploymentCommentWrapper>
          {environment && (
            <DeploymentControls
              deployment={deploy}
              environment={environment}
              lastPastDeploymentId={lastPastDeploymentId}
            />
          )}
        </TopRow>
        <div className={'flex-row flex-centered'}>
          {deploy.status && (
            <div className={'flex-column mr-lg'}>
              <WalLabel>{translations.DEPLOYMENT_STATUS}</WalLabel>
              <DisplayDeploymentStatus
                status={deploy.status}
                hasRunningPods={hasRunningPods}
                activeDeployment={Boolean(isActiveDeployment)}
                greyIcon
              />
            </div>
          )}
          <div className={'flex-column mr-lg'}>
            <WalLabel>{translations.DEPLOYED_BY}</WalLabel>
            {deploy.created_by === user?.id
              ? user?.name
              : users?.find((role) => role.id === deploy.created_by)?.name}
          </div>
          <div className={'flex-column mr-lg'}>
            <WalLabel>{translations.DEPLOYMENT_ID}</WalLabel>
            <span>{deploy.id}</span>
          </div>
          {deploy?.created_at && (
            <div className={'flex-column mr-lg'}>
              <WalLabel>{translations.DEPLOYED_AT}</WalLabel>
              <span>
                {formatDate(deploy?.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
              </span>
            </div>
          )}
          {pipelineRun && (
            <div className={'flex-column mr-lg'}>
              <WalLabel>{translations.PIPELINE_RUN}</WalLabel>
              <Link
                to={generatePipelinesURL(orgId, appId, pipelineRun.pipeline_id, pipelineRun?.id)}
                className={'txt-base title-link'}>
                {formatDate(
                  pipelineRun?.created_at,
                  DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE,
                  undefined
                )}
              </Link>
            </div>
          )}
        </div>

        {hasError && (
          <DeploymentCardErrorTable
            isActiveDeployment={isActiveDeployment}
            errors={{
              runtime: runtimeErrorsTableData,
              deployment: deploymentErrorsTableData,
            }}
          />
        )}

        {Boolean(urls?.length) && isActiveDeployment && (
          <>
            <span className={'txt-translucent mt-md mb-sm'}>URLs</span>
            {urls?.map((url) => (
              <Link to={url} key={url} target={'_blank'}>
                {url}
              </Link>
            ))}
          </>
        )}
      </Card>

      {cloneModalOpen && (
        <CloneModal fromDeployment={deploy} openState={[cloneModalOpen, setCloneModalOpen]} />
      )}

      {exportManifestModalOpen && (
        <ExportManifestModal
          state={[exportManifestModalOpen, setExportManifestModalOpen]}
          deployment={deploy}
        />
      )}
      {environment && (
        <DeploymentOverviewModal
          environment={environment}
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          deploymentType={{ type: 'redeploy', data: deploy }}
        />
      )}
    </>
  );
};

export default DeploymentCard;
