import { datadogRum } from '@datadog/browser-rum';
import { Button, DropdownItem, FormGenerator, MultiSelectGroup } from '@humanitec/ui-components';
import { intersection } from 'lodash';
import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import AddHeadersModal from '@src/components/shared/modals/AddHeadersModal';
import AddPayloadModal from '@src/components/shared/modals/AddPayloadModal';
import useCreateWebhookMutation from '@src/hooks/react-query/webhooks/mutations/useCreateWebhookMutation';
import { useRBAC } from '@src/hooks/useRBAC';
import { EventTrigger, Webhook } from '@src/models/webhook';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const gridTemplate = css`
  grid-template-columns: 1fr 1fr auto 1fr auto;
`;

const Form = styled.form`
  display: grid;
  margin-bottom: ${units.margin.xl};
  max-width: ${rem(1100)};
  align-self: center;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${units.margin.lg};
`;

const Separator = styled.div`
  width: ${rem(10)};
  height: ${rem(1)};
  background-color: ${({ theme }) => theme.color.baseOutline};
`;

interface NewWebhookFormProps {
  triggerOptions: (MultiSelectGroup | DropdownItem<string>)[];
  triggers: EventTrigger[];
  existingIds: string[];
}

const generateTriggersFromFormValues = (
  formValues: any,
  allTriggers: EventTrigger[]
): EventTrigger[] => {
  const triggers: EventTrigger[] = [];
  Object.keys(formValues).forEach((scope) => {
    Object.keys(formValues[scope]).forEach((type) => {
      if (formValues[scope][type]) {
        const findTrigger = allTriggers.find((t) => t.scope === scope && t.type === type);
        if (findTrigger) {
          triggers.push(findTrigger);
        }
      }
    });
  });

  return triggers;
};

const resetDefaultTriggers = (formValues: any): Record<string, unknown> => {
  const triggers: any = {
    all: true,
  };
  Object.keys(formValues.triggers).forEach((scope) => {
    Object.keys(formValues.triggers[scope]).forEach((type) => {
      if (!triggers[scope]) {
        triggers[scope] = {};
      }
      triggers[scope][type] = true;
    });
  });

  return triggers;
};

export const NewWebhookForm = ({ triggerOptions, triggers, existingIds }: NewWebhookFormProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const webhookTranslations = t('APP_SETTINGS').WEBHOOKS;

  // Component state
  const [triggersValid, setTriggersValid] = useState(false);
  const [headers, setHeaders] = useState<Record<string, string>>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [openPayloadModal, setOpenPayloadModal] = useState<boolean>(false);

  // Form
  const newWebhookFormMethods = useWalhallForm({
    defaultValues: {
      id: '',
      url: '',
      triggers: {
        all: true,
      },
      payloadData: '',
    },
  });

  // Form
  const {
    handleSubmit,
    reset,
    getValues,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { isSubmitted, dirtyFields },
  } = newWebhookFormMethods;
  const triggersFormData = watch('triggers');
  const payloadDataDirty = dirtyFields.payloadData;
  const payloadData = watch('payloadData');

  // RBAC
  const canDeleteApplication = useRBAC('deleteApplication');

  // React Query
  const { mutate: createWebhook } = useCreateWebhookMutation(() => {
    setHeaders({});
    reset({
      url: '',
      id: '',
      triggers: resetDefaultTriggers(getValues()),
    });
  });

  useEffect(() => {
    if (isSubmitted) {
      if (triggersValid) {
        clearErrors('triggers');
      } else {
        setError('triggers', { message: webhookTranslations.MUST_SELECT_TRIGGER });
      }
    }
  }, [triggersValid, clearErrors, isSubmitted, setError, webhookTranslations.MUST_SELECT_TRIGGER]);

  /**
   * Update the payload based on the selected triggers.
   * We should only display the variables which are common among the triggers.
   */
  useEffect(() => {
    const selectedTriggers = generateTriggersFromFormValues(triggersFormData, triggers);

    if (!selectedTriggers?.length || payloadDataDirty || !openPayloadModal) return;

    const defaultPayload: any = {};

    const all = selectedTriggers
      .map((trigger) => trigger.properties)
      .filter((trigger) => trigger?.length);
    const commonTypes = intersection(...all);

    commonTypes.forEach((type) => (defaultPayload[type] = `\${${type}}`));

    setValue('payloadData', JSON.stringify(defaultPayload, null, '\t'));
  }, [openPayloadModal, payloadDataDirty, setValue, triggersFormData, triggers]);

  const onMultiSelectValid = useCallback((valid: boolean) => {
    setTriggersValid(valid);
  }, []);

  const createWebhookClick = (values: any) => {
    if (!triggersValid) return;

    const webhook: Partial<Webhook> = {
      id: values.id,
      disabled: false,
      triggers: generateTriggersFromFormValues(values.triggers, triggers)?.map((trigger) => ({
        scope: trigger.scope,
        type: trigger.type,
      })),
      headers,
      url: values.url.trim(),
    };
    let parsedPayload = null;

    try {
      if (values.payloadData !== null) {
        parsedPayload = JSON.parse(values.payloadData);
      }
    } catch (e) {
      datadogRum.addError(e);
    }

    if (parsedPayload) {
      webhook.payload = parsedPayload;
    }

    createWebhook(webhook);
  };

  const onHeadersChange = useCallback((newHeaders: Record<string, string>) => {
    setHeaders(newHeaders);
  }, []);

  return (
    <FormProvider {...newWebhookFormMethods}>
      <Form onSubmit={handleSubmit(createWebhookClick)}>
        <FormGenerator
          size={'large'}
          gridTemplate={gridTemplate}
          fields={[
            {
              type: 'input',
              props: {
                required: true,
                id: 'id',
                name: 'id',
                standardValidation: [{ type: 'id' }, { type: 'existingId', ids: existingIds }],
                label: webhookTranslations.WEBHOOK_NAME,
              },
            },
            {
              type: 'input',
              props: {
                required: true,
                id: 'url',
                name: 'url',
                label: webhookTranslations.URL,
                validate: {
                  protocol: (value) => {
                    let url: URL | undefined;
                    try {
                      // Add an extra string to end. typing 'http://' does not register as a URL in js.
                      url = new URL(value + 'a');
                    } catch {
                      url = undefined;
                    }
                    return url
                      ? url.protocol !== 'https:'
                        ? webhookTranslations.URL_MUST_BE_HTTPS
                        : true
                      : true;
                  },
                },
              },
            },
            {
              type: 'spacer',
              props: {
                name: 'buttons',
                component: (
                  <Inner>
                    <Separator />
                    <Button
                      variant={Object.keys(headers).length ? 'border' : 'secondary'}
                      size={'large'}
                      onClick={() => setModalOpen(true)}>
                      {webhookTranslations.HEADERS}
                    </Button>
                    <Separator />
                    <Button
                      variant={payloadData ? 'border' : 'secondary'}
                      size={'large'}
                      onClick={() => {
                        setOpenPayloadModal(true);
                      }}>
                      {webhookTranslations.PAYLOAD}
                    </Button>
                    <Separator />
                  </Inner>
                ),
              },
            },
            {
              type: 'multiselect',
              props: {
                buttonSize: 'medium',
                buttonVariant: 'input',
                name: 'triggers',
                items: triggerOptions,
                defaultText: 'Triggers *',
                label: webhookTranslations.TRIGGERS,
                enableSelectAll: true,
                selectAllByDefault: true,
                setFormFieldValid: onMultiSelectValid,
                alwaysRenderContent: true,
              },
            },
          ]}
          submitButton={{ children: uiTranslations.CREATE }}
        />
      </Form>
      {modalOpen && (
        <AddHeadersModal
          subtitle={webhookTranslations.ADD_HEADERS_MODAL_SUBTITLE}
          headersState={[headers, setHeaders]}
          openState={[modalOpen, setModalOpen]}
          onClickSave={onHeadersChange}
          readonly={!canDeleteApplication}
        />
      )}
      {openPayloadModal && <AddPayloadModal openState={[openPayloadModal, setOpenPayloadModal]} />}
    </FormProvider>
  );
};
