import {
  Button,
  Icon,
  SecretValue,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { ReactElement, useState, VFC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import { AppValueVersionItem } from '@src/hooks/react-query/shared-values/sharedValueVersionsTypes';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useRBAC } from '@src/hooks/useRBAC';
import { units } from '@src/styles/variables';

import { SharedAppValueHistoryForm } from './SharedAppValueHistoryForm';

const LoadMoreButton = styled.div.attrs({ className: 'flex-centered' })`
  justify-content: center;
  margin-top: ${units.margin.sm};
`;

const CurrentText = styled.span`
  width: 50px;
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const CurrentFooterText = styled.div`
  margin-top: ${units.margin.md};
`;

interface SharedAppValuesHistoryTableProps {
  keyValue: string;
  onPurge: (changeId: string) => void;
  onRevert: (changeId: string) => void;
  isEnvironmentOverride?: boolean;
}

const getCurrentValueIndexes = (sharedAppValuesHistory: AppValueVersionItem[]) => {
  if (sharedAppValuesHistory.length === 0) {
    return [];
  }
  const currentPurgeChangeId = sharedAppValuesHistory?.[0]?.purgeChangeId;
  return sharedAppValuesHistory
    .filter((valueHistory) => valueHistory.purgeChangeId === currentPurgeChangeId)
    .map((valueHistory) => valueHistory.changeId);
};

const VERSIONS_PAGE_SIZE = 10;

const SharedAppValuesHistoryTable = ({
  keyValue,
  onPurge,
  onRevert,
  isEnvironmentOverride = false,
}: SharedAppValuesHistoryTableProps) => {
  const { orgRole } = useGetUserRoles();
  // fetch data
  const { data, isLoading, isError } = useSharedAppValuesHistoryQuery(isEnvironmentOverride);
  const [versionsDisplayed, setVersionsDisplayed] = useState(VERSIONS_PAGE_SIZE);

  const sharedAppValuesHistory = (data || {})[keyValue] || [];
  const currentValueVersions = getCurrentValueIndexes(sharedAppValuesHistory);

  // rbac
  const canRevertVersion = useRBAC('revertValueVersion');

  // translations
  const { t } = useTranslation();
  const valueHistoryTranslations = t('APP_SETTINGS').VALUE_HISTORY;

  const columns: WalTableColumn<AppValueVersionItem>[] = [
    {
      label: valueHistoryTranslations.EDIT_DATE,
      prop: 'editedDate',
    },
    {
      label: valueHistoryTranslations.EDITED_BY,
      prop: 'editedBy',
    },
    {
      label: valueHistoryTranslations.VALUE,
      prop: 'value',
      fullWidth: true,
      ellipsisTooltip: {
        maxWidth: 280,
        maxCharacters: 10,
        text: (row) =>
          row.data.isSecret && row.data.secretId
            ? valueHistoryTranslations.SECRET_ID + ': ' + row.data.secretId
            : row.data.value || '',
      },
    },
    {
      prop: 'actions',
      justifyContent: 'flex-end',
      template: (version) =>
        currentValueVersions.includes(version.data.changeId) ? (
          <CurrentText>{valueHistoryTranslations.CURRENT}</CurrentText>
        ) : (
          canRevertVersion &&
          !version.data.isDeleted &&
          !version.data.isPurged && (
            <Button
              variant={'secondary'}
              size={'small'}
              onClick={() => onRevert(version.data.changeId)}>
              {valueHistoryTranslations.REVERT}
            </Button>
          )
        ),
    },
  ];

  // if the component is shown in environment overrides screen, show an extra column for source of the value
  if (isEnvironmentOverride) {
    columns.splice(2, 0, {
      label: valueHistoryTranslations.SOURCE,
      prop: 'source',
      template: (version) =>
        version.data.source === 'env'
          ? valueHistoryTranslations.Override
          : valueHistoryTranslations.APP,
    });
  }

  /**
   * Internal component to show footer for expanded content
   * Condition matrix to decide what to show
   * Current value: show the message that purge cannot be shown as its current value
   * if app value in environment screen: Show the message that app values cannot be purged in environment screen
   * Rest of the cases: show the purge button
   */
  const ExpandableContentFooter: VFC<{ valueHistory: AppValueVersionItem; itemIndex: number }> = ({
    valueHistory,
    itemIndex,
  }) => {
    if (orgRole !== 'administrator') return null;

    const source = valueHistory.source;
    // check if version is the current item. The logic is different for env and app.
    const isCurrentItem = isEnvironmentOverride
      ? itemIndex === 0
      : currentValueVersions.includes(valueHistory.changeId);

    if (isCurrentItem) {
      return <CurrentFooterText>{valueHistoryTranslations.CANNOT_PURGE}</CurrentFooterText>;
    } else if (isEnvironmentOverride && source === 'app') {
      return (
        <CurrentFooterText>
          <div>{valueHistoryTranslations.CANNOT_PURGE1}</div>
          <div>{valueHistoryTranslations.CANNOT_PURGE2}</div>
        </CurrentFooterText>
      );
    } else if (!valueHistory.isDeleted && !valueHistory.isPurged) {
      return (
        <Button
          className={'mt-md'}
          onClick={() => onPurge?.(valueHistory.changeId)}
          size={'small'}
          variant={'secondary'}>
          {valueHistoryTranslations.PURGE}
        </Button>
      );
    }
    return null;
  };

  const rowData: WalTableRow<
    AppValueVersionItem | (Omit<AppValueVersionItem, 'value'> & { value: ReactElement })
  >[] = sharedAppValuesHistory.slice(0, versionsDisplayed).map((valueHistory, index) => ({
    data: valueHistory.isDeleted
      ? {
          ...valueHistory,
          value: (
            <div className={'flex'}>
              <Icon name={'delete'} className={'mr-sm'} />
              {valueHistoryTranslations.DELETED}
            </div>
          ),
        }
      : valueHistory.isPurged
        ? {
            ...valueHistory,
            value: (
              <div className={'flex'}>
                <Icon name={'warning'} overrideColor={'yellow'} className={'mr-sm'} />
                {valueHistoryTranslations.PURGED_ON} {valueHistory.purgeDetails.purgedDate}
              </div>
            ),
          }
        : valueHistory.isSecret && valueHistory.secretId
          ? { ...valueHistory, value: <SecretValue secretVersion={valueHistory.secretId} /> }
          : valueHistory,

    expandableContent: valueHistory.isDeleted ? undefined : (
      <SharedAppValueHistoryForm
        historyItem={valueHistory}
        showBlockquote={valueHistory.isPurged}
        footer={<ExpandableContentFooter valueHistory={valueHistory} itemIndex={index} />}
      />
    ),
  }));

  return isLoading ? (
    <Trans defaults={valueHistoryTranslations.LOADING} values={{ keyValue }} />
  ) : isError ? (
    <Trans defaults={valueHistoryTranslations.ERROR} values={{ keyValue }} />
  ) : sharedAppValuesHistory.length > 0 ? (
    <>
      {isEnvironmentOverride && (
        <>
          <div className={'mt-md'}>{valueHistoryTranslations.SUB_TITLE_TABLE1}</div>
          <div className={'mt-md'}>{valueHistoryTranslations.SUB_TITLE_TABLE2}</div>
        </>
      )}
      <WalTable
        className={'mt-md'}
        caption={t('APP_SETTINGS.VALUE_HISTORY.TITLE', { keyValue })}
        rows={rowData}
        columns={columns}
        tableStyle={'expandable'}
      />
      {versionsDisplayed < sharedAppValuesHistory.length && (
        <LoadMoreButton>
          <Button
            variant={'secondary'}
            onClick={() => setVersionsDisplayed(versionsDisplayed + VERSIONS_PAGE_SIZE)}>
            {valueHistoryTranslations.LOAD_MORE}
          </Button>
        </LoadMoreButton>
      )}
    </>
  ) : (
    <p>{valueHistoryTranslations.NO_VERSIONS}</p>
  );
};
export default SharedAppValuesHistoryTable;
