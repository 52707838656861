import useApplicationsQuery from './react-query/applications/queries/useApplicationsListQuery';

const useFilterApplications = (
  filterValue: string | undefined,
  sorting: 'create-date' | 'name' = 'create-date'
) => {
  // React Query
  const {
    isLoading: applicationsLoading,
    isSuccess: applicationsLoaded,
    data: applications = [],
  } = useApplicationsQuery(sorting);

  return {
    applicationsLoading,
    applicationsLoaded,
    appCount: applications.length,
    filteredApplications: filterValue
      ? applications.filter((application) =>
          application.name.toLowerCase()?.includes(filterValue.toLowerCase())
        )
      : applications,
  };
};

export default useFilterApplications;
