import { WalInput } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface GCPFieldsProps {
  isNew?: boolean;
  namePrefix?: string;
}

const GCPFields = ({ isNew, namePrefix }: GCPFieldsProps) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.GCP;
  const uiTranslations = t('UI');

  return (
    <WalInput
      name={namePrefix ? `${namePrefix}-token` : 'token'}
      isMonacoEditor
      monacoEditorFileExtension={'json'}
      label={isNew ? formTranslations.TOKEN_LABEL : formTranslations.TOKEN_LABEL_EDITING}
      labelAbove={!isNew}
      placeholder={!isNew ? '••••••••••' : undefined}
      required={isNew}
      validate={{
        validateJSON: (value: string) => {
          try {
            return value ? JSON.parse(value) : true;
          } catch (_) {
            return uiTranslations.INVALID_JSON;
          }
        },
      }}
      rows={8}
    />
  );
};

export default GCPFields;
