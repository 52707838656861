import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { KeyValue } from '@src/models/general';
import { units } from '@src/styles/variables';

import { Button } from '../../base/Button/Button';
import { WalLabel } from '../../base/Label';
import { AutoCompleteOptions } from '../AutoCompleteInput/AutoCompleteInput';
import { KeyValidation, KeyValueEntry, ValueValidation } from './components/KeyValueEntry';

const Title = styled.h5`
  margin: ${units.margin.md} 0;
`;

const AddButton = styled(Button)`
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.lg};
`;

const DeleteButton = styled(Button)`
  margin-left: ${units.margin.sm};
`;

const LabelsWrapper = styled.div<{ deleteButtonEnabled?: boolean }>`
  display: grid;
  width: 100%;
  grid-column-gap: ${rem(1)};
  ${({ deleteButtonEnabled }) =>
    deleteButtonEnabled
      ? css`
          grid-template-columns: repeat(2, 1fr) ${rem(50)};
        `
      : css`
          grid-template-columns: repeat(2, 1fr);
        `}
`;

const Entry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem(1)};
`;

interface KeyValueEntriesProps {
  group: { name: string; label?: string };
  addButtonText?: string;
  entries: KeyValue[];
  errorMoreInformationLink?: string;
  pairValidations?: PairValidation[];
  keyValidation?: KeyValidation;
  editingEnabled: boolean;
  valueValidation?: ValueValidation;
  title?: string;
  autocompleteOptions?: AutoCompleteOptions;
  onFieldNumberChanged?: (fieldsNumber: number) => void;
}

interface ValidationRule {
  /** The regex to validate against */
  value: string;
  /** The message to be displayed if the regex fails */
  message?: string;
}

export interface PairValidation {
  key: ValidationRule;
  value: ValidationRule;
}

export const KeyValueEntries = ({
  autocompleteOptions,
  title,
  group,
  addButtonText,
  entries,
  errorMoreInformationLink,
  keyValidation,
  valueValidation,
  editingEnabled,
  pairValidations,
  onFieldNumberChanged,
}: KeyValueEntriesProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Form
  const { setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray<Record<string, KeyValue[]>>({
    name: group.name,
  });

  // Component state
  const [defaultEntriesSet, setDefaultEntriesSet] = useState(false);

  useEffect(() => {
    if (!entries.length || defaultEntriesSet) return;

    // Switch to `replace` once the necassary changes have been completed - WAL-4455.
    setValue(
      group.name,
      entries.map((entry) => ({
        key: entry.key,
        value: entry.value,
      }))
    );
  }, [setValue, entries, defaultEntriesSet, group.name]);

  useEffect(() => {
    if (fields.length) {
      setDefaultEntriesSet(true);
    }
  }, [fields.length]);

  const addNewEntry = () => {
    append({
      key: '',
      value: '',
    });
    if (onFieldNumberChanged) {
      onFieldNumberChanged(fields.length + 1);
    }
  };

  const removeEntry = (index: number) => {
    remove(index);
    if (onFieldNumberChanged) {
      onFieldNumberChanged(fields.length - 1);
    }
  };

  return (
    <>
      <Title>{title}</Title>
      {fields.length > 0 && (
        <LabelsWrapper deleteButtonEnabled={editingEnabled}>
          <WalLabel>{uiTranslations.KEY_LABEL}</WalLabel>
          <WalLabel>{uiTranslations.VALUE_LABEL}</WalLabel>
        </LabelsWrapper>
      )}
      {fields.map((field, index) => (
        <Entry key={field.id}>
          <KeyValueEntry
            group={group}
            index={index}
            errorMoreInformationLink={errorMoreInformationLink}
            keyValidation={keyValidation}
            valueValidation={valueValidation}
            existingKeys={entries.filter((e) => e.key !== field.key).map((e) => e.key)}
            editingEnabled={editingEnabled}
            pairValidations={pairValidations}
            autoCompleteOptions={autocompleteOptions}
          />
          {editingEnabled && (
            <DeleteButton
              iconLeft={'delete'}
              variant={'secondary'}
              ariaLabel={`${uiTranslations.DELETE} ${group.label} ${field.key ? field.key : index}`}
              onClick={() => removeEntry(index)}
            />
          )}
        </Entry>
      ))}
      {editingEnabled && (
        <AddButton
          ariaLabel={!addButtonText ? uiTranslations.ADD_ENTRY : undefined}
          onClick={addNewEntry}
          iconLeft={'plus'}
          variant={'secondary'}
          innerWrapperNotFullWidth>
          {addButtonText}
        </AddButton>
      )}
    </>
  );
};
