import { DropdownItem, Icon, WalDropdownMenu } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useWorkloadProfilesListQuery from '@src/hooks/react-query/workload-profiles/queries/useWorkloadProfilesListQuery';
import { useDecision } from '@src/hooks/useDecision';
import { ManagedProfile, WorkloadProfile } from '@src/models/workload-profile';

const ExternalResourceItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface WorkloadDropdownProps {
  onClick: (profile: WorkloadProfile) => void;
  currentSelectedProfile?: WorkloadProfile | ManagedProfile;
  className?: string;
}

const WorkloadDropdown = ({
  onClick,
  className,
  currentSelectedProfile,
}: WorkloadDropdownProps) => {
  // React Query
  const { data: workloadProfiles = [] } = useWorkloadProfilesListQuery();

  // Optimizely
  const [managedWorkloadsDecision] = useDecision('managed-workloads');

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const workloadTranslations = t('VIEW_WORKLOADS');

  const managedProfiles: DropdownItem<WorkloadProfile | string | ManagedProfile>[] = [
    {
      label: workloadTranslations.MANAGED,
      value: 'managed',
      separatorItem: true,
      id: 'managed',
    },
    {
      label: workloadTranslations.RABBITMQ,
      value: { id: 'rabbitmq', managed: true },
      id: 'rabbitmq',
      hideFromList: currentSelectedProfile?.id === 'rabbitmq',
      component: (
        <ExternalResourceItemWrapper>
          <Icon marginRight={'xs'} name={'rabbitmq'} />
          <span>{workloadTranslations.RABBITMQ}</span>
        </ExternalResourceItemWrapper>
      ),
    },
    {
      label: workloadTranslations.REDIS,
      value: { id: 'redis', managed: true },
      id: 'redis',
      hideFromList: currentSelectedProfile?.id === 'redis',
      component: (
        <ExternalResourceItemWrapper>
          <Icon marginRight={'xs'} name={'redis'} />
          <span>{workloadTranslations.REDIS}</span>
        </ExternalResourceItemWrapper>
      ),
    },
    {
      label: workloadTranslations.MARIADB,
      value: { id: 'mariadb', managed: true },
      id: 'mariadb',
      hideFromList: currentSelectedProfile?.id === 'mariadb',
      component: (
        <ExternalResourceItemWrapper>
          <Icon marginRight={'xs'} name={'mariadb'} />
          <span>{workloadTranslations.MARIADB}</span>
        </ExternalResourceItemWrapper>
      ),
    },
    {
      label: workloadTranslations.ELASTICSEARCH,
      value: { id: 'elasticsearch', managed: true },
      id: 'elasticsearch',
      hideFromList: currentSelectedProfile?.id === 'elasticsearch',
      component: (
        <ExternalResourceItemWrapper>
          <Icon marginRight={'xs'} name={'elasticsearch'} />
          <span>{workloadTranslations.ELASTICSEARCH}</span>
        </ExternalResourceItemWrapper>
      ),
    },
    {
      label: workloadTranslations.MONGODB,
      value: { id: 'mongodb', managed: true },
      id: 'mongodb',
      hideFromList: currentSelectedProfile?.id === 'mongodb',
      component: (
        <ExternalResourceItemWrapper>
          <Icon marginRight={'xs'} name={'mongodb'} />
          <span>{workloadTranslations.MONGODB}</span>
        </ExternalResourceItemWrapper>
      ),
    },
  ];

  const items: DropdownItem<WorkloadProfile | string | ManagedProfile>[] = [
    {
      label: workloadTranslations.DEFAULT_WORKLOAD_PROFILE,
      value: 'default',
      separatorItem: true,
      id: 'default',
      hideFromList:
        !workloadProfiles?.filter((p) => p.id === 'default-module').length ||
        currentSelectedProfile?.id === 'default-module',
    },
    ...workloadProfiles
      .filter((p) => p.id === 'default-module' && currentSelectedProfile?.id !== 'default-module')
      .map((p) => ({
        label: p.id,
        value: p,
        id: p.id,
      })),
    {
      label: workloadTranslations.CUSTOM_WORKLOAD_PROFILES,
      value: 'custom',
      separatorItem: true,
      id: 'custom',
      hideFromList: !workloadProfiles?.filter((p) => p.org_id !== 'humanitec').length,
    },
    ...workloadProfiles
      .filter((p) => p.org_id !== 'humanitec')
      .map((p) => ({
        label: p.id,
        value: p,
        id: p.id,
      })),
    {
      label: workloadTranslations.JOBS,
      value: 'default',
      separatorItem: true,
      id: 'default',
      hideFromList: !workloadProfiles?.filter(
        (p) =>
          p.id !== 'default-module' &&
          p.org_id === 'humanitec' &&
          currentSelectedProfile?.id !== p.id
      ).length,
    },
    ...workloadProfiles
      .filter(
        (p) =>
          p.id !== 'default-module' &&
          p.org_id === 'humanitec' &&
          currentSelectedProfile?.id !== p.id
      )
      .map((p) => ({
        label: p.id,
        value: p,
        id: p.id,
      })),
    ...managedProfiles.map((p) => ({ ...p, hideFromList: !managedWorkloadsDecision.enabled })),
  ];

  return (
    <WalDropdownMenu
      className={className}
      buttonSize={'small'}
      items={items}
      maxHeight={200}
      defaultText={uiTranslations.CHANGE}
      overflowScrollable
      onItemClick={(id, profile) =>
        typeof profile !== 'string' && onClick(profile.value as WorkloadProfile)
      }
      alwaysShowDefaultText
    />
  );
};

export default WorkloadDropdown;
