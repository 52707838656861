import { WarningSection } from '@humanitec/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';

interface EnvironmentValueSetOutdatedWarningProps {
  environment?: Environment;
  lastDeployment?: DeploymentObject;
}
const EnvironmentValueSetOutdatedWarning = ({
  environment,
}: EnvironmentValueSetOutdatedWarningProps) => {
  const { t: tViewApplicationList } = useTranslation('viewEnvironment');
  const valueSetWarningTranslations = tViewApplicationList('VALUE_SET_WARNING');
  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);

  return (
    <>
      <WarningSection
        className={'mb-lg'}
        disableDefaultMargin
        title={valueSetWarningTranslations.VALUE_SET_OUTDATED_WARNING_TITLE}
        actionButton={{
          text: valueSetWarningTranslations.REDEPLOY_LATEST_VALUES_SECRETS,
          onClick: () => setDeploymentOverviewModalOpen(true),
          isPrimary: true,
        }}>
        <span className={'mb-md'}>
          {valueSetWarningTranslations.VALUE_SET_OUTDATED_WARNING_TEXT}
        </span>
      </WarningSection>
      {deploymentOverviewModalOpen && environment?.last_deploy && (
        <DeploymentOverviewModal
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          environment={environment}
          deploymentType={{
            type: 'redeploy',
            data: environment.last_deploy,
            deployLatestValueSet: true,
          }}
        />
      )}
    </>
  );
};
export default EnvironmentValueSetOutdatedWarning;
