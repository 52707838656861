import { RefObject } from 'react';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { WalCard } from '..//Card/Card';
import { WalLabel } from '..//Label';
import WarningSection, { WarningSectionProps } from '..//WarningSection/WarningSection';

const ErrorMessage = styled.p`
  white-space: pre-line;
`;

const ErrorDetails = styled.pre`
  margin: 0;
  font-size: ${units.fontSize.sm};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

interface ErrorWarningProps extends Pick<WarningSectionProps, 'disableDefaultMargin' | 'mode'> {
  /** the error message from the API */
  message?: string;
  /** the error code from the API */
  code?: string;
  /** error details as a string */
  details?: string;
  /** the react reference of the error component */
  ref?: RefObject<any>;
  className?: string;
  responseLabel?: string;
}

const ErrorWarning = ({
  code,
  message,
  ref,
  mode,
  details,
  disableDefaultMargin,
  className,
  responseLabel,
}: ErrorWarningProps) => {
  return (
    <WarningSection
      mode={mode || 'warning'}
      className={className}
      disableDefaultMargin={disableDefaultMargin}
      showIcon>
      <div>
        {code && (
          <p ref={ref} className={'txt-md mb-sm'}>
            {code}
          </p>
        )}
        {message && <ErrorMessage>{message}</ErrorMessage>}
        {details && (
          <div className={'flex-column mt-md'}>
            <WalLabel>{responseLabel || 'Error details'}</WalLabel>
            <WalCard cardStyle={'transparent'}>
              <ErrorDetails>{details}</ErrorDetails>
            </WalCard>
          </div>
        )}
      </div>
    </WarningSection>
  );
};

export default ErrorWarning;
