import { WalInput } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

const AWSRoleFields = ({ isNew, namePrefix }: { isNew?: boolean; namePrefix: string }) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.AWS_ROLE;

  return (
    <>
      <WalInput
        name={`${namePrefix}-awsRole`}
        className={'mb-md'}
        labelAbove={!isNew ? true : false}
        label={formTranslations.AWS_ROLE}
        required={isNew ? true : false}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
      <WalInput
        name={`${namePrefix}-externalId`}
        labelAbove={!isNew ? true : false}
        label={formTranslations.EXTERNAL_ID}
        required={isNew ? true : false}
        placeholder={!isNew ? '••••••••••' : undefined}
      />
    </>
  );
};

export default AWSRoleFields;
