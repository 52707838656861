import { Text, WalLabel } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';
import useGetUserByIdQuery from '@src/hooks/react-query/user/useGetUserByIdQuery';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

export const ResourceDefinitionBasicInfo = () => {
  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  // React Query
  const { data: resourceDefinition } = useResourceDefinitionByIdQuery();

  const nonDefaultResource = !resourceDefinition?.is_default;

  const { data: createdByUser } = useGetUserByIdQuery(
    (nonDefaultResource && resourceDefinition?.created_by) || ''
  );

  return (
    !resourceDefinition?.is_default && (
      <div className={'flex-centered mb-xl'} data-testid={'resource-definition-creation-info'}>
        <div className={'flex-column mr-xl'}>
          <WalLabel>{resourcesTranslations.RESOURCE_TYPE}</WalLabel>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {resourceDefinition?.type}
          </Text>
        </div>
        <div className={'flex-column mr-xl'}>
          <WalLabel>{resourcesTranslations.CREATED_AT}</WalLabel>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {formatDate(
              resourceDefinition?.created_at,
              DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
            )}
          </Text>
        </div>
        {createdByUser?.name && (
          <div className={'flex-column mr-xl'}>
            <WalLabel>{resourcesTranslations.CREATED_BY}</WalLabel>
            <Text size={'base'} className={'mt-sm flex-centered'}>
              {createdByUser.name}
            </Text>{' '}
          </div>
        )}
      </div>
    )
  );
};
