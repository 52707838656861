import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';
import { generateDeploymentURL } from '@src/utilities/navigation';

const Container = styled(PageContainer)`
  flex: auto;
  padding-top: ${units.padding.lg};
  padding-bottom: ${units.padding.lg};
  font-size: ${units.fontSize.sm};
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.baseBrightest};
`;

const OldDeploymentBanner = () => {
  // Context
  const { onLatestDeployment } = useDeploymentOrDeltaContext();

  // React Query
  const { data: environment } = useEnvironmentQuery();

  // Router hooks
  const { orgId, appId, envId, deployId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t } = useTranslation('viewDeployment');
  const translations = t('OLD_DEPLOYMENT_BANNER');

  return (
    deployId &&
    !onLatestDeployment && (
      <Wrapper>
        <Container>
          <span className={'mr-xs'}>{translations.OLD_DEPLOYMENT}</span>
          {environment?.last_deploy?.id && (
            <Link
              to={generateDeploymentURL(
                orgId,
                appId,
                envId,
                environment?.last_deploy?.id,
                'workloads'
              )}>
              {translations.VIEW_LATEST}({environment?.last_deploy?.id})
            </Link>
          )}
        </Container>
      </Wrapper>
    )
  );
};

export default OldDeploymentBanner;
