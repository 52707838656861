import { WalModal } from '@humanitec/ui-components';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface PauseEnvironmentModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  content: ReactNode;
  onPause: () => void;
  onResume: () => void;
  onCancel: () => void;
  isEnvironmentPaused?: boolean;
  isPauseEnvironmentPending?: boolean;
}

const PauseEnvironmentModal = ({
  openState,
  content,
  onPause,
  onResume,
  onCancel,
  isEnvironmentPaused,
  isPauseEnvironmentPending,
}: PauseEnvironmentModalProps) => {
  // Components state
  const [open, setOpen] = openState;
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <WalModal
      title={
        isEnvironmentPaused ? uiTranslations.RESUME_ENVIRONMENT : uiTranslations.PAUSE_ENVIRONMENT
      }
      openState={[open, setOpen]}
      content={content}
      actions={{
        main: {
          text: isEnvironmentPaused
            ? uiTranslations.RESUME_ENVIRONMENT
            : uiTranslations.PAUSE_ENVIRONMENT,
          props: {
            iconLeft: {
              name: isEnvironmentPaused ? 'play' : 'pause',
              disableInvert: true,
              size: 12,
            },
            loading: isPauseEnvironmentPending,
            onClick: isEnvironmentPaused ? onResume : onPause,
          },
        },
        cancel: {
          props: {
            onClick: onCancel,
          },
        },
      }}
    />
  );
};

export default PauseEnvironmentModal;
