import { Button, Icon, MenuItem, WalModal, WalTable, WalTableRow } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import FilterOrgMembers from '@src/components/shared/FilterOrgMembers/FilterOrgMembers';
import useRoleCreateMutation from '@src/hooks/react-query/roles/mutations/useRoleCreateMutation';
import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { EnvironmentType } from '@src/models/environment-type';
import { EnvTypeRoles, OrgRoles, Role } from '@src/models/role';

interface ManageDeployersModalProps {
  envType: EnvironmentType;
  envTypeRoles: Role<EnvTypeRoles>[];
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const ManageDeployersModal = ({ envType, envTypeRoles, openState }: ManageDeployersModalProps) => {
  // i18n
  const { t } = useTranslation();
  const { orgRole } = useGetUserRoles();
  const title =
    orgRole === 'administrator'
      ? t('MANAGE_ENV_TYPES.MANAGE_DEPLOYERS_MODAL.HEADING', { replace: { name: envType.id } })
      : t('MANAGE_ENV_TYPES.VIEW_DEPLOYERS_MODAL.HEADING', { replace: { name: envType.id } });
  const uiTranslations = t('UI');
  const envTypeTranslations = t('MANAGE_ENV_TYPES');

  // Mutations
  const { mutate: createRole } = useRoleCreateMutation({ type: 'env-types', id: envType.id });
  const { mutate: deleteRole } = useRoleDeleteMutation({ type: 'env-types', id: envType.id });

  const onItemClick = (item: MenuItem<Role<OrgRoles>>) => {
    createRole({ id: item.value.id, role: 'deployer' });
  };

  const deleteUserClick = (roleId: string) => {
    deleteRole(roleId);
  };

  return (
    <WalModal
      title={title}
      titleInfoPopUp={{
        text: envTypeTranslations.MANAGE_DEPLOYERS_INFO_TEXT,
        moreInformationLink: envTypeTranslations.MANAGE_DEPLOYERS_INFO_LINK,
      }}
      openState={openState}
      disableClickOutside
      disableInitialFocus
      size={'large'}
      content={
        <div tabIndex={0}>
          {orgRole === 'administrator' && (
            <FilterOrgMembers
              onItemClick={onItemClick}
              existingIds={envTypeRoles?.map((r) => r.id)}
            />
          )}
          <WalTable
            caption={envTypeTranslations.TABLE_CAPTION}
            rows={
              envTypeRoles?.map(
                (role): WalTableRow => ({
                  data: role,
                })
              ) ?? []
            }
            tableRowStyle={'transparent'}
            columns={[
              {
                prop: 'name',
                label: 'Name',
              },
              {
                prop: 'email',
                label: 'Email',
                justifyContent: 'flex-start',
              },
              {
                prop: 'type',
                label: 'User type',
                template: (row) => <Icon name={row.data.type === 'service' ? 'robot' : 'person'} />,
              },
              {
                prop: 'actions',
                template: (row) =>
                  orgRole === 'administrator' ? (
                    <Button
                      size={'small'}
                      variant={'secondary'}
                      iconLeft={'remove'}
                      ariaLabel={`${envTypeTranslations.REMOVE_DEPLOYER} ${row.data.name}`}
                      onClick={() => deleteUserClick(row.data.id)}
                    />
                  ) : undefined,
              },
            ]}
          />
        </div>
      }
      actions={{
        main: {
          text: uiTranslations.DONE,
          props: {
            onClick: () => openState[1](false),
          },
        },
      }}
    />
  );
};

export default ManageDeployersModal;
