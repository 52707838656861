import { MatchParams } from '@src/models/routing';

export type OrgSections =
  | 'images'
  | 'registries'
  | 'accounts'
  | 'environment-types'
  | 'service-users'
  | 'org-members';

export const generateDraftURL = (
  orgId: string,
  appId: string,
  envId: string,
  deltaId: string,
  workloadsOrResources: 'workloads' | 'shared-resources'
) => `${generateAppURL(orgId, appId, envId)}/draft/${deltaId}/${workloadsOrResources}`;

export const generateDeploymentURL = (
  orgId: string,
  appId: string,
  envId: string,
  deploymentId: string,
  workloadsOrResources: 'workloads' | 'shared-resources'
) => `${generateAppURL(orgId, appId, envId)}/deploys/${deploymentId}/${workloadsOrResources}`;

export const generateWorkloadURL = (
  orgId: string,
  appId: string,
  envId: string,
  deploymentId: string,
  workloadId: string
) => `${generateDeploymentURL(orgId, appId, envId, deploymentId, 'workloads')}/${workloadId}`;

export const generateAppSettingsURL = (orgId: string, appId: string) =>
  `${generateAppURL(orgId, appId)}/settings`;

export const generateEnvironmentSettingsURL = (orgId: string, appId: string, envId: string) =>
  `${generateAppURL(orgId, appId, envId)}/settings`;

export const generateCompareDeploymentsURL = (
  orgId: string,
  appId: string,
  envId: string,
  leftDeploymentId: string,
  rightDeploymentId: string
) =>
  `${generateAppURL(
    orgId,
    appId,
    envId
  )}/compare-deployments/${leftDeploymentId}/${rightDeploymentId}`;

export const generateAppsListURL = (orgId: string) => `/orgs/${orgId}/apps`;

export const generateAppURL = (orgId: string, appId?: string, envId?: string) => {
  let url = `/orgs/${orgId}`;
  if (appId) url += `/apps/${appId}`;
  if (envId) url += `/envs/${envId}`;
  return url;
};

export const generateProfileSettingsURL = () => `/profile-settings`;

export const generateSettingsURL = (orgId: string, section?: OrgSections) => {
  let url = `/orgs/${orgId}`;
  if (section) url += `/${section}`;
  return url;
};

export const generateInviteURL = (orgId: string) => `/orgs/${orgId}/invite`;

export const generateResourcesUrl = (orgId: string) => `/orgs/${orgId}/resources`;
export const generateResourceDefinitionUrl = (orgId: string, defId: string) =>
  `/orgs/${orgId}/resources/definitions/${defId}/usage`;
export const generateResourceDefinitionVersionUrl = (
  orgId: string,
  defId: string,
  versionId?: string
) => `/orgs/${orgId}/resources/definitions/${defId}/versions${versionId ? `/${versionId}` : ''}`;

export const generatePipelinesURL = (
  orgId?: string,
  appId?: string,
  pipelineId?: string,
  pipelineRunId?: string
) => {
  let url = `/orgs/${orgId}`;

  if (appId) {
    url += `/apps/${appId}`;

    if (pipelineId) {
      url += `/pipelines/${pipelineId}`;
      if (pipelineRunId) {
        url += `/runs/${pipelineRunId}`;
      }
    }
  }

  return url;
};

export const generateContainerURL = (
  params: MatchParams,
  moduleId: string,
  containerId?: string
) => {
  let URL = generateDraftURL(params.orgId, params.appId, params.envId, params.deltaId, 'workloads');
  if (params.deployId) {
    URL = generateDeploymentURL(
      params.orgId,
      params.appId,
      params.envId,
      params.deployId,
      'workloads'
    );
  }
  URL = `${URL}/${moduleId}`;
  if (containerId) {
    URL = `${URL}/containers/${containerId}`;
  }
  return URL;
};

export const generateEnvPath = (appId: string, envId: string) => `/apps/${appId}/envs/${envId}`;
