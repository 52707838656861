import { DropdownItem, MonacoEditor, WalDropdownMenu } from '@humanitec/ui-components';
import { ReactNode, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components/macro';

import { ResourceForm } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { DynamicFormSchema } from '@src/types/dynamic-form';
import { useWalhallForm } from '@src/utilities/form';

import ThemeSwitcher from '../ThemeSwitcher';

const Wrapper = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  height: 100vh;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: ${units.padding.md};
`;

const EditorWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Editor = styled(MonacoEditor)`
  flex: 1;
`;

const Theme = styled(ThemeSwitcher)`
  padding: ${units.padding.lg};
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface WYSIWYGProps<T> {
  renderedContent: ReactNode;
  defaultEditorContent: T;
  onEditorContentChange: (data: T) => void;
  examples: DropdownItem<DynamicFormSchema>[];
}

const WYSIWYG = <T extends unknown>({
  renderedContent,
  defaultEditorContent,
  onEditorContentChange,
  examples,
}: WYSIWYGProps<T>) => {
  // Form
  const formMethods = useWalhallForm<ResourceForm>();

  const editorFormMethods = useWalhallForm<{ examples: string; editorContent: string }>();
  const { setError } = editorFormMethods;
  const { reset } = formMethods;

  // Component state
  const [parsedContent, setParsedContent] = useState<T>(defaultEditorContent);
  const [editorValue, setEditorValue] = useState<string>();

  useEffect(() => {
    setEditorValue(JSON.stringify(defaultEditorContent, null, 2));
  }, [defaultEditorContent, setEditorValue]);

  const onEditorChange = (value: string) => {
    try {
      setParsedContent(JSON.parse(value));
    } catch (_) {
      setError('editorContent', { message: 'Invalid JSON' });
    }
  };

  useEffect(() => {
    reset({});
  }, [parsedContent, reset]);

  useEffect(() => {
    onEditorContentChange(parsedContent);
  }, [parsedContent, onEditorContentChange]);

  const onMenuSelect = (id: string, item: DropdownItem<any>) => {
    setEditorValue(JSON.stringify(item.value, null, 2));
    try {
      setParsedContent(item.value);
    } catch (_) {
      setError('editorContent', { message: 'Invalid JSON' });
    }
  };

  return (
    <Wrapper>
      <EditorWrapper>
        <FormProvider {...editorFormMethods}>
          <MenuWrapper>
            <Theme />
            <WalDropdownMenu
              name={'examples'}
              defaultText={'Change example'}
              items={examples}
              onItemClick={onMenuSelect}
            />
          </MenuWrapper>
          <Editor
            width={'100%'}
            value={editorValue}
            onChange={(value) => value && onEditorChange(value)}
            hideSyntaxDropdown
            fileExtension={'json'}
          />
        </FormProvider>
      </EditorWrapper>
      <FormWrapper>{renderedContent}</FormWrapper>
    </Wrapper>
  );
};

export default WYSIWYG;
