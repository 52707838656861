import {
  Button,
  WalDropdownMenu,
  WalInput,
  WalModal,
  WarningSection,
} from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useRoleUpdateMutation from '@src/hooks/react-query/roles/mutations/useRoleUpdateMutation';
import useSendInvitations from '@src/hooks/react-query/roles/mutations/useSendInvitations';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { useRoleDropdownOptions } from '@src/hooks/useRoleDropdownOptions';
import { OrgRoles, Role } from '@src/models/role';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { EMAIL_PATTERN } from '@src/utilities/string-utility';

import RoleDescriptions from '../../RoleDescriptions';

const DowngradeRoleWarning = styled(WarningSection)`
  margin-bottom: ${units.margin.md};
`;

const AddAnotherButton = styled(Button)`
  align-self: flex-start;
`;

interface OrgRoleModalProps {
  mode:
    | {
        name: 'change';
        user: Role<OrgRoles>;
        buttonVariant: 'main' | 'small';
      }
    | {
        name: 'invite';
      };
}

const MultipleInputs = () => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const { fields, append, remove } = useFieldArray({
    name: 'emails',
  });

  // React Query
  const { data: usersData } = useOrgRolesQuery();

  useEffect(() => {
    append('');
  }, [append]);

  return (
    <>
      {fields.map((field, index) => {
        const isFirst = index === 0;
        return (
          <WalInput
            key={field.id}
            required={isFirst}
            label={authTranslations.EMAIL}
            name={`emails.${index}`}
            pattern={{ value: EMAIL_PATTERN, message: 'invalid' }}
            deleteAction={!isFirst ? () => remove(index) : undefined}
            standardValidation={[
              {
                type: 'existingId',
                ids:
                  (usersData?.filter((user) => user.email).map((user) => user.email) as string[]) ??
                  [],
              },
            ]}
          />
        );
      })}

      <AddAnotherButton
        className={'my-md'}
        iconLeft={'plus'}
        variant={'secondary'}
        size={'small'}
        onClick={() => append('')}>
        {authTranslations.ADD_ANOTHER}
      </AddAnotherButton>
    </>
  );
};

const ModalContent = ({
  mode,
  openState,
}: OrgRoleModalProps & { openState: [boolean, Dispatch<SetStateAction<boolean>>] }) => {
  // Component state
  const [, setOpen] = openState;

  // i18n
  const { t } = useTranslation('orgMembers');
  const { t: tCommon } = useTranslation();
  const translations = t('ORG_MEMBERS_LIST').ORG_ROLE_MODAL;
  const uiTranslations = tCommon('UI');

  // Form
  const { watch } = useFormContext();
  const role = watch('role');
  const emails: string[] = watch('emails');

  const regularUsersRoleOptions = useRoleDropdownOptions();

  // React Query
  const { data: currentUser } = useGetCurrentUserQuery();
  const { mutate: updateRole, isSuccess: isUpdatedSuccessfully } = useRoleUpdateMutation();
  const {
    mutate: sendInvite,
    isPending: sendingInvites,
    isSuccess: invitesSentSuccess,
  } = useSendInvitations();

  const showDowngradeWarning = () => {
    if (mode.name !== 'change') return;
    if (currentUser?.id === mode.user.id) {
      if (
        (mode.user.role === 'administrator' && role !== 'administrator') ||
        (mode.user.role === 'manager' && role === 'member')
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (invitesSentSuccess || isUpdatedSuccessfully) {
      setOpen(false);
    }
  }, [invitesSentSuccess, setOpen, isUpdatedSuccessfully]);

  return (
    <>
      <WalModal
        openState={openState}
        handleFormSubmit={(formValues) => {
          if (mode.name === 'invite') {
            formValues.emails.map((email: string) =>
              sendInvite({
                email,
                role,
              })
            );
          } else if (mode.name === 'change') {
            updateRole({ roleId: mode.user.id, newRole: role });
          }
        }}
        title={mode.name === 'change' ? translations.CHANGE_TITLE : translations.INVITE_USERS}
        content={
          <>
            {mode.name === 'invite' && <MultipleInputs />}
            <WalDropdownMenu
              items={regularUsersRoleOptions}
              name={'role'}
              label={'Role'}
              allowUpdatesToDefaultValue
            />
            <RoleDescriptions
              roleType={'org'}
              items={[
                {
                  role: translations.MEMBER,
                  description: translations.MEMBER_DESC,
                },
                {
                  role: translations.MANAGER,
                  description: translations.MANAGER_DESC,
                },
                {
                  role: translations.ADMINISTRATOR,
                  description: translations.ADMINISTRATOR_DESC,
                },
              ]}
            />
            {showDowngradeWarning() && (
              <DowngradeRoleWarning mode={'alert'}>
                <p>{translations.ARE_YOU_SURE}</p>
                {translations.YOU_WILL_LOSE}
              </DowngradeRoleWarning>
            )}
          </>
        }
        actions={{
          main: {
            text:
              mode.name === 'change'
                ? uiTranslations.SAVE
                : emails.length >= 2
                  ? translations.SEND_INVITES
                  : translations.SEND_INVITE,
            props: {
              type: 'submit',
              loading: sendingInvites,
            },
          },
          cancel: {},
        }}
      />
    </>
  );
};

const OrgRoleModal = ({ mode }: OrgRoleModalProps) => {
  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('ORG_MEMBERS_LIST').ORG_ROLE_MODAL;

  // Component state
  const [open, setOpen] = useState(false);

  // Form
  const formMethods = useWalhallForm({
    values: {
      'email-wrapper': [],
      emails: [],
      email: '',
      role: mode.name === 'change' ? mode.user.role : 'member',
    },
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <div>
      {mode.name === 'change' ? (
        mode.buttonVariant === 'small' ? (
          <Button size={'small'} variant={'secondary'} onClick={() => setOpen(true)}>
            {translations.CHANGE_ROLE}
          </Button>
        ) : (
          mode.buttonVariant === 'main' && (
            <Button iconLeft={'edit'} variant={'primary'} onClick={() => setOpen(true)}>
              {translations.CHANGE_ORG_ROLE}
            </Button>
          )
        )
      ) : (
        <Button size={'medium'} iconLeft={'plus'} variant={'primary'} onClick={() => setOpen(true)}>
          {translations.INVITE_USERS}
        </Button>
      )}
      <FormProvider {...formMethods}>
        <ModalContent mode={mode} openState={[open, setOpen]} />
      </FormProvider>
    </div>
  );
};

export default OrgRoleModal;
