import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AppRoles, EnvTypeRoles, OrgRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';

import useApplicationEnvironmentsQuery from './react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentQuery from './react-query/environments/queries/useEnvironmentQuery';
import useGetCurrentUserQuery from './react-query/user/useGetCurrentUserQuery';
import { useGetUserRoles } from './useGetUserRoles';

const administratorPermissons = ['viewResources', 'viewRegistries', 'testCluster'] as const;
export type AdministratorPermissons = (typeof administratorPermissons)[number];

export type RBACPermissionTypes = keyof ReturnType<typeof usePermissions> | AdministratorPermissons;

interface CustomParams {
  orgId?: string;
  appId?: string;
  envId?: string;
}

/**
 * Internal hook. This is separated from the `useRBAC` hook so we can automatically infer the return type in the useRBAC hook.
 * Guidelines:
 * ```
 * - Be explicit about permission names. Even if the permitted roles are the same for two different features, define it as a separate key/name.
 *   This will improve readability & make it easier to understand what part of the UI it's referring to.
 * - Be explicit about what roles have permssions i.e. positive checks vs negative checks. Negative checks could lead to a new role type being granted permission once it's added.
 *   e.g. (appRole === 'owner' || appRole === 'developer') vs appRole !== 'viewer'
 * - orgType administator has permission to do everything by default. There's no need to include it in the permissions defined here.
 * ```
 */
const usePermissions = (orgRole: OrgRoles, customParams?: CustomParams) => {
  // Router hooks
  const { orgId: routerOrgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const appId = customParams?.appId || routerAppId;
  const orgId = customParams?.orgId || routerOrgId;

  // React Query
  const { data: user } = useGetCurrentUserQuery();
  const { data: applicationEnvironments } = useApplicationEnvironmentsQuery({ orgId, appId });
  const { data: environment } = useEnvironmentQuery({ orgId, appId, envId: customParams?.envId });

  const deployerRoleForAllEnvTypes = useMemo(() => {
    if (!applicationEnvironments?.length || !user) return false;

    const appEnvTypes = applicationEnvironments?.map((env) => env.type);

    const envTypeRolesForApplication: Record<string, EnvTypeRoles> = appEnvTypes.reduce(
      (prevState, envType) => {
        const role = user.roles[`/orgs/${orgId}/env-types/${envType}`] as EnvTypeRoles;

        return role
          ? {
              ...prevState,
              [envType]: role,
            }
          : prevState;
      },
      {}
    );

    if (!Object.keys(envTypeRolesForApplication).length) {
      return false;
    }

    /**
     * An Owner will not be able to delete an App unless they have the Deployer Role for all the Environment Types used in the App.
     */
    return appEnvTypes?.every((envTypeId) => envTypeRolesForApplication[envTypeId] === 'deployer');
  }, [applicationEnvironments, user, orgId]);

  // Selectors
  const appRole = user?.roles?.[`/orgs/${orgId}/apps/${appId}`];

  const envTypeRole = environment
    ? user?.roles?.[`/orgs/${orgId}/env-types/${environment.type}`]
    : undefined;

  // Permissions
  const isAppOwnerOrDeveloperRole = ['owner', 'developer'].includes(appRole as AppRoles);
  const isAppOwner = appRole === 'owner';
  const isDeployer = envTypeRole === 'deployer';

  return {
    ...administratorPermissons.reduce(
      (acc, permission) => ({ ...acc, [permission]: orgRole === 'administrator' }),
      {} as Record<AdministratorPermissons, boolean>
    ),
    createEnvironment: isAppOwnerOrDeveloperRole,
    deleteEnvironment: isAppOwnerOrDeveloperRole && isDeployer,
    accessDraftURL: isAppOwnerOrDeveloperRole,
    pauseEnvironment: isAppOwnerOrDeveloperRole && isDeployer,
    revertValueVersion: isAppOwnerOrDeveloperRole,
    deleteApplication: isAppOwner && deployerRoleForAllEnvTypes,
    createApplication: orgRole === 'manager',
    updateWebhook: isAppOwner,
    editApplication: isAppOwnerOrDeveloperRole,
    deployEnvironment: isAppOwnerOrDeveloperRole && isDeployer,
    viewEnvironmentTypesPage: orgRole === 'manager',
    viewAccountsPage: orgRole === 'manager',
    viewImagesPage: orgRole === 'manager',
    viewServiceUsersPage: orgRole === 'manager',
    viewOrgMembersPage: orgRole === 'manager',
    viewApplications: orgRole === 'manager' || orgRole === 'member',
  };
};

/**
 * @param action Name of the action you want to get the permissions for.
 * @example
 * When naming your return variable when using the hook, prefix with `can`.
 * Permission `pauseEnvironment` will be named `canPauseEnvironment`.
 * ```
 *  const canPauseEnvironment = useRBAC('pauseEnvironment');
 * ```
 * @returns boolean
 */
export const useRBAC = (action?: RBACPermissionTypes, customParams?: CustomParams) => {
  // Selectors
  const { orgRole } = useGetUserRoles({ orgId: customParams?.orgId });

  const permissions = usePermissions(orgRole, customParams);

  // Administrator can access everything
  return (action && permissions[action]) || orgRole === 'administrator';
};
