import { rem } from 'polished';
import React, {
  Dispatch,
  forwardRef,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import { Chip } from '../../base/Chip/Chip';
import { InputSize, ValidationTypes, WalInput } from '../../base/Input/Input';
import { MenuItem, WalMenu } from '../Menu/Menu';

const ComboSelectInput = styled(WalInput)`
  flex: 1;
  min-width: ${rem(80)};
  width: 100%;
  .input-element {
    width: 100%;
    padding: 0;
    border: 0;
    box-shadow: none;
    min-height: unset;
    height: 100%;
  }
`;

const SelectedItems = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
  width: 100%;
  height: max-content;
  padding: ${units.padding.md} ${units.padding.sm};
`;

const ImageChip = styled(Chip)`
  margin-right: ${units.margin.sm};
`;

const FakeInput = styled(WalInput)`
  width: 100%;

  .input-element {
    padding: 0;
  }
  .input-wrapper {
    height: 100%;
    align-items: center;
  }
  .input-wrapper input {
    padding: 0px ${rem(8)};
  }
`;

export interface ComboSelectMenuItem<T = any> extends MenuItem<T> {
  // Combine any strings(with spaces) that should be searched for.
  searchString: string;
  id: string;
}

export interface ComboSelectProps {
  // Note: you can access the ref of this component by passing on like so <ComboSelect ref={ref} ...{OTHER_PROPS} />
  /** List of menu items */
  items: ComboSelectMenuItem[];
  name: string;
  /** Set to true to clear the input */
  resetSelectedItemsState?: [boolean, Dispatch<SetStateAction<boolean>>];
  /** callback when selected items have changed */
  onChange?: (items: string[]) => void;
  onItemClick?: (item: ComboSelectMenuItem) => void;
  /** default selected items
   * when in 'set-selected-value' mode it chooses the first item as the default and also shows the item in the options list
   * In other modes, it makes use of all the defaultValues passed and removes them from the list of options
   */
  defaultValues?: string[];
  /** placeholder to be shown in the filter input */
  inputPlaceholder?: string;
  /** size of the input */
  inputSize?: InputSize;
  readonly?: boolean;
  className?: string;
  label?: string;
  maxHeight?: number;
  maxWidth?: number;
  /**
   * 'combo' will add a chip when an option is selected
   * 'set-selected-value' does not use chips, but just sets the input value to the option that was selected. If a subsequent option is selected, the first selection option will be replaced.
   * 'close-on-click' simply closes the menu on click of an option. The value inputted by the user doesn't change.
   */
  mode?: 'combo' | 'set-selected-value' | 'close-on-click';
  hideMenuWhenNoResults?: boolean;
  standardValidation?: ValidationTypes[];
  /* set to true if using FormGenerator */
  hideError?: boolean;
  hideLabel?: boolean;
  /* whether the ellipsis should be inverted in the chips and the menu items */
  invertEllipsis?: boolean;
  noTooltipChips?: boolean;
  /** the possibility to add chips/options that don't exist in the list of the available options in the menu */
  addCustomChips?: boolean;
}

/**
 * The ComboSelect is a combination of input & menu.
 *
 * The options will be filtered based on the user input.
 * When the user selects an option, the option appears as a chip. The option is then removed from the menu.
 *
 * Chips can be removed by the user. Removed chips will reappear in the menu.
 */
export const ComboSelect = forwardRef((props: ComboSelectProps, ref) => {
  const {
    items = [],
    defaultValues, // only the first value is used as the defaultValue in 'set-selected-value' mode
    inputPlaceholder,
    resetSelectedItemsState,
    name,
    onChange,
    onItemClick,
    inputSize,
    readonly,
    className,
    label,
    maxHeight,
    maxWidth,
    mode = 'combo',
    hideMenuWhenNoResults,
    standardValidation,
    hideError,
    hideLabel,
    invertEllipsis,
    noTooltipChips,
    addCustomChips,
  } = props;

  // Form
  const { watch, setValue, resetField } = useFormContext();
  const inputName = name || 'combo-select-filter';

  // i18n
  const { t } = useTranslation();
  const translations = t('UI');

  // Component state
  const [selectedItems, setSelectedItems] = useState<ComboSelectMenuItem[]>([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState<ComboSelectMenuItem[]>(items);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const inputValue = watch(inputName);
  const resetSelectedItems = resetSelectedItemsState && resetSelectedItemsState[0];
  const setResetSelectedItems = resetSelectedItemsState && resetSelectedItemsState[1];
  const [isInputFocused, setIsInputFocused] = useState(false);

  const dropdownRef: any = useRef();
  const inputRef: any = useRef();
  useImperativeHandle(ref, () => inputRef.current);

  useEffect(() => {
    if (
      items.length > 0 &&
      mode !== 'set-selected-value' &&
      defaultValues &&
      selectedItems.length === 0
    ) {
      const defaultItems = items.filter((item) => defaultValues.includes(item.id));
      // default items that were added by free text entry and don't exist in the autocomplete options
      const defaultItemsNotExistingInOptions = defaultValues
        .filter((value) => !items.find((item) => item.id === value))
        .map((value) => ({ id: value, value, searchString: value, label: value }));
      setSelectedItems([...defaultItems, ...defaultItemsNotExistingInOptions]);
    }
  }, [defaultValues, selectedItems.length, items, mode]);

  useEffect(() => {
    setFilteredMenuItems(
      items.filter(
        (i) =>
          !selectedItems.map((si) => si.id).includes(i.id) &&
          (inputValue ? i.searchString.toLowerCase().includes(inputValue.toLowerCase()) : true)
      )
    );
  }, [items, selectedItems, inputValue]);

  useEffect(() => {
    if (resetSelectedItems && setResetSelectedItems) {
      setSelectedItems([]);
      setResetSelectedItems(false);
    }
  }, [resetSelectedItems, setResetSelectedItems]);

  const handleInputChange = () => {
    if (!menuOpen && filteredMenuItems?.length !== 0) {
      setMenuOpen(true);
    }
  };

  const handleInputClick = () => {
    if (!menuOpen && filteredMenuItems?.length !== 0) {
      setMenuOpen(true);
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const setSelectedChipsValue = (selectedChips: ComboSelectMenuItem[]) => {
    setValue(
      `${name}SelectedChips`,
      selectedChips.map((i) => i.id)
    );
  };

  const handleMenuItemClick = (item: ComboSelectMenuItem) => {
    if (mode === 'set-selected-value') {
      setMenuOpen(false);
      setValue(inputName, item?.id);
    } else if (mode === 'close-on-click') {
      setMenuOpen(false);
    } else if (item) {
      const newSelectedItems = [...selectedItems, item];
      const newFilteredItems = filteredMenuItems.filter(
        (menuItem: ComboSelectMenuItem) => menuItem.value !== item.value
      );
      setSelectedItems(newSelectedItems);
      setFilteredMenuItems(newFilteredItems);

      onChange?.(newSelectedItems.map((i) => i.id));
      setSelectedChipsValue(newSelectedItems);
      inputRef.current.focus();
      inputRef.current.value = '';
    }

    onItemClick?.(item);
  };

  const handleRemoveChip = (id: string) => {
    const newItems = selectedItems.filter((i) => i.id !== id);
    setSelectedItems(newItems);
    inputRef.current.focus();
    if (onChange) {
      onChange(newItems.map((i) => i.id));
      setSelectedChipsValue(newItems);
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent) => {
    if (
      (event.keyCode === KeyBindings.ENTER || event.keyCode === KeyBindings.TAB) &&
      inputValue !== ''
    ) {
      if (filteredMenuItems[0]) {
        handleMenuItemClick(filteredMenuItems[0]);
      } else if (addCustomChips) {
        setSelectedItems((prevState) => {
          return [
            ...prevState,
            { id: inputValue, label: inputValue, value: inputValue, searchString: inputValue },
          ];
        });
        resetField(inputName);
      }
    }

    if (event.keyCode === KeyBindings.ARROWDOWN) {
      event.preventDefault();
      if (dropdownRef.current && filteredMenuItems.length !== 0) {
        setMenuOpen(true);
        dropdownRef.current.focus();
      }
    }
    if (event.keyCode === KeyBindings.ARROWUP) {
      event.preventDefault();
    }

    const lastItemId = selectedItems?.[selectedItems.length - 1]?.id;

    if (lastItemId && event.keyCode === KeyBindings.BACKSPACE && inputRef.current.value === '') {
      // remove last item
      handleRemoveChip(lastItemId);
    }
  };

  const handleMenuKeyboardIndexOutside = () => {
    inputRef.current.focus();
  };

  const handleInputBlur = useCallback(
    (value: string) => {
      if (value !== '' && addCustomChips) {
        setSelectedItems((prevState) => {
          onChange?.([...prevState.map((item) => item.id), value]);
          return [...prevState, { id: value, label: value, value, searchString: value }];
        });
        resetField(inputName);
      }
    },
    [addCustomChips, inputName, onChange, resetField]
  );

  useEffect(() => {
    const handleDocumentEvent = (event: Event) => {
      if (isInputFocused) {
        if (inputRef.current && event.target && !inputRef.current.contains(event.target)) {
          handleInputBlur(inputRef.current.value);
        }
        setIsInputFocused(false);
      }
    };

    document.addEventListener('click', handleDocumentEvent);
    document.addEventListener('keydown', handleDocumentEvent);

    return () => {
      document.removeEventListener('click', handleDocumentEvent);
      document.removeEventListener('keydown', handleDocumentEvent);
    };
  }, [handleInputBlur, isInputFocused]);

  return (
    <WalMenu
      className={className}
      items={
        mode === 'set-selected-value'
          ? filteredMenuItems
          : filteredMenuItems.filter((item) => !defaultValues?.includes(item.id))
      }
      openState={[menuOpen, setMenuOpen]}
      panelSize={'parent'}
      onItemClick={handleMenuItemClick}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      keyboardIndexOutside={handleMenuKeyboardIndexOutside}
      fullWidth
      menuRef={dropdownRef}
      floatingFocusManagerInitialFocus={-1}
      disableCloseOnClick
      noItemsText={!hideMenuWhenNoResults ? translations.NO_RESULTS_FOUND : undefined}
      disableToggleElementTabIndexAndDefaults
      disabled={readonly}
      invertEllipsis={invertEllipsis}
      mode={'combobox'}
      toggle={
        <FakeInput
          name={inputName}
          label={label}
          fakeInput
          size={inputSize}
          readonly={readonly}
          hideError={hideError}
          hideLabel={hideLabel}
          labelAbove={Boolean(selectedItems.length) || inputValue}>
          <SelectedItems data-testid={'combo-select-selected-items'}>
            {mode === 'combo' &&
              selectedItems.map((value) => (
                <ImageChip
                  key={value.id}
                  id={value.id}
                  label={value.label}
                  variant={'blue'}
                  invertEllipsis={invertEllipsis}
                  noTooltipOnHover={noTooltipChips}
                  onRemove={readonly ? undefined : (val) => handleRemoveChip(val)}
                />
              ))}
            {!readonly && (
              <ComboSelectInput
                defaultValue={mode === 'set-selected-value' ? defaultValues?.[0] : undefined}
                name={inputName}
                hideLabel
                onClick={handleInputClick}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onKeyDown={handleInputKeyDown}
                propagateClickEvent
                placeholder={inputPlaceholder}
                inputRef={inputRef}
                size={inputSize}
                noHover
                autoComplete={'off'}
                hideError
                hideOutline
                standardValidation={standardValidation}
              />
            )}
          </SelectedItems>
        </FakeInput>
      }
    />
  );
});

ComboSelect.displayName = 'ComboSelect';
export default ComboSelect;
