import { WalInput } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AivenAndCloudflareFields = ({ namePrefix }: { namePrefix: string }) => {
  const { t } = useTranslation();
  const formTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS.ACCOUNT_MODAL.AIVEN;

  return (
    <React.Fragment>
      <WalInput
        name={namePrefix ? `${namePrefix}-token` : 'token'}
        label={formTranslations.TOKEN}
        required
      />
    </React.Fragment>
  );
};
