import { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { Button, ButtonSize } from '../../base/Button/Button';
import { CardStyles, WalCard } from '../../base/Card/Card';
import ActionButtons from '../ActionButtons/ActionButtons';
import { ObjectTypeProps } from '../Menu/Menu';

type ItemsAlignmentProps =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit'
  | 'custom';

export interface ViewEditProps {
  /* event handler to call when the confirmation button is clicked */
  onConfirm: (formValues: any) => void;
  /* event handler to call when the delete button is clicked */
  onDelete?: () => void;
  /* event handler to call when the cancel button is clicked */
  onCancel?: () => void;
  /* used for accessibility purposes and should take the name of the section this component is being used in. e.g The value would be 'variables' if the component is used in the Variables section of the app. */
  sectionName: ObjectTypeProps;
  /* shows a menu dropdown in place of an edit button in view mode, when set to true */
  hasDropdown?: boolean;
  /* hides the button that let's you switch to edit mode from view mode, when set to true. */
  readonly?: boolean;
  /* displays a checkmark in place of an edit button in edit mode*/
  hasCheckmark?: boolean;
  /* the content that should be displayed on the left side of the component in edit mode */
  editModeContent: ReactNode;
  /* the content that should be displayed on the left side of the component in view mode */
  viewModeContent: ReactNode;
  /* used to set the align-items property. You can override this by setting a number which represents a margin-top value in pixels. */
  editModeContentAlignment?: ItemsAlignmentProps;
  /* used to set the align-items property. You can override this by setting a number which represents a margin-top value in pixels. */
  viewModeContentAlignment?: ItemsAlignmentProps;
  /* to overide the size of all buttons in the component */
  buttonSize?: ButtonSize;
  /* toggles the visibility of the delete button */
  hasDeleteButton?: boolean;
  cardStyle?: CardStyles;
}

const Container = styled.div<{ contentAlignment: ItemsAlignmentProps }>`
  ${({ contentAlignment }) => {
    return css`
      display: flex;
      justify-content: space-between;
      align-items: ${contentAlignment !== 'custom' ? contentAlignment : 'flex-start'};
    `;
  }}
`;

const StyledButton = styled(Button)`
  margin-right: ${units.margin.sm};
`;

const ContentWrapper = styled.div<{ contentAlignment: ItemsAlignmentProps }>`
  ${({ contentAlignment }) => {
    return css`
      margin-top: ${contentAlignment !== 'custom' ? '0px' : '4px'};
      margin-left: ${units.margin.sm};
      display: flex;
    `;
  }}
`;

const WalCardWrapper = styled(WalCard)<{ showCursorOnHover: boolean }>`
  ${({ showCursorOnHover }) => {
    return css`
      cursor: ${showCursorOnHover === true ? 'pointer' : 'auto'};
    `;
  }}
`;

const ViewModeContentWrapper = styled.div`
  width: 100%;
`;

const ViewEdit = ({
  onConfirm,
  onDelete,
  hasDeleteButton = false,
  viewModeContent,
  editModeContent,
  readonly = false,
  hasCheckmark = false,
  onCancel,
  editModeContentAlignment = 'custom',
  viewModeContentAlignment = 'center',
  sectionName,
  cardStyle,
}: ViewEditProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { handleSubmit, reset } = useFormContext();

  const closeEditMode = () => {
    setIsEditMode(false);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleConfirm = (formValues: unknown) => {
    onConfirm?.(formValues);
    closeEditMode();
  };

  const handleCancel = () => {
    onCancel?.();
    reset();
    closeEditMode();
  };

  const handleSwitchEditModeOnClick = () => {
    return !readonly ? handleEdit() : null;
  };

  const renderLeftSideContent = () => {
    return !isEditMode ? (
      <ViewModeContentWrapper onClick={handleSwitchEditModeOnClick}>
        {viewModeContent}
      </ViewModeContentWrapper>
    ) : (
      editModeContent
    );
  };

  const renderRightSideContent = () => {
    if (isEditMode) {
      return (
        <ContentWrapper
          contentAlignment={isEditMode ? editModeContentAlignment : viewModeContentAlignment}>
          <StyledButton variant={'secondary'} onClick={handleCancel} size={'small'}>
            {uiTranslations.CANCEL}
          </StyledButton>
          <Button
            variant={'primary'}
            iconLeft={hasCheckmark ? 'checkmark' : undefined}
            type={'submit'}
            size={'small'}
            ariaLabel={uiTranslations.CONFIRM}>
            {!hasCheckmark && uiTranslations.CONFIRM}
          </Button>
        </ContentWrapper>
      );
    }
    return !hasDeleteButton ? (
      <Button
        variant={'secondary'}
        iconLeft={'edit'}
        onClick={handleEdit}
        size={'small'}
        ariaLabel={uiTranslations.EDIT}
      />
    ) : (
      <ActionButtons buttons={['edit', 'delete']} onEdit={handleEdit} onDelete={onDelete} />
    );
  };

  return (
    <WalCardWrapper
      cardStyle={cardStyle || 'base'}
      ariaLabel={`${sectionName} entry`}
      showCursorOnHover={!readonly}>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <Container
          contentAlignment={isEditMode ? editModeContentAlignment : viewModeContentAlignment}>
          {renderLeftSideContent()}
          {!readonly && renderRightSideContent()}
        </Container>
      </form>
    </WalCardWrapper>
  );
};

export default ViewEdit;
