import { Button } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import { OrgRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import { generateSettingsURL } from '@src/utilities/navigation';

interface OrgMemberRemoveProps {
  user: Role<OrgRoles>;
}

const OrgMemberRemove = ({ user }: OrgMemberRemoveProps) => {
  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('REMOVE_USER_TAB');

  // React Router
  const navigate = useNavigate();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { mutate: deleteRole, isPending: isDeleting } = useRoleDeleteMutation(undefined, () => {
    navigate(generateSettingsURL(orgId, 'org-members'));
  });

  return (
    <div>
      <div className={'txt-base mb-sm'}>{translations.ARE_YOU_SURE}</div>
      <div className={'txt-sm txt-translucent mb-lg'}>{translations.YOU_WILL_LOSE_ACCESS}</div>
      <Button
        iconLeft={'delete'}
        variant={'danger'}
        onClick={() => deleteRole(user.id)}
        loading={isDeleting}>
        {translations.REMOVE_USER}
      </Button>
    </div>
  );
};

export default OrgMemberRemove;
