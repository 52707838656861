import { Button, Text } from '@humanitec/ui-components';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import useDeletePipelineMutation from '@src/hooks/react-query/pipelines/mutation/useDeletePipelineMutation';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';

export const DeletePipeline = () => {
  const { pipelineId, appId } = useParams();

  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');

  // state
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // react-query
  const { mutate: deletePipeline } = useDeletePipelineMutation();
  const { data: pipeline } = usePipelineDetailsQuery({ id: pipelineId, appId });

  return (
    <div className={'flex-column'}>
      <Text size={'lg'}>{translations.DELETE_PIPELINE_WARNING}</Text>
      <Text size={'sm'} className={'mt-sm flex-centered'} color={'textTranslucent'}>
        {translations.DELETE_PIPELINE_WARNING2}
      </Text>
      <div>
        <Button
          className={'mt-md'}
          type={'submit'}
          variant={'danger'}
          onClick={() => setShowConfirmModal(true)}>
          {translations.DELETE_PIPELINE}
        </Button>
      </div>

      {pipeline?.name && (
        <ConfirmDeleteModal
          state={[showConfirmModal, setShowConfirmModal]}
          deleteConfirmedCallback={deletePipeline}
          customContentComponent={<Trans defaults={translations.DELETE_PIPELINE_CONFIRM_TEXT} />}
          title={`${translations.DELETE_PIPELINE} ${pipeline.name}`}
          deleteButtonText={translations.DELETE}
          inputConfirmation={{
            correctValue: pipeline?.name,
            objectName: 'pipeline',
            propertyToType: `name "${pipeline?.name}"`,
            placeholder: translations.ENTER_PIPELINE_NAME_PLACEHOLDER,
            validateOnChange: true,
          }}
        />
      )}
    </div>
  );
};
