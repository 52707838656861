import { Button } from '@humanitec/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import CloneModal from '@src/components/shared/CloneModal/CloneModal';
import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import ExportManifestModal from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/components/ExportManifestModal/ExportManifestModal';
import { useRBAC } from '@src/hooks/useRBAC';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateCompareDeploymentsURL } from '@src/utilities/navigation';

const DeploymentControlsWrapper = styled.div`
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  column-gap: ${units.margin.sm};
`;

interface DeploymentControlsProps {
  deployment: DeploymentObject;
  environment: Environment;
  lastPastDeploymentId?: string;
}
const DeploymentControls = ({
  deployment,
  lastPastDeploymentId,
  environment,
}: DeploymentControlsProps) => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const deploysTranslations = tViewEnvironment('DEPLOYS');
  // state
  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);
  const [exportManifestModalOpen, setExportManifestModalOpen] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);

  // rbac
  const canDeployEnv = useRBAC('deployEnvironment');
  const canEditApplication = useRBAC('editApplication');

  const isActiveDeployment = environment.last_deploy?.id === deployment.id;

  return (
    <DeploymentControlsWrapper>
      <Button
        variant={'secondary'}
        size={'small'}
        onClick={() => {
          setCloneModalOpen(true);
        }}
        disabled={!canEditApplication}>
        {deploysTranslations.CLONE}
      </Button>
      <Button
        variant={'secondary'}
        size={'small'}
        onClick={() => {
          setExportManifestModalOpen(true);
        }}>
        {deploysTranslations.MANIFEST}
      </Button>
      {environment?.last_deploy && (
        <Button
          variant={'secondary'}
          size={'small'}
          link={{
            to: generateCompareDeploymentsURL(
              orgId,
              appId,
              environment.id,
              deployment.id,
              isActiveDeployment ? lastPastDeploymentId || '0' : environment?.last_deploy?.id
            ),
          }}>
          {deploysTranslations.DIFF}
        </Button>
      )}
      <Button
        disabled={deployment?.status === 'in progress' || !canDeployEnv}
        size={'small'}
        variant={'secondary'}
        onClick={() => {
          setDeploymentOverviewModalOpen(true);
        }}>
        {deployment?.status === 'in progress'
          ? deploysTranslations.CURRENTLY_DEPLOYING
          : deploysTranslations.REDEPLOY}
      </Button>
      {deploymentOverviewModalOpen && (
        <DeploymentOverviewModal
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          environment={environment}
          deploymentType={{ type: 'redeploy', data: deployment }}
        />
      )}
      {cloneModalOpen && (
        <CloneModal fromDeployment={deployment} openState={[cloneModalOpen, setCloneModalOpen]} />
      )}

      {exportManifestModalOpen && (
        <ExportManifestModal
          state={[exportManifestModalOpen, setExportManifestModalOpen]}
          deployment={deployment}
        />
      )}
    </DeploymentControlsWrapper>
  );
};

export default DeploymentControls;
