import { DropdownItem } from '@humanitec/ui-components';

import { PipelineCriteria } from '@src/models/pipeline';

export const criteriaIDStrings = ['env_type', 'deployment_type', 'env_id'] as const;

export type CriteriaLabel = 'Deployment type' | 'Environment type' | 'Environment ID';
export type CriteriaID = 'env_type' | 'deployment_type' | 'env_id';

export const defaultDropdownOptions: DropdownItem<keyof PipelineCriteria>[] = [
  // Options available in the dropdown for a user to add to the currentCriteriaSelections
  { label: 'Environment type', value: 'env_type', id: 'env_type' },
  { label: 'Environment ID', value: 'env_id', id: 'env_id' },
  { label: 'Deployment type', value: 'deployment_type', id: 'deployment_type' },
  { label: 'Application ID', value: 'app_id', id: 'app_id' },
];

export interface MatchingCriteriaFormValues {
  environment_type: string;
  environment_id: string;
  deployment_type: 'deploy' | 're-deploy';
}
