import { WarningSection } from '@humanitec/ui-components';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import EnvironmentValueSetOutdatedWarning from '@src/components/shared/EnvironmentValueSetOutdatedWarning';
import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import PauseButtonSection from '@src/components/shared/PauseEnvironment/PauseButtonSection/PauseButtonSection';
import { TestClusterModal } from '@src/containers/Orgs/Resources/components/ResourcesTable/components/TestClusterModal';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { useDecision } from '@src/hooks/useDecision';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';

interface ViewEnvironmentHeaderProps {
  environment?: Environment;
  showEnvironmentValueSetOutdatedWarning?: boolean;
}
export const ViewEnvironmentHeader = ({
  showEnvironmentValueSetOutdatedWarning,
  environment,
}: ViewEnvironmentHeaderProps) => {
  // router
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // reactQuery
  const { data: envPausedStatusMap } = useEnvironmentPauseStatusQuery({ appId, orgId });
  const {
    data: matchedResourceDefinitions,
    isFetching: isMatchingResourceFetching,
    isError: isMatchedResourceDefinitionError,
  } = useResourceDefinitionsQuery({
    env: envId,
    app: appId,
    env_type: environment?.type,
  });

  const envPauseStatus = envPausedStatusMap?.[envId];

  // i18n
  const { t } = useTranslation();
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const uiTranslations = t('UI');
  const viewEnvironmentTranslations = tViewEnvironment('HEADER');

  // Optimizely
  const [testClusterDecision] = useDecision('test-cluster');

  const showEnvironmentId = environment?.id !== environment?.name;
  const hasMatchingClusterResourceDefinition = useMemo(
    () =>
      matchedResourceDefinitions &&
      Boolean(
        matchedResourceDefinitions?.find((def) => def.type === 'k8s-cluster') &&
          matchedResourceDefinitions?.find((def) => def.type === 'k8s-namespace')
      ),
    [matchedResourceDefinitions]
  );

  return (
    <>
      <PageHeader
        rightContent={
          <>
            <PauseButtonSection disableInfoText disableWrapperPadding />
            {testClusterDecision.enabled && <TestClusterModal />}
          </>
        }
        environment={environment}
        showPageContext
        isEnvironmentPaused={envPauseStatus === true}
      />
      {showEnvironmentId && (
        <>
          <div className={'txt-translucent txt-sm'}>{uiTranslations.ENVIRONMENT_ID}</div>
          <div className={'mb-lg'}>{environment?.id}</div>
        </>
      )}
      {!hasMatchingClusterResourceDefinition &&
        !isMatchingResourceFetching &&
        !isMatchedResourceDefinitionError && (
          <WarningSection
            title={viewEnvironmentTranslations.NO_MATCHING_CLUSTER_WARNING_TITLE}
            fullBorder
            mode={'alert'}
            showIcon>
            <p className={'txt-translucent txt-sm'}>
              <Trans defaults={viewEnvironmentTranslations.NO_MATCHING_CLUSTER_WARNING_TEXT}>
                <Link
                  to={
                    'https://developer.humanitec.com/platform-orchestrator/resources/resource-definitions/'
                  }
                  target={'_blank'}
                />
                <Link
                  to={
                    'https://developer.humanitec.com/platform-orchestrator/resources/resource-packs/'
                  }
                  target={'_blank'}
                />
              </Trans>
            </p>
          </WarningSection>
        )}
      {showEnvironmentValueSetOutdatedWarning && environment?.last_deploy && !envPauseStatus && (
        <EnvironmentValueSetOutdatedWarning
          lastDeployment={environment.last_deploy}
          environment={environment}
        />
      )}
    </>
  );
};
export default ViewEnvironmentHeader;
