import { Button, WalCard } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getVersionStatus } from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/resource-definition-versions.utils';
import { ResourceDefinitionVersion } from '@src/models/resources';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface SelectResourceDefinitionVersionCardProps {
  title?: string;
  resourceDefinitionVersion?: ResourceDefinitionVersion;
  onChangeClick?: () => void;
  isLatest: boolean;
}

const SelectResourceDefinitionVersionCard = ({
  title,
  resourceDefinitionVersion,
  onChangeClick,
  isLatest,
}: SelectResourceDefinitionVersionCardProps) => {
  // i18n
  const { t } = useTranslation();
  const versionsTranslations = t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;

  return (
    <div className={'flex-column'}>
      <span className={'my-md'}>{title}</span>
      <WalCard cardStyle={'base'}>
        {resourceDefinitionVersion ? (
          <ContentWrapper>
            <div className={'flex-column mr-md'}>
              <span className={'mb-sm'}>
                {formatDate(
                  resourceDefinitionVersion.created_at,
                  DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
                )}
              </span>
              <span className={'txt-translucent txt-sm'}>
                {getVersionStatus(resourceDefinitionVersion, isLatest)}
              </span>
            </div>
            <div>
              <Button
                variant={'secondary'}
                size={'small'}
                onClick={() => {
                  if (onChangeClick) {
                    onChangeClick();
                  }
                }}>
                {versionsTranslations.CHANGE}
              </Button>
            </div>
          </ContentWrapper>
        ) : (
          <span>{versionsTranslations.NO_VERSION_AVAILABLE}</span>
        )}
      </WalCard>
    </div>
  );
};

export default SelectResourceDefinitionVersionCard;
