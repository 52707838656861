import ResourceDefinitionVersionsTable from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/components/ResourceDefinitionVersionsTable';
import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';

const ResourceDefinitionVersions = () => {
  // React Query
  const { data: resourceDefintion } = useResourceDefinitionByIdQuery();
  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();

  return (
    resourceDefinitionVersions && (
      <ResourceDefinitionVersionsTable
        resourceDefinitionVersions={resourceDefinitionVersions}
        showActivateButton
        showArchiveButton
        showDiffButton
        isDefaultResourceDefinition={resourceDefintion?.is_default}
      />
    )
  );
};

export default ResourceDefinitionVersions;
