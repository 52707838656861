import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useDeploymentDeltasStore } from '@src/hooks/zustand/useDeploymentDeltasStore';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentSet } from '@src/models/deployment-set';
import makeRequest from '@src/utilities/make-request';

import { deploymentDeltaQueryKeys } from '../deploymentDeltaQueryKeys';

type ActionsModifiers = 'success' | 'fail';

// Add to this type if you need to track specific delta updates
export type DeltaCurrentActions =
  | 'add-workload'
  | 'update-overrides'
  | 'add-variables'
  | 'add-files'
  | 'add-volume-mount';

export type DeltaCurrentActionsAll =
  | DeltaCurrentActions
  | `${DeltaCurrentActions}-${ActionsModifiers}`;

// To correctly type literals
const createDeltaActionString = (
  base: DeltaCurrentActions,
  modifier: ActionsModifiers
): DeltaCurrentActionsAll => `${base}-${modifier}`;

const useDeploymentDeltaUpdateMutation = () => {
  const queryClient = useQueryClient();

  const { setCurrentDeltaAction } = useDeploymentDeltasStore();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<DeploymentSet>,
    AxiosError,
    {
      orgId: string;
      appId: string;
      envId: string;
      deltaId: string;
      deploymentDelta: Partial<DeploymentDelta> | Partial<DeploymentDelta>[];
      currentAction?: DeltaCurrentActions;
    }
  >({
    mutationFn: ({ orgId, appId, deltaId, deploymentDelta, currentAction }) => {
      if (currentAction) {
        setCurrentDeltaAction(currentAction);
      }

      return makeRequest(
        'PATCH',
        `/orgs/${orgId}/apps/${appId}/deltas/${deltaId}`,
        Array.isArray(deploymentDelta) ? deploymentDelta : [deploymentDelta]
      );
    },
    onSuccess: (_, { orgId, appId, envId, deltaId, currentAction }) => {
      if (currentAction) {
        setCurrentDeltaAction(createDeltaActionString(currentAction, 'success'));
      }

      // Invalidate individual delta
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.detail(orgId, appId, deltaId),
      });

      // Invalidate delta list request
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.list(orgId, appId, { env: envId }),
      });
    },
    onError: (_, { currentAction }) => {
      if (currentAction) {
        setCurrentDeltaAction(createDeltaActionString(currentAction, 'fail'));
      }
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useDeploymentDeltaUpdateMutation;
