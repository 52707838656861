import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { breakpoints } from '@src/styles/breakpoints';
import { units } from '@src/styles/variables';

export const containerStyle = (disableOverflow: boolean = false) => {
  return css`
    display: flex;
    flex: 1;

    width: 100%;
    padding: 0 ${units.containerHorizontalPadding};
    ${!disableOverflow && `overflow: hidden;`};

    margin: 0 auto;

    @media ${breakpoints.mobile} {
      padding-right: ${units.containerHorizontalPaddingMobile};
      padding-left: ${units.containerHorizontalPaddingMobile};
    }
  `;
};

const Container = styled.div<{ disableOverflow: boolean }>`
  ${({ disableOverflow }) => containerStyle(disableOverflow)};
`;

interface PageContainerProps {
  disableOverflow?: boolean;
  className?: string;
  children?: ReactNode;
  tabIndex?: number;
}

export const PageContainer = ({
  disableOverflow = false,
  className,
  children,
  tabIndex,
}: PageContainerProps) => {
  return (
    <Container className={className} disableOverflow={disableOverflow} tabIndex={tabIndex}>
      {children}
    </Container>
  );
};
