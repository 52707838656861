import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { DeploymentSet } from '@src/models/deployment-set';
import { Environment } from '@src/models/environment';
import makeRequest from '@src/utilities/make-request';
import { convertToNameAndId } from '@src/utilities/string-utility';

import useDeploymentDeltaCreateMutation from '../../deployment-delta/mutations/useDeploymentDeltaCreateMutation';
import { environmentQueryKeys } from '../environmentQueryKeys';

export interface CreateEnvironmentVariables {
  orgId: string;
  appId: string;
  envName: string;
  envType: string;
  deploymentId?: string;
}

export type EnvironmentCreateMutationType = UseMutateFunction<
  AxiosResponse<DeploymentSet>,
  AxiosError,
  CreateEnvironmentVariables
>;

/**
 * Hook to create Environment based on deployment
 */
const useEnvironmentCreateMutation = () => {
  // React Query
  const queryClient = useQueryClient();
  const { mutate: createDeploymentDeltaMutation } = useDeploymentDeltaCreateMutation();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Environment>,
    AxiosError,
    CreateEnvironmentVariables
  >({
    mutationFn: ({ orgId, appId, envName, envType, deploymentId }) => {
      const converted = convertToNameAndId(envName);
      const requestBody = {
        id: converted.id,
        name: converted.name,
        type: envType,
        from_deploy_id: deploymentId,
      };
      return makeRequest('POST', `/orgs/${orgId}/apps/${appId}/envs`, requestBody);
    },
    onSuccess: (response, { orgId, appId }) => {
      createDeploymentDeltaMutation({
        envId: response.data.id,
        navigateAfterCreation: 'existing-path',
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentDetail(orgId, appId, response.data.id),
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.applicationEnvironments(orgId, appId),
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useEnvironmentCreateMutation;
