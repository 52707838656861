import { rem } from 'polished';
import { FunctionComponent, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { NonStyledLink } from '@src/components/shared/NonStyledLink';
import { units } from '@src/styles/variables';

import Icon from '..//Icon/Icon';

export const CARD_BORDER_RADIUS = rem(4);

export type CardStyles = 'default' | 'transparent' | 'base' | 'warning' | 'alert';

interface CardElementProps {
  id?: string;
  /** Highlight on hover */
  $highlightOnHover?: boolean;
  $cardStyle?: CardStyles;
  padding?: 'small' | 'medium';
  $error?: boolean;
  selected?: boolean;
  arrow?: 'down' | 'right' | 'none';
  onMouseUp?: (event: MouseEvent) => void;
  noBorder?: boolean;
  dataTestId?: string;
  // if the card has a link attached to it
  link?: Pick<LinkProps, 'to' | 'state'>;
}

interface WalCardProps
  extends Omit<CardElementProps, '$highlightOnHover' | '$cardStyle' | '$boxShadow' | '$error'> {
  /** The fixed content of the card */
  children?: ReactNode;
  /** Classname passed in when extending styled component */
  className?: string;
  /** Click handler for the card */
  onClick?: (event: MouseEvent) => void;
  /** On Mouse Enter handler for the card */
  onMouseEnter?: (event: MouseEvent) => void;
  /** On Mouse Leave handler for the card */
  onMouseLeave?: (event: MouseEvent) => void;
  tabIndex?: number;
  /** aria role */
  role?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  ariaLabel?: string;
  cardStyle?: CardStyles;
  highlightOnHover?: boolean;
  error?: boolean;
  noBorder?: boolean;
  /** shows a right arrow in the end of the card */
  showRightArrow?: boolean;
}

export const getCardStyle = (style?: CardStyles) => css`
  ${({ theme }) =>
    style === 'transparent'
      ? css`
          background-color: transparent;
          border-color: ${theme.color.baseOutline};
        `
      : style === 'warning'
        ? css`
            border-color: ${theme.color.yellow};
          `
        : style === 'alert'
          ? css`
              border-color: ${theme.color.alert};
            `
          : style === 'base'
            ? css`
                background-color: ${theme.color.base};
              `
            : css`
                background-color: ${theme.color.baseBrighter};
              `}
`;

export const getCardPadding = (padding?: 'small' | 'medium') => css`
  padding: ${units.padding.lg} ${units.padding.xl};
  ${() => {
    const cardPadding =
      padding === 'small' &&
      css`
        padding: ${units.padding.sm} ${units.padding.md};
      `;

    return css`
      ${cardPadding}
    `;
  }}
`;
export const cardCss = (
  $cardStyle?: CardStyles,
  selected?: boolean,
  $error?: boolean,
  $highlightOnHover?: boolean,
  padding?: 'small' | 'medium',
  noBorder?: boolean
) => {
  return css`
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${({ theme }) => theme.color.text};
    font-size: ${rem(14)};
    border-radius: ${CARD_BORDER_RADIUS};
    border: ${rem(1)} solid ${({ theme }) => theme.color.baseBrighter};
    transition: border-color 200ms ease;
    border-radius: ${CARD_BORDER_RADIUS};
    ${getCardPadding(padding)}
    ${getCardStyle($cardStyle)}
    
    ${({ theme }) =>
      $highlightOnHover &&
      css`
        &:hover {
          border-color: ${theme.color.main};
          cursor: pointer;
        }
      `}


    ${() =>
      noBorder &&
      css`
        border: 0;
        border-color: transparent;
      `}
  
    ${({ theme }) =>
      selected &&
      css`
        background-color: ${theme.color.mainTransparent};
        border: ${rem(1)} solid ${theme.color.main};
      `}
  
    ${({ theme }) =>
      $error && selected
        ? css`
            border-color: ${theme.color.main};
          `
        : $error &&
          css`
            border-color: ${theme.color.alert};
          `}
  `;
};

const ArrowWrapper = styled.div`
  margin-right: ${units.margin.md};
`;

export const Card = styled.div<CardElementProps>`
  ${({ $cardStyle, selected, $error, $highlightOnHover, padding, noBorder }) =>
    cardCss($cardStyle, selected, $error, $highlightOnHover, padding, noBorder)}
`;

const LinkCard = styled(NonStyledLink)<CardElementProps>`
  ${({ $cardStyle, selected, $error, $highlightOnHover, padding, noBorder }) =>
    cardCss($cardStyle, selected, $error, $highlightOnHover, padding, noBorder)}
`;

const ARROW_WIDTH = 9;

const Arrow = styled.div<CardElementProps>`
  position: absolute;
  width: ${rem(ARROW_WIDTH)};
  height: ${rem(ARROW_WIDTH)};
  border-right: 1px solid ${({ theme }) => theme.color.main};
  border-bottom: 1px solid ${({ theme }) => theme.color.main};
  background-color: ${({ theme }) => theme.color.mainTransparentFlat};
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  ${({ $error, theme }) =>
    $error &&
    css`
      background-color: ${theme.color.alertTransparentFlat};
    `}

  ${({ arrow }) =>
    arrow === 'right'
      ? css`
          right: ${`-${rem(ARROW_WIDTH / 2 + 1)}`};
          top: ${`calc(50% - ${rem(ARROW_WIDTH / 2)})`};
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        `
      : arrow === 'down' &&
        css`
          bottom: ${`-${rem(ARROW_WIDTH / 2 + 1)}`};
          left: ${`calc(50% - ${rem(ARROW_WIDTH / 2)})`};
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        `}
`;

/**
 * Returns a card component.
 */
export const WalCard: FunctionComponent<WalCardProps> = ({
  children,
  padding,
  arrow = 'none',
  onMouseUp,
  tabIndex,
  onKeyDown,
  ariaLabel,
  cardStyle,
  error,
  highlightOnHover,
  onClick,
  link,
  dataTestId,
  selected,
  showRightArrow,
  ...rest
}) => {
  /**
   * call onClick callback function
   */
  const handleCardClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleCardKeydown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const cardDefaultProps: CardElementProps = {
    $highlightOnHover: false,
    $cardStyle: 'default',
    selected: false,
    $error: false,
    padding: 'medium',
  };

  return link ? (
    <LinkCard
      {...cardDefaultProps}
      {...rest}
      $error={error}
      $cardStyle={cardStyle}
      $highlightOnHover={highlightOnHover}
      data-testid={dataTestId}
      selected={selected}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      padding={padding}
      onMouseUp={onMouseUp}
      {...link}>
      {children}
      {arrow !== 'none' && selected && <Arrow arrow={arrow} $error={error} />}
      {showRightArrow && (
        <ArrowWrapper>
          <Icon name={'arrow-right'} />
        </ArrowWrapper>
      )}
    </LinkCard>
  ) : (
    <Card
      {...cardDefaultProps}
      {...rest}
      $error={error}
      $cardStyle={cardStyle}
      $highlightOnHover={highlightOnHover}
      onClick={handleCardClick}
      onKeyDown={handleCardKeydown}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      padding={padding}
      onMouseUp={onMouseUp}
      selected={selected}>
      {children}
      {arrow !== 'none' && selected && <Arrow arrow={arrow} $error={error} />}
      {showRightArrow && (
        <ArrowWrapper>
          <Icon name={'arrow-right'} size={12} />
        </ArrowWrapper>
      )}
    </Card>
  );
};
