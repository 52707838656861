import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import type { DropdownItem } from '../../molecules/Menu/DropdownMenu/DropdownMenu';
import BasicEditor from './BasicEditor';
import {
  LANGUAGE_EXTENSIONS_MAP,
  syntaxHighlightingItems,
  SyntaxHighlightingMode,
} from './editor-constants';
import EditorProps from './EditorProps';
import SelectSyntax from './SelectSyntax';
import WhitespaceHighlightCheckbox from './WhitespaceHighlightCheckbox';

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownHelperText = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-bottom: ${units.margin.md};
  margin-left: ${units.margin.md};
  font-size: ${units.fontSize.sm};
`;

const StyledBasicEditor = styled(BasicEditor)`
  border: ${({ theme }) => `${rem(1)} solid ${theme.color.baseOutline}`};
  resize: vertical;
  overflow: auto;
`;
const replaceCR = (text: string | undefined) => text?.replace(/␍/g, '\r');
const replaceReturn = (text: string | undefined) => text?.replace(/\r/g, '␍');

export interface MonacoEditorProps extends EditorProps {
  /**
   * Text to be displayed initially when component mounts. `initialValue` is one-way data binding.
   * We set it initially and after that we only take value from editor.
   * We can not alter the editor value outside the component. For ex: Files section.
   *
   * `value` is two-way data binding. Editor can change its text and we can also change it. For ex: Forms Playground.
   * For example here :
   ```
    function Demo(){
      const [code, setCode] = useState('');
      return (
         <Editor value={code} onChange={setCode} />
     )
    }
   ```
   * When user types, editor changes its value, sends out the change to Demo component.
   * Which changes its value, code value is updated and prop change is informed to the Editor and
   * Editor refreshes its text ( which is essentially what it used to be earlier ) 
   **/
  initialValue?: string;
  /**
   * enable if we need to disable showing whitespaces
   */
  disableHighlightWhitespaces?: boolean;
}

export const MonacoEditor = (props: MonacoEditorProps) => {
  const {
    disableHighlightWhitespaces = false,
    fileExtension,
    hideSyntaxDropdown,
    initialValue,
    onChange,
    readOnly,
    value,
    customLanguage,
    className,
  } = props;
  const [selectedHighlightingSyntax, setSelectedHighlightingSyntax] =
    useState<SyntaxHighlightingMode>('text');

  // 118n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  /*
   Editor text is managed in three places:
   1) in monaco editors internal memory,  ( contains CR or \r depending on setting)
   2) The component using the editor. It passes its value as initialValue. We are only using it to set initial value.
    We ignore the prop in further changes. Otherwise for every keystroke the prop changes (always contains true value, \r)
   3) localValue ( below this) .Local value to replace \r with CR and keep track of it ( contains CR or \r depending on setting)

   How they get updated:
     user enters test --> 2) & 3) updated
     whitespace toggle clicked --> 1) and 3) updated --> 2) & 3) are updated
   */
  const [localValue, setLocalValue] = useState<string>('');

  const [whitespaceHighlightEnabled, setWhitespaceHighlightedEnabled] = useState(
    !disableHighlightWhitespaces
  );

  // This is used in two places so extracted as a reusable function. It sets the local value
  // if whitespaceHighlightEnabled value changes, show or hide CR
  const setValueWithWhitespaces = useCallback(
    (val: string) =>
      setLocalValue((whitespaceHighlightEnabled ? replaceReturn(val) : replaceCR(val)) || ''),
    [whitespaceHighlightEnabled]
  );

  /*
   * When user checks/unchecks show/hide white spaces checkbox
   */
  useEffect(
    () => setValueWithWhitespaces(localValue),
    [whitespaceHighlightEnabled, setValueWithWhitespaces, localValue]
  );

  /** If value is passed and updated, replace localValue with it and also replace \r with CR */
  useEffect(() => {
    if (value !== undefined) {
      setValueWithWhitespaces(value);
    }
  }, [value, setValueWithWhitespaces]);

  /* Use initialValue to update localValue, Do this only if localValue is not set,
   * in other words initialValue changes are ignored after setting it once */
  useEffect(() => {
    if (!localValue && initialValue) {
      setValueWithWhitespaces(initialValue);
    }
  }, [initialValue, setValueWithWhitespaces, localValue]);

  /** Guesses higlighting syntax based on fileExtension. */
  useEffect(() => {
    if (fileExtension) {
      const languageExtensionItem = LANGUAGE_EXTENSIONS_MAP.find((languageExtenstion) =>
        languageExtenstion.extensions.includes(fileExtension)
      );
      const selectedSyntax: DropdownItem<SyntaxHighlightingMode> | undefined =
        syntaxHighlightingItems.find((item) => item.value === languageExtensionItem?.language);
      if (selectedSyntax) {
        setSelectedHighlightingSyntax(selectedSyntax.id as SyntaxHighlightingMode);
      }
    }
  }, [fileExtension, setSelectedHighlightingSyntax]);

  return (
    <EditorWrapper className={className}>
      {
        <FlexWrapper>
          {!hideSyntaxDropdown && (
            <FlexWrapper>
              <SelectSyntax
                selectedHighlightingSyntax={selectedHighlightingSyntax}
                setSelectedHighlightingSyntax={setSelectedHighlightingSyntax}
              />
              <DropdownHelperText>{uiTranslations.SYNTAX_NOT_SAVED_WITH_FILE}</DropdownHelperText>
            </FlexWrapper>
          )}

          {!disableHighlightWhitespaces && !readOnly && (
            <WhitespaceHighlightCheckbox
              onChange={() => setWhitespaceHighlightedEnabled(!whitespaceHighlightEnabled)}
            />
          )}
        </FlexWrapper>
      }
      <StyledBasicEditor
        {...props}
        renderWhitespace={whitespaceHighlightEnabled}
        fileExtension={selectedHighlightingSyntax}
        customLanguage={customLanguage}
        usedForInput={false}
        value={localValue}
        onChange={(val, ev) => {
          if (val === undefined || val === null) return;
          setLocalValue(val);
          // We always replace CR with \r regardless of the setting. It simplifies the code without any sideeffects
          if (onChange) onChange(replaceCR(val), ev);
        }}
      />
    </EditorWrapper>
  );
};

export default MonacoEditor;
