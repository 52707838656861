import { Button, MonacoEditor, WalInput } from '@humanitec/ui-components';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import usePlaceholders from '@src/hooks/usePlaceholders';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { MODE_REGEXP } from '@src/utilities/string-utility';

import { extractFileExtension, FileItem } from '../Files';

export interface FilesViewAddEditProps {
  /* list of existing files to validate against because you can't add duplicate file paths */
  existingFiles: FileItem[];
  /* file that was opened in edit mode */
  fileToEdit?: FileItem;
  /* method called when the save button is clicked */
  onSave?: (formData: any) => void;
  /* shows an indicator that the Save button was pressed */
  isSaving?: boolean;
  /* method called when the cancel button is clicked */
  onCancel?: () => void;
  /* switches to one of 3 possible mode */
  mode?: 'add' | 'edit' | 'view';
}

const ButtonContainer = styled.div`
  margin-top: ${units.margin.xl};
  display: flex;
`;

const LeftButton = styled(Button)`
  margin-right: ${units.margin.sm};
`;

const FieldTitle = styled.div`
  font-size: ${units.fontSize.sm};
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.sm};
`;

const FieldInput = styled(WalInput)`
  textarea,
  input {
    margin-top: ${units.margin.sm};
    margin-bottom: ${units.margin.sm};
  }
  label {
    color: ${({ theme }) => theme.color.text};
  }
`;

const EmptyState = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.baseLayer};
  padding: ${units.padding.lg} 0;
  text-align: center;
  font-size: ${units.fontSize.base};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const FilesViewAddEdit = ({
  existingFiles,
  fileToEdit,
  onSave,
  isSaving = false,
  onCancel,
  mode = 'view',
}: FilesViewAddEditProps) => {
  const methods = useWalhallForm({
    defaultValues: { value: '', path: '', mode: '' },
  });
  const { watch, handleSubmit, setValue, reset } = methods;
  const formValues = watch();
  const pathInputRef = useRef<HTMLInputElement>(null);
  const modeInputRef = useRef<HTMLInputElement>(null);
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const filesTranslations = t('VIEW_MODULE').FILES;

  // constants
  const readonly = mode === 'view';
  // Placeholders for MonacoEditor
  const placeholders = usePlaceholders();
  const fileExtension = useMemo(
    () => extractFileExtension(fileToEdit?.path || formValues?.path),
    [fileToEdit, formValues]
  );

  useEffect(() => {
    // Focus on path input initially
    pathInputRef.current?.focus();
  }, []);

  useEffect(() => {
    setValue(`value`, fileToEdit?.value || '');
  }, [fileToEdit?.value, setValue]);

  const handleConfirm = () => {
    onSave?.(formValues);
    if (mode === 'add') {
      reset();
    }
    if (mode === 'edit') {
      onCancel?.();
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handleEditorChange = useCallback(
    (value: string | undefined) => {
      setValue(`value`, value || '');
    },
    [setValue]
  );

  const renderEmptyFileValueState = <EmptyState>{filesTranslations.NO_CONTENTS}</EmptyState>;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleConfirm)}>
        {!readonly && (
          <>
            <FieldInput
              labelAbove
              name={'path'}
              inputRef={pathInputRef}
              label={filesTranslations.PATH}
              placeholder={filesTranslations.PATH}
              readonly={readonly}
              defaultValue={fileToEdit?.path}
              required
              standardValidation={[
                { type: 'path' },
                {
                  type: 'existingId',
                  ids: existingFiles
                    .filter((file) => file.path !== fileToEdit?.path)
                    .map((f) => f.path),
                },
              ]}
            />
            <FieldInput
              labelAbove
              name={'mode'}
              inputRef={modeInputRef}
              label={filesTranslations.MODE}
              placeholder={filesTranslations.MODE}
              readonly={readonly}
              defaultValue={fileToEdit?.mode}
              required
              errorMoreInformationLink={
                'https://developer.humanitec.com/platform-orchestrator/working-with/workloads/#full-paths-and-permission-mode'
              }
              validate={{
                validateMode: (value: string) =>
                  MODE_REGEXP.test(value) || filesTranslations.INVALID_MODE,
              }}
            />
          </>
        )}
        <FieldTitle>{filesTranslations.CONTENTS}</FieldTitle>
        {readonly && Boolean(fileToEdit?.value) === false ? (
          renderEmptyFileValueState
        ) : (
          <MonacoEditor
            width={'100%'}
            minHeight={'150px'}
            fileExtension={fileExtension || 'txt'}
            customLanguage={!fileExtension || fileExtension === 'txt' ? 'files' : undefined}
            readOnly={readonly}
            value={formValues?.value}
            onChange={(value) => handleEditorChange(value)}
            hideSyntaxDropdown={readonly}
            placeholders={JSON.stringify([placeholders])}
          />
        )}

        {!readonly && (
          <ButtonContainer>
            {isSaving ? (
              <LeftButton variant={'primary'} type={'submit'} disabled loading>
                {uiTranslations.SAVING}...
              </LeftButton>
            ) : (
              <LeftButton variant={'primary'} type={'submit'}>
                {uiTranslations.SAVE}
              </LeftButton>
            )}
            <Button variant={'secondary'} onClick={handleCancel}>
              {uiTranslations.CANCEL}
            </Button>
          </ButtonContainer>
        )}
      </form>
    </FormProvider>
  );
};

export default FilesViewAddEdit;
