export const environmentQueryKeys = {
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs']
  all: (orgId: string | undefined, appId: string | undefined) => [
    'orgs',
    orgId,
    'apps',
    appId,
    'envs',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'list']
  applicationEnvironments: (orgId: string | undefined, appId: string | undefined) => [
    ...environmentQueryKeys.all(orgId, appId),
    'list',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'detail']
  environmentBase: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined
  ) => [...environmentQueryKeys.all(orgId, appId), envId],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'detail']
  environmentDetail: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined
  ) => [...environmentQueryKeys.environmentBase(orgId, appId, envId), 'detail'],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'deploys']
  listEnvironmentDeployments: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined,
    piplineRunFilter?: { pipelineId?: string; pipelineRunId?: string }
  ) => {
    const key: (
      | string
      | undefined
      | {
          pipelineId?: string;
          pipelineRunId?: string;
        }
    )[] = [...environmentQueryKeys.environmentBase(orgId, appId, envId), 'deploys'];

    if (piplineRunFilter) {
      key.push(piplineRunFilter);
    }
    return key;
  },
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'runtime']
  environmentRuntimeDetail: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined
  ) => [...environmentQueryKeys.environmentBase(orgId, appId, envId), 'runtime'],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'resources']
  listEnvironmentResources: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined
  ) => [...environmentQueryKeys.environmentBase(orgId, appId, envId), 'resources'],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'deploys', 'my-deploy', 'detail']
  environmentDeploymentDetail: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined,
    deploymentId: string | undefined
  ) => [
    ...environmentQueryKeys.environmentBase(orgId, appId, envId),
    'deploys',
    deploymentId,
    'detail',
  ],
  // e.g. ['orgs', 'my-org', 'apps', 'my-app', 'envs', 'my-env', 'deploys', deployId, 'errors']
  environmentDeploymentErrors: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined,
    deployId: string | undefined
  ) => [
    ...environmentQueryKeys.environmentBase(orgId, appId, envId),
    'deploys',
    deployId,
    'errors',
  ],

  environmentPauseStatus: (orgId: string | undefined, appId: string | undefined) => [
    ...environmentQueryKeys.all(orgId, appId),
    'runtime',
    'paused',
  ],
};
