import {
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset as floatingUIOffset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { rem } from 'polished';
import { ReactElement, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { units } from '@src/styles/variables';

import Icon from '..//Icon/Icon';

const TooltipContainer = styled.span`
  position: relative;
  min-height: ${rem(15)};
  pointer-events: all;
`;

const MoreInfoLink = styled.a`
  font-size: ${units.fontSize.sm};
  margin-left: ${units.margin.sm};
`;
const MoreInfoLinkWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const TriggerComponentWrapper = styled.span<{
  noPadding?: boolean;
}>`
  flex: 1;
`;

interface TooltipWrapperProps {
  width?: number;
  noLineBreak?: boolean;
  noPadding?: boolean;
  whiteSpace?: 'nowrap';
}

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.color.text};
  font-size: ${units.fontSize.sm};
  font-weight: normal;
  background-color: ${({ theme }) => theme.color.baseLayer};
  padding: ${rem(10)} ${units.margin.lg} ${units.margin.md};
  line-height: ${rem(16)};
  width: ${({ width }) => width && rem(width)};
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  word-break: normal;
  text-align: left;
  border-radius: ${rem(4)};
  z-index: 100;
  box-shadow:
    0 ${rem(1)} ${rem(3)} 0 rgb(0, 0, 0, 0.01),
    0 ${rem(4)} ${rem(8)} 0 rgb(0, 0, 0, 0.12);
  ${({ noLineBreak }) =>
    noLineBreak &&
    css`
      white-space: pre;
    `}
`;

interface TooltipProps {
  /* component that triggers the tooltip */
  triggerComponent: ReactElement;
  tabIndex?: number;
  /* text to show inside the tooltip */
  text?: string | ReactNode;
  /* disable showing the tooltip */
  disableTooltip?: boolean;
  children?: any;
  /* link to get more information */
  moreInformationLink?: string;
  /* more information link text */
  moreInformationText?: string;
  className?: string;
  noLineBreak?: boolean;
  position?: Placement;
  /** If the tooltip should have a min width */
  width?: number;
  /** disable cliking on the tooltip */
  preventDefault?: boolean;
  /* Specify a custom buffer for the hover area. Defaults to 3 */
  hoverBuffer?: number;
  /* white-space options for the tooltip content */
  whiteSpace?: 'nowrap';
  /*  spacing between the trigger and the floating element */
  offset?: number;
  focusOrder?: ('reference' | 'content' | 'floating')[];
}

const Tooltip = ({
  text,
  triggerComponent,
  tabIndex,
  disableTooltip,
  children,
  moreInformationLink,
  moreInformationText,
  className,
  noLineBreak,
  width,
  position,
  preventDefault,
  hoverBuffer = 3,
  whiteSpace,
  offset,
  focusOrder = ['reference', 'content'],
}: TooltipProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const moreInfoWrapperRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    placement: position || 'top-start',
    onOpenChange: (val) => {
      if (disableTooltip) {
        return;
      }
      setOpen(val);
    },
    // shift is needed to shift the item in the preferred axe in case it can't be shown
    // flip will flip the tooltip example from top to bottom if there is no place on top
    // offset is the spacing between the trigger and the floating element
    middleware: [shift(), flip(), floatingUIOffset(offset || 5)],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: !disableTooltip,
      // safe polygon will make sure that the hover area between the trigger and the floating element
      // so the floating element does not close unexpectedly
      handleClose: safePolygon({
        buffer: hoverBuffer,
      }),
    }),
    useClick(context, {
      enabled: !disableTooltip,
    }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
    useFocus(context, {
      visibleOnly: false,
    }),
  ]);

  /**
   * If more information link doesn't exist, close.
   */
  const handleOnBlur = (): void => {
    if (!moreInformationLink) {
      setOpen(false);
    }
  };

  return (
    <TooltipContainer className={className}>
      <TriggerComponentWrapper
        data-testid={'tooltip-trigger'}
        {...getReferenceProps({
          ref: refs.setReference,
          className: 'js-tooltip-trigger',
          tabIndex: !disableTooltip ? (tabIndex ? tabIndex : 0) : undefined,
          onBlur: handleOnBlur,
        })}>
        {triggerComponent}
      </TriggerComponentWrapper>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false} order={focusOrder}>
            <TooltipWrapper
              whiteSpace={whiteSpace}
              data-testid={'tooltip'}
              noLineBreak={noLineBreak}
              width={width}
              {...getFloatingProps({
                ref: refs.setFloating,
                className: 'Tooltip',
                onClick: preventDefault ? (e) => e.preventDefault() : undefined,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
                onBlur: () => {
                  setOpen(false);
                },
              })}>
              {children ? (
                <span className={'js-tooltip-text'}>{children}</span>
              ) : (
                <span ref={moreInfoWrapperRef} className={'js-tooltip-text'}>
                  {text}
                  {moreInformationLink && (
                    <MoreInfoLink
                      href={moreInformationLink}
                      tabIndex={0}
                      target={'_blank'}
                      rel={'noopener noreferrer'}>
                      <MoreInfoLinkWrapper>
                        {moreInformationText || uiTranslations.MORE_INFORMATION}
                        <Icon name={'link'} overrideColor={'main-brighter'} />
                      </MoreInfoLinkWrapper>
                    </MoreInfoLink>
                  )}
                </span>
              )}
            </TooltipWrapper>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </TooltipContainer>
  );
};

export default Tooltip;
