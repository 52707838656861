import { Dispatch, SetStateAction } from 'react';

import { useResourceDefinitionVersionQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersionQuery';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';
import useResourceDriversQuery from '@src/hooks/react-query/resources/queries/useResourceDriversQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';

import ResourceDefinitionForm from './ResourceDefinitionForm';

interface EditResourceDefinitionFormProps {
  editState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const EditResourceDefinitionForm = ({ editState }: EditResourceDefinitionFormProps) => {
  // State
  const [editMode, setEditMode] = editState;

  // React query
  const { data: resourceDrivers } = useResourceDriversQuery();
  const { data: resourceTypes } = useResourceTypesQuery();
  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();
  const { data: resourceDefinitionVersionRequest } = useResourceDefinitionVersionQuery();

  // If there's a response from the get resource definition endpoint, use that (i.e. there's a :defVersionId param)
  const resourceDefinitionVersion =
    resourceDefinitionVersionRequest || resourceDefinitionVersions?.[0];

  const resourceType = resourceTypes?.find((rt) => rt.type === resourceDefinitionVersion?.type);

  return (
    resourceType && (
      <ResourceDefinitionForm
        key={editMode ? 'edit-mode-form' : 'view-mode-form'}
        selectedDriver={resourceDrivers?.find(
          (driver) => `${driver.org_id}/${driver.id}` === resourceDefinitionVersion?.driver_type
        )}
        resourceType={resourceType}
        isLatest
        resource={resourceDefinitionVersion}
        onCanceButtonClicked={() => setEditMode(false)}
        onResourceDefinitionCreatedOrUpdated={() => setEditMode(false)}
        viewMode={!editMode}
      />
    )
  );
};
