import { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { Button } from '../../base/Button/Button';

export interface AddModeProps {
  /* the text on the full-width button */
  buttonText: string;
  /* the form that should be displayed on the left side of the component in edit mode */
  formContent: ReactNode;
  /* event handler to call when the 'Add' button is clicked */
  onAdd: (formValues: any) => void;
  /* event handler to call when the cancel button is clicked */
  onCancel?: () => void;
  /* Hides add mode button */
  readonly?: boolean;
  shouldNotResetOnConfirm?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'flex-start';
`;

const StyledButton = styled(Button)`
  margin-right: ${units.margin.sm};
`;

const ContentWrapper = styled.div`
  margin-top: ${units.margin.lg};
  margin-left: ${units.margin.sm};
  display: flex;
  height: fit-content;
`;

const AddMode = ({
  onAdd,
  onCancel,
  buttonText,
  formContent,
  readonly = false,
  shouldNotResetOnConfirm,
}: AddModeProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [showForm, setShowForm] = useState(false);
  const { handleSubmit, reset } = useFormContext();

  const handleCancel = () => {
    onCancel?.();
    reset();
    setShowForm(false);
  };

  const handleConfirm = (formValues: unknown) => {
    onAdd?.(formValues);
    if (!shouldNotResetOnConfirm) {
      reset();
    }
  };

  const renderRightSideContent = () => {
    return (
      <ContentWrapper>
        <StyledButton variant={'secondary'} onClick={handleCancel}>
          {uiTranslations.CANCEL}
        </StyledButton>
        <Button variant={'primary'} type={'submit'} ariaLabel={uiTranslations.ADD}>
          {uiTranslations.ADD}
        </Button>
      </ContentWrapper>
    );
  };

  if (readonly) return <div />;

  return !showForm ? (
    <Button
      variant={'secondary'}
      className={'full-width-button'}
      iconLeft={'plus'}
      onClick={() => setShowForm(true)}>
      {buttonText}
    </Button>
  ) : (
    <form onSubmit={handleSubmit(handleConfirm)}>
      <Container>
        {formContent}
        {renderRightSideContent()}
      </Container>
    </form>
  );
};

export default AddMode;
