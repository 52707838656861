import { WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useResourceClassUpdateMutation } from '@src/hooks/react-query/resources/mutations/useResourceClassUpdateMutation';
import { ResourceClass } from '@src/models/resources';
import { useWalhallForm } from '@src/utilities/form';

import { AddResourceClassForm } from '../components/AddResourceClassForm';

interface UpdateResourceClassModalProps {
  resourceClass: ResourceClass;
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const UpdateResourceClassModal = ({
  resourceClass,
  openState,
}: UpdateResourceClassModalProps) => {
  // Component state
  const [open, setOpen] = openState;

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm({
    defaultValues: {
      id: resourceClass.id,
      description: resourceClass.description,
    },
  });

  // React Query
  const {
    mutate: updateResourceClass,
    isPending: updatingResourceClass,
    isSuccess: resourceClassUpdated,
  } = useResourceClassUpdateMutation();

  const submitForm = (values: { id: string; description: string }) => {
    updateResourceClass({
      resourceTypeId: resourceClass.resource_type,
      classId: resourceClass.id,
      description: values.description,
    });
  };

  useEffect(() => {
    if (resourceClassUpdated) {
      setOpen(false);
    }
  }, [resourceClassUpdated, setOpen]);

  return (
    <>
      <FormProvider {...formMethods}>
        <WalModal
          handleFormSubmit={submitForm}
          openState={[open, setOpen]}
          title={`${uiTranslations.EDIT} ${resourceClass.id}`}
          content={<AddResourceClassForm idDisabled />}
          actions={{
            main: {
              text: uiTranslations.SAVE,
              props: {
                type: 'submit',
                loading: updatingResourceClass,
              },
            },
            cancel: {},
          }}
        />
      </FormProvider>
    </>
  );
};
