import { ButtonVariant, WalDropdownMenu as DropdownMenu } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

interface MembersRoleDropdownProps {
  defaultValue: string | undefined;
  label?: string;
  fullWidth?: boolean;
  buttonVariant?: ButtonVariant;
}
const MembersRoleDropdown = ({
  defaultValue,
  label,
  fullWidth,
  buttonVariant = 'input',
}: MembersRoleDropdownProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  return (
    <DropdownMenu
      name={'role'}
      label={label}
      defaultValue={defaultValue}
      buttonVariant={buttonVariant}
      defaultText={uiTranslations.NONE}
      fullWidth={fullWidth}
      items={[
        {
          id: 'viewer',
          label: 'Viewer',
          value: 'viewer',
        },
        {
          id: 'developer',
          label: 'Developer',
          value: 'developer',
        },
        {
          id: 'owner',
          label: 'Owner',
          value: 'owner',
        },
      ]}
      allowUpdatesToDefaultValue={false}
    />
  );
};

export default MembersRoleDropdown;
