import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import useResourceDefinitionVersionActionMutation from '@src/hooks/react-query/resources/mutations/useResourceDefinitionVersionActionMutation';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';
import { ResourceDefinitionVersion } from '@src/models/resources';

import DiffResourceDefinitionModal from '../DiffResourceDefinitionModal/DiffResourceDefinitionModal';

interface ResourceDefinitionVersionActionsProps {
  resourceDefinitionVersion: ResourceDefinitionVersion;
  showArchiveButton?: boolean;
  showActivateButton?: boolean;
  showDiffButton?: boolean;
  onPinVersion?: (version: ResourceDefinitionVersion) => void;
  isDefaultResourceDefinition: boolean | undefined;
}

export const ResourceDefinitionVersionActions = ({
  resourceDefinitionVersion,
  showActivateButton,
  showDiffButton,
  showArchiveButton,
  onPinVersion,
  isDefaultResourceDefinition,
}: ResourceDefinitionVersionActionsProps) => {
  // Component state
  const [diffModalOpen, setDiffModalOpen] = useState<boolean>(false);
  const [confirmArchiveModalOpen, setConfirmArchiveModalOpen] = useState<boolean>(false);

  // React Query
  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();
  const { mutate: archiveResourceDefinitionVersion } =
    useResourceDefinitionVersionActionMutation('archive');
  const { mutate: setResourceDefinitionVersionAsActive } =
    useResourceDefinitionVersionActionMutation('promote');

  // i18n
  const { t } = useTranslation();
  const versionsTranslations = t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;

  const archiveVersion = () => {
    archiveResourceDefinitionVersion({
      versionId: resourceDefinitionVersion.id,
      archived: true,
    });
    setConfirmArchiveModalOpen(false);
  };

  return (
    <>
      {diffModalOpen && resourceDefinitionVersions && (
        <DiffResourceDefinitionModal
          state={[diffModalOpen, setDiffModalOpen]}
          selectedVersion={resourceDefinitionVersion}
          resourceDefinitionVersions={resourceDefinitionVersions}
        />
      )}
      {confirmArchiveModalOpen && (
        <ConfirmDeleteModal
          state={[confirmArchiveModalOpen, setConfirmArchiveModalOpen]}
          deleteConfirmedCallback={archiveVersion}
          deleteButtonText={versionsTranslations.ARCHIVE}
          confirmMessage={versionsTranslations.ARE_YOU_SURE_ARCHIVE}
          title={versionsTranslations.ARCHIVE_VERSION}
        />
      )}
      <div className={'flex flex-row'}>
        {showActivateButton &&
          !resourceDefinitionVersion.active &&
          !isDefaultResourceDefinition && (
            <Button
              size={'small'}
              variant={'secondary'}
              className={'mr-md'}
              iconLeft={'set-active'}
              onClick={(e) => {
                e.stopPropagation();
                setResourceDefinitionVersionAsActive({ versionId: resourceDefinitionVersion.id });
              }}>
              {versionsTranslations.SET_ACTIVE}
            </Button>
          )}
        {showDiffButton && (
          <Button
            size={'small'}
            variant={'secondary'}
            className={'mr-md'}
            disabled={resourceDefinitionVersion.active}
            onClick={(e) => {
              e.stopPropagation();
              setDiffModalOpen(true);
            }}>
            {versionsTranslations.DIFF}
          </Button>
        )}

        {showArchiveButton &&
          !isDefaultResourceDefinition &&
          (!resourceDefinitionVersion.archived ? (
            <Button
              size={'small'}
              variant={'secondary'}
              disabled={resourceDefinitionVersion.active}
              onClick={(e) => {
                e.stopPropagation();
                setConfirmArchiveModalOpen(true);
              }}>
              {versionsTranslations.ARCHIVE}
            </Button>
          ) : (
            <Button
              size={'small'}
              variant={'secondary'}
              onClick={(e) => {
                e.stopPropagation();
                archiveResourceDefinitionVersion({
                  versionId: resourceDefinitionVersion.id,
                  archived: false,
                });
              }}>
              {versionsTranslations.RESTORE}
            </Button>
          ))}
        {onPinVersion && (
          <Button
            size={'small'}
            variant={'secondary'}
            onClick={() => {
              onPinVersion(resourceDefinitionVersion);
            }}>
            {versionsTranslations.SELECT}
          </Button>
        )}
      </div>
    </>
  );
};
