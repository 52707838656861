import { EmptyStateCard } from '@humanitec/ui-components';
import { Trans, useTranslation } from 'react-i18next';


const DeploymentCardEmptyState = () => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const deploysTranslations = t('DEPLOYS');

  return (
    <EmptyStateCard>
      <p className={'mb-sm'}>{deploysTranslations.NO_DEPLOYS_TO_THIS_ENVIRONMENT}</p>
      {
        <p>
          <Trans defaults={deploysTranslations.CLICK_CREATE_DELTA} />
        </p>
      }
    </EmptyStateCard>
  );
};

export default DeploymentCardEmptyState;
