import { useQuery } from '@tanstack/react-query';

import makeRequest from '@src/utilities/make-request';

import useGetCurrentUserQuery from './useGetCurrentUserQuery';
import { userQueryKeys } from './userQueryKeys';

export interface AuthorizationDeviceData {
  location: {
    country: string;
    city: string;
  };
  ip_address: string;
  created_at: string;
}

const useDeviceLoginQuery = (login_code: string | null) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  return useQuery({
    queryKey: userQueryKeys.deviceLogin(currentUser?.id, login_code),
    queryFn: () =>
      makeRequest<AuthorizationDeviceData>('POST', `/users/${currentUser?.id}/device-info`, {
        login_code,
      }),
    enabled: Boolean(currentUser?.id && login_code),
    select: ({ data }) => data,
    retry: 0,
  });
};

export default useDeviceLoginQuery;
