import { useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import { useParams } from 'react-router-dom';

import { Application } from '@src/models/application';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { applicationsQueryKeys } from '../applicationsQueryKeys';

const sortAppsByCreateDate = (apps: Application[]) =>
  orderBy(apps, (a) => parseISO(a.created_at), 'desc');
const sortAppsByName = (apps: Application[]) => orderBy(apps, (a) => a.name.toLowerCase(), 'asc');

const useApplicationsQuery = (sorting: 'create-date' | 'name' = 'create-date') => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return useQuery({
    queryKey: applicationsQueryKeys.list(orgId),
    queryFn: () => makeRequest<Application[]>('GET', `/orgs/${orgId}/apps`),
    enabled: Boolean(orgId),
    select: (response) => {
      if (sorting === 'create-date') {
        return sortAppsByCreateDate(response.data);
      } else if (sorting === 'name') {
        return sortAppsByName(response.data);
      }
      return sortAppsByCreateDate(response.data);
    },
  });
};

export default useApplicationsQuery;
