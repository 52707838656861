import { Button } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useApplicationDeleteMutation from '@src/hooks/react-query/applications/mutations/useApplicationDeleteMutation';
import useApplicationQuery from '@src/hooks/react-query/applications/queries/useApplicationQuery';
import { MatchParams } from '@src/models/routing';

import ConfirmDeleteAppModal from '../../../../../../../../../../../components/shared/ConfirmDeleteAppModal/ConfirmDeleteAppModal';

const DeleteApplicationButton = () => {
  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('APP_SETTINGS');
  const { appId } = useParams<keyof MatchParams>() as MatchParams;

  // Component state
  const [confirmDeleteAppModalOpen, setConfirmDeleteAppModalOpen] = useState<boolean>(false);

  // React Query
  const { data: application } = useApplicationQuery();
  const {
    mutate: deleteApplication,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useApplicationDeleteMutation();

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setConfirmDeleteAppModalOpen(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const openConfirmDeleteAppModal = () => {
    setConfirmDeleteAppModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (application) {
      deleteApplication({ appId: application.id });
    }
  };

  return (
    <>
      <Button
        dataTestid={'delete-application'}
        variant={'danger'}
        onClick={openConfirmDeleteAppModal}>
        <Trans defaults={settingsTranslations.DELETE_APP_BUTTON} values={{ appId }} />
      </Button>
      {application && (
        <ConfirmDeleteAppModal
          openState={[confirmDeleteAppModalOpen, setConfirmDeleteAppModalOpen]}
          deleteConfirmedCallback={handleConfirmDelete}
          application={application}
        />
      )}
    </>
  );
};

export default DeleteApplicationButton;
