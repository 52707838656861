import { ReactNode } from 'react';

import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';

import { SectionTileProps } from '../SectionTile';
import {
  HumanitecFeatureType,
  isSpecFeature,
} from '../ViewWorkloadProfile/types/view-workload-profile-types';
import WorkloadFeatureBase from './components/WorkloadFeatureBase';

// Type guard for delta features type.
export const isDeltaFeature = (
  value: RuntimeFeatureType | DeltaFeatureType
): value is DeltaFeatureType => Boolean((value as DeltaFeatureType).type === 'delta');

interface RuntimeFeatureType {
  /**
   * A runtime feature is a feature that's only displayed on a running deployment.
   * e.g. Replicas, Container logs.
   */
  type: 'runtime';
  id: string;
}

interface DeltaFeatureType {
  type: 'delta';
  deltaPath: string;
  featureKey: string;
}

export interface WorkloadFeatureProps extends SectionTileProps {
  children: ReactNode;
  featureType: HumanitecFeatureType;
}

interface DeltaWorkloadFeatureProps extends SectionTileProps {
  deltaPath: string;
}

/**
 * This component is separate because react hooks can't be called conditionally.
 * In some cases, we have featureType.type === 'runtime'. In that case, the `deltaPath` isn't present, so we can't use `useDeltaUtils`.
 */
export const DeltaWorkloadFeature = (props: DeltaWorkloadFeatureProps) => {
  const { data } = useDeltaUtils(props.deltaPath);

  return (
    <WorkloadFeatureBase
      expandedByDefault={props.editable ? true : Boolean(Object.keys(data || {}).length)}
      id={props.deltaPath}
      {...props}
    />
  );
};

const WorkloadFeature = (props: WorkloadFeatureProps) => {
  // Check if it's featureType.type === 'spec'...
  return isSpecFeature(props.featureType) ? (
    <DeltaWorkloadFeature
      deltaPath={props.featureType.deltaPath}
      title={props.title}
      editable={props.editable}
      infoPopup={props.infoPopup}>
      {props.children}
    </DeltaWorkloadFeature>
  ) : (
    // ... if it's not featureType.type === 'delta', we're sure it's featureType.type === 'runtime'
    // Runtime feature should always be expanded by default.
    <WorkloadFeatureBase expandedByDefault id={props.featureType.featureName} {...props} />
  );
};

export default WorkloadFeature;
