import { rem } from 'polished';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import { Button } from '../../base/Button/Button';
import { Chip } from '../../base/Chip/Chip';
import { WalInput } from '../../base/Input/Input';
import { WalLabel } from '../../base/Label';
import ComboSelect, { ComboSelectMenuItem } from '../ComboSelect/ComboSelect';
import WalDropdownMenu, { DropdownItem } from '../Menu/DropdownMenu/DropdownMenu';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${units.margin.lg};
  margin-bottom: ${units.margin.md};
`;

const Label = styled(WalLabel)`
  margin-right: ${units.margin.md};
`;

const Text = styled.span`
  font-size: ${units.fontSize.sm};
`;

const FilterInputForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, max-content) minmax(${rem(150)}, max-content) repeat(
      2,
      max-content
    );
  column-gap: ${units.margin.md};
  align-items: center;
`;

const FilterChip = styled(Chip)`
  margin-right: ${units.margin.md};
`;
const Row = styled.div<{ margin?: boolean }>`
  ${({ margin }) =>
    margin &&
    `
  margin-bottom: ${units.margin.lg};
`}
`;

export interface GroupByItem<T> extends DropdownItem<T> {
  /** when defined the data will be grouped by both the current property and the parent property. We need this for more specific groupings */
  parent?: string;
}

interface FilterFormData {
  filterOption: string;
  groupByOption: string;
  filterValue: string;
}

export interface FilterItem {
  value: string;
  label: string;
}

export interface FilterOption extends DropdownItem<string> {
  /** combo select autocomplete items for the filter option. */
  comboSelectItems?: ComboSelectMenuItem[];
}

interface FiltersProps {
  filterOptions?: FilterOption[];
  onFiltersChange?: (filters: Record<string, FilterItem[]>) => void;
  groupByOptions?: GroupByItem<string>[];
  onGroupByChange?: (groupBy: GroupByItem<string>) => void;
}
const Filters = ({
  filterOptions,
  groupByOptions,
  onFiltersChange,
  onGroupByChange,
}: FiltersProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const [showAddFilterInputs, setShowAddFilterInputs] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<Record<string, FilterItem[]>>({});
  const methods = useWalhallForm<FilterFormData>({
    defaultValues: {
      groupByOption: groupByOptions?.[0]?.id,
      filterOption: filterOptions?.[0]?.id,
    },
  });
  const { handleSubmit, reset, resetField, watch } = methods;

  const selectedFilterOption = filterOptions?.find(
    (filterOption) => filterOption.value === watch('filterOption')
  );

  const handleAddFilter = (formData: FilterFormData) => {
    const newFilters = { ...activeFilters };
    const selectedItem = filterOptions?.find((item) => item.id === formData.filterOption);
    if (!newFilters[formData.filterOption]) {
      newFilters[formData.filterOption] = [];
    }
    newFilters?.[formData.filterOption]?.push({
      value: formData.filterValue,
      label: selectedItem?.label || formData.filterOption,
    });
    setActiveFilters(newFilters);
    if (onFiltersChange) {
      onFiltersChange(newFilters);
    }
    resetField('filterValue');
  };

  const handleRemoveFilter = (key: string, value: string) => {
    const newFilters = { ...activeFilters };
    const filterIndex = newFilters[key]?.findIndex((filter) => filter.value === value);

    if (filterIndex !== undefined) {
      newFilters?.[key]?.splice(filterIndex, 1);
    }
    if (newFilters[key]?.length === 0) {
      delete newFilters[key];
    }
    setActiveFilters(newFilters);
    if (onFiltersChange) {
      onFiltersChange(newFilters);
    }
  };

  const handleOnGroupByItemClick = (id: string, item: GroupByItem<string>) => {
    if (onGroupByChange) {
      onGroupByChange(item);
    }
  };

  const handleShowFilterInputs = () => {
    setShowAddFilterInputs(true);
    reset();
  };

  return (
    <div className={'flex-column'}>
      <FormProvider {...methods}>
        <Row className={'flex-centered'} margin={Object.keys(activeFilters).length > 0}>
          {groupByOptions && groupByOptions.length > 0 && (
            <Wrapper>
              <Label>{uiTranslations.GROUP_BY}</Label>
              <WalDropdownMenu
                name={'groupByOption'}
                items={groupByOptions}
                onItemClick={handleOnGroupByItemClick}
                buttonVariant={'secondary'}
                buttonSize={'small'}
              />
            </Wrapper>
          )}
          {filterOptions && filterOptions.length > 0 && (
            <Wrapper>
              <Label>{uiTranslations.FILTERS}</Label>
              {!showAddFilterInputs ? (
                <Button
                  variant={'secondary'}
                  iconLeft={'plus'}
                  size={'small'}
                  onClick={handleShowFilterInputs}>
                  {uiTranslations.ADD_FILTER}
                </Button>
              ) : (
                <>
                  <FilterInputForm onSubmit={handleSubmit(handleAddFilter)}>
                    <WalDropdownMenu
                      name={'filterOption'}
                      items={filterOptions}
                      buttonVariant={'input'}
                      buttonSize={'small'}
                    />
                    <Text>{uiTranslations.IS}</Text>
                    {selectedFilterOption?.comboSelectItems ? (
                      <ComboSelect
                        items={selectedFilterOption?.comboSelectItems}
                        name={'filterValue'}
                        mode={'set-selected-value'}
                        inputSize={'small'}
                        hideLabel
                      />
                    ) : (
                      <WalInput name={'filterValue'} size={'small'} hideLabel />
                    )}
                    <Button type={'submit'} iconLeft={'plus'} size={'small'}>
                      {uiTranslations.ADD}
                    </Button>
                    <Button
                      variant={'secondary'}
                      size={'small'}
                      onClick={() => setShowAddFilterInputs(false)}>
                      {uiTranslations.CANCEL}
                    </Button>
                  </FilterInputForm>
                </>
              )}
            </Wrapper>
          )}
        </Row>
        <Row className={'flex-centered'} margin>
          {Object.entries(activeFilters).map(([key, filterItems]) => {
            return filterItems.map((filterItem) => {
              return (
                <FilterChip
                  key={key}
                  id={key}
                  label={`${filterItem.label} ${uiTranslations.IS} '${filterItem.value}'`}
                  evenBorderRadius
                  onRemove={() => handleRemoveFilter(key, filterItem.value)}
                  noTooltipOnHover
                />
              );
            });
          })}
        </Row>
      </FormProvider>
    </div>
  );
};

export default Filters;
