import { Link, useParams } from 'react-router-dom';

import { ResourceDefinitionVersion } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generateResourceDefinitionVersionUrl } from '@src/utilities/navigation';

interface VersionLinkProps {
  resourceDefinitionVersion: ResourceDefinitionVersion | undefined;
}

export const VersionLink = ({ resourceDefinitionVersion }: VersionLinkProps) => {
  // Router hooks
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={generateResourceDefinitionVersionUrl(orgId, defId, resourceDefinitionVersion?.id)}>
      {formatDate(
        resourceDefinitionVersion?.created_at,
        DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
      )}
    </Link>
  );
};
