import { rem } from 'polished';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { KeyValue } from '@src/models/general';
import { placeholderStyle } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';

import { Button } from '../../base/Button/Button';
import Tooltip from '../../base/Tooltip/Tooltip';
import { AutoCompleteOptions, placeholderHasError } from '../AutoCompleteInput/AutoCompleteInput';

const FieldsWrapper = styled.div<{ showDescription?: boolean; showSecret?: boolean }>`
  display: grid;
  column-gap: ${units.margin.sm};
  align-items: center;
  ${({ showDescription, showSecret }) => css`
    grid-template-columns: ${rem(164)} auto;
    grid-template-columns: ${rem(164)} ${!showDescription && !showSecret ? `auto` : rem(210)} ${showDescription &&
      rem(240)} ${showSecret && rem(100)} auto;
  `}
`;

const Field = styled.div`
  font-size: ${units.fontSize.base};
  padding-right: ${units.padding.lg};
  max-height: ${rem(35)};
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ValueField = styled(Field)`
  word-break: break-all;
  font-family: 'Source Code Pro', sans-serif;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecretLabel = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

const CopyButton = styled(Button)`
  margin-left: ${units.margin.md};
`;

// Position fake textarea for clipboard copy.
const FakeTextArea = styled.textarea`
  position: absolute;
  left: -1000vh;
  top: -100vw;
`;

const PlaceholderText = styled.span<{ hasError?: boolean }>`
  color: ${({ theme }) => theme.color.main};
  font-size: ${units.fontSize.sm};
  white-space: normal !important;
  word-break: break-all;
  ${({ hasError }) => placeholderStyle(hasError)};
  margin: 0;
`;

interface KeyValueEditorViewComponentProps {
  keyValue: KeyValue;
  showDescription?: boolean;
  showSecret?: boolean;
  enableValueCopy?: boolean;
  isSecret?: boolean;
  autoCompleteOptions?: AutoCompleteOptions;
}

const VALUE_CHARACTERS_PER_LINE = 48;
const KEY_CHARACTERS_PER_LINE = 12;

const KeyValueEditorViewComponent = ({
  keyValue,
  enableValueCopy,
  showDescription,
  isSecret,
  autoCompleteOptions,
  showSecret,
}: KeyValueEditorViewComponentProps) => {
  const valueRef = useRef(null);
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const [showKeyEllipsis, setShowKeyEllipsis] = useState<boolean>(false);
  const [showValueEllipsis, setShowValueEllipsis] = useState<boolean>(false);

  useEffect(() => {
    const newLines = keyValue.value?.match(/\n/g);
    if (newLines && newLines.length >= 2) {
      setShowValueEllipsis(true);
    } else if (Math.floor(keyValue.value?.length / VALUE_CHARACTERS_PER_LINE) > 2) {
      setShowValueEllipsis(true);
    } else {
      setShowValueEllipsis(false);
    }
  }, [keyValue.value]);

  useEffect(() => {
    const newLines = keyValue.key?.match(/\n/g);
    if (newLines && newLines.length >= 2) {
      setShowValueEllipsis(true);
    }
    if (Math.floor(keyValue.key?.length / KEY_CHARACTERS_PER_LINE) > 2) {
      setShowKeyEllipsis(true);
    } else {
      setShowKeyEllipsis(false);
    }
  }, [keyValue.key]);

  const copySnippet = (e?: MouseEvent) => {
    e?.stopPropagation();
    if (valueRef !== null) {
      (valueRef.current as any).select();
      document.execCommand('copy');
    }
  };

  /**
   * highlights placeholders
   */
  const getHighlightedFieldValue = (value: string) => {
    if (autoCompleteOptions && autoCompleteOptions.delimiterString) {
      const placeholderRegex = new RegExp(
        `\\${autoCompleteOptions.delimiterString.start}{1}.*?${autoCompleteOptions.delimiterString.end}{1}`,
        'g'
      );
      const fieldValueSegmentsBetweenPlaceholders = value?.split(placeholderRegex);

      if (fieldValueSegmentsBetweenPlaceholders?.length <= 1) {
        return value;
      }

      const placeholderMatches = value?.match(placeholderRegex);

      return fieldValueSegmentsBetweenPlaceholders?.reduce(
        (result: any[], segment: string, index: number) => {
          return placeholderMatches && placeholderMatches[index]
            ? [
                ...result,
                segment,
                <PlaceholderText
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  hasError={
                    autoCompleteOptions.delimiterString &&
                    placeholderHasError(
                      placeholderMatches[index],
                      autoCompleteOptions.lookupObject,
                      autoCompleteOptions.delimiterString
                    )
                  }>
                  {placeholderMatches[index]}
                </PlaceholderText>,
              ]
            : [...result, segment];
        },
        []
      );
    }
  };

  const valueFieldText = isSecret
    ? '••••••••••'
    : autoCompleteOptions
      ? getHighlightedFieldValue(keyValue.value?.replace(/&lt;/g, '<'))
      : keyValue.value?.replace(/&lt;/g, '<');

  return (
    <FieldsWrapper showDescription={showDescription} showSecret={showSecret}>
      <FieldWrapper>
        <Tooltip
          triggerComponent={<Field>{keyValue.key.replace(/([_.-])/g, '$1\u200b')}</Field>}
          text={keyValue.key.replace(/([_.-])/g, '$1\u200b')}
          disableTooltip={!showKeyEllipsis}
        />
        {showKeyEllipsis && <span>...</span>}
      </FieldWrapper>
      <FieldWrapper>
        <Tooltip
          triggerComponent={
            <ValueField>
              {valueFieldText}
              {enableValueCopy && !isSecret && !autoCompleteOptions && (
                <>
                  <FakeTextArea readOnly ref={valueRef} value={keyValue.value} />
                  <CopyButton size={'small'} variant={'secondary'} onClick={(e) => copySnippet(e)}>
                    {uiTranslations.COPY}
                  </CopyButton>
                </>
              )}
            </ValueField>
          }
          text={valueFieldText}
          disableTooltip={!showValueEllipsis}
        />
        {showValueEllipsis && <span>...</span>}
      </FieldWrapper>
      {showDescription && <Field>{keyValue.description}</Field>}
      <SecretLabel>{isSecret && uiTranslations.SECRET}</SecretLabel>
    </FieldsWrapper>
  );
};

export default KeyValueEditorViewComponent;
