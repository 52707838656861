export const userQueryKeys = {
  userByUserId: (id: string) => ['users', id],
  currentUser: () => ['current-user'],
  invite: (inviteId: string | null) => ['invite', inviteId],
  user: (orgId: string | undefined, userId: string | undefined) => ['orgs', orgId, 'users', userId],
  userRoles: (orgId: string | undefined, userId: string | undefined) => [
    ...userQueryKeys.user(orgId, userId),
    'roles',
  ],
  deviceLogin: (userId: string | undefined, deviceId: string | null) => [
    'users',
    userId,
    'device',
    deviceId,
  ],
};
