import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { Application } from '@src/models/application';
import { NewApplication } from '@src/models/new-application';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';

import { userQueryKeys } from '../../user/userQueryKeys';
import { applicationsQueryKeys } from '../applicationsQueryKeys';

export const useApplicationCreateMutation = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  // Router hooks
  const navigate = useNavigate();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Application>,
    AxiosError,
    {
      newApplicationPayload: NewApplication;
    }
  >({
    mutationFn: ({ newApplicationPayload }) => {
      return makeRequest('POST', `/orgs/${orgId}/apps`, newApplicationPayload);
    },
    onSuccess: ({ data: application }) => {
      queryClient.invalidateQueries({
        queryKey: userQueryKeys.currentUser(),
      });

      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.list(orgId),
      });

      navigate(generateAppURL(orgId, application.id));
    },
  });

  return { ...queryResult, data: data?.data };
};
