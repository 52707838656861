import { Button, WalCard, WarningSection } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

interface ProblemLoadingSectionProps {
  title?: string;
  className?: string;
}

export const ProblemLoadingSection = ({ title, className }: ProblemLoadingSectionProps) => {
  const { t } = useTranslation();
  const translations = t('COMPONENTS').PROBLEM_LOADING_SECTION;

  return (
    <WalCard className={className}>
      <div className={'txt-lg mb-md'}>{title}</div>
      <WarningSection disableDefaultMargin>
        <p className={'mb-md'}>{translations.PROBLEM}</p>
        <p className={'mb-md'}>{translations.REFRESH_COULD_FIX}</p>
        <div>
          <Button variant={'secondary'} size={'small'} onClick={() => window.location.reload()}>
            {translations.REFRESH_PAGE}
          </Button>
        </div>
      </WarningSection>
    </WalCard>
  );
};
