import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import { Rule } from '@src/models/rule';
import makeRequest from '@src/utilities/make-request';

import { rulesQueryKeys } from '../rulesQueryKeys';

const useEnvironmentRuleUpdateMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ rule }: { rule: Rule }) => {
      // the backend supports the new and the old API but the FE should only send one set of properties in the payload
      // thus removing the properties related to the old one (should be deleted after we fully migrate) WAL-4882
      return makeRequest<Rule[]>(
        'PUT',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/rules/${rule.id}`,
        omit(rule, [
          'id',
          'created_at',
          'updated_at',
          'images_filter',
          'exclude_images_filter',
          'match',
          'update_to',
        ])
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rulesQueryKeys.list(orgId, appId, envId),
      });
    },
  });
};

export default useEnvironmentRuleUpdateMutation;
