import { CardStyles } from '@humanitec/ui-components';

import { RenderWorkloadProps } from '@src/hooks/useGetWorkloadData';

import WorkloadCard from '../WorkloadCard/WorkloadCard';

interface WorkloadsListProps {
  workloadDefinitions: RenderWorkloadProps[];
  /** Only pass this prop if displaying this card in the active deployment card  */
  deploymentId?: string;
  cardStyle?: CardStyles;
}

const WorkloadsList = ({ workloadDefinitions, deploymentId, cardStyle }: WorkloadsListProps) => {
  return (
    <>
      {workloadDefinitions.map(({ workloadId, workload, deleted, edited }) => (
        <WorkloadCard
          key={workloadId}
          workloadId={workloadId}
          workload={workload}
          deleted={deleted}
          edited={edited}
          deploymentId={deploymentId}
          cardStyle={cardStyle}
        />
      ))}
    </>
  );
};

export default WorkloadsList;
