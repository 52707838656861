import {
  Button,
  EmptyStateCard,
  SearchInput,
  WalLabel,
  WalTable,
  WalTableRow,
} from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useResourceClassesQuery from '@src/hooks/react-query/resources/queries/useResourceClassesQuery';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { ResourceClass } from '@src/models/resources';
import { units } from '@src/styles/variables';

import { AddResourceClassModal } from '../../components/modals/AddResourceClassModal/AddResourceClassModal';
import { UpdateResourceClassModal } from '../../components/modals/UpdateResourceClassModal/UpdateResourceClassModal';
import ResourcesTable from '../../components/ResourcesTable/ResourcesTable';
import { getResourceDefinitionsWithClassInCriteria } from '../../utils';
import { DeleteClassModal } from './components/DeleteClassModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${units.margin.md};
`;

export const ResourceClasses = () => {
  // React Query
  const { data: resourceClasses = [] } = useResourceClassesQuery();
  const { data: resourceDefinitions = [] } = useResourceDefinitionsQuery();

  // i18n
  const { t } = useTranslation();
  const classesTranslations = t('ACCOUNT_SETTINGS').RESOURCES.CLASSES;
  const uiTranslations = t('UI');

  // Component state
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [openEditClassModal, setOpenEditClassModal] = useState<boolean>(false);
  const [classToEdit, setClassToEdit] = useState<ResourceClass>();

  const filteredResourceClasses = resourceClasses.filter(
    (resourceClass) =>
      resourceClass.resource_type.toLowerCase().includes(searchValue) ||
      resourceClass.id.toLowerCase().includes(searchValue)
  );

  return (
    <Wrapper>
      <ButtonWrapper>
        <AddResourceClassModal />
        <SearchInput
          name={'filterClasses'}
          label={classesTranslations.FIND_ID_OR_TYPE}
          onChange={setSearchValue}
        />
      </ButtonWrapper>
      {filteredResourceClasses.length > 0 ? (
        <WalTable
          showCount
          maxDisplayedRows={20}
          tableStyle={'expandable'}
          expandedRowState={[expandedRow, setExpandedRow]}
          caption={classesTranslations.RESOURCE_CLASSES}
          rows={
            filteredResourceClasses
              ?.sort((a, b) => (a.resource_type < b.resource_type ? -1 : 1))
              .map((resourceClass: ResourceClass) => {
                const defsWithClassInCriteria = getResourceDefinitionsWithClassInCriteria(
                  resourceDefinitions,
                  resourceClass
                );
                return {
                  data: resourceClass,
                  expandableContent: (
                    <div className={'flex flex-column'}>
                      <WalLabel>{uiTranslations.ID}</WalLabel>
                      <span className={'mb-md'}>{resourceClass.id}</span>
                      <WalLabel>{uiTranslations.TYPE}</WalLabel>
                      <span className={'mb-md'}>{resourceClass.resource_type}</span>
                      <WalLabel>{uiTranslations.DESCRIPTION}</WalLabel>
                      <span className={'mb-md'}>{resourceClass.description}</span>
                      {defsWithClassInCriteria.length > 0 && (
                        <>
                          <WalLabel>{classesTranslations.DEFINITIONS}</WalLabel>
                          <ResourcesTable
                            resourceDefinitions={defsWithClassInCriteria}
                            hideFilters
                            readonly
                            maxDisplayedRows={5}
                          />
                        </>
                      )}
                    </div>
                  ),
                };
              }) ?? []
          }
          columns={[
            {
              prop: 'id',
              label: uiTranslations.ID,
            },
            {
              prop: 'type',
              label: uiTranslations.TYPE,
              template: ({ data }) => data.resource_type,
            },
            {
              prop: 'definitions',
              label: classesTranslations.DEFINITIONS,
              template: ({ data }) => {
                const defsWithClassInCriteria = getResourceDefinitionsWithClassInCriteria(
                  resourceDefinitions,
                  data
                );
                return <div>{defsWithClassInCriteria?.length || uiTranslations.NONE}</div>;
              },
            },
            {
              prop: 'description',
              label: uiTranslations.DESCRIPTION,
            },
            {
              prop: 'actions',
              template: ({ data }: WalTableRow<ResourceClass>) => (
                <div className={'flex'} onClick={(e) => e.stopPropagation()}>
                  <Button
                    variant={'secondary'}
                    size={'small'}
                    iconLeft={'edit'}
                    className={'mr-md'}
                    onClick={() => {
                      setOpenEditClassModal(true);
                      setClassToEdit(data);
                    }}
                    ariaLabel={uiTranslations.EDIT}
                  />
                  <DeleteClassModal resourceClass={data} />
                </div>
              ),
            },
          ]}
        />
      ) : (
        <EmptyStateWrapper className={'flex-column'}>
          <EmptyStateCard>{classesTranslations.NO_RESOURCE_CLASSES_AVAILABLE}</EmptyStateCard>
        </EmptyStateWrapper>
      )}
      {classToEdit && openEditClassModal && (
        <UpdateResourceClassModal
          resourceClass={classToEdit}
          openState={[openEditClassModal, setOpenEditClassModal]}
        />
      )}
    </Wrapper>
  );
};
