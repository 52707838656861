import { Button, WalModal } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePinActiveResourceResourceDefinitionVersionMutation } from '@src/hooks/react-query/resources/mutations/usePinActiveResourceResourceDefinitionVersionMutation';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';
import { ActiveResource } from '@src/models/resources';

import ResourceDefinitionVersionsTable from '../../../ResourceDefinitionVersions/components/ResourceDefinitionVersionsTable';

interface PinActiveResourceToVersionModalProps {
  activeResource: ActiveResource;
}

export const PinActiveResourceToVersionModal = ({
  activeResource,
}: PinActiveResourceToVersionModalProps) => {
  // React Query
  const { mutate: pinResource } = usePinActiveResourceResourceDefinitionVersionMutation();
  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();

  // Component state
  const [modalOpen, setModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.ACTIVE_RESOURCES;

  return (
    <>
      <Button
        className={'mr-sm'}
        size={'small'}
        variant={'secondary'}
        iconLeft={activeResource.target_def_version_id ? 'unpin' : 'pin'}
        onClick={(e) => {
          e.stopPropagation();
          if (activeResource.target_def_version_id) {
            pinResource({
              resource: activeResource,
              target_def_version_id: null,
            });
          } else {
            setModalOpen(true);
          }
        }}>
        {activeResource.target_def_version_id ? translations.UNPIN : translations.PIN_TO}
      </Button>
      {modalOpen && (
        <WalModal
          onModalClick={(e) => e.stopPropagation()}
          openState={[modalOpen, setModalOpen]}
          title={translations.PIN_TO_VERSION}
          actions={{ cancel: {} }}
          content={
            <ResourceDefinitionVersionsTable
              resourceDefinitionVersions={resourceDefinitionVersions || []}
              tableRowStyle={'base'}
              onPinVersion={(version) => {
                pinResource({
                  resource: activeResource,
                  target_def_version_id: activeResource.target_def_version_id ? null : version.id,
                });
                setModalOpen(false);
              }}
            />
          }
        />
      )}
    </>
  );
};
