import { Button, DiffViewer, WalModal } from '@humanitec/ui-components';
import { dump } from 'js-yaml';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RightArrowSVG from '@src/components/shared/svg/RightArrowSVG';
import SelectResourceDefinitionVersionCard from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/components/DiffResourceDefinitionModal/components/SelectResourceDefinitionVersionCard';
import ResourceDefinitionVersionsTable from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/components/ResourceDefinitionVersionsTable';
import { getPartialResourceDefinitionObjectToCompare } from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/resource-definition-versions.utils';
import { ResourceDefinitionVersion } from '@src/models/resources';

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface DiffResourceDefinitionModalProps {
  resourceDefinitionVersions: ResourceDefinitionVersion[];
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  selectedVersion: ResourceDefinitionVersion;
}

const DiffResourceDefinitionModal = ({
  state,
  selectedVersion,
  resourceDefinitionVersions,
}: DiffResourceDefinitionModalProps) => {
  // i18n
  const { t } = useTranslation();
  const versionsTranslations = t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;
  const uiTranslations = t('UI');

  // state
  const [_, setOpenModal] = state;
  const [leftVersion, setLeftVersion] = useState<ResourceDefinitionVersion>();
  const [rightVersion, setRightVersion] = useState<ResourceDefinitionVersion>();
  const [selectingSide, setSelectingSide] = useState<'left' | 'right'>();

  const latestVersion = resourceDefinitionVersions[0];

  useEffect(() => {
    setLeftVersion(resourceDefinitionVersions.find((defVersion) => defVersion.active));
    setRightVersion(selectedVersion);
  }, [selectedVersion, resourceDefinitionVersions]);

  const handleSelectVersion = (version: ResourceDefinitionVersion) => {
    if (selectingSide === 'left') {
      setLeftVersion(version);
    } else {
      setRightVersion(version);
    }
    setSelectingSide(undefined);
  };

  return (
    <WalModal
      openState={state}
      title={
        <TitleWrapper>
          {versionsTranslations.DIFF_DEFINITION_VERSIONS}
          {Boolean(selectingSide) && (
            <>
              <div className={'mx-md'}>
                <RightArrowSVG size={'small'} />
              </div>
              {versionsTranslations.SELECT_COMPARISON}
            </>
          )}
        </TitleWrapper>
      }
      size={'large'}
      showClose
      content={
        Boolean(selectingSide) ? (
          <>
            <ResourceDefinitionVersionsTable
              resourceDefinitionVersions={resourceDefinitionVersions.filter(
                (defVersion) =>
                  defVersion.id !== leftVersion?.id && defVersion.id !== rightVersion?.id
              )}
              selectDiff
              onSelectVersion={handleSelectVersion}
            />
            <Button
              className={'mt-md'}
              variant={'secondary'}
              size={'small'}
              onClick={() => setSelectingSide(undefined)}>
              {uiTranslations.CANCEL}
            </Button>
          </>
        ) : (
          <>
            <CardsWrapper className={'mb-md'}>
              <SelectResourceDefinitionVersionCard
                title={versionsTranslations.COMPARING}
                resourceDefinitionVersion={leftVersion}
                isLatest={leftVersion?.id === latestVersion?.id}
                onChangeClick={() => setSelectingSide('left')}
              />
              <SelectResourceDefinitionVersionCard
                title={versionsTranslations.WITH}
                resourceDefinitionVersion={rightVersion}
                isLatest={rightVersion?.id === latestVersion?.id}
                onChangeClick={() => setSelectingSide('right')}
              />
            </CardsWrapper>
            <span className={'txt-translucent txt-sm'}>{versionsTranslations.DIFF_HINT}</span>
            <div className={'my-md'}>
              {leftVersion && rightVersion && (
                <DiffViewer
                  oldValue={dump(getPartialResourceDefinitionObjectToCompare(leftVersion))}
                  newValue={dump(getPartialResourceDefinitionObjectToCompare(rightVersion))}
                  showDiffOnly
                />
              )}
            </div>
            <Button
              className={'mt-md'}
              variant={'secondary'}
              size={'small'}
              onClick={() => setOpenModal(false)}>
              {uiTranslations.CLOSE}
            </Button>
          </>
        )
      }
    />
  );
};

export default DiffResourceDefinitionModal;
