type K8SUnit = {
  [key: string]: UnitTypes;
};

export type UnitTypes = 'kb' | 'mb' | 'gb' | 'tb' | 'pb' | 'eb';

export interface UnitOption {
  label: string;
  value: UnitTypes;
}

export interface UnitValuePayload {
  value: number;
  unitType: UnitTypes;
}

enum ByteMapping {
  kb = Math.pow(1000, 1),
  mb = Math.pow(1000, 2),
  gb = Math.pow(1000, 3),
  tb = Math.pow(1000, 4),
  pb = Math.pow(1000, 5),
  eb = Math.pow(1000, 6),
}

const k8sUnitMapping: K8SUnit = {
  Ki: 'kb',
  Mi: 'mb',
  Gi: 'gb',
  Ti: 'tb',
  Pi: 'pb',
  Ei: 'eb',
};

export const memoryUnitOptions: UnitOption[] = [
  {
    label: 'KB',
    value: 'kb',
  },
  {
    label: 'MB',
    value: 'mb',
  },
  {
    label: 'GB',
    value: 'gb',
  },
  {
    label: 'TB',
    value: 'tb',
  },
  {
    label: 'PB',
    value: 'pb',
  },
  {
    label: 'EB',
    value: 'eb',
  },
];

/**
 * Converts a k8s unit to standard format.
 *
 * @param value e.g. 2Gi.
 */
export const convertK8sUnitToStandard = (
  value: string | undefined
): UnitValuePayload | undefined => {
  if (!value) {
    return undefined;
  }
  for (const k8sUnit in k8sUnitMapping) {
    if (k8sUnitMapping.hasOwnProperty(k8sUnit)) {
      const unit = k8sUnitMapping[k8sUnit]!;
      if (value.includes(k8sUnit)) {
        const indexOfUnit = value.indexOf(k8sUnit);
        const amount = value.substr(0, indexOfUnit);
        return {
          value: parseInt(amount, 10),
          unitType: unit,
        };
      }
    }
  }
};

/**
 * Converts a standard unit to k8s format.
 */
export const convertStandardUnitToK8s = (
  payload: UnitValuePayload | undefined
): string | undefined => {
  let newValue;
  if (!payload) {
    return newValue;
  }
  Object.keys(k8sUnitMapping).forEach((k8sUnit) => {
    const standardUnit = k8sUnitMapping[k8sUnit];
    if (payload.value && payload.unitType === standardUnit) {
      newValue = `${payload.value}${k8sUnit}`;
    }
  });
  return newValue;
};

export const checkMemoryValueValidity = (
  newValue: string | undefined,
  otherValue: string | undefined,
  type: 'requests' | 'limits'
): string | undefined => {
  const newPayload = convertK8sUnitToStandard(newValue);
  const otherPayload = convertK8sUnitToStandard(otherValue);

  const newFactor = newPayload && ByteMapping[newPayload?.unitType];
  const otherFactor = otherPayload && ByteMapping[otherPayload?.unitType];

  const newBytes = newFactor && newPayload ? newFactor * newPayload?.value : null;
  const otherBytes = otherFactor && otherPayload ? otherFactor * otherPayload?.value : null;

  if (type === 'requests' && newBytes && otherBytes && newBytes > otherBytes) {
    // min is greater than max. Set to max
    return 'minGreaterThanMax';
  } else if (type === 'limits' && newBytes && otherBytes && newBytes < otherBytes) {
    // max is less than min. Set to min
    return 'maxLessThanMin';
  }
};
