import { Button } from '@humanitec/ui-components';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

import useGetSpecificOrgQuery from '@src/hooks/react-query/organisations/queries/useGetSpecificOrgQuery';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.main};
  padding: ${units.padding.lg};
  font-size: ${units.fontSize.sm};
`;

const FreeTrialBannerContainer = styled(PageContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
`;

/**
 * Rounds up expiry to the nearest full day.
 */
const getDaysRemaining = (isoTime: string) => {
  const msRemaining = differenceInMilliseconds(parseISO(isoTime), new Date());
  const secondsRemaining = msRemaining / 1000;
  const minutesRemaining = secondsRemaining / 60;
  const hoursRemaining = minutesRemaining / 60;

  return Math.ceil(hoursRemaining / 24);
};

const FreeTrialBanner = () => {
  const match = useMatch('/orgs/:orgId/*');
  const { data: org } = useGetSpecificOrgQuery({ orgId: match?.params.orgId });

  const daysRemaining = org?.trial_expires_at && getDaysRemaining(org?.trial_expires_at);

  const { t } = useTranslation();

  return (
    org?.trial_expires_at && (
      <Wrapper>
        <FreeTrialBannerContainer>
          {daysRemaining && daysRemaining > 0
            ? t('FREE_TRIAL_EXPIRY', { count: daysRemaining || 0 })
            : t('FREE_TRIAL_EXPIRED')}
          <Button
            variant={'secondary'}
            target={'_blank'}
            size={'small'}
            link={{
              href: 'https://humanitec.com/schedule-demo?utm_source=product&utm_medium=referral&utm_campaign=free_trial&utm_content=countdown_banner',
            }}>
            {t('FREE_TRIAL_CONTACT_US')}
          </Button>
        </FreeTrialBannerContainer>
      </Wrapper>
    )
  );
};

export default FreeTrialBanner;
