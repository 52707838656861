import { WalTable, WalTableColumn } from '@humanitec/ui-components';
import { capitalize } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SectionHeader from '@src/components/shared/SectionHeader';
import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { Org } from '@src/models/user';
import { cl } from '@src/styles/global-styles';
import { useWalhallForm } from '@src/utilities/form';

const ProfileSettingsMain = () => {
  // Form
  const formMethods = useWalhallForm();

  // i18n
  const { t } = useTranslation();
  const profileTranslations = t('PROFILE_SETTINGS');

  // Selectors
  const { data: user } = useGetCurrentUserQuery();

  const { data: orgs = [] } = useGetOrgsQuery();

  const columns: WalTableColumn<Org>[] = [
    {
      prop: 'name',
      label: profileTranslations.ORGANIZATION,
    },
    {
      prop: 'id',
      label: profileTranslations.ORGANIZATION_ID,
    },
    {
      prop: 'role',
      label: profileTranslations.ROLE,
      template: (rowData) => capitalize(user?.roles[`/orgs/${rowData.data.id}`]),
      justifyContent: 'flex-start',
    },
  ];

  return (
    <div {...cl('flex-column', 'flex-1')}>
      <FormProvider {...formMethods}>
        {user &&
          (user.email ? (
            <div {...cl('flex-column')}>
              <div {...cl('flex-column', 'mb-lg')}>
                <SectionHeader>{profileTranslations.PRIMARY_EMAIL}</SectionHeader>
                <div {...cl('flex', 'align-center', 'txt-sm', 'mr-lg')}>{user?.email}</div>
              </div>
            </div>
          ) : (
            '-'
          ))}
      </FormProvider>
      <WalTable
        caption={profileTranslations.ORGANIZATIONS}
        rows={orgs.map((org) => ({ data: org }))}
        columns={columns}
      />
    </div>
  );
};

export default ProfileSettingsMain;
