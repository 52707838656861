import { KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { KeyValue } from '@src/models/general';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';

import { Button } from '../../base/Button/Button';
import { CardStyles, WalCard } from '../../base/Card/Card';
import { AutoCompleteOptions } from '../AutoCompleteInput/AutoCompleteInput';
import { KeyValidation, ValueValidation } from '../KeyValueEntries/components/KeyValueEntry';
import KeyValueEntriesModal from '../KeyValueEntriesCard/components/KeyValueEntriesModal';

const DetailsButton = styled(Button)`
  position: absolute;
  right: ${units.margin.md};
`;

const Card = styled(WalCard)<{ $readonly?: boolean }>`
  position: relative;
  flex-direction: row;
  align-items: center;
  ${({ $readonly }) =>
    !$readonly &&
    css`
      cursor: pointer;
    `}
`;

interface KeyValueEntriesCardProps {
  title: string;
  entries: KeyValue[];
  handleModalFormSubmit: (formValues: { entries: KeyValue[] }) => void;
  emptyStateText: string;
  entriesAmountTextSingular: string;
  entriesAmountTextPlural: string;
  addButtonText: string;
  keyValidation?: KeyValidation;
  valueValidation?: ValueValidation;
  editingEnabled?: boolean;
  errorMoreInformationLink?: string;
  cardStyle?: CardStyles;
  autocompleteOptions?: AutoCompleteOptions;
}

const KeyValueEntriesCard = ({
  title,
  entries,
  handleModalFormSubmit,
  emptyStateText,
  entriesAmountTextSingular,
  entriesAmountTextPlural,
  addButtonText,
  keyValidation,
  valueValidation,
  editingEnabled,
  errorMoreInformationLink,
  cardStyle,
  autocompleteOptions,
}: KeyValueEntriesCardProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const entriesCount = entries.length;

  const handleCardKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KeyBindings.ENTER && editingEnabled) {
      setModalOpen(true);
    }
  };

  return (
    <>
      {!editingEnabled && entriesCount === 0 ? (
        <span>{emptyStateText}</span>
      ) : (
        <Card
          onClick={() => editingEnabled && setModalOpen(true)}
          onKeyDown={handleCardKeyDown}
          cardStyle={cardStyle || 'base'}
          tabIndex={0}
          $readonly={!editingEnabled}>
          {entriesCount === 0 ? (
            <span>{emptyStateText}</span>
          ) : (
            <>
              <span>
                {entriesCount}{' '}
                {entriesCount > 1 || entriesCount === 0
                  ? entriesAmountTextPlural
                  : entriesAmountTextSingular}
              </span>

              {entriesCount > 0 && (
                <DetailsButton
                  onClick={() => setModalOpen(true)}
                  variant={'secondary'}
                  size={'small'}>
                  {uiTranslations.DETAILS}
                </DetailsButton>
              )}
            </>
          )}
        </Card>
      )}
      <KeyValueEntriesModal
        title={title}
        state={[modalOpen, setModalOpen]}
        handleFormSubmit={handleModalFormSubmit}
        entries={entries}
        addButtonText={addButtonText}
        keyValidation={keyValidation}
        valueValidation={valueValidation}
        editingEnabled={editingEnabled}
        errorMoreInformationLink={errorMoreInformationLink}
        entryNameSingular={entriesAmountTextSingular}
        autocompleteOptions={autocompleteOptions}
      />
    </>
  );
};

export default KeyValueEntriesCard;
