import { Button, WalInput } from '@humanitec/ui-components';
import { rem } from 'polished';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { windowEnv } from '@src/environment';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { EMAIL_PATTERN } from '@src/utilities/string-utility';
import { useGetUrlParam } from '@src/utilities/url-params';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: ${rem(300)};
  align-items: center;
`;

const EmailInput = styled(WalInput)`
  margin-bottom: ${units.margin.md};
`;

const EnterEmailText = styled.div`
  font-size: ${units.fontSize.lg};
  font-weight: bold;
  margin-bottom: ${units.margin.lg};
`;

export const SSOLogin = () => {
  // Form
  const formMethods = useWalhallForm<{ email: string }>();

  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const uiTranslations = t('AUTHENTICATE');

  const redirectParam = useGetUrlParam('redirect');

  return (
    <FormProvider {...formMethods}>
      <EnterEmailText>{authTranslations.ENTER_SSO_EMAIL}</EnterEmailText>
      <Form action={`${windowEnv.BASE_URL}/auth/sso-login`}>
        <EmailInput
          name={'email'}
          label={uiTranslations.EMAIL}
          required
          pattern={{
            value: EMAIL_PATTERN,
            message: authTranslations.INVALID_SSO_EMAIL,
          }}
        />
        {redirectParam && <input type={'hidden'} name={'redirect'} value={redirectParam} />}
        <Button type={'submit'}>{authTranslations.LOGIN_WITH_SSO}</Button>
      </Form>
    </FormProvider>
  );
};
