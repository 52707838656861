import { createFilterConditionsDictionary, FilterConditions } from '@humanitec/ui-components';

import { ActiveResource, ResourceDefinition } from '@src/models/resources';

/**
 * filters the resource definition according to the given filter conditions
 *
 * @param resourceDefinitions
 * @param filterConditions
 */
export const filterResourceDefinitions = (
  resourceDefinitions: ResourceDefinition[],
  filterConditions?: FilterConditions
): ResourceDefinition[] => {
  const detailsFilter = createFilterConditionsDictionary(filterConditions?.details);
  const matchingCriteriaFilter = createFilterConditionsDictionary(filterConditions?.criteria);

  return resourceDefinitions
    .filter((def) => (detailsFilter?.id ? detailsFilter.id.includes(def.id) : true))
    .filter((def) => (detailsFilter?.type ? detailsFilter?.type.includes(def.type) : true))
    .filter((def) =>
      detailsFilter?.driver_type ? detailsFilter?.driver_type?.includes(def.driver_type) : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.app_id
        ? def.criteria?.some(
            (criteria) =>
              criteria.app_id && matchingCriteriaFilter?.app_id?.includes(criteria.app_id)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.env_id
        ? def.criteria?.some(
            (criteria) =>
              criteria.env_id && matchingCriteriaFilter?.env_id?.includes(criteria.env_id)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.env_type
        ? def.criteria?.some(
            (criteria) =>
              criteria.env_type && matchingCriteriaFilter?.env_type?.includes(criteria.env_type)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.res_id
        ? def.criteria?.some(
            (criteria) =>
              criteria.res_id && matchingCriteriaFilter?.res_id?.includes(criteria.res_id)
          )
        : true
    )
    .filter((def) =>
      matchingCriteriaFilter?.class
        ? def.criteria?.some(
            (criteria) => criteria.class && matchingCriteriaFilter?.class?.includes(criteria.class)
          )
        : true
    );
};

/**
 * filters the active resource according to the given filter conditions
 *
 * @param activeResources
 * @param filterConditions
 */
export const filterActiveResources = (
  activeResources: ActiveResource[],
  filterConditions?: FilterConditions
): ActiveResource[] => {
  const matchingCriteriaFilter = createFilterConditionsDictionary(filterConditions?.criteria);

  return activeResources
    .filter((res) =>
      matchingCriteriaFilter?.app_id ? matchingCriteriaFilter?.app_id.includes(res.app_id) : true
    )
    .filter((res) =>
      matchingCriteriaFilter?.res_id ? matchingCriteriaFilter?.res_id.includes(res.res_id) : true
    )
    .filter((res) =>
      matchingCriteriaFilter?.env_type
        ? matchingCriteriaFilter?.env_type.includes(res.env_type)
        : true
    )
    .filter((res) =>
      matchingCriteriaFilter?.env_id ? matchingCriteriaFilter?.env_id.includes(res.env_id) : true
    )
    .filter((res) =>
      matchingCriteriaFilter?.class ? matchingCriteriaFilter?.class.includes(res.class) : true
    );
};
