import { useTranslation } from 'react-i18next';

import DeleteResourceDefinitionModalWithButton from '@src/containers/Orgs/Resources/components/DeleteResourceDefinitionModalWithButton';
import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';

const ResourceDefinitionDelete = () => {
  // I18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  const { data: resourceDefinition } = useResourceDefinitionByIdQuery();

  return (
    <div className={'flex-column'}>
      <span className={'mb-md'}>{resourcesTranslations.DELETE_RESOURCE_DEFINITION_TEXT}</span>
      {resourceDefinition && (
        <DeleteResourceDefinitionModalWithButton
          variant={'danger'}
          resourceDefinition={resourceDefinition}
          buttonText={resourcesTranslations.DELETE}
        />
      )}
    </div>
  );
};

export default ResourceDefinitionDelete;
