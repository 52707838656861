import { Button } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import HumanitecPlatformLogo from '@src/components/shared/HumanitecPlatformLogo';
import SupportPanel from '@src/components/shared/SupportPanel/SupportPanel';
import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';

import { units } from '../../../styles/variables';

const AuthHeaderWrapper = styled.div<{ darkBackground?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${units.navHeight};
  padding: ${units.padding.xl} ${units.padding.xxxl};
  ${({ darkBackground }) =>
    darkBackground &&
    css`
      background-color: ${({ theme }) => theme.color.baseDarker};
    `}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${units.margin.md};
  }
`;

interface AuthHeaderProps {
  darkBackground?: boolean;
}

const AuthHeader = ({ darkBackground }: AuthHeaderProps) => {
  // i18n
  const { t } = useTranslation();
  const authranslations = t('AUTH');

  // Router hooks
  const navigate = useNavigate();

  // Zustand
  const { openContactForm } = useUserPreferencesStore();

  const goToLogin = () => {
    navigate('/auth/login');
  };

  return (
    <AuthHeaderWrapper darkBackground={darkBackground}>
      <HumanitecPlatformLogo onClick={goToLogin} />
      <Actions>
        <SupportPanel openContact={openContactForm} />
        <Button
          size={'medium'}
          variant={'secondary'}
          link={{
            href: 'https://developer.humanitec.com/',
          }}
          target={'_blank'}
          rel={'noopener noreferrer'}>
          {authranslations.DOCUMENTATION}
        </Button>
      </Actions>
    </AuthHeaderWrapper>
  );
};

export default AuthHeader;
