import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MatchParams, useDeployOrDeltaSegment } from '@src/models/routing';
import { addActiveDeltaUpdatesToDeploymentSet } from '@src/utilities/deployment-delta-utils';

import useDeploymentDeltaQuery from './react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentSetQuery from './react-query/deployment-set/useDeploymentSetQuery';

export const useGetDeploymentSetWithDeltaUpdates = () => {
  // Router hooks
  const { orgId, appId, deltaId, deployId } = useParams<keyof MatchParams>() as MatchParams;

  const { deployOrDeltaSegment } = useDeployOrDeltaSegment();
  const draftModeActive = deployOrDeltaSegment === 'draft';

  // React Query
  const { data: deploymentSet } = useDeploymentSetQuery({ orgId, appId });

  const { data: activeDelta } = useDeploymentDeltaQuery(
    draftModeActive ? { orgId, appId, deltaId: draftModeActive ? deltaId : deployId } : undefined
  );

  return useMemo(
    () =>
      addActiveDeltaUpdatesToDeploymentSet(
        cloneDeep(deploymentSet) || {},
        draftModeActive,
        cloneDeep(activeDelta)
      ),
    [activeDelta, deploymentSet, draftModeActive]
  );
};
