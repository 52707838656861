import { Button, WalModal } from '@humanitec/ui-components';
import { rem } from 'polished';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import { UpdateWorkloadChanges, useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { IngressPath, IngressPathTypes, IngressRule } from '@src/models/deployment-set';
import { units } from '@src/styles/variables';
import { encodePathKey } from '@src/utilities/deployment-delta-utils';
import { useWalhallForm } from '@src/utilities/form';

import IngressPathForm from './IngressPathForm';

const Buttons = styled.div`
  margin-top: ${units.margin.lg};
  button {
    margin-right: ${units.margin.sm};
  }
`;

const IngressPathFormStyled = styled(IngressPathForm)`
  margin-bottom: ${rem(3)};
`;

export interface IngressFormProps {
  path_type: IngressPathTypes | '';
  ingress_path: string;
  ingress_port: string;
}

const defaultIngressFormValues: IngressFormProps = {
  path_type: '',
  ingress_path: '',
  ingress_port: '',
};

interface IngressPathModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  resource: ResourceDependency & { path: string };
  type: 'externals' | 'shared';
  deltaPath: string;
}

const IngressPathModal = ({ openState, resource, type, deltaPath }: IngressPathModalProps) => {
  // Component state
  const [, setOpen] = openState;
  const [resourceRules, setResourceRules] = useState<Record<'http', IngressRule>>();
  const [existingPaths, setExistingPaths] = useState<Record<string, IngressPath>>();
  const [appendNewRow, setAppendNewRow] = useState(false);
  const [defaultOptionExists, setDefaultOptionExists] = useState(true);
  // remove and append should not be triggered in the same action. See Rules section of https://react-hook-form.com/api/usefieldarray
  const [shouldAppend, setShouldAppend] = useState(false);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm<{ ingress: IngressFormProps[] }>({
    defaultValues: { ingress: [] },
  });
  const { watch, control, handleSubmit } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingress',
  });
  const formValues = watch();

  // Delta utils
  const { updateWorkload: updateHttp } = useDeltaUtils(`${deltaPath}/${type}.${resource.id}/http`);
  const { data: rules } = useDeltaUtils<Record<'http', IngressRule>>(deltaPath);

  useEffect(() => {
    setDefaultOptionExists(
      Boolean(Object.values(formValues.ingress).find((v) => v.path_type === 'default'))
    );
  }, [formValues]);

  useEffect(() => {
    setResourceRules(rules);
    if (
      (existingPaths && !Object.keys(existingPaths).length) ||
      (rules && !Object.keys(rules).find((r) => r.split('.')[1] === resource.id)) ||
      !rules
    ) {
      append(defaultIngressFormValues);
    }
  }, [existingPaths, append, resource.id, rules]);

  useEffect(() => {
    const entries = Object.entries(existingPaths || {});
    if (!entries.length) return;

    append(
      entries.map(([path, value]) => ({
        path_type: value.type,
        ingress_port: value.port.toString(),
        ingress_path: path,
      }))
    );
  }, [existingPaths, append]);

  useEffect(() => {
    const [, value] =
      Object.entries(resourceRules ?? {}).find(([key]) => key.split('.')[1] === resource.id) || [];
    setExistingPaths(value?.http);
  }, [resourceRules, resource.id]);

  useEffect(() => {
    const lastFieldValues = fields.length ? formValues.ingress[fields.length - 1] : undefined;
    const lastFieldPath = lastFieldValues?.ingress_path;
    const lastFieldPort = lastFieldValues?.ingress_port;
    const isDefaultAndPortFilled = lastFieldValues?.path_type === 'default' && lastFieldPort;
    if ((lastFieldPath && lastFieldPort) || isDefaultAndPortFilled) {
      append(defaultIngressFormValues);
    }
  }, [fields, append, formValues]);

  useEffect(() => {
    if (shouldAppend) {
      append(defaultIngressFormValues);
      setShouldAppend(false);
    }
  }, [shouldAppend, append]);

  const cancel = () => {
    setOpen(false);
  };

  const submit = (formData: { ingress: IngressFormProps[] }) => {
    const deltas: UpdateWorkloadChanges = [];

    Object.values(formData.ingress).forEach((entry) => {
      if (entry.path_type) {
        const port = parseInt(entry.ingress_port, 10);
        const key = entry.ingress_path ? encodePathKey(entry.ingress_path) : '*';

        const existing = Object.entries(existingPaths || {}).find(
          ([k]) => k === entry.ingress_path || (entry.path_type === 'default' && k === '*')
        );
        const existingIsDefaultAndChanged =
          existing &&
          existing[1].type === 'default' &&
          entry.path_type === 'default' &&
          port !== existing[1].port;

        if (
          existing &&
          (existing[1].port !== port ||
            existing[1].type !== entry.path_type ||
            existingIsDefaultAndChanged)
        ) {
          deltas.push({
            key,
            value: {
              type: entry.path_type,
              port,
            },
            op: 'replace',
          });
        } else if (!existing && !existingIsDefaultAndChanged) {
          deltas.push({
            key,
            value: {
              type: entry.path_type,
              port,
            },
            op: 'add',
          });
        }
      }
    });

    const toRemove = Object.keys(existingPaths || {}).filter((key) => {
      const paths = Object.values(formData.ingress).map((e) =>
        e.path_type === 'default' ? '*' : e.ingress_path
      );
      return !paths.includes(key);
    });

    toRemove.forEach((key) => {
      deltas.push({
        key,
        op: 'remove',
      });
    });
    updateHttp(deltas);
    setOpen(false);
  };

  const removeEntry = (index: number) => {
    remove(index);
    if (fields.length <= 1) {
      setShouldAppend(true);
    }
  };

  return (
    <WalModal
      disableClickOutside
      title={`Ingress ${resource?.id} settings`}
      openState={openState}
      disableOverflow
      content={
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submit)}>
            {fields.map((field, index) => (
              <IngressPathFormStyled
                removeEntry={removeEntry}
                appendNewRowState={[appendNewRow, setAppendNewRow]}
                hideLabels={index !== 0}
                key={field.id}
                index={index}
                pathType={formValues.ingress[index]?.path_type || field.path_type}
                path={
                  formValues.ingress[index]?.ingress_path !== undefined
                    ? formValues.ingress[index]?.ingress_path
                    : field.ingress_path
                }
                port={formValues.ingress[index]?.ingress_port || field.ingress_port}
                defaultOptionExists={defaultOptionExists}
              />
            ))}
            <Buttons>
              <Button type={'submit'}>{uiTranslations.DONE}</Button>
              <Button variant={'secondary'} onClick={cancel}>
                {uiTranslations.CANCEL}
              </Button>
            </Buttons>
          </form>
        </FormProvider>
      }
    />
  );
};

export default IngressPathModal;
