import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import makeRequest from '@src/utilities/make-request';

import useGetCurrentUserQuery from './useGetCurrentUserQuery';

const useConfirmDeviceLoginMutation = () => {
  const { data: currentUser } = useGetCurrentUserQuery();

  return useMutation<AxiosResponse, AxiosError, { approved: boolean; login_code: string }>({
    mutationFn: ({ approved, login_code }) =>
      makeRequest('POST', `/users/${currentUser?.id}/device-login`, { login_code, approved }),
  });
};

export default useConfirmDeviceLoginMutation;
