import { differenceInMilliseconds, isAfter } from 'date-fns';

import { DeploymentObject, DeploymentStatus } from '@src/models/deployment-object';
import { ActiveResource, ResourceDependencyGraphNode } from '@src/models/resources';

export interface DeploymentTime {
  id: string;
  entry: string;
  createdAt?: string;
  finishedAt?: string;
  status?: DeploymentStatus;
  depends_on?: string[];
  provision_time?: string;
}

/**
 * this function calculate the provision times of the resources by looking at the last deployment and the active resource
 * this code is the javascript version of the function implemented in the CLI found here: https://github.com/humanitec/cli-internal/blob/5e01ab7b9f9e2be6477c28aa9b700525ae0d95d9/internal/cmd/resources/graph.go#L246
 *
 * @param deployment
 * @param activeResources
 * @param resourceDependencyGraphNodes
 */
export const useResourceDeploymentTimes = (
  deployment?: DeploymentObject,
  activeResources?: ActiveResource[],
  resourceDependencyGraphNodes?: ResourceDependencyGraphNode[]
) => {
  if (!deployment || !activeResources) return {};
  const resourceDeploymentTimes: Record<string, DeploymentTime> = {};
  activeResources?.forEach((activeResource) => {
    if (activeResource.gu_res_id) {
      resourceDeploymentTimes[activeResource.gu_res_id] = {
        id: activeResource.gu_res_id,
        entry: `${activeResource.res_id}.${activeResource.class}-${activeResource.type}`,
        createdAt: deployment.created_at,
        finishedAt: activeResource.updated_at,
        status: deployment.status,
        provision_time: (
          differenceInMilliseconds(activeResource.updated_at || '', deployment.created_at || '') /
          1000
        ).toString(),
      };
    }
  });

  // Adjust start times for resources that waited for other resources (resources with dependencies)
  resourceDependencyGraphNodes?.forEach((node) => {
    const resourceDeploymentTime = resourceDeploymentTimes[node.guresid];
    if (node.depends_on.length > 0 && resourceDeploymentTime) {
      node.depends_on.forEach((nodeDependency) => {
        const dependencyDeploymentTime = resourceDeploymentTimes[nodeDependency];

        if (
          dependencyDeploymentTime &&
          dependencyDeploymentTime.finishedAt &&
          resourceDeploymentTime.createdAt &&
          isAfter(dependencyDeploymentTime.finishedAt, resourceDeploymentTime.createdAt)
        ) {
          resourceDeploymentTime.createdAt = dependencyDeploymentTime.finishedAt;
          resourceDeploymentTime.provision_time = (
            differenceInMilliseconds(
              resourceDeploymentTime.finishedAt || '',
              resourceDeploymentTime.createdAt || ''
            ) / 1000
          ).toString();
        }
      });
      resourceDeploymentTime.depends_on = node.depends_on;
    }
  });
  return resourceDeploymentTimes;
};
