import { Button, Radio, Spinner, Text, WalInput, WarningSection } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import NewDiffContent from '@src/components/NewDiffContent/NewDiffContent';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import useCheckLatestValueSetDeployed from '@src/hooks/useCheckLatestValueSetDeployed';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { PipelineCriteria } from '@src/models/pipeline';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generatePipelinesURL } from '@src/utilities/navigation';

const Wrapper = styled.div<{ isRedeployingLatestValuesAndSecrets?: boolean }>`
  display: flex;
  padding: ${units.padding.lg} 0;
  flex-direction: column;
  min-width: ${rem(500)};
`;

interface ModalContentProps {
  displayedDelta?: DeploymentDelta;
  deltaCreationError: boolean;
  hideCommentField?: boolean;
  deploymentType:
    | { type: 'deploy'; data: DeploymentDelta }
    | { type: 'redeploy'; data: DeploymentObject; deployLatestValueSet?: boolean };
  onChangeValueSet?: (valueSet?: string) => void;
  isRedeployingLatestValuesAndSecrets?: boolean;
  matchedPipeline?: PipelineCriteria | undefined;
  isPipelineCriteriaLoaded?: boolean;
}

export const DeploymentOverviewModalContent = ({
  displayedDelta,
  deltaCreationError,
  hideCommentField = false,
  deploymentType,
  onChangeValueSet,
  isRedeployingLatestValuesAndSecrets,
  matchedPipeline,
  isPipelineCriteriaLoaded,
}: ModalContentProps) => {
  const [showSelectValueSetRadioButtons, setSelectValueSetRadioButtons] = useState<boolean>(false);
  const { appId, orgId, envId } = useParams();
  // i18n
  const { t } = useTranslation();
  const deploymentModalTranslations = t('DEPLOYMENT_MODAL');

  // react-query
  const { valueSetDeployedIsOutdated, environment } = useCheckLatestValueSetDeployed({ envId });

  const { data: lastDeployedSet } = useDeploymentSetQuery({
    setId: environment?.last_deploy?.set_id,
  });

  return (
    <Wrapper isRedeployingLatestValuesAndSecrets={isRedeployingLatestValuesAndSecrets}>
      {!hideCommentField && (
        <WalInput name={'comment'} label={'Comment'} labelAbove className={'mb-sm'} />
      )}
      <>
        <Text color={'textTranslucent'} className={'mt-md'}>
          {deploymentModalTranslations.DEPLOYMENT_PIPELINE}
        </Text>
        {isPipelineCriteriaLoaded &&
          (matchedPipeline ? (
            <Link
              to={generatePipelinesURL(orgId, appId, matchedPipeline.pipeline_id)}
              className={'mb-md mt-sm'}>
              {matchedPipeline.pipeline_name}
            </Link>
          ) : (
            <WarningSection className={'mt-md mb-lg'} mode={'alert'} showIcon disableDefaultMargin>
              <span className={'txt-base mb-md'}>
                {deploymentModalTranslations.NO_MATCHING_PIPELINE}
              </span>
              <span className={'txt-translucent'}>
                <Trans defaults={deploymentModalTranslations.NO_MATCHING_PIPELINE_TEXT}>
                  <Link
                    to={
                      'https://developer.humanitec.com/integration-and-extensions/humanitec-pipelines/deployment-pipelines/#pipeline-matching-criteria'
                    }
                    target={'_blank'}
                  />
                </Trans>
              </span>
            </WarningSection>
          ))}
      </>
      {!isRedeployingLatestValuesAndSecrets && deploymentType.type === 'redeploy' && (
        <div className={'mb-md'}>
          <Text color={'textTranslucent'} className={'mt-md'}>
            {deploymentModalTranslations.VALUES_SECRETS}
          </Text>
          {!showSelectValueSetRadioButtons ? (
            <>
              <div className={'flex-row flex-centered mt-md'}>
                <Trans
                  className={'mt-sm'}
                  defaults={deploymentModalTranslations.BY_DEFAULT_VALUES_WILL_BE_ROLLED_BACK}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    className={'mx-sm'}
                    href={
                      'https://developer.humanitec.com/platform-orchestrator/working-with/shared-values'
                    }
                    target={'_blank'}
                    rel={'noreferrer'}
                  />
                </Trans>
              </div>
              <div className={'flex-row flex-centered my-sm'}>
                <span className={'mr-sm'}>
                  {deploymentModalTranslations.ROLLBACK_TO}:{' '}
                  {formatDate(
                    deploymentType.data.created_at,
                    DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
                  )}
                </span>
                <Button
                  variant={'secondary'}
                  size={'small'}
                  onClick={() => setSelectValueSetRadioButtons(true)}
                  disabled={!valueSetDeployedIsOutdated}
                  infoPopup={
                    !valueSetDeployedIsOutdated
                      ? { text: deploymentModalTranslations.NO_DIFFERENCE_IN_VALUE_SETS }
                      : undefined
                  }>
                  {deploymentModalTranslations.CHANGE}
                </Button>
              </div>
            </>
          ) : (
            <div className={'my-md'}>
              <Radio
                name={'select-value-set'}
                value={'rollback'}
                label={`${deploymentModalTranslations.ROLLBACK_TO}: ${formatDate(deploymentType.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}`}
                subLabel={deploymentModalTranslations.REDEPLOY_USING_DEPLOYMENT_VALUES_SECRETS}
                defaultChecked
                onClick={(value) => onChangeValueSet && onChangeValueSet(value)}
              />
              <Radio
                name={'select-value-set'}
                value={'latest'}
                label={deploymentModalTranslations.LATEST_VALUES_SECRETS}
                subLabel={deploymentModalTranslations.DEPLOY_WITH_MOST_RECENT_VALUES}
                onClick={(value) => onChangeValueSet && onChangeValueSet(value)}
              />
            </div>
          )}
        </div>
      )}
      {}
      {!isRedeployingLatestValuesAndSecrets && (
        <>
          {displayedDelta ? (
            <NewDiffContent delta={displayedDelta} rightDeploymentSet={lastDeployedSet} />
          ) : (
            <div className={'flex mt-md gap-sm'}>
              <Spinner size={'small'} />{' '}
              <span> {deploymentModalTranslations.LOADING_CHANGES} </span>
            </div>
          )}
        </>
      )}

      <div className={'flex-column'}>
        {deltaCreationError && (
          <Text color={'alert'} size={'sm'} className={'mb-md'}>
            {deploymentModalTranslations.INVALID_DELTA}
          </Text>
        )}
      </div>
    </Wrapper>
  );
};
