import {
  ActionButtons,
  Icon,
  InfoPopup,
  OptionGrid,
  OptionGridOption,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import AddRegistryModal from '@src/containers/Orgs/Registries/components/AddRegistryModal';
import {
  getRegistryIcon,
  getRegistryTitle,
} from '@src/containers/Orgs/Registries/registries.utilities';
import useRegistryDeleteMutation from '@src/hooks/react-query/registries/mutations/useRegistryDeleteMutation';
import useRegistriesListQuery from '@src/hooks/react-query/registries/queries/useRegistriesListQuery';
import { Registry, RegistryType } from '@src/models/registry';
import { units } from '@src/styles/variables';

const OptionGridWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const Registries = () => {
  // i18n
  const { t } = useTranslation();
  const registriesTranslations = t('ACCOUNT_SETTINGS').REGISTRIES;

  // Component state
  const [registryModalOpen, setRegistryModalOpen] = useState<boolean>(false);
  const [registryToEdit, setRegistryToEdit] = useState<Registry>();
  const [newRegistryType, setNewRegistryType] = useState<RegistryType>();

  const sourceOptions: OptionGridOption<any, RegistryType>[] = [
    {
      name: registriesTranslations.BASIC_CONTAINER,
      id: 'basic',
      icon: 'registry',
    },
    {
      name: registriesTranslations.GOOGLE_CONTAINER,
      id: 'google_gcr',
      icon: 'gcp',
    },
    {
      name: registriesTranslations.AMAZON_CONTAINER,
      id: 'amazon_ecr',
      icon: 'aws',
      customWidth: 200,
    },
  ];

  // React Query
  const { data: registries = [] } = useRegistriesListQuery();
  const { mutate: deleteRegistry } = useRegistryDeleteMutation();

  useEffect(() => {
    if (!registryModalOpen) {
      setNewRegistryType(undefined);
      setRegistryToEdit(undefined);
    }
  }, [registryModalOpen]);

  const openAddRegistryModal = (id: RegistryType) => {
    setNewRegistryType(id);
    setRegistryToEdit(undefined);
    setRegistryModalOpen(true);
  };

  const handleDeleteRegistry = (id: string) => {
    deleteRegistry(id);
  };

  const handleEditRegistry = (registry: Registry) => {
    setRegistryToEdit(registry);
    setRegistryModalOpen(true);
  };

  const registriesTableColumns: WalTableColumn<Registry>[] = [
    {
      prop: 'id',
      label: registriesTranslations.REGISTRY_ID,
      template: (registryRow) => (
        <Cell>
          <Icon name={getRegistryIcon(registryRow.data.type)} marginRight={'sm'} />
          {registryRow.data.id}
        </Cell>
      ),
    },
    { prop: 'registry', label: registriesTranslations.REGISTRY_URL },
    {
      prop: 'type',
      label: registriesTranslations.REGISTRY_TYPE,
      template: (registryRow) => getRegistryTitle(registryRow.data.type),
    },
    {
      prop: 'actions',
      template: (row) =>
        row.data.id !== 'humanitec' && (
          <ActionButtons
            buttons={['edit', 'delete']}
            onEdit={() => handleEditRegistry(row.data)}
            onDelete={() => handleDeleteRegistry(row.data.id)}
          />
        ),
    },
  ];

  return (
    <SectionsSettingWrapper>
      <HeadingWrapper>
        <h4>{registriesTranslations.MANAGE_REGISTRIES}</h4>
        <InfoPopup
          text={registriesTranslations.MANAGE_REGISTRIES_INFO_TEXT}
          moreInformationLink={registriesTranslations.MANAGE_REGISTRIES_INFO_LINK}
          position={'bottom'}
        />
      </HeadingWrapper>
      <OptionGridWrapper>
        <OptionGrid<any, RegistryType>
          onOptionClick={openAddRegistryModal}
          title={registriesTranslations.ADD_REGISTRY}
          options={sourceOptions}
          itemMinWidth={150}
        />
      </OptionGridWrapper>
      <WalTable
        className={'mb-md'}
        caption={registriesTranslations.TABLE_CAPTION}
        rows={registries.map((registry): WalTableRow => ({ data: registry }))}
        columns={registriesTableColumns}
        disableScrolling
        showCount
      />
      {(registryToEdit || newRegistryType) && (
        <AddRegistryModal
          openState={[registryModalOpen, setRegistryModalOpen]}
          registry={registryToEdit}
          type={registryToEdit?.type || newRegistryType || 'basic'}
          exisitingIds={registries.map((reg) => reg.id)}
          dataTestId={
            registryToEdit?.id
              ? `${registryToEdit?.id}-modal`
              : newRegistryType
                ? `${newRegistryType}-modal`
                : undefined
          }
        />
      )}
    </SectionsSettingWrapper>
  );
};

export default Registries;
