import { ResourceDependencyGraph } from '@humanitec/resource-graph';
import { Navigate, useParams } from 'react-router-dom';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentQuery from '@src/hooks/react-query/environments/queries/useDeploymentQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useResourceDependencyGraphQuery from '@src/hooks/react-query/resources/queries/useResourceDependencyGraphQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { DeploymentObject } from '@src/models/deployment-object';
import { ResourceDependencyGraph as ResourceDependencyGraphModel } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { generateDraftURL } from '@src/utilities/navigation';

interface ResourceDependencyGraphProps {
  customDeployment?: DeploymentObject;
  customResourceDependencyGraph?: Partial<ResourceDependencyGraphModel>;
  hideContainerBorder?: boolean;
}

const ResourceDependencyGraphWrapper = ({
  customDeployment,
  customResourceDependencyGraph,
  hideContainerBorder,
}: ResourceDependencyGraphProps) => {
  // Router hooks
  const { orgId, appId, envId, deltaId, graphId } = useParams<keyof MatchParams>() as MatchParams;

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();

  const { data: deployment } = useDeploymentQuery({
    orgId,
    appId,
    envId,
    deploymentId: customDeployment?.id,
  });
  const { data: environment } = useEnvironmentQuery();
  const { data: activeResources } = useEnvironmentResourcesQuery();
  const {
    data: currentDeploymentResourceDependencyGraph,
    isFetching: resourceDependencyGraphFetching,
  } = useResourceDependencyGraphQuery(
    graphId || deployment?.dependency_graph_id || environment?.last_deploy?.dependency_graph_id,
    !customResourceDependencyGraph
  );

  const { data: resourceTypes } = useResourceTypesQuery();

  if (draftModeActive) {
    return <Navigate to={generateDraftURL(orgId, appId, envId, deltaId, 'workloads')} />;
  }
  return (
    <ResourceDependencyGraph
      hideContainerBorder={hideContainerBorder}
      resourceDependencyGraph={
        customResourceDependencyGraph || currentDeploymentResourceDependencyGraph
      }
      resourceTypes={resourceTypes}
      activeResources={activeResources}
      resourceDependencyGraphFetching={resourceDependencyGraphFetching}
      deployment={customDeployment || deployment}
      resourceDefinitions={resourceDefinitions}
      showResDefLink
      showDriverAccount
    />
  );
};

export default ResourceDependencyGraphWrapper;
