import { ValueSet } from '@src/hooks/react-query/shared-values/sharedValueVersionsTypes';
import { Environment } from '@src/models/environment';

/**
 * returns an array of the values that are outdated in the environment i.e. the values that got updated after the last environment deployment
 *
 * @param environment - the environment
 * @param valueSets - the environment value sets
 */
export const getOutdatedValues = (environment: Environment, valueSets: ValueSet[]) => {
  const latestValueSet = valueSets[0];
  const deployedValueSet = valueSets.find(
    (valueSet) => valueSet.id === environment.last_deploy?.value_set_version_id
  );
  return latestValueSet?.id !== deployedValueSet?.id
    ? Object.keys(latestValueSet?.values ?? {}).filter(
        (key) =>
          latestValueSet?.values[key]?.value !== deployedValueSet?.values[key]?.value ||
          latestValueSet?.values[key]?.secret_version !==
            deployedValueSet?.values[key]?.secret_version ||
          latestValueSet?.values[key]?.secret_key !== deployedValueSet?.values[key]?.secret_key
      )
    : [];
};
