import { UIMatch } from '@remix-run/router';

import i18n from '@src/i18n/i18n';
import { BreadcrumbData } from '@src/react-router-types';
import {
  generateAppURL,
  generateDeploymentURL,
  generateDraftURL,
  generateWorkloadURL,
} from '@src/utilities/navigation';

const translations = i18n.t('NAVIGATION');

export const getSharedBreadcrumbs = (match?: UIMatch, data?: BreadcrumbData) => {
  const sharedCrumbs = [
    {
      name: data?.app?.name,
      label: translations?.APP,
      pathname: generateAppURL(match?.params.orgId || '', match?.params.appId),
    },
  ];
  let deploymentCrumbName = data?.deploy?.id;
  if (data?.env?.last_deploy && data?.deploy?.id === data?.env.last_deploy?.id) {
    deploymentCrumbName += ` ${translations?.LATEST_BRACKETS}`;
  }
  if (match?.params.envId) {
    sharedCrumbs.push({
      name: data?.env?.name,
      label: translations?.ENV,
      pathname: generateAppURL(match?.params.orgId || '', match?.params.appId, match?.params.envId),
    });
  }
  if (data?.deploy) {
    sharedCrumbs.push({
      label: translations?.DEPLOY,
      name: deploymentCrumbName,
      pathname: generateDeploymentURL(
        match?.params.orgId || '',
        match?.params.appId || '',
        match?.params.envId || '',
        match?.params.deployId || '',
        'workloads'
      ),
    });
  } else if (data?.delta) {
    sharedCrumbs.push({
      label: translations?.DRAFT,
      name: data?.delta?.metadata?.name || translations?.UNTITLED_DRAFT,
      pathname: `${generateDraftURL(
        match?.params.orgId || '',
        match?.params.appId || '',
        match?.params.envId || '',
        match?.params.deltaId || '',
        'workloads'
      )}`,
    });
  }
  if (match?.params.moduleId) {
    sharedCrumbs.push({
      label: translations?.WORKLOAD,
      name: match?.params.moduleId,
      pathname: data?.delta
        ? `${generateDraftURL(
            match?.params.orgId || '',
            match?.params.appId || '',
            match?.params.envId || '',
            match?.params.deltaId || '',
            'workloads'
          )}/${match?.params.moduleId}`
        : generateWorkloadURL(
            match?.params.orgId || '',
            match?.params.appId || '',
            match?.params.envId || '',
            match?.params.deployId || '',
            match.params.moduleId
          ),
    });
  }

  return sharedCrumbs;
};
