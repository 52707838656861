import { QueryObserverResult, useQueries, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { PipelineRunJob } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../../types';
import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

interface UsePipelineRunDetailsQueryProps {
  jobId: string;
  runId?: string;
}

interface UsePipelineRunDetailsQueryPropsWithIds {
  jobIds: string[];
  runId?: string;
}

type UsePipelineRunJobDetailsQueryProps<T> = T extends UsePipelineRunDetailsQueryPropsWithIds
  ? QueryObserverResult<PipelineRunJob>[]
  : T extends UsePipelineRunDetailsQueryProps
    ? QueryResponse<PipelineRunJob>
    : never;

const usePipelineRunJobDetailsQuery = <
  T extends UsePipelineRunDetailsQueryPropsWithIds | UsePipelineRunDetailsQueryProps,
>(
  props: T
): UsePipelineRunJobDetailsQueryProps<T> => {
  const { orgId, appId, pipelineId, pipelineRunId } = useParams<keyof MatchParams>() as MatchParams;

  const jobIds = 'jobId' in props ? [props.jobId] : props.jobIds || [];

  const results = useQueries({
    queries: jobIds.map<UseQueryOptions<AxiosResponse<PipelineRunJob>>>((singleId: string) => ({
      queryKey: pipelineRunsQueryKeys.pipelineRunJobDetails(
        orgId,
        appId,
        pipelineId,
        pipelineRunId,
        singleId
      ),
      queryFn: () =>
        makeRequest(
          'GET',
          `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${pipelineRunId}/jobs/${singleId}`
        ),
      // Don't make request if any of the params don't exist
      enabled: Boolean(orgId && appId && pipelineId && pipelineRunId && singleId),
    })),
  });
  return (
    'jobIds' in props
      ? results.map((result) => ({
          ...result,
          data: result?.data?.data,
        }))
      : { ...results[0], data: results[0]?.data?.data }
  ) as UsePipelineRunJobDetailsQueryProps<T>;
};

export default usePipelineRunJobDetailsQuery;
