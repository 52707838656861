import { Checkbox } from '@humanitec/ui-components';
import { Spacer } from '@humanitec/ui-components/src/base/Spacer/Spacer';
import { rem } from 'polished';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { AuthenticateFailedReasons } from '@src/hooks/zustand/useCurrentUserStore';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { useGetUrlParam } from '@src/utilities/url-params';

import AuthButtons from '../AuthButtons/AuthButtons';

const CheckboxText = styled.p`
  font-size: ${units.fontSize.sm};
`;

const CheckboxLink = styled.a`
  font-size: ${units.fontSize.sm};
`;

const PrivacyPolicyWrapper = styled.div<{ addMarginBottom: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ addMarginBottom }) =>
    addMarginBottom &&
    css`
      margin-bottom: ${units.margin.lg};
    `}
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: column;
`;

const Error = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.alert};
`;

const AlreadyHaveAnAccount = styled.div`
  text-align: center;
  margin-bottom: ${units.margin.lg};
`;

const Form = styled.form`
  max-width: ${rem(320)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface SignUpFormProps {
  googleAuthSuccess: (token: string) => void;
  isInvite: boolean;
  authenticateFailedReason?: AuthenticateFailedReasons;
  isMvp?: boolean;
}

const TermsCheckbox = ({ updatedText }: { updatedText?: boolean }) => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  const methods = useFormContext();
  const { clearErrors } = methods;

  const checkBoxChange = (event: any) => {
    if (event.target.checked) {
      clearErrors();
    }
  };

  return (
    <PrivacyPolicyWrapper addMarginBottom={!updatedText}>
      <CheckBoxWrapper>
        <Checkbox name={'privacy_disclaimer_accepted'} requiredTrue handleChange={checkBoxChange} />
        <CheckboxText>
          {authTranslations.I_AGREE}{' '}
          <CheckboxLink href={'https://humanitec.com/privacy-policy'} target={'_blank'}>
            {authTranslations.PRIVACY_POLICY}
          </CheckboxLink>
          <span>{' & '}</span>
          <CheckboxLink
            href={
              updatedText
                ? 'https://humanitec.com/policies/subscription-terms'
                : 'https://humanitec.com/terms-and-conditions'
            }
            target={'_blank'}>
            {updatedText ? authTranslations.SUBSCRIPTION_TERMS : authTranslations.TERMS_OF_USE}
          </CheckboxLink>
        </CheckboxText>
      </CheckBoxWrapper>
      {methods.formState.errors.privacy_disclaimer_accepted && (
        <Error>{authTranslations.YOU_MUST_AGREE}</Error>
      )}
    </PrivacyPolicyWrapper>
  );
};

const SignUpForm = ({
  googleAuthSuccess,
  authenticateFailedReason,
  isInvite,
  isMvp,
}: SignUpFormProps) => {
  const methods = useWalhallForm();
  const { watch, trigger } = methods;
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const inviteToken = useGetUrlParam('inviteToken');

  const githubOnClick = () => {
    trigger();
  };

  const googleOnClick = () => {
    trigger();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <AuthButtons
          checked={Boolean(watch('privacy_disclaimer_accepted'))}
          isInvite={isInvite}
          isMvp={isMvp}
          type={'register'}
          googleAuthSuccess={googleAuthSuccess}
          googleOnClick={googleOnClick}
          githubOnClick={githubOnClick}
          authenticateFailedReason={authenticateFailedReason}
        />
        <TermsCheckbox updatedText />
        <Spacer className={'my-lg'} />
        <AlreadyHaveAnAccount>
          <span className={'txt-sm'}>{authTranslations.ALREADY_HAVE_AN_ACCOUNT}</span>{' '}
          <Link
            className={'txt-sm'}
            to={`/auth/login${inviteToken ? `?inviteToken=${inviteToken}` : ''}`}>
            {authTranslations.LOG_IN}
          </Link>
        </AlreadyHaveAnAccount>
      </Form>
    </FormProvider>
  );
};

export default SignUpForm;
