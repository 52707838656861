import { useQuery } from '@tanstack/react-query';

import { Invite } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from './userQueryKeys';

const useLoadInviteQuery = (token: string | null) => {
  const { data, ...queryResult } = useQuery({
    queryKey: userQueryKeys.invite(token),
    queryFn: () => makeRequest<Invite>('GET', `/invitations/${token}`),
    enabled: Boolean(token),
  });

  return { ...queryResult, data: data?.data };
};

export default useLoadInviteQuery;
