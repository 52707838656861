import {
  Button,
  DropdownItem,
  InfoPopup,
  INPUT_LABEL_MARGIN,
  Toggle,
  UnitsInput,
  WalDropdownMenu,
  WalInput,
} from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import DynamicForm from '@src/components/shared/DynamicForm/DynamicForm';
import { removeUntouchedPropsFromPayload } from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/resource-form.utils';
import useDeploymentDeltaUpdateMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaUpdateMutation';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useResourceClassesQuery from '@src/hooks/react-query/resources/queries/useResourceClassesQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { Workload } from '@src/models/deployment-set';
import { ResourceTypes } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { createSharedDeploymentDraftPatchValue } from '@src/utilities/deployment-delta-utils';
import { useWalhallForm } from '@src/utilities/form';
import { convertStandardUnitToK8s, UnitValuePayload } from '@src/utilities/memory-units';

interface ResourceDependencyValue {
  type: ResourceTypes;
  class: string;
  source?: {
    medium?: 'Memory';
    sizeLimit?: string;
  };
}

export const generatePath = (
  scope: 'shared' | 'externals',
  resourceType: ResourceTypes,
  resourceId: string
) => {
  let path = scope === 'shared' ? '' : 'externals';
  if (resourceType === 'emptyDir' && scope !== 'shared') path = 'volumes';

  return `${path ? '/' : ''}${
    path ? (resourceType === 'emptyDir' ? 'spec/' : '') : ''
  }${path}${`/${resourceId}`}`;
};

const EmptyDirWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const InMemoryToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InMemoryToggle = styled(Toggle)`
  margin-top: ${INPUT_LABEL_MARGIN};
  label {
    margin-top: 0;
  }
`;

const Actions = styled.div`
  margin-top: ${units.margin.sm};
  display: flex;
  justify-content: space-between;
  button {
    margin-right: ${units.margin.sm};
  }
`;

interface AddResourceFormProps {
  resource: ResourceDependency;
  scope: 'private' | 'shared';
  finish: () => void;
  goBack: () => void;
}

export const AddResourceDependencyForm = ({
  resource,
  finish,
  goBack,
  scope,
}: AddResourceFormProps) => {
  const resourceType = resource.type;

  // Form
  const formMethods = useWalhallForm<{ resourceId: string; params: any; memoryMedium: boolean }>();
  const {
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields },
  } = formMethods;
  const memoryMedium = watch('memoryMedium');

  // Delta utils
  const { sharedResourcesList, updateWorkloadVolumes, workloadResourceDependencies } =
    useDeltaUtils<Workload>();
  const { updateWorkload } = useDeltaUtils<Workload>('externals');

  // React query
  const { mutate: updateDeploymentDelta } = useDeploymentDeltaUpdateMutation();
  const { data: activeDelta } = useDeploymentDeltaQuery();
  const { data: resourceClasses } = useResourceClassesQuery();
  // Router hooks
  const { orgId, appId, envId, deltaId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t } = useTranslation();
  const moduleTranslation = t('VIEW_MODULE');
  const uiTranslations = t('UI');
  const [sizeLimit, setSizeLimit] = useState<string>();
  const unitChange = (unit: UnitValuePayload) => {
    setSizeLimit(convertStandardUnitToK8s(unit));
  };

  const typeResourceClasses = resourceClasses?.filter(
    (resourceClass) => resource.type === resourceClass.resource_type
  );

  const availableResourceClassesDropdownItems: DropdownItem<string>[] = [
    {
      id: 'default',
      label: 'default',
      value: 'default',
      description: moduleTranslation.DEFAULT_RESOURCE_CLASS_DESCRIPTION,
      hideFromList: Boolean(typeResourceClasses?.find((c) => c.id.toLowerCase() === 'default')),
    },
    ...(typeResourceClasses?.map((resourceClass) => ({
      id: resourceClass.id,
      label: resourceClass.id,
      value: resourceClass.id,
      description: resourceClass.description,
    })) || []),
  ];

  const existingIds = [
    ...workloadResourceDependencies.map((res) => res.id),
    ...sharedResourcesList.map((sr) => sr.id),
  ];

  const done = (formValues: { resourceId: string; resourceClass?: string; params: any }) => {
    finish();
    reset();

    const { resourceId, resourceClass, params } = removeUntouchedPropsFromPayload(
      formValues,
      dirtyFields
    );

    const value: ResourceDependencyValue | Record<string, ResourceDependencyValue> = {
      type: resourceType,
      class: resourceClass || 'default',
    };

    if (resourceType === 'emptyDir') {
      if (sizeLimit) {
        value.source = {
          ...value.source,
          sizeLimit,
        };
      }
      if (memoryMedium) {
        value.source = {
          ...value.source,
          medium: 'Memory',
        };
      }
      if (scope === 'private') {
        updateWorkloadVolumes([
          {
            key: resourceId,
            value,
            op: 'add',
          },
        ]);

        return;
      }
    }
    if (scope === 'private') {
      updateWorkload([
        {
          key: resourceId,
          op: 'add',
          value: { ...value, params },
        },
      ]);
    } else {
      const path = resource ? generatePath('shared', resourceType, resourceId) : '';
      const hasBeenRemoved = activeDelta?.shared?.find((db) => db.path === path)?.op === 'remove';
      const deltaToPatch = hasBeenRemoved
        ? createSharedDeploymentDraftPatchValue(path, { scope: 'delta' }, 'remove')
        : createSharedDeploymentDraftPatchValue(path, { ...value, params });

      if (deltaToPatch) {
        updateDeploymentDelta({
          orgId,
          appId,
          envId,
          deploymentDelta: deltaToPatch,
          deltaId,
        });
      }
    }
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(done)}>
          <div>
            <WalInput
              required
              name={'resourceId'}
              label={uiTranslations.ID}
              labelAbove
              className={'mb-md'}
              standardValidation={[{ type: 'id' }, { type: 'existingId', ids: existingIds }]}
            />
            <WalDropdownMenu
              items={availableResourceClassesDropdownItems}
              name={'resourceClass'}
              label={uiTranslations.CLASS}
              defaultValue={'default'}
              disabled={availableResourceClassesDropdownItems.length === 1}
              menuSize={'parent'}
              fullWidth
            />
            {resourceType === 'emptyDir' ? (
              <EmptyDirWrapper>
                <InMemoryToggleWrapper>
                  <InMemoryToggle
                    name={'memoryMedium'}
                    label={moduleTranslation.IN_MEMORY}
                    noLabelMargin
                  />
                  <InfoPopup text={moduleTranslation.IN_MEMORY_INFO} />
                </InMemoryToggleWrapper>
                <UnitsInput
                  label={'Size limit'}
                  id={'sizeLimit'}
                  hideLabel={false}
                  name={'sizeLimit'}
                  onChange={unitChange}
                />
              </EmptyDirWrapper>
            ) : (
              resource.param_schema && (
                <DynamicForm
                  formSchema={resource.param_schema}
                  prefix={'params'}
                  editing={Boolean(resource)}
                />
              )
            )}
          </div>
          <br />
          <Actions>
            <Button variant={'secondary'} onClick={goBack}>
              {uiTranslations.BACK}
            </Button>
            <Button type={'submit'} onClick={handleSubmit(done)}>
              {uiTranslations.DONE}
            </Button>
          </Actions>
        </form>
      </FormProvider>
    </div>
  );
};
