import { DeploymentDeltaChange } from '@src/models/deployment-delta';

/**
 * Returns a modified array of the shared resources JSON patch updates grouped by resource id to show in the UI. The array replaces
 * multiple add/remove operations for updated properties in the same resource with a single "replace" operation. See https://humanitec.atlassian.net/browse/WAL-9476 for more details
 *
 * @param sharedResourceUpdates
 */
export const groupSharedResourcesUpdatesArray = (
  sharedResourceUpdates: DeploymentDeltaChange[]
) => {
  return sharedResourceUpdates?.reduce((acc: DeploymentDeltaChange[], sharedResourceUpdate) => {
    const resourceId = sharedResourceUpdate.path.split('/')[1];
    const updatedProperty = sharedResourceUpdate.path.split('/')[2];
    if (updatedProperty) {
      if (resourceId && !acc.find((change) => change.path === `/${resourceId}`)) {
        const newChange: DeploymentDeltaChange = {
          op: 'replace',
          path: `/${resourceId}`,
        };
        acc.push(newChange);
      } else {
        const newChange: DeploymentDeltaChange = {
          op: 'replace',
          path: `/${resourceId}`,
        };
        acc.splice(
          acc.findIndex((change) => change.path === `/${resourceId}`),
          1
        );
        acc.push(newChange);
      }
    } else {
      acc.push(sharedResourceUpdate);
    }
    return acc;
  }, []);
};
