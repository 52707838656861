import { Placement } from '@floating-ui/react';
import { MutableRefObject, ReactNode } from 'react';

import { default as Tooltip } from '..//Tooltip/Tooltip';
import { IconColorOverrides, IconSizes } from '../Icon/Icon';
import InfoIcon from './components/InfoIcon';

const WIDTH = 360;

export interface InfoPopupProps {
  children?: ReactNode;
  /* text to be shown in the popup */
  text?: string;
  /* link to get more information */
  moreInformationLink?: string;
  /* more information link text */
  moreInformationText?: string;
  /* popup position relative to the info icon. If not set the position is top by default */
  position?: Placement;
  /* Removes left margin */
  noMargin?: boolean;
  marginRight?: boolean;
  boundaryRef?: MutableRefObject<any>;
  disableMainColor?: boolean;
  size?: IconSizes;
  width?: number;
  color?: 'alert';
  overrideColor?: IconColorOverrides;
  className?: string;
}

const InfoPopup = ({
  children,
  text,
  moreInformationLink,
  position,
  noMargin,
  marginRight,
  moreInformationText,
  disableMainColor,
  size,
  width,
  overrideColor,
  className,
}: InfoPopupProps) => (
  <Tooltip
    className={className}
    triggerComponent={
      <InfoIcon
        dataTestId={'tooltip-icon'}
        noMargin={noMargin}
        marginRight={marginRight}
        disableMainColor={disableMainColor}
        size={size}
        overrideColor={overrideColor}
      />
    }
    text={text}
    moreInformationText={moreInformationText}
    moreInformationLink={moreInformationLink}
    position={position}
    width={width || WIDTH}>
    {children}
  </Tooltip>
);

export default InfoPopup;
