import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { KeyValue } from '@src/models/general';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import appValuesQueryKeys from '../queries/appValuesQueryKeys';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

const useAppValuesCreateMutation = () => {
  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<ApiErrorResponse>, { appValue: KeyValue }>({
    mutationFn: ({ appValue }) =>
      makeRequest('POST', `/orgs/${orgId}/apps/${appId}/values`, appValue),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appValuesQueryKeys.list(orgId, appId),
      });
      queryClient.invalidateQueries({
        queryKey: sharedValuesQueryKeys.appValuesList(orgId, appId),
      });
    },
  });
};

export default useAppValuesCreateMutation;
