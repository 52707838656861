import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components/macro';

import { useWalhallForm } from '@src/utilities/form';

import Icon from '../base/Icon/Icon';
import { WalInput, WalInputProps } from '../base/Input/Input';

const SearchWrapper = styled.div<{ width?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ width }) => rem(width || 300)};
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  right: ${rem(10)};
  top: ${rem(8)};
`;

const SearchInputField = styled(WalInput)`
  flex: 1;
`;

interface SearchInputProps extends WalInputProps {
  width?: number;
  // If this is set to true the onChange event will be debounced by 250ms
  debounceEvent?: boolean;
}

const SearchInput = ({
  className,
  width,
  debounceEvent,
  onChange,
  name,
  ...rest
}: SearchInputProps) => {
  const methods = useWalhallForm();
  const [inputValue, setInputValue] = useState<string>(rest.defaultValue || '');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (onChange && debounceEvent) {
        onChange(inputValue);
      }
    }, 250);
    return () => clearTimeout(timeoutId);
  }, [inputValue, debounceEvent, onChange]);

  return (
    <SearchWrapper className={className} width={width}>
      <FormProvider {...methods}>
        <SearchInputField
          {...rest}
          name={name}
          hideLabel
          onChange={debounceEvent ? setInputValue : onChange}
        />
        <SearchIcon name={'search'} />
      </FormProvider>
    </SearchWrapper>
  );
};

export default SearchInput;
