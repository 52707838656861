import {
  Button,
  Icon,
  InfoPopup,
  TabGroup,
  TabGroupOptionProps,
  Tooltip,
} from '@humanitec/ui-components';
import yaml from 'js-yaml';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import PipelineRevertModal from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineVersions/components/PipelineRevertModal/PipelineRevertModal';
import { useWalhallContext } from '@src/context/walhallContext';
import usePipelineRunCreateMutation from '@src/hooks/react-query/pipeline-runs/mutation/usePipelineRunCreateMutation';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import usePipelinesVersions from '@src/hooks/react-query/pipelines/queries/usePipelineVersionsQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { PipelineYamlSchema } from '@src/models/pipeline';
import { CodePlaceholder } from '@src/styles/global-styles';
import { PageContainer } from '@src/styles/layout/PageContainer';

import TriggerPipelineRunModal from '../../../../../../../../../../../components/shared/TriggerPipelineRunModal/TriggerPipelineRunModal';
import { PipelinesYaml } from '../PipelinesYaml/PipelinesYaml';

const Wrapper = styled(PageContainer)`
  flex-direction: column;
`;

const CustomLink = styled(Link)`
  display: inline-flex;
`;

interface PipelineDetailsProps {
  isCreation?: boolean;
}

const PipelineDetails = ({ isCreation }: PipelineDetailsProps) => {
  const { pipelineId, pipelineVersionId, appId } = useParams();
  const location = useLocation();
  const canEditPipeline = useRBAC('editApplication');

  // state
  const [openTriggerPipelineForm, setOpenTriggerPipelineForm] = useState(false);
  const [revertModalOpen, setRevertModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');
  const tabTranslations = t('TABS');
  const uiTranslations = useTranslation().t('UI');

  // react-query
  const { data: pipeline } = usePipelineDetailsQuery({
    id: pipelineId,
    versionId: pipelineVersionId,
    appId,
  });
  const { data: pipelineVersions = [] } = usePipelinesVersions();
  const { data: latestPipeline } = usePipelineDetailsQuery({
    id: pipelineVersionId ? pipelineId : undefined,
    appId,
  });
  const { data: schema = '' } = usePipelineSchemaQuery();
  const { shouldConfirmOnNavigateState } = useWalhallContext();

  const schemaObject = yaml.load(schema) as PipelineYamlSchema;
  const pipelineCall = schemaObject?.on?.pipeline_call;
  const hasDeploymentRequest = schemaObject?.on?.deployment_request;
  const { mutate: createPipelineRun } = usePipelineRunCreateMutation(pipelineId || '');
  const selectedPipelineVersion = pipelineVersions.find((p) => p.id === pipeline?.version);

  const getItems = (): TabGroupOptionProps[] => [
    {
      label: translations.RUNS,
      value: 'runs',
      link: { to: 'runs' },
    },
    {
      label: translations.DEFINITION,
      value: 'definition',
      link: { to: 'definition' },
      confirmOnLeave: shouldConfirmOnNavigateState[0],
    },
    {
      label: translations.MATCHING_CRITERIA_TITLE,
      value: 'matching-criteria',
      link: { to: 'matching-criteria' },
      disabled: !hasDeploymentRequest,
      hoverText: translations.MATCHING_CRITERIA_HOVER_TEXT,
    },
    {
      label: tabTranslations.VERSIONS,
      value: 'versions',
      link: { to: 'versions' },
    },
    {
      hide: !canEditPipeline,
      label: tabTranslations.DELETE,
      value: 'delete',
      link: { to: 'delete' },
    },
  ];

  return (
    <Wrapper>
      <PageHeader
        showPageContext={!isCreation}
        customHeading={
          isCreation ? (
            ''
          ) : (
            <div className={'flex'}>
              {pipeline?.name}{' '}
              {pipeline?.id === 'default' && (
                <InfoPopup moreInformationLink={uiTranslations.HERE}>
                  <Trans
                    defaults={translations.DEFAULT_TOOLTIP}
                    components={{
                      urlLink: (
                        <CustomLink
                          target={'_blank'}
                          to={
                            'https://developer.humanitec.com/integration-and-extensions/humanitec-pipelines/deployment-pipelines/#the-default-deployment-pipeline'
                          }>
                          {uiTranslations.HERE}
                          <Icon name={'link'} overrideColor={'main-brighter'} />
                        </CustomLink>
                      ),
                    }}>
                    {' '}
                  </Trans>
                </InfoPopup>
              )}
            </div>
          )
        }
        subtitles={
          isCreation
            ? [
                <div key={'learn-more'} className={'flex'}>
                  {translations.CREATE_NEW_PIPELINE}
                  <InfoPopup
                    text={translations.CREATE_TOOLTIP}
                    moreInformationText={uiTranslations.HERE}
                    moreInformationLink={
                      'https://developer.humanitec.com/integration-and-extensions/humanitec-pipelines/specification'
                    }
                  />
                </div>,
              ]
            : undefined
        }
        rightContent={
          !isCreation &&
          location.pathname.match(/\/(preview|definition)/) &&
          canEditPipeline && (
            <Tooltip
              offset={15}
              width={240}
              text={
                <Trans defaults={translations.CANNOT_TRIGGER_PIPELINE}>
                  <CodePlaceholder />
                </Trans>
              }
              disableTooltip={typeof pipelineCall !== undefined}
              triggerComponent={
                <Button
                  onClick={() => {
                    if (pipelineCall && isEmpty(pipelineCall?.inputs)) {
                      createPipelineRun({ inputs: { inputs: {} } });
                    } else {
                      setOpenTriggerPipelineForm(true);
                    }
                  }}
                  disabled={!canEditPipeline || typeof pipelineCall === undefined}>
                  {translations.RUN}
                </Button>
              }
            />
          )
        }
      />

      {isCreation ? (
        <TabGroup
          options={[
            {
              label: isCreation ? translations.YAML : translations.VIEW,
              value: 'view',
              element: <PipelinesYaml isCreation={isCreation} />,
            },
          ]}
        />
      ) : (
        <TabGroup options={getItems()} />
      )}
      <Outlet />
      {openTriggerPipelineForm && pipelineId && (
        <TriggerPipelineRunModal
          openState={[openTriggerPipelineForm, setOpenTriggerPipelineForm]}
          pipelineId={pipelineId}
        />
      )}
      {revertModalOpen && selectedPipelineVersion && latestPipeline && (
        <PipelineRevertModal
          openState={[revertModalOpen, setRevertModalOpen]}
          selectedPipelineVersion={selectedPipelineVersion}
          pipeline={latestPipeline}
        />
      )}
    </Wrapper>
  );
};
export default PipelineDetails;
