import {
  Button,
  Checkbox,
  DiffViewer,
  Text,
  WalModal,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { MouseEvent, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import PipelineRevertModal from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/Pipelines/components/PipelineVersions/components/PipelineRevertModal/PipelineRevertModal';
import usePipelineDetailsQuery from '@src/hooks/react-query/pipelines/queries/usePipelineDetailsQuery';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import usePipelinesVersions from '@src/hooks/react-query/pipelines/queries/usePipelineVersionsQuery';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { PipelineVersion } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { useWalhallForm } from '@src/utilities/form';

const PipelineVersions = () => {
  const canEditApp = useRBAC('editApplication');
  // i18n
  const { t } = useTranslation('viewApplication');
  const translations = t('PIPELINES');

  // Router
  const { appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;
  const { state } = useLocation();

  // State
  const [selectedPipelineVersion, setSelectedPipelineVersion] = useState<PipelineVersion>();
  const [selectedVersionId, setSelectedVersionId] = useState<string | undefined>(state?.versionId);
  const [revertModalOpen, setRevertModalOpen] = useState<boolean>(false);
  const [showDiff, setShowDiff] = useState(false);

  // React Query
  const { data: pipelineVersions = [] } = usePipelinesVersions();
  const { data: pipeline } = usePipelineDetailsQuery({ id: pipelineId, appId });
  const { data: users } = useOrgRolesQuery();
  const { data: schema = '', isLoading: isLoadingPipelineSchema } =
    usePipelineSchemaQuery(selectedVersionId);
  const { data: latestSchema = '', isLoading: isLoadingLatestPipelineSchema } =
    usePipelineSchemaQuery();

  const formMethods = useWalhallForm();

  const columns: WalTableColumn<PipelineVersion>[] = [
    {
      fixedWidth: 280,
      label: translations.VERSION_ID,
      prop: 'id',
      template: (version) => version.data.id,
    },
    {
      label: translations.EDIT_DATE,
      prop: 'created_at',
      template: (version) =>
        formatDate(version.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE),
    },
    {
      label: translations.EDIT_BY,
      prop: 'created_by',
      template: (version) => users?.find((user) => version.data.created_by === user.id)?.name,
    },
    {
      label: '',
      prop: 'revert-button',
      template: (version) =>
        pipeline?.version === version.data.id ? (
          <Button size={'small'} variant={'secondary'} disabled>
            {translations.CURRENT}
          </Button>
        ) : (
          <>
            <Button
              size={'small'}
              variant={'secondary'}
              className={'mr-md'}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedVersionId(version.data.id);
              }}>
              {translations.VIEW}
            </Button>
            {canEditApp && (
              <Button
                size={'small'}
                variant={'secondary'}
                onClick={(e) => onRevertButtonClick(e, version.data)}>
                {translations.REVERT}
              </Button>
            )}
          </>
        ),
    },
  ];

  const onRevertButtonClick = (e: MouseEvent, version: PipelineVersion) => {
    e.stopPropagation();
    setSelectedPipelineVersion(version);
    setRevertModalOpen(true);
  };

  useEffect(() => {
    if (!selectedVersionId) {
      setShowDiff(false);
      formMethods.setValue('show-diff-toggle', false);
    }
  }, [formMethods, selectedVersionId]);

  return (
    <>
      <WalTable
        rows={pipelineVersions?.map((version) => ({
          data: version,
        }))}
        columns={columns}
        caption={translations.PIPELINE_VERSIONS}
      />
      {revertModalOpen && selectedPipelineVersion && pipeline && (
        <PipelineRevertModal
          openState={[revertModalOpen, setRevertModalOpen]}
          selectedPipelineVersion={selectedPipelineVersion}
          pipeline={pipeline}
        />
      )}
      {selectedVersionId && (
        <WalModal
          size={'large'}
          showClose
          title={pipeline?.name}
          subTitle={<Text color={'textTranslucent'}>{selectedVersionId}</Text>}
          openState={[
            Boolean(selectedVersionId),
            (val) => (!val ? setSelectedVersionId(undefined) : selectedVersionId),
          ]}
          content={
            !isLoadingPipelineSchema &&
            !isLoadingLatestPipelineSchema && (
              <FormProvider {...formMethods}>
                <Checkbox
                  className={'mt-lg mb-md'}
                  name={'show-diff-toggle'}
                  handleChange={() => setShowDiff(!showDiff)}
                  label={translations.DIFF_VERSION_TEXT}
                />
                <DiffViewer
                  rounded
                  showDiffOnly={false}
                  showNoChangesText={showDiff}
                  newValue={!showDiff ? schema : latestSchema}
                  oldValue={schema}
                />
              </FormProvider>
            )
          }
        />
      )}
    </>
  );
};

export default PipelineVersions;
