import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import Icon from './Icon/Icon';

const Cross = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`;

interface CloseIconProps {
  onClick: MouseEventHandler;
  ariaLabel?: string;
}
export const CloseIcon = ({ onClick, ariaLabel }: CloseIconProps) => {
  return (
    <Cross role={'button'} onClick={onClick} aria-label={ariaLabel || 'close-icon'}>
      <Icon name={'cross'} />
    </Cross>
  );
};
