import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import useEnvironmentRulesQuery from '@src/hooks/react-query/rules/queries/useEnvironmentRulesQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { Rule } from '@src/models/rule';
import { units } from '@src/styles/variables';

import EnvRuleEntry from './EnvRuleEntry';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const Subtitle = styled.span`
  margin-bottom: ${units.margin.md};
  font-size: ${units.fontSize.base};
`;

const AddRuleButton = styled(Button)`
  margin-bottom: ${units.margin.lg};
`;

const EnvAutomation = () => {
  // i18n
  const { t } = useTranslation();
  const envAutomationModalTranslations = t('ENVIRONMENT_AUTOMATION_MODAL', {
    returnObjects: true,
  });

  const [envRuleDraft, setEnvRuleDraft] = useState<Rule>();

  // Context
  const canDeployEnv = useRBAC('deployEnvironment');
  const { data: environmentRules = [] } = useEnvironmentRulesQuery();

  const onAddRuleClick = () => {
    setEnvRuleDraft({
      active: true,
      artefacts_filter: [],
      match_ref: '',
      type: 'update',
      exclude_artefacts_filter: false,
      id: 'id',
    });
  };

  return (
    <Wrapper>
      <Subtitle>{envAutomationModalTranslations.AUTOMATION_MODAL_SUBTITLE}</Subtitle>
      {canDeployEnv && (
        <AddRuleButton
          variant={'secondary'}
          size={'medium'}
          iconLeft={'plus'}
          onClick={onAddRuleClick}>
          {environmentRules.length === 0
            ? envAutomationModalTranslations.ADD_RULE
            : envAutomationModalTranslations.ADD_ANOTHER_RULE}
        </AddRuleButton>
      )}
      {environmentRules.map((rule) => (
        <EnvRuleEntry rule={rule} key={rule.id} newlyCreated={false} />
      ))}
      {envRuleDraft && (
        <EnvRuleEntry
          rule={envRuleDraft}
          newlyCreated
          onDiscard={() => setEnvRuleDraft(undefined)}
        />
      )}
    </Wrapper>
  );
};

export default EnvAutomation;
