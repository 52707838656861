import { rem } from 'polished';
import { ReactNode } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { units } from '@src/styles/variables';

import { ButtonProps } from '../../base/Button/Button';
import { FormFieldGeneratorDefinition, FormGenerator } from '../FormGenerator/FormGenerator';
import { WalTable, WalTableProps } from '../Table/Table';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div<{ $maxWidth?: number }>`
  display: flex;
  justify-content: center;
  margin-bottom: ${units.margin.lg};
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth && rem($maxWidth)};
`;

const TableElement = styled(WalTable)<{ disableOverflow?: boolean }>`
  ${({ disableOverflow }) =>
    !disableOverflow &&
    css`
      overflow: auto;
    `}
`;

const Form = styled(FormGenerator)`
  width: 100%;
`;

interface FormTableProps {
  table: Pick<WalTableProps, 'columns' | 'rows' | 'caption'>;
  form?: {
    submitButton: ButtonProps;
    onSubmit: (formValues: any) => void;
    fields: FormFieldGeneratorDefinition[];
    formSubmitting?: boolean;
    /** Sometimes an extra error(one that is not associated with any of the inputs) needs to be displayed */
    extraErrors?: {
      name: string;
      label: string;
    }[];
  };
  formMaxWidth?: number;
  customForm?: ReactNode;
  formGridTemplate?: FlattenSimpleInterpolation;
  hideForm?: boolean;
  outerRightRowElementColumnWidth?: number;
  disableOverflow?: boolean;
  showCount?: boolean;
}

const FormTable = ({
  formGridTemplate,
  form,
  formMaxWidth,
  customForm,
  hideForm,
  table,
  disableOverflow,
  showCount,
}: FormTableProps) => (
  <Wrapper>
    {!hideForm && (
      <FormWrapper $maxWidth={formMaxWidth}>
        {form ? <Form gridTemplate={formGridTemplate} size={'large'} {...form} /> : customForm}
      </FormWrapper>
    )}
    <TableElement
      showCount={showCount}
      disableOverflow={disableOverflow}
      disableScrolling={disableOverflow}
      {...table}
    />
  </Wrapper>
);

export default FormTable;
