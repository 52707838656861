import { Button } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { getSelectedOrganization } from '@src/utilities/local-storage';

const DefaultErrorPageButtons = () => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <>
      <Button
        variant={'secondary'}
        size={'medium'}
        className={'mr-md'}
        link={{ href: `/orgs/${getSelectedOrganization()}/apps` }}>
        {uiTranslations.GO_TO_APPS_OVERVIEW}
      </Button>
      <Button
        variant={'secondary'}
        size={'medium'}
        target={'_blank'}
        link={{ href: 'https://developer.humanitec.com/getting-started/help/' }}>
        {uiTranslations.HELP}
      </Button>
    </>
  );
};

export default DefaultErrorPageButtons;
