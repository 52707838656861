import {
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { Button } from '../../base/Button/Button';
import { Spinner } from '../../base/Spinner/Spinner';
import { MenuItem, WalMenu } from '../Menu/Menu';

const ActionButton = styled(Button)`
  align-self: normal;
  justify-self: flex-start;
`;

const Menu = styled(WalMenu)`
  align-self: normal;
`;

const DropdownLabelText = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

interface TabItem {
  id: string;
  component: ReactNode;
  link?: Pick<LinkProps, 'to' | 'state'>;
}

interface TabsSwitcherProps {
  /* tabs to be shown **/
  tabs: TabItem[];
  /* open add modal set state variable **/
  setOpenCreateModal: Dispatch<SetStateAction<boolean>>;
  /* text for creating a new tab **/
  createNewTabText: string;
  /** id of the selected tab by default */
  defaultTabId?: string;
  /* max tabs before showing the additional tabs dropdown **/
  maxTabs?: number;
  /* if a tab is being created **/
  isCreating?: boolean;
  dropdownTitle?: string;

  /* if the tabs switcher is readonly (no tab creation possible) **/
  readonly?: boolean;
  /* callback for once an item is clicked from the dropdown */
  onDropdownItemClick?: (e: MouseEvent | KeyboardEvent, tabId: string) => void;
}

export const TabsSwitcher = ({
  tabs,
  maxTabs = 3,
  dropdownTitle,
  createNewTabText,
  isCreating,
  readonly,
  setOpenCreateModal,
  defaultTabId,
  onDropdownItemClick,
}: TabsSwitcherProps) => {
  // Component state
  const [tabArr, setTabArr] = useState<TabItem[]>([]);

  useEffect(() => {
    if (tabs?.length !== tabArr.length) {
      setTabArr(tabs);
    }
  }, [tabs, tabArr.length]);

  const swapLastTab = useCallback(
    (tabId: string) => {
      const newTabArr = [...tabArr];
      // swap the last tab with the selected tab from the dropdown
      const selectedTabIndex = newTabArr?.findIndex((tab: TabItem) => tab.id === tabId);
      const temp = newTabArr[maxTabs - 1];

      const lastTabIndex = maxTabs - 1;

      const selectedItem = newTabArr[selectedTabIndex];
      if (newTabArr?.[lastTabIndex] && selectedItem) {
        newTabArr![lastTabIndex] = selectedItem;
      }

      if (temp) {
        newTabArr![selectedTabIndex] = temp;
      }

      setTabArr(newTabArr);
    },
    [tabArr, maxTabs]
  );

  useEffect(() => {
    // swap tabs if the default tab is under the addtional tabs dropdown
    if (defaultTabId && tabArr.findIndex((tab) => tab.id === defaultTabId) > maxTabs - 1) {
      swapLastTab(defaultTabId);
    }
  }, [defaultTabId, tabArr, swapLastTab, maxTabs]);

  const tabDropdownItems =
    tabArr.length > maxTabs &&
    tabArr.slice(maxTabs, tabArr.length).map((tab) => {
      return {
        label: tab.id,
        value: tab.id,
        link: tab.link,
      };
    });

  const handleDropdownClick = (menuOption: MenuItem<string>, e: MouseEvent | KeyboardEvent) => {
    swapLastTab(menuOption.value);
    if (onDropdownItemClick) {
      onDropdownItemClick(e, menuOption.value);
    }
  };

  const shownTabs: (ReactNode | undefined)[] = tabArr.slice(0, maxTabs).map((shownTab) => {
    const tabItem = tabs && tabs.find((tab) => tab?.id === shownTab?.id);
    return tabItem?.component;
  });

  const createTab = () => {
    setOpenCreateModal(true);
  };

  return (
    <>
      {shownTabs}
      {tabDropdownItems && (
        <Menu
          onItemClick={handleDropdownClick}
          maxHeight={200}
          items={tabDropdownItems}
          overflowScrollable
          fixedTopRowComponent={
            dropdownTitle && <DropdownLabelText>{dropdownTitle}</DropdownLabelText>
          }
          fixedBottomRow={
            !readonly
              ? {
                  component: <span>{createNewTabText}</span>,
                  onClick: createTab,
                }
              : undefined
          }
          panel={{ placement: 'bottom-end' }}
          toggle={
            <ActionButton
              variant={'secondary'}
              size={'medium'}
              ariaLabel={dropdownTitle}
              iconLeft={'arrow-down'}
            />
          }
        />
      )}
      {!readonly && !tabDropdownItems && (
        <ActionButton
          onClick={createTab}
          dataTestid={'add-tab-button'}
          size={'medium'}
          variant={'secondary'}
          ariaLabel={createNewTabText}
          iconLeft={isCreating ? undefined : 'plus'}>
          {isCreating ? <Spinner diameter={12} /> : tabs.length === 0 ? createNewTabText : ''}
        </ActionButton>
      )}
    </>
  );
};

export default TabsSwitcher;
