import { datadogRum } from '@datadog/browser-rum';

import { BuildEnvironmentNames } from '@src/models/build-environment';
import { User } from '@src/models/user';
import { makeRequest } from '@src/utilities/make-request';

interface HubspotInterface {
  fields: {
    name: string;
    value: string;
  }[];
  skipValidation: true;
  context?: {
    // Hubspot cookie
    hutk: string;
  };
}

/**
 * Hubspot is used for tracking leads by the marketing & sales teams.
 * We post to a specific form for tracking new sign ups. We send the user's email in all cases.
 *
 * We were previously sending the orgId for new sign ups as implemented here - https://humanitec.atlassian.net/browse/WAL-2873
 * But it seems that this functionality was (accidentally?) removed in this PR - https://github.com/Humanitec/walhall-react/commit/6f5f471776b7a9f24d2fffcefad2b9a3162052a8#diff-6321f9158f33721f75d181321f11e531066b207b09df8665a6a4de6893f018a2R78-R79.
 * Tracking the bug in this ticket - https://humanitec.atlassian.net/browse/WAL-7607.
 *
 * @param user The current user
 * @param orgId The user's orgId (only sent in case of accept invite)
 */
export const hubspotLogin = (
  user: User,
  buildEnvironmentName: BuildEnvironmentNames,
  orgId?: string
) => {
  const hubspotpayload: HubspotInterface = {
    fields: [
      {
        name: 'uuid',
        value: user.id,
      },
    ],
    skipValidation: true,
  };

  // Email is not always available. Only add it if it's available.
  if (user.email) {
    hubspotpayload.fields.push({
      name: 'email',
      value: user.email,
    });
  }

  if (user.name) {
    hubspotpayload.fields.push({
      name: 'name',
      value: user.name,
    });
  }

  if (orgId) {
    hubspotpayload.fields.push({
      name: 'organization_id',
      value: orgId,
    });
  }

  const cookieValue = document.cookie.split('; ').find((row) => row.startsWith('hubspotutk'));
  const hutkCookie = cookieValue && cookieValue.split('=')[1];
  if (hutkCookie) {
    hubspotpayload.context = {
      hutk: hutkCookie,
    };
  }

  // Send datadog event so we can see the payload being sent on dev, staging etc.
  datadogRum.addAction('sending hubspot login', hubspotpayload);

  // Only send on production so we don't pollute the entries with testing data
  if (buildEnvironmentName === 'production') {
    // Create hubspot user
    makeRequest(
      'POST',
      'https://api.hsforms.com/submissions/v3/integration/submit/5890440/aecbed31-3e93-4e4a-a2ef-fc9857b97deb',
      hubspotpayload,
      true
    );
  }
};
