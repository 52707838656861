import { EmptyStateCard, WalCard, WalLabel } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import useResourceDriversQuery from '@src/hooks/react-query/resources/queries/useResourceDriversQuery';
import { ResourceDriver, ResourceType } from '@src/models/resources';
import { units } from '@src/styles/variables';

const DriversSectionWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;

const DriverCard = styled(WalCard)`
  margin-bottom: ${units.margin.sm};
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const DriveNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface SelectResourceDriverStepProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setSelectedDriver: Dispatch<SetStateAction<ResourceDriver | undefined>>;
  selectedResourceType?: ResourceType;
}
const SelectResourceDriverStep = ({
  selectedResourceType,
  setCurrentStep,
  setSelectedDriver,
}: SelectResourceDriverStepProps) => {
  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  // React query
  const { data: resourceDrivers } = useResourceDriversQuery();

  const hasResourceOutputsSchema =
    Object.keys(selectedResourceType?.outputs_schema?.properties || {}).length ||
    selectedResourceType?.outputs_schema?.additionalProperties;

  const handleDriverCardClick = (driver: ResourceDriver) => {
    setSelectedDriver(driver);
    setCurrentStep(2);
  };

  const getDrivers = (customDrivers?: boolean) => {
    const drivers = resourceDrivers?.filter((driver) =>
      customDrivers ? driver.org_id !== 'humanitec' : driver.org_id === 'humanitec'
    );

    return drivers?.filter(
      (driver) =>
        selectedResourceType &&
        (driver.type === selectedResourceType.type || driver.type === '') &&
        driver.inputs_schema &&
        // Don't show static or echo drivers if there's no corresponding resource schema
        !((driver.id === 'static' || driver.id === 'echo') && !hasResourceOutputsSchema)
    );
  };

  const renderDrivers = (customDrivers?: boolean) => {
    return getDrivers(customDrivers)
      ?.sort((a, b) => (a.id > b.id ? 1 : -1))
      .map((driver) => (
        <DriverCard
          dataTestId={`${driver.org_id === 'humanitec' ? 'humanitec' : 'custom'}-driver-card`}
          key={driver.id}
          cardStyle={'base'}
          showRightArrow={driver.id !== 'static'}
          highlightOnHover={driver.id !== 'static'}
          onClick={driver.id !== 'static' ? () => handleDriverCardClick(driver) : undefined}>
          <DriveNameWrapper>
            <span className={`${driver.id === 'static' ? 'txt-translucent' : ''}`}>
              {driver.id}
            </span>
            {driver.id === 'static' && (
              <span className={'txt-sm txt-translucent'}>
                {resourcesTranslations.STATIC_DRIVER_DEPRECATED}
              </span>
            )}
          </DriveNameWrapper>
        </DriverCard>
      ));
  };

  return (
    <>
      <DriversSectionWrapper>
        <WalLabel>{resourcesTranslations.HUMANITEC_DRIVERS}</WalLabel>
        {renderDrivers()}
      </DriversSectionWrapper>
      <DriversSectionWrapper>
        <WalLabel>{resourcesTranslations.CUSTOM_DRIVERS}</WalLabel>
        {getDrivers(true)?.length === 0 ? (
          <EmptyStateCard>{resourcesTranslations.NO_CUSTOM_DRIVERS}</EmptyStateCard>
        ) : (
          renderDrivers(true)
        )}
      </DriversSectionWrapper>
    </>
  );
};

export default SelectResourceDriverStep;
