import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ResourceDefinitionVersion } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

export const useResourceDefinitionVersionsQuery = () => {
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  return useQuery({
    queryKey: resourcesQueryKeys.definitionVersionsList(orgId, defId),
    queryFn: () =>
      makeRequest<ResourceDefinitionVersion[]>(
        'GET',
        `/orgs/${orgId}/resources/defs/${defId}/versions`
      ),
    enabled: Boolean(orgId && defId),
    select: (data) => data.data,
  });
};
