import i18n from '@src/i18n/i18n';
import { ResourceDefinitionVersion } from '@src/models/resources';

const translations = i18n.t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;

export const getVersionStatus = (
  version: ResourceDefinitionVersion,
  isLatest: boolean | undefined
) =>
  version.archived
    ? translations.ARCHIVED
    : version.active
      ? translations.ACTIVE
      : version.proposed
        ? translations.PROPOSED
        : isLatest
          ? translations.LATEST
          : translations.OUTDATED;

/**
 It returns a partial object from the version, this object only includes the properties that should be included in the diff
 
 * @param resourceDefinitionVersion
 */
export const getPartialResourceDefinitionObjectToCompare = (
  resourceDefinitionVersion: ResourceDefinitionVersion
) => ({
  name: resourceDefinitionVersion.name,
  driver_inputs: resourceDefinitionVersion?.driver_inputs,
  provision:
    resourceDefinitionVersion?.provision &&
    Object.keys(resourceDefinitionVersion?.provision).length > 0
      ? resourceDefinitionVersion.provision
      : undefined,
});
