import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { resourceAccountsQueryKeys } from '@src/hooks/react-query/resource-accounts/resourceAccountsQueryKeys';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ActiveResource, ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

export interface DeleteAccountAPIResponseDetails {
  referencing_resources: {
    active_resources: ActiveResource[];
    resource_definitions: ResourceDefinition[];
  };
}
const useAccountDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError<ApiErrorResponse<DeleteAccountAPIResponseDetails>>,
    string
  >({
    mutationFn: (accountId) =>
      makeRequest('DELETE', `/orgs/${orgId}/resources/accounts/${accountId}`),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: resourceAccountsQueryKeys.listResourceAccounts(orgId),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useAccountDeleteMutation;
