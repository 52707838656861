import { position } from 'caret-pos';

/**
 * moves the caret position to the end of an html element
 *
 * @param element - html element
 * @param offset -  offset of the caret from the element length
 */
export const setCaretPositionToElement = (element: any, offset = 0) => {
  if (element) {
    const range = document.createRange();
    const sel = window.getSelection();
    const lastChildIndex = element.childNodes.length - 1;
    if (element.childNodes[lastChildIndex]) {
      try {
        range.setStart(
          element.childNodes[lastChildIndex],
          element.childNodes[lastChildIndex].textContent.length + offset
        );
        range.collapse(true);
      } catch (_) {
        return false;
      }
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }
};

export const getCaretPosition = (element: HTMLElement): number => {
  return position(element).pos;
};

export const setCaretPosition = (element: HTMLElement, pos: number): void => {
  position(element, pos);
};
