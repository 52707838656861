import { IconNames } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import {
  generateAppsListURL,
  generateResourcesUrl,
  generateSettingsURL,
} from '@src/utilities/navigation';

import { useRBAC } from './useRBAC';

interface LinkProps {
  to: string;
  label: string;
  icon: IconNames;
  endOfGroup?: boolean;
  canAccess: boolean;
}

/**
 * Hook which returns the navigation list for navbar. This is used for 2 purposes:
 * 1. Rendering the items on the NavigationBar component.
 * 2. Rendering the items on the "Org level settings" pages e.g. Resource definitions, Registries etc.
 *
 * This hook takes care of handling RBAC for each item.
 */
export const useGetNavigationPermissions = (orgId: string) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('NAVIGATION');

  const canViewApplications = useRBAC('viewApplications', { orgId });
  const canViewResourcesPage = useRBAC('viewResources', { orgId });
  const canViewRegistriesPage = useRBAC('viewRegistries', { orgId });
  const canViewEnvironmentTypesPage = useRBAC('viewEnvironmentTypesPage', { orgId });
  const canViewAccountsPage = useRBAC('viewAccountsPage', { orgId });
  const canViewImagesPage = useRBAC('viewImagesPage', { orgId });
  const canViewServiceUsersPage = useRBAC('viewServiceUsersPage', { orgId });
  const canViewOrgMembersPage = useRBAC('viewOrgMembersPage', { orgId });

  const items: LinkProps[] = [
    {
      to: generateAppsListURL(orgId),
      label: translations.APPLICATIONS,
      icon: 'applications',
      canAccess: canViewApplications,
      endOfGroup: true,
    },
    {
      to: generateResourcesUrl(orgId),
      label: translations.RESOURCES,
      icon: 'resources',
      canAccess: canViewResourcesPage,
    },
    {
      to: generateSettingsURL(orgId, 'registries'),
      label: translations.REGISTRIES,
      icon: 'registries',
      canAccess: canViewRegistriesPage,
    },
    {
      to: generateSettingsURL(orgId, 'environment-types'),
      label: translations.ENVIRONMENT_TYPES,
      icon: 'environment-types',
      canAccess: canViewEnvironmentTypesPage,
    },
    {
      to: generateSettingsURL(orgId, 'accounts'),
      label: translations.CLOUD_ACCOUNTS,
      icon: 'cloud-accounts',
      canAccess: canViewAccountsPage,
    },
    {
      to: generateSettingsURL(orgId, 'images'),
      label: translations.CONTAINER_IMAGES,
      icon: 'container-images',
      canAccess: canViewImagesPage,
    },
    {
      to: generateSettingsURL(orgId, 'service-users'),
      label: translations.SERVICE_USERS,
      icon: 'api-tokens',
      canAccess: canViewServiceUsersPage,
    },
    {
      to: generateSettingsURL(orgId, 'org-members'),
      label: translations.ORG_MEMBERS,
      icon: 'org-members',
      canAccess: canViewOrgMembersPage,
      endOfGroup: true,
    },
  ];

  return items.filter((item) => item.canAccess);
};
