import { rem } from 'polished';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { Button } from '../../base/Button/Button';

const Wrapper = styled.div`
  position: absolute;
  top: ${rem(25)};
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: ${units.margin.sm};
  align-items: center;
  background-color: ${({ theme }) => theme.color.baseLayer};
  padding: ${units.margin.sm} ${units.margin.md};
  border-radius: ${rem(4)};
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
  font-size: ${units.fontSize.sm};
  z-index: 10;
`;

const ConfirmText = styled.span`
  margin-right: ${units.margin.sm};
`;

interface ConfirmationDialogProps {
  hiddenState: [boolean, Dispatch<SetStateAction<boolean>>];
  onConfirm: () => void;
  confirmationButtonText?: string;
  confirmationMessage?: string;
}

const ConfirmationDialog = ({
  hiddenState,
  onConfirm,
  confirmationButtonText,
  confirmationMessage,
}: ConfirmationDialogProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [hidden, setHidden] = hiddenState;
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRef.current?.contains(event.target as Node)) {
        return;
      }
      if (setHidden) {
        setHidden(true);
      }
    };
    if (!hidden) {
      document.addEventListener('pointerdown', handleClickOutside);
    } else {
      document.removeEventListener('pointerdown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [hidden, setHidden]);

  const handleConfirm = () => {
    onConfirm();
    setHidden(true);
  };

  return (
    <Wrapper ref={wrapperRef} data-testid={'quick-delete-dialog'}>
      <ConfirmText>{confirmationMessage || uiTranslations.ARE_YOU_SURE}</ConfirmText>
      <Button
        variant={'secondary'}
        onClick={(e) => {
          e.stopPropagation();
          setHidden(true);
        }}
        size={'small'}>
        {uiTranslations.CANCEL}
      </Button>
      <Button
        variant={'danger'}
        onClick={(e) => {
          e.stopPropagation();
          handleConfirm();
        }}
        size={'small'}>
        {confirmationButtonText || uiTranslations.DELETE}
      </Button>
    </Wrapper>
  );
};

export default ConfirmationDialog;
