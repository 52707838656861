import { Spinner } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Status from '@src/components/shared/Status';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { useGetReplicaSetRuntimeStatus } from '@src/hooks/useGetReplicaSetRuntimeStatus';
import { MatchParams } from '@src/models/routing';

export interface WorkLoadStatusProps {
  workloadId: string;
}

export const WorkloadStatus = ({ workloadId }: WorkLoadStatusProps) => {
  // Router hooks
  const { envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const runtimeStatus = useGetReplicaSetRuntimeStatus(workloadId);
  const { data: environment } = useEnvironmentQuery();
  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery();

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // i18n
  const { t: tViewDeployment } = useTranslation('viewDeployment');
  const workloadTranslations = tViewDeployment('WORKLOADS');

  if (draftModeActive) {
    return <></>;
  }

  if (environment?.last_deploy?.status === 'in progress') {
    return (
      <span className={'flex align-center gap-sm'}>
        <Spinner size={'small'} />
      </span>
    );
  } else {
    if (envPauseStatusMap?.[envId]) {
      return <Status color={'text'} text={workloadTranslations.STOPPED} />;
    }
    switch (runtimeStatus) {
      case 'Success':
        return <Status color={'green'} text={workloadTranslations.HEALTHY} />;
      case 'Failure':
        return <Status color={'red'} text={workloadTranslations.FAILED} />;
      case 'Warning':
        return <Status color={'yellow'} text={workloadTranslations.WARNING} />;
      case 'Unknown':
        return <Status color={'text-translucent'} text={workloadTranslations.UNKNOWN} />;
      case 'Stopped':
      default:
        return <Status color={'text'} text={workloadTranslations.STOPPED} />;
    }
  }
};
