import styled from 'styled-components';

import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

import Icon from '../Icon/Icon';

export interface SecretValueProps {
  /**
   * Maximum width of the text to show ellipsis
   */
  maxWidth?: number;
  /**
   * secretVersion that needs to be displayed
   */
  secretVersion?: string;
  secretKey?: string;
}

const SecretVersionNumber = styled.span<{ maxWidth?: number }>`
  ${({ maxWidth }) => ellipsisStyle(maxWidth)}
  padding-left: ${units.padding.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const SecretWrapper = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Component that can be used to display a secret value with a secret id
 */
const SecretValue = ({ secretVersion, secretKey, maxWidth }: SecretValueProps) => {
  return (
    <SecretWrapper>
      <Icon name={'lock'} size={14} />
      {
        <SecretVersionNumber maxWidth={maxWidth}>
          {secretKey && <span>{secretKey}</span>}
          {secretVersion && <span>({secretVersion})</span>}
        </SecretVersionNumber>
      }
    </SecretWrapper>
  );
};

export default SecretValue;
