import { Button, EmptyStateCard } from '@humanitec/ui-components';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { units } from '@src/styles/variables';

import DeploymentCard from './components/DeploymentCard';

const ShowMoreButton = styled(Button)`
  align-self: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const DISPLAYED_DEPLOYMENTS = 10;

const Deploys = () => {
  // Component state
  const [displayedDeployments, setDisplayedDeployments] = useState(DISPLAYED_DEPLOYMENTS);
  // i18n
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const deploysTranslations = tViewEnvironment('DEPLOYS');

  // React Query
  const { data: environmentDeployments = [], isLoading: areEnvironmentDeploymentsLoading } =
    useDeploymentsListQuery();
  const { data: environment } = useEnvironmentQuery();

  const pastDeployments = useMemo(
    () => environmentDeployments.filter((deploy) => deploy.id !== environment?.last_deploy?.id),
    [environment?.last_deploy?.id, environmentDeployments]
  );

  const lastDeployment = useMemo(
    () => environmentDeployments.find((deploy) => deploy.id === environment?.last_deploy?.id),
    [environment?.last_deploy?.id, environmentDeployments]
  );

  const hasDeployment = Boolean(pastDeployments.length || environment?.last_deploy);

  return (
    <Wrapper>
      {areEnvironmentDeploymentsLoading ? (
        <span className={'flex-column gap-md'}>
          <SkeletonGroup count={3} />
        </span>
      ) : (
        <>
          {!hasDeployment && (
            <EmptyStateCard>
              <p className={'mb-sm'}>{deploysTranslations.NO_DEPLOYS_TO_THIS_ENVIRONMENT}</p>
              {
                <p>
                  <Trans defaults={deploysTranslations.CLICK_CREATE_DELTA} />
                </p>
              }
            </EmptyStateCard>
          )}
          {
            <>
              {lastDeployment && (
                <>
                  <DeploymentCard
                    deploy={lastDeployment}
                    lastPastDeploymentId={pastDeployments[0]?.id}
                    isActiveDeployment
                  />
                </>
              )}
              {pastDeployments.slice(0, displayedDeployments).map((deploy) => (
                <DeploymentCard key={deploy.id} deploy={deploy} />
              ))}
              {pastDeployments.length > displayedDeployments && (
                <ShowMoreButton
                  variant={'secondary'}
                  onClick={() =>
                    setDisplayedDeployments((prevState) => prevState + DISPLAYED_DEPLOYMENTS)
                  }>
                  {deploysTranslations.SHOW_MORE}
                </ShowMoreButton>
              )}
            </>
          }
        </>
      )}
    </Wrapper>
  );
};

export default Deploys;
