import { Button, SearchInput } from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { units } from '@src/styles/variables';

import AddNewServiceUserModal from './components/AddNewServiceUserModal/AddNewServiceUserModal';
import ServiceUsersTable from './components/ServiceUsersTable';

const AddServiceUsersWrapper = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${units.padding.xxl};
`;

const ServiceUsers = () => {
  // Component state
  const [openAddServiceUsersModal, setOpenAddServiceUsersModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  // i18n
  const { t } = useTranslation();
  const serviceUsersTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS;

  // React Query
  const { data: users } = useOrgRolesQuery();

  const filteredServiceUsers = useMemo(() => {
    // Filters out service users from list of users received.
    return (
      users?.filter(
        (user) =>
          user.type === 'service' &&
          (searchValue ? user.name.toLowerCase()?.includes(searchValue) : true) // filter service users by search term if one is inputted
      ) || []
    );
  }, [searchValue, users]);

  const handleFilterInputChange = (value: string) => {
    setSearchValue(value.toLowerCase());
  };

  return (
    <SectionsSettingWrapper
      rightContent={
        <SearchInput
          defaultValue={''}
          name={'filter-service-users'}
          placeholder={serviceUsersTranslations.FILTER_BY_NAME}
          onChange={handleFilterInputChange}
        />
      }>
      <AddServiceUsersWrapper>
        <Button
          variant={'primary'}
          iconLeft={'plus'}
          onClick={() => setOpenAddServiceUsersModal(true)}>
          {serviceUsersTranslations.CREATE_NEW_SERVICE_USER}
        </Button>
      </AddServiceUsersWrapper>

      {openAddServiceUsersModal && (
        <AddNewServiceUserModal
          openState={[openAddServiceUsersModal, setOpenAddServiceUsersModal]}
        />
      )}
      <ServiceUsersTable serviceUsers={filteredServiceUsers} />
    </SectionsSettingWrapper>
  );
};

export default ServiceUsers;
