import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ReadMoreProps {
  roleType: 'org' | 'app' | 'env';
}

const ReadMore = ({ roleType }: ReadMoreProps) => {
  // i18n
  const { t } = useTranslation('orgMembers');

  return (
    <span>
      <span>{t('YOU_CAN')}</span>
      <Link
        target={'_blank'}
        to={
          roleType === 'org'
            ? 'https://developer.humanitec.com/platform-orchestrator/security/rbac/#organization-level-roles'
            : roleType === 'app'
              ? 'https://developer.humanitec.com/platform-orchestrator/security/rbac/#application-level-roles'
              : 'https://developer.humanitec.com/platform-orchestrator/security/rbac/#environment-type-level-roles'
        }>
        {t('READ_MORE')}
      </Link>
      .
    </span>
  );
};

export default ReadMore;
