import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { pipelineRunsQueryKeys } from '@src/hooks/react-query/pipeline-runs/queries/pipelineRunsQueryKeys';
import { useEnvironmentCloneStore } from '@src/hooks/zustand/useEnvironmentCloneStore';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { PipelineRun } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { generatePipelinesURL } from '@src/utilities/navigation';

import { deploymentDeltaQueryKeys } from '../../deployment-delta/deploymentDeltaQueryKeys';
import useDeploymentDeltaMetadataMutation from '../../deployment-delta/mutations/useDeploymentDeltaMetadataMutation';
import { environmentQueryKeys } from '../../environments/environmentQueryKeys';
import { userQueryKeys } from '../../user/userQueryKeys';

const usePipelineDeployMutation = (customParams?: {
  appId?: string;
  envId?: string;
  onSuccess?: (res: PipelineRun) => void;
}) => {
  // Router hooks
  const navigate = useNavigate();
  const { orgId, appId, envId: routerEnvId } = useParams<keyof MatchParams>() as MatchParams;

  const envId = customParams?.envId || routerEnvId;
  // zustand
  const { deleteEnvironmentClone } = useEnvironmentCloneStore();

  // react-query
  const queryClient = useQueryClient();
  const { mutate: updateDeploymentDeltaMetadata } = useDeploymentDeltaMetadataMutation();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<PipelineRun>,
    AxiosError<ApiErrorResponse>,
    {
      inputs: {
        delta_id?: string;
        comment?: string;
        set_id?: string;
        env_id?: string;
        value_set_version_id?: string;
      };
      navigate?: {
        navigateFn: NavigateFunction;
        url: string;
      };
    }
  >({
    mutationFn: ({ inputs }) => {
      const deploymentPayload = {
        ...inputs,
        environment: envId,
        trigger: 'deployment_request',
      };
      return makeRequest('POST', `/orgs/${orgId}/apps/${appId}/pipeline-runs`, deploymentPayload);
    },
    onSuccess: (res, { inputs }) => {
      queryClient.invalidateQueries({
        queryKey: pipelineRunsQueryKeys.pipelineRuns(orgId, appId, envId),
      });
      if (customParams?.onSuccess) {
        customParams.onSuccess(res.data);
      } else {
        navigate(
          generatePipelinesURL(res.data.org_id, res.data.app_id, res.data.pipeline_id, res.data.id)
        );
      }

      if (inputs.delta_id) {
        // Invalidate the delta that was deployed
        updateDeploymentDeltaMetadata({
          deltaId: inputs.delta_id,
          metadata: 'archived',
          value: true,
        });
      }

      queryClient.invalidateQueries({
        queryKey: userQueryKeys.currentUser(),
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentDetail(orgId, appId, envId),
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.applicationEnvironments(orgId, appId),
      });

      deleteEnvironmentClone(appId, envId);

      // Invalidate deployments list
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.listEnvironmentDeployments(orgId, appId, envId),
      });

      // Invalidate individual delta
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.detail(orgId, appId, inputs.delta_id),
      });
      // Invalidate all deltas
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.list(orgId, appId, { env: envId }),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineDeployMutation;
