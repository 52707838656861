import { Button, WalCard } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { units } from '@src/styles/variables';

const Card = styled(WalCard)<{ $last: boolean }>`
  ${({ $last }) =>
    !$last &&
    css`
      margin-bottom: ${units.margin.md};
    `}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface ContainerCardProps {
  containerId: string;
  /** To tell if this is the last item. No bottom padding will be added if it's the last entry */
  last: boolean;
  /** path to navigate to */
  to: string;
  /** if true, card is in readonly mode & will only display `View Details` button */
  readonly: boolean;
  /** The path to the container in the delta */
  deltaPath: string;
}

const ContainerCard = ({ containerId, last, readonly, to, deltaPath }: ContainerCardProps) => {
  // Component state
  const [deleteContainerModalOpen, setDeleteContainerModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const { updateWorkload } = useDeltaUtils(deltaPath, () => {
    setDeleteContainerModalOpen(false);
  });

  return (
    <>
      <ConfirmDeleteModal
        state={[deleteContainerModalOpen, setDeleteContainerModalOpen]}
        deleteConfirmedCallback={() => {
          updateWorkload([{ op: 'remove' }]);
        }}
      />
      <Card $last={last} cardStyle={'base'} key={containerId} dataTestId={'container-card'}>
        <Link to={to} className={'title-link'}>
          {containerId}
        </Link>
        {!readonly && (
          <Button
            variant={'secondary'}
            iconLeft={'delete'}
            ariaLabel={uiTranslations.DELETE}
            onClick={() => setDeleteContainerModalOpen(true)}
          />
        )}
      </Card>
    </>
  );
};

export default ContainerCard;
