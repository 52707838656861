import { WalCard, WalLabel } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { ResourceCategory, ResourceType, ResourceUse } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { allLowerCaseExceptFirstWord } from '@src/utilities/string-utility';

export const getResourceSection = (resourceCategory: ResourceCategory): ResourceTypeSection =>
  resourceCategory === 'k8s'
    ? 'cluster'
    : resourceCategory === 'datastore'
      ? 'datastore'
      : resourceCategory === 'dns' || resourceCategory === 'ingress'
        ? 'routing'
        : 'other';

interface SelectResourceTypeStepProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setSelectedResourceType: Dispatch<SetStateAction<ResourceType | undefined>>;
  useFilter?: ResourceUse;
}

const ResourceTypeCard = styled(WalCard)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${units.margin.sm};
`;

const CategoryWrapper = styled.div`
  margin-bottom: ${units.margin.md};
`;

export type ResourceTypeSection = 'cluster' | 'datastore' | 'routing' | 'other';

const SelectResourceTypeStep = ({
  setCurrentStep,
  setSelectedResourceType,
  useFilter,
}: SelectResourceTypeStepProps) => {
  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  // React query
  const { data: resourceTypes } = useResourceTypesQuery();
  const getResourceCategoryTitle = (category: ResourceTypeSection) => {
    let title;
    switch (category) {
      case 'cluster':
        title = resourcesTranslations.CLUSTER_RESOURCES;
        break;
      case 'datastore':
        title = resourcesTranslations.DATA_STORAGES;
        break;
      case 'routing':
        title = resourcesTranslations.ROUTING;
        break;
      default:
        title = resourcesTranslations.OTHER_RESOURCES;
    }
    return title;
  };

  const handleResourceTypeClick = (resourceType: ResourceType) => {
    setSelectedResourceType(resourceType);
    setCurrentStep(1);
  };

  const renderResourceTypes = () => {
    const sections: ResourceTypeSection[] = ['cluster', 'datastore', 'routing', 'other'];
    return sections.map((section) => {
      const filteredResources =
        resourceTypes?.filter(
          (resourceType) =>
            getResourceSection(resourceType.category) === section &&
            (useFilter ? useFilter === resourceType.use : true)
        ) ?? [];
      return (
        filteredResources.length > 0 && (
          <CategoryWrapper key={section}>
            <WalLabel>{getResourceCategoryTitle(section)}</WalLabel>
            {filteredResources?.map((resourceType) => (
              <ResourceTypeCard
                dataTestId={`${section}-resource-type-card`}
                cardStyle={'base'}
                key={resourceType.name}
                highlightOnHover
                onClick={() => handleResourceTypeClick(resourceType)}
                showRightArrow>
                <div className={'flex-centered'}>
                  <ResourceIcon type={resourceType.type} hasMargin />
                  {allLowerCaseExceptFirstWord(resourceType.name)}
                </div>
              </ResourceTypeCard>
            ))}
          </CategoryWrapper>
        )
      );
    });
  };
  return <>{renderResourceTypes()}</>;
};

export default SelectResourceTypeStep;
