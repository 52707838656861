import { ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { windowEnv } from '@src/environment';
import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import { useCurrentUserStore } from '@src/hooks/zustand/useCurrentUserStore';
import { MatchParams } from '@src/models/routing';

const AuthWrapper = ({ children }: { setAdBlockerWarningOpen: any; children: ReactNode }) => {
  // Router hooks

  const { data: user } = useGetCurrentUserQuery();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const { data: orgs = [], isSuccess: areOrgsLoaded } = useGetOrgsQuery();

  // Zustand
  const { authErrorMessage, setAuthErrorMessage, setOptimizelyUser } = useCurrentUserStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (user && authErrorMessage) {
      setAuthErrorMessage(null);
    }
  }, [authErrorMessage, setAuthErrorMessage, user]);

  useEffect(() => {
    if (user && orgId) {
      setOptimizelyUser({
        id: user.id,
        attributes: {
          organization_uuid: orgId,
          environment: windowEnv.ENVIRONMENT_NAME,
          user_id: user.id,
        },
      });
    } else {
      // Without optimizelyUser, optimizely doesn't work when the user is not logged in. Setting user with fake id.

      setOptimizelyUser({
        id: 'unknown-user',
      });
    }
  }, [user, orgId, setOptimizelyUser]);

  /**
   * Handles the redirects for /auth/no-organization.
   */
  useEffect(() => {
    if (!user) return;

    if (!user.name) {
      navigate('/auth/account-details');
    } else if (!orgs.length && areOrgsLoaded) {
      navigate('/auth/create-organization');
    }

    // useNavigate function uses useLocation().pathname as a dependency for relative navigation.
    // This causes a rerender on each route change. Since we are using absolute navigation here anyway,
    // it's safe to disable the deps rule. Keep any eye on this issue which may solve the problem at some point
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, orgs, user?.name, areOrgsLoaded]);

  return <>{children}</>;
};

export default AuthWrapper;
