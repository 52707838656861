import { ResourceDefinitionsQueryFilters } from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';

export const resourcesQueryKeys = {
  // e.g. ['orgs', 'my-org', 'resources']
  all: (orgId: string | undefined) => ['orgs', orgId, 'resources'],
  // e.g. ['orgs', 'my-org', 'resources', 'drivers', 'list']
  listDrivers: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'drivers', 'list'],
  // e.g. ['orgs', 'my-org', 'resources', 'types', 'list']
  listTypes: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'types', 'list'],
  // e.g. ['orgs', 'my-org', 'resources', 'classes', 'list']
  listClasses: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'classes', 'list'],
  // e.g. ['orgs', 'my-org', 'resources', 'defs']
  allDefinitions: (orgId: string | undefined) => [...resourcesQueryKeys.all(orgId), 'defs'],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'list']
  listDefinitions: (orgId: string | undefined, filters?: ResourceDefinitionsQueryFilters) => {
    const key: (string | undefined | ResourceDefinitionsQueryFilters)[] = [
      ...resourcesQueryKeys.allDefinitions(orgId),
      'list',
    ];
    if (filters) {
      key.push(filters);
    }
    return key;
  },
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'detail', 'my-def']
  definitionDetail: (orgId: string | undefined, defId: string | undefined) => [
    ...resourcesQueryKeys.allDefinitions(orgId),
    'detail',
    defId,
  ],
  definitionVersions: (orgId: string | undefined, defId: string | undefined) => [
    ...resourcesQueryKeys.allDefinitions(orgId),
    defId,
    'versions',
  ],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'my-def', 'versions', 'detail', 'version1']
  definitionVersion: (
    orgId: string | undefined,
    defId: string | undefined,
    versionId: string | undefined
  ) => [...resourcesQueryKeys.definitionVersions(orgId, defId), 'detail', versionId],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'my-def', 'versions', 'list']
  definitionVersionsList: (orgId: string | undefined, defId: string | undefined) => [
    ...resourcesQueryKeys.definitionVersions(orgId, defId),
    'list',
  ],
  // e.g. ['orgs', 'my-org', 'resources', 'defs', 'detail', 'my-def', 'active-resources']
  activeResources: (orgId: string | undefined, defId: string | undefined) => [
    ...resourcesQueryKeys.definitionDetail(orgId, defId),
    'active-resources',
    'list',
  ],
  dependencyGraph: (
    orgId: string | undefined,
    appId: string | undefined,
    envId: string | undefined,
    graphId: string | undefined
  ) => {
    const key = ['orgs', orgId, 'apps', appId, 'envs', envId, 'resources', 'graphs'];
    if (graphId) {
      key.push(graphId);
    }
    return key;
  },
};
