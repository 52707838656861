import {
  HumanitecFeatureType,
  isHardcodedFeature,
  isRuntimeFeature,
  isSpecFeature,
} from '@src/components/shared/ViewWorkloadProfile/types/view-workload-profile-types';
import { HumanitecWorkloadProfileFeatureKeys } from '@src/models/workload-profile-v2';

import HumanitecDeltaFeature from './HumanitecDeltaFeature/HumanitecDeltaFeature';
import HumanitecRuntimeFeature from './HumanitecRuntimeFeature/HumanitecRuntimeFeature';

/**
 * A Humanitec feature is a feature defined by Humanitec, as opposed to a schema or collection feature.
 */
const HumanitecFeature = ({ feature }: { feature: HumanitecFeatureType }) => {
  return isSpecFeature(feature) ? (
    <HumanitecDeltaFeature
      featureName={feature.featureDefinition.feature_name as HumanitecWorkloadProfileFeatureKeys}
      deltaPath={feature.deltaPath}
      featureKey={feature.featurePath}
    />
  ) : isRuntimeFeature(feature) ? (
    <HumanitecRuntimeFeature
      feature={feature.featureDefinition}
      featureKey={feature.deltaPath.split('/')[feature.deltaPath.split('/').length - 1]!}
    />
  ) : isHardcodedFeature(feature) ? (
    <>{feature.element}</>
  ) : (
    <></>
  );
};

export default HumanitecFeature;
