import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

export interface TestAccountSuccessResponse {
  identity_fields: {
    description: string;
    id: string;
    value: string;
  }[];
  warnings: string[];
}

export const useResourceAccountTestMutation = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return useMutation<
    AxiosResponse<TestAccountSuccessResponse>,
    AxiosError<ApiErrorResponse>,
    {
      resourceAccountId: string;
    }
  >({
    mutationFn: ({ resourceAccountId }) =>
      makeRequest('POST', `/orgs/${orgId}/resources/accounts/${resourceAccountId}/actions/check`),
  });
};
