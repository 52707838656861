import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { Org } from '@src/models/user';
import { getFreeTrialDisabled, removeFreeTrialDisabled } from '@src/utilities/local-storage';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';
import { convertToNameAndId } from '@src/utilities/string-utility';

import { userQueryKeys } from '../../user/userQueryKeys';
import { orgQueryKeys } from '../orgQueryKeys';

interface UseOrgCreateMutationProps {
  onError?: (error: AxiosError<{ message: string; code: number }>) => void;
}

const useOrgCreateMutation = ({ onError }: UseOrgCreateMutationProps | undefined = {}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, ...results } = useMutation<
    AxiosResponse<Org>,
    AxiosError<{ message: string; code: number }>,
    string
  >({
    mutationFn: (orgName: string) => {
      const { name, id } = convertToNameAndId(orgName);

      const payload: { name: string; id: string; trial_expires_at?: null } = { name, id };

      if (getFreeTrialDisabled()) {
        payload.trial_expires_at = null;
      }

      return makeRequest<Org>('POST', '/orgs', payload);
    },
    onSuccess: async (response) => {
      removeFreeTrialDisabled();
      await queryClient.invalidateQueries({
        queryKey: orgQueryKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: userQueryKeys.currentUser(),
      });
      navigate(generateAppURL(response.data.id));
    },
    onError: (error) => {
      datadogRum.addError(error);
      onError?.(error);
    },
  });

  return { ...results, data: data?.data };
};

export default useOrgCreateMutation;
