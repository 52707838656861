import { BuildEnvironment } from './models/build-environment';

export const isLocal = window.location.href.includes('localhost');

if (isLocal) {
  (window as any).env = {
    BASE_URL: import.meta.env.VITE_REACT_APP_BASE_URL,
    BASE_WEBSOCKET_URL: import.meta.env.VITE_REACT_APP_BASE_WEBSOCKET_URL,
    OAUTH_GITHUB_CLIENT_ID: import.meta.env.VITE_REACT_APP_OAUTH_GITHUB_CLIENT_ID,
    OPTIMIZELY_SDK_KEY: import.meta.env.VITE_REACT_APP_OPTIMIZELY_SDK_KEY,
    GOOGLE_CLIENT_ID: import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID,
    ENVIRONMENT_NAME: import.meta.env.VITE_REACT_APP_ENVIRONMENT_NAME,
    DATADOG_KEY: import.meta.env.VITE_REACT_APP_DATADOG_KEY,
    SSO_BUTTONS: import.meta.env.VITE_REACT_APP_SSO_BUTTONS,
    VERSION: 'dev',
  };
}

export const windowEnv: BuildEnvironment = (window as any).env;
