import { Button, WalModal } from '@humanitec/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResourceAccountTestMutation } from '@src/hooks/react-query/resource-accounts/mutations/useResourceAccountTestMutation';

import { TestAccountResponse } from '../TestAccountResponse';

interface TestAccountModalProps {
  resourceAccountId: string;
}

export const TestAccountModal = ({ resourceAccountId }: TestAccountModalProps) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const accountsTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS;

  const {
    data: testSuccess,
    error: testError,
    mutate: testAccountMutation,
    reset,
    isPending,
  } = useResourceAccountTestMutation();

  useEffect(() => {
    reset();
  }, [open, reset]);

  const hasReponse = Boolean(testSuccess || testError);

  return (
    <>
      <Button
        size={'small'}
        className={'mr-sm'}
        variant={'secondary'}
        onClick={() => setOpen(true)}>
        {uiTranslations.TEST}
      </Button>
      <WalModal
        openState={[open, setOpen]}
        title={`${uiTranslations.TEST} ${resourceAccountId}`}
        size={'large'}
        content={
          <div>
            <div className={'mb-md'}>{accountsTranslations.TEST_DESCRIPTION}</div>
            <TestAccountResponse
              testError={testError?.response?.data}
              testSuccess={{ success: Boolean(testSuccess), details: testSuccess?.data }}
            />
          </div>
        }
        actions={{
          main: {
            text: hasReponse ? uiTranslations.TEST_AGAIN : uiTranslations.TEST,
            props: {
              loading: isPending,
              onClick: () => testAccountMutation({ resourceAccountId }),
            },
          },
          cancel: {},
        }}
      />
    </>
  );
};
