import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { AppValue } from '@src/models/app-value';
import makeRequest from '@src/utilities/make-request';

import envValuesQueryKeys from '../envValuesQueryKeys';

export const getEnvValuesQueryOptions = (orgId?: string, appId?: string, envId?: string) => ({
  queryKey: envValuesQueryKeys.list(orgId, appId, envId),
  queryFn: () =>
    makeRequest<AppValue[]>('GET', `/orgs/${orgId}/apps/${appId}/envs/${envId}/values`),
  enabled: !!(orgId && appId && envId),
});

export const useEnvValuesQuery = () => {
  const { orgId, appId, envId } = useParams();

  const { data, ...queryResult } = useQuery(getEnvValuesQueryOptions(orgId, appId, envId));

  return {
    ...queryResult,
    data: data?.data,
  };
};
export default useEnvValuesQuery;
