import { datadogRum } from '@datadog/browser-rum';
import {
  Button,
  ConfirmationDialog,
  DropdownItem,
  MenuItem,
  MultiSelectGroup,
  MultiSelectMenu,
  WalInput,
  WalMenu,
} from '@humanitec/ui-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import AddPayloadModal from '@src/components/shared/modals/AddPayloadModal';
import useDeleteWebhookMutation from '@src/hooks/react-query/webhooks/mutations/useDeleteWebhookMutation';
import useUpdateWebhookMutation from '@src/hooks/react-query/webhooks/mutations/useUpdateWebhookMutation';
import { useRBAC } from '@src/hooks/useRBAC';
import { EventTrigger, Webhook } from '@src/models/webhook';
import { units } from '@src/styles/variables';

import AddHeadersModal from '../../../../../../../../../../../components/shared/modals/AddHeadersModal';
import { WebhookGrid } from './styles';

const LastColumn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ButtonWithMargin = styled(Button)<{ hideLabel?: boolean }>`
  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${units.margin.lg};
    `}
`;

const MultiSelectWithMargin = styled(MultiSelectMenu)<{ hideLabel?: boolean }>`
  ${({ hideLabel }) =>
    !hideLabel &&
    css`
      margin-top: ${units.margin.lg};
    `}
`;

interface WebhookFormProps {
  triggerOptions: (MultiSelectGroup | DropdownItem<string>)[];
  webhook: Webhook;
  hideLabel?: boolean;
}

const generateTriggersFromFormValues = (
  formValues: any
): Pick<EventTrigger, 'scope' | 'type'>[] => {
  const triggers: Pick<EventTrigger, 'scope' | 'type'>[] = [];
  Object.keys(formValues.triggers).forEach((scope) => {
    Object.keys(formValues.triggers[scope]).forEach((type) => {
      if (formValues.triggers[scope][type]) {
        triggers.push({ scope, type });
      }
    });
  });

  return triggers;
};

const WebhookForm = ({ webhook, triggerOptions, hideLabel }: WebhookFormProps) => {
  // Component state
  const [headersModalOpen, setHeadersModalOpen] = useState(false);
  const [headers, setHeaders] = useState<Record<string, string>>({});
  const [selectedTriggers, setSelectedTriggers] = useState<string[]>([]);
  const [webhookState, setWebhookState] = useState<'view' | 'edit'>('view');
  const [triggersValid, setTriggersValid] = useState(false);
  const [openPayloadModal, setOpenPayloadModal] = useState<boolean>(false);

  const urlInputRef = useRef<HTMLInputElement | null>(null);

  const [confirmDeleteBoxHidden, setConfirmDeleteBoxHidden] = useState<boolean>(true);

  // Form
  const { handleSubmit, getValues, setValue, watch } = useFormContext();

  const payloadData = watch('payloadData');

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const webhooksTranslations = t('APP_SETTINGS').WEBHOOKS;
  const editWebhookHeader = t('APP_SETTINGS.WEBHOOKS.EDIT_HEADERS_MODAL_TITLE', {
    webhookId: webhook.id,
  });

  // Context
  const canUpdateWebhook = useRBAC('updateWebhook');

  // React Query
  const { mutate: deleteWebhook } = useDeleteWebhookMutation();
  const { mutate: updateWebhook } = useUpdateWebhookMutation();

  useEffect(() => {
    setSelectedTriggers(
      webhook?.triggers.map((trigger) => `triggers.${trigger.scope}.${trigger.type}`) ?? []
    );
  }, [webhook]);

  useEffect(() => {
    if (webhookState === 'edit') {
      setValue('id', webhook?.id ?? '');
      setValue('url', webhook?.url ?? '');
      urlInputRef.current?.focus();
    }
  }, [webhook, setValue, webhookState]);

  const selectMenuOption = (item: MenuItem<string>) => {
    if (item.value === 'edit-headers') {
      setHeadersModalOpen(true);
    } else if (item.value === 'open-payload') {
      setOpenPayloadModal(true);
    } else if (item.value === 'edit') {
      setWebhookState('edit');
    } else if (item.value === 'delete') {
      setConfirmDeleteBoxHidden(false);
    }
  };

  const onConfirmDeleteWebhook = () => {
    if (webhook) {
      deleteWebhook(webhook.id);
    }
  };

  const onHeadersChange = useCallback(
    (newHeaders: Record<string, string>) => {
      setHeaders(newHeaders);
      if (!webhook?.id) return;

      updateWebhook({
        webhookId: webhook?.id,
        webhook: {
          headers: newHeaders,
        },
      });
    },
    [webhook?.id, updateWebhook]
  );

  useEffect(() => {
    if (!webhook.payload) return;
    setValue('payloadData', JSON.stringify(webhook.payload, null, '\t'));
  }, [setValue, webhook]);

  const editWebhookClick = (formValues: any) => {
    updateWebhook({
      webhookId: webhook?.id,
      webhook: {
        url: formValues.url,
      },
    });
    setWebhookState('view');
  };

  const onCloseTriggerDropdown = () => {
    if (webhook) {
      updateWebhook({
        webhookId: webhook?.id,
        webhook: {
          triggers: generateTriggersFromFormValues(getValues()),
        },
      });
    }
  };

  const onMultiSelectValid = useCallback((valid: boolean) => {
    setTriggersValid(valid);
  }, []);

  const onPayloadChange = () => {
    let parsed = '';
    try {
      parsed = JSON.parse(payloadData ?? '');
    } catch (e) {
      datadogRum.addError(e);
    }
    if (!parsed) return;
    updateWebhook({
      webhookId: webhook?.id,
      webhook: {
        payload: parsed,
      },
    });
  };

  return (
    <WebhookGrid>
      <Text>{webhook?.id}</Text>
      {webhookState === 'view' ? (
        <Text>{webhook?.url}</Text>
      ) : (
        <WalInput
          required
          id={`${webhook?.id ?? ''}url`}
          name={'url'}
          label={'URL'}
          inputRef={urlInputRef}
          hideLabel={hideLabel}
        />
      )}
      <div data-testid={'qa-webhooks-headers-column'}>
        {(webhook.headers && Object.keys(webhook?.headers).length) || 'None'}
      </div>
      <MultiSelectWithMargin
        hideLabel={hideLabel}
        buttonSize={'small'}
        readonly={!canUpdateWebhook}
        buttonVariant={'secondary'}
        name={'triggers'}
        items={triggerOptions}
        defaultText={'Triggers *'}
        selectedOptions={selectedTriggers}
        enableSelectAll
        selectAllByDefault={!Boolean(webhook)}
        onMenuClosed={onCloseTriggerDropdown}
        setFormFieldValid={onMultiSelectValid}
        disableOptions={!canUpdateWebhook}
        alwaysRenderContent
      />
      <LastColumn>
        {webhookState === 'view' ? (
          <>
            <WalMenu
              panel={{ placement: 'bottom-end' }}
              standardToggle={{ type: 'dots', objectName: webhook.id, objectType: 'webhook' }}
              items={[
                {
                  value: 'edit-headers',
                  label: canUpdateWebhook ? 'Edit headers' : 'View headers',
                },
                {
                  value: 'open-payload',
                  label: 'Open payload',
                },
                {
                  value: 'edit',
                  label: uiTranslations.EDIT,
                  hideFromList: !canUpdateWebhook,
                },
                {
                  value: 'delete',
                  label: uiTranslations.DELETE,
                  hideFromList: !canUpdateWebhook,
                },
              ]}
              onItemClick={selectMenuOption}
            />
            {!confirmDeleteBoxHidden && (
              <ConfirmationDialog
                hiddenState={[confirmDeleteBoxHidden, setConfirmDeleteBoxHidden]}
                onConfirm={onConfirmDeleteWebhook}
              />
            )}
          </>
        ) : (
          <ButtonWithMargin
            dataTestid={'qa-webhook-create'}
            hideLabel={hideLabel}
            disabled={!triggersValid}
            variant={'primary'}
            onClick={handleSubmit(editWebhookClick)}>
            {webhookState === 'edit' ? uiTranslations.SAVE : uiTranslations.CREATE}
          </ButtonWithMargin>
        )}
      </LastColumn>
      {headersModalOpen && (
        <AddHeadersModal
          headersState={[headers, setHeaders]}
          title={editWebhookHeader}
          subtitle={webhooksTranslations.ADD_HEADERS_MODAL_SUBTITLE}
          initialHeaders={webhook.headers}
          openState={[headersModalOpen, setHeadersModalOpen]}
          onClickSave={onHeadersChange}
          readonly={!canUpdateWebhook}
        />
      )}
      <AddPayloadModal
        openState={[openPayloadModal, setOpenPayloadModal]}
        onClose={onPayloadChange}
      />
    </WebhookGrid>
  );
};

export default WebhookForm;
