import { SearchInput,TableCount } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AddResourceDependency from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import ResourceDependencyList, {
  ResourceCardItem,
} from '@src/components/shared/ResourceDependencyList/ResourceDependencyList';
import { TopBarWrapper } from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/EnvironmentWorkloads/components/TopBar/TopBar';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useApplicationQuery from '@src/hooks/react-query/applications/queries/useApplicationQuery';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { useRBAC } from '@src/hooks/useRBAC';

import DeploymentInfo from '../EnvironmentWorkloads/components/DeploymentInfo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Search = styled(SearchInput)`
  margin: 0;
  width: ${rem(200)};
`;

interface SharedResourcesProps {
  inEnvStatusTab?: boolean;
}

const SharedResources = ({ inEnvStatusTab }: SharedResourcesProps) => {
  // Component state
  const [resourceSearchValue, setResourceSearchValue] = useState<string>();

  // i18n
  const { t } = useTranslation();
  const sharedResourcesTranslations = t('VIEW_SHARED_RESOURCES');

  // Context
  const canEditApp = useRBAC('editApplication');
  const { draftModeActive, onRunningDeployment } = useDeploymentOrDeltaContext();
  const { sharedResourcesList } = useDeltaUtils();

  // React Query
  const { data: application } = useApplicationQuery();
  const { data: environmentResources = [] } = useEnvironmentResourcesQuery();

  const resourceCardItems: ResourceCardItem[] = useMemo(() => {
    return draftModeActive
      ? sharedResourcesList.map((resDep) => ({
          resourceDependency: resDep,
        }))
      : onRunningDeployment || inEnvStatusTab
        ? environmentResources
            .filter((activeResource) => !activeResource.res_id.startsWith('modules.'))
            .map((activeResource) => ({
              resourceDependency:
                sharedResourcesList.find((resDep) => `shared.${resDep.id}`) ?? undefined,
              activeResource,
            }))
        : [];
  }, [
    sharedResourcesList,
    environmentResources,
    onRunningDeployment,
    draftModeActive,
    inEnvStatusTab,
  ]);

  const filterOnChange = (value: string) => {
    setResourceSearchValue(value);
  };

  const filteredResourceCardItems = resourceCardItems.filter((res) =>
    resourceSearchValue ? res.resourceDependency?.path?.includes(resourceSearchValue) : true
  );

  return (
    <Wrapper>
      <TopBarWrapper>
        {application && draftModeActive ? (
          <AddResourceDependency scope={'shared'} />
        ) : (
          canEditApp && <DeploymentInfo />
        )}
        <Search
          name={'filter-shared-resources'}
          label={sharedResourcesTranslations.FILTER_RESOURCES}
          onChange={filterOnChange}
        />
      </TopBarWrapper>
      <ResourceDependencyList
        resourceCardItems={filteredResourceCardItems}
        scope={'shared'}
        onRunningDeployment={onRunningDeployment}
      />
      <TableCount totalCount={filteredResourceCardItems.length} />
    </Wrapper>
  );
};

export default SharedResources;
