import { ConnectionLineType, Edge, getIncomers, getOutgoers, Node, Position } from '@xyflow/react';
import dagre from 'dagre';

/**
 * Get automatic layouted elements using the dagre layout algorithm.
 *
 * @param {Node[]} nodes - An array of nodes.
 * @param {Edge[]} edges - An array of edges.
 * @param {string} [direction] - The direction of the layout: 'TB', 'BT', 'RL' or 'LR'. Defaults to 'LR'.
 * @returns object - An object containing the layouted nodes and edges.
 */
export const getLayoutedElements = <NodeData extends Record<string, unknown>>(
  nodes: Node<NodeData>[],
  edges: Edge[],
  direction = 'LR'
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const defaultNodeWidth = 300;
  const defaultNodeHeight = 100;

  dagreGraph.setGraph({
    rankdir: direction,
    align: 'UR',
    nodesep: 30,
    ranksep: 200,
    ranker: 'longest-path',
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.measured?.width || defaultNodeWidth,
      height: node.measured?.height || defaultNodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
    edge.type = ConnectionLineType.SimpleBezier;
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = Position.Top;
    node.sourcePosition = Position.Bottom;

    node.position = {
      x: nodeWithPosition.x,
      y: nodeWithPosition.y,
    };

    return node;
  });

  return { nodes, edges };
};

export const getAllIncomers = (node: Node, nodes: Node[], edges: Edge[]): Node[] => {
  return getIncomers(node, nodes, edges).reduce<Node[]>(
    (memo, incomer) => [...memo, incomer, ...getAllIncomers(incomer, nodes, edges)],
    []
  );
};

export const getAllOutgoers = (node: Node, nodes: Node[], edges: Edge[]): Node[] => {
  return getOutgoers(node, nodes, edges).reduce<Node[]>(
    (memo, outgoer) => [...memo, outgoer, ...getAllOutgoers(outgoer, nodes, edges)],
    []
  );
};

/**
 * it checks if an edge is related of a given node
 */
export const isEdgeRelatedToNode = (edge: Edge, node: Node, nodes: Node[], edges: Edge[]) => {
  const outgoerIds = getOutgoers(node, nodes, edges).map((o) => o.id);
  const incomerIds = getIncomers(node, nodes, edges).map((i) => i.id);
  return (
    (outgoerIds.includes(edge.target) && edge.source.includes(node.id)) ||
    (incomerIds.includes(edge.source) && edge.target.includes(node.id))
  );
};

/**
 * gets center position (x,y) of multiple nodes
 */
export const getCenterPosition = (nodes: Node[]) => {
  const horizontalCenterPosition =
    nodes.reduce((acc, currentNode) => acc + currentNode.position.x, 0) / nodes.length;
  const verticalCenterPosition =
    nodes.reduce((acc, currentNode) => acc + currentNode.position.y, 0) / nodes.length;
  return {
    x: horizontalCenterPosition,
    y: verticalCenterPosition,
  };
};
