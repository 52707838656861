import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorBoundary from '@src/components/shared/ErrorBoundary/ErrorBoundary';
import { ProblemLoadingSection } from '@src/components/shared/ErrorBoundary/fallbacks/ProblemLoadingSection';
import WorkloadResource from '@src/components/shared/ViewWorkloadProfile/components/RenderFeature/components/HumanitecFeature/HumanitecDeltaFeature/features/WorkloadResource/WorkloadResource';
import WorkloadFeature from '@src/components/shared/WorkloadFeature/WorkloadFeature';
import ExternalResources from '@src/containers/Orgs/Apps/containers/App/containers/ViewWorkload/components/WorkloadSideInfo/components/ExternalResources/ExternalResources';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import {
  HumanitecWorkloadProfileFeatureKeys,
  isCollectionFeature,
  isHumanitecFeature,
  isSchemaFeature,
  WorkloadFeatureDefinition,
} from '@src/models/workload-profile-v2';

import {
  HumanitecFeatureType,
  HumanitecRuntimeFeature,
  HumanitecSpecFeature,
  isSpecFeature,
} from '../../types/view-workload-profile-types';
import { getSectionFeatureInfo } from '../../utils/utils';
import CollectionFeature from './components/CollectionFeature';
import HumanitecFeature from './components/HumanitecFeature/HumanitecFeature';
import SchemaFeature from './components/SchemaFeature';

interface RenderFeatureProps {
  features: Record<string, WorkloadFeatureDefinition>;
  runtimeProperties: WorkloadFeatureDefinition[];
  deltaPath: string;
  /**
   * Resource dependencies should only be shown at the root of the WorkloadProfile.
   */
  showResourceDependencies?: boolean;
}

/**
 * Renders a feature in a SectionTile.
 */
const RenderFeature = ({
  features,
  deltaPath,
  runtimeProperties,
  showResourceDependencies,
}: RenderFeatureProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery();
  const { workloadController } = environmentRuntimeData || {};

  // Context
  const { draftModeActive, onRunningDeployment } = useDeploymentOrDeltaContext();

  const orderedFeatures: HumanitecFeatureType[] = useMemo(() => {
    const runTimeDefs: HumanitecRuntimeFeature[] = runtimeProperties.reduce(
      (acc: HumanitecRuntimeFeature[], runtimeProperty) => {
        const definition: HumanitecRuntimeFeature = {
          type: 'runtime',
          featureName: runtimeProperty.feature_name as HumanitecWorkloadProfileFeatureKeys,
          featureDefinition: runtimeProperty,
          deltaPath,
          order: runtimeProperty.ui_hints?.order ?? 0,
          title: runtimeProperty.title,
          hidden: runtimeProperty.ui_hints?.hidden || false,
        };

        if (runtimeProperty.feature_name === 'humanitec/replicas' && !workloadController) {
          return acc;
        }

        return [...acc, definition];
      },
      []
    );

    const deltaDefs: HumanitecSpecFeature[] = Object.entries(features || {}).map(
      ([featureKey, feature]) => {
        const newPath = deltaPath ? `${deltaPath}/${featureKey}` : featureKey;
        const definition: HumanitecSpecFeature = {
          type: 'spec',
          featureName: feature.feature_name as HumanitecWorkloadProfileFeatureKeys,
          featureDefinition: feature,
          deltaPath: newPath,
          featurePath: featureKey,
          order: feature.ui_hints?.order ?? 0,
          title: feature.title,
          hidden: feature.ui_hints?.hidden || false,
        };
        return definition;
      }
    );

    const combined: HumanitecFeatureType[] = [
      ...(onRunningDeployment ? runTimeDefs : []),
      ...deltaDefs,
    ];

    if (showResourceDependencies) {
      combined.push({
        type: 'hardcoded',
        featureName: 'hardcoded/resource-dependencies',
        element: <ExternalResources />,
        order: 0,
        title: sectionsTranslations.EXTERNAL_RESOURCES_TITLE,
        deltaPath: '/externals',
        hidden: false,
      });
      combined.push({
        type: 'hardcoded',
        featureName: 'hardcoded/workload-resource-dependency',
        element: <WorkloadResource />,
        order: 1,
        title: sectionsTranslations.WORKLOAD_RESOURCE_TITLE,
        deltaPath: '',
        hidden: draftModeActive,
      });
    }

    return combined.sort((a, b) => a.order - b.order).filter((def) => !def.hidden);
  }, [
    deltaPath,
    features,
    draftModeActive,
    onRunningDeployment,
    workloadController,
    runtimeProperties,
    sectionsTranslations.EXTERNAL_RESOURCES_TITLE,
    sectionsTranslations.WORKLOAD_RESOURCE_TITLE,
    showResourceDependencies,
  ]);

  return (
    <>
      {orderedFeatures.map((property) => {
        const sectionTileInfo = getSectionFeatureInfo(property.featureName, draftModeActive) || {
          title: property.title || '',
        };

        return (
          <ErrorBoundary
            key={property.deltaPath}
            fallback={
              <ProblemLoadingSection
                title={property.title || sectionTileInfo.title}
                className={'mb-lg'}
              />
            }>
            <WorkloadFeature
              featureType={property}
              title={
                property.title ||
                sectionTileInfo.title ||
                (isSpecFeature(property) ? property.featurePath : '')
              }
              infoPopup={sectionTileInfo.infoPopup}
              editable={draftModeActive}>
              {property.featureName && isHumanitecFeature(property.featureName) ? (
                <HumanitecFeature feature={property} />
              ) : (
                isSpecFeature(property) &&
                (isCollectionFeature(property.featureDefinition) ? (
                  <CollectionFeature
                    deltaPath={property.deltaPath}
                    featureKey={property.featurePath}
                  />
                ) : (
                  isSchemaFeature(property.featureDefinition) && (
                    <SchemaFeature
                      deltaPath={property.deltaPath}
                      schema={property.featureDefinition.schema}
                    />
                  )
                ))
              )}
            </WorkloadFeature>
          </ErrorBoundary>
        );
      })}
    </>
  );
};

export default RenderFeature;
