import { Button, Icon } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SectionHeader from '@src/components/shared/SectionHeader';
import ThemeSwitcher from '@src/components/shared/ThemeSwitcher';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import useLogoutMutation from '@src/hooks/react-query/user/useLogoutMutation';
import { cl } from '@src/styles/global-styles';

const Sidebar = styled.div`
  align-items: flex-start;
`;

const ProfileIconWrapper = styled.div`
  width: ${rem(80)};
  height: ${rem(80)};
  background-color: ${({ theme }) => theme.color.baseBrighter};
  border-radius: 50%;
`;

const ProfileSettingsSidebar = () => {
  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('ACCOUNT_SETTINGS');
  const uiTranslations = t('UI');

  // Context
  const { data: user } = useGetCurrentUserQuery();
  const { mutate: logoutUser } = useLogoutMutation();

  const onLogoutClick = () => {
    logoutUser();
  };

  return (
    <Sidebar>
      <SectionHeader>{settingsTranslations.PROFILE_SETTINGS}</SectionHeader>
      <div {...cl('flex', 'mb-md')}>
        <ProfileIconWrapper {...cl('flex', 'align-center', 'justify-center', 'mr-lg')}>
          <Icon name={'profile'} size={40} />
        </ProfileIconWrapper>
        <div {...cl('flex-column')}>
          <div {...cl('m-0')}>{user?.name}</div>
        </div>
      </div>
      <ThemeSwitcher />
      <Button
        {...cl('mt-lg')}
        variant={'secondary'}
        size={'small'}
        onClick={onLogoutClick}
        iconRight={'logout'}>
        {uiTranslations.LOGOUT}
      </Button>
    </Sidebar>
  );
};

export default ProfileSettingsSidebar;
