import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

const useDeletePipelineCriteriaMutation = () => {
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<ApiErrorResponse>, { criteriaId: string }>({
    mutationFn: ({ criteriaId }) => {
      return makeRequest(
        'DELETE',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/criteria/${criteriaId}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: pipelinesQueryKeys.criteria(orgId, appId) });
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });
};

export default useDeletePipelineCriteriaMutation;
