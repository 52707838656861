import { ErrorWarning } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { TestAccountSuccessResponse } from '@src/hooks/react-query/resource-accounts/mutations/useResourceAccountTestMutation';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';

interface TestAccountResponseProps {
  testError?: ApiErrorResponse;
  testSuccess?: {
    success: boolean;
    details?: TestAccountSuccessResponse;
  };
}

export const TestAccountResponse = ({ testError, testSuccess }: TestAccountResponseProps) => {
  const { t } = useTranslation();
  const accountsTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS;

  return (
    <div className={'mt-md'}>
      {testError ? (
        <ErrorWarning
          code={accountsTranslations.TEST_FAILED}
          mode={'warning'}
          details={JSON.stringify(testError, null, 2)}
          responseLabel={accountsTranslations.RESPONSE}
        />
      ) : (
        testSuccess?.success && (
          <ErrorWarning
            code={accountsTranslations.TEST_SUCCESSFUL}
            mode={'success'}
            responseLabel={accountsTranslations.RESPONSE}
            details={JSON.stringify(testSuccess.details, null, 2)}
          />
        )
      )}
    </div>
  );
};
