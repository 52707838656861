import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SkeletonGroup from '@src/components/shared/Skeleton/SkeletonGroup';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';

import EnvironmentCard from '../../../../components/EnvironmentCard';
import { CreateEnvironmentModal } from './components/CreateEnvironmentModal/CreateEnvironmentModal';

const Environments = () => {
  // Component state
  const [environmentModalOpen, setEnvironmentModalOpen] = useState<boolean>(false);

  // i18n
  const { t } = useTranslation('viewApplication');
  const envsTranslations = t('ENVIRONMENTS');

  // Router hooks
  const { appId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: applicationEnvironments = [], isFetching: areEnvironmentsLoading } =
    useApplicationEnvironmentsQuery();

  const canCreateEnvironment = useRBAC('createEnvironment');

  return (
    <>
      <div className={'flex align-center mb-md gap-sm'}>
        <Button
          iconLeft={'plus'}
          onClick={() => setEnvironmentModalOpen(true)}
          disabled={!canCreateEnvironment || areEnvironmentsLoading}>
          {envsTranslations.CREATE}
        </Button>
        {!canCreateEnvironment && !areEnvironmentsLoading && (
          <p className={'txt-sm txt-translucent'}>
            {!canCreateEnvironment ? envsTranslations.NO_PERMISSION : envsTranslations.NOT_DEPLOYED}
          </p>
        )}
      </div>

      <CreateEnvironmentModal state={[environmentModalOpen, setEnvironmentModalOpen]} />

      {areEnvironmentsLoading ? (
        <SkeletonGroup count={3} itemProps={{ className: 'mt-md' }} />
      ) : (
        applicationEnvironments.map((environment) => (
          <EnvironmentCard
            key={environment.id}
            appId={appId}
            envId={environment.id}
            environmentType={environment.type}
            lastDeploy={environment.last_deploy}
            showStatus
            showValuesOutdatedWarning
          />
        ))
      )}
    </>
  );
};

export default Environments;
