import { DynamicFormSchema } from '@src/types/dynamic-form';

/**
 * Features that can be defined in the workload profile spec definiton.
 */
export const humanitecWorkloadFeatureKeysArray = [
  'humanitec/annotations',
  'humanitec/containers',
  'humanitec/ingress',
  'humanitec/labels',
  'humanitec/schedules',
  'humanitec/replicas',
  'humanitec/service',
  'humanitec/service-account',
  /**
   * humanitec/volumes only exists on workload profiles v1.
   * But we need to keep it here, as this is used to generate the i18n for V1 & V2.
   * We use the same i18n for humanitec/volumes and humanitec/volume-mounts.
   */
  'humanitec/volumes',
  'humanitec/volume-mounts',
  'humanitec/pod-lifecycle',
  'humanitec/variables',
  'humanitec/files',
  'humanitec/image',
  'humanitec/resources',
  'humanitec/probe',
  'humanitec/overrides',
  'humanitec/logs',
  'hardcoded/resource-dependencies',
  'hardcoded/workload-resource-dependency',
] as const;

export type HumanitecWorkloadProfileFeatureKeys =
  (typeof humanitecWorkloadFeatureKeysArray)[number];

interface WorkloadProfileFeature {
  version?: string;
}

export type HumanitecWorkloadProfileFeatures = Partial<
  Record<HumanitecWorkloadProfileFeatureKeys, WorkloadProfileFeature>
>;

export const isHumanitecFeature = (
  value: HumanitecWorkloadProfileFeatureKeys | string
): value is HumanitecWorkloadProfileFeatureKeys => {
  return (
    humanitecWorkloadFeatureKeysArray.includes(value as HumanitecWorkloadProfileFeatureKeys) ||
    false
  );
};

export type WorkloadFeatureDefinition = SchemaFeature | CollectionFeature;

export interface BaseSchema {
  /* The name of the feature. This featureName can be user defined, but if it matches an existing feature e.g. humanitec/labels, the custom labels component will be displayed */
  feature_name: string;
  title?: string;
  properties?: Record<string, WorkloadFeatureDefinition>;
  runtime_properties?: WorkloadFeatureDefinition[];
  /**
   * ui_hints are properties that tell us any extra layout/styles information about the feature.
   */
  ui_hints?: {
    /**
     * Specify what order the features will be shown in.
     */
    order?: number;
    /**
     * Some features are defined in the backend but shouldn't be displayed in the frontend.
     * Set 'hidden' to true to hide it on the frontend.
     */
    hidden?: boolean;
  };
}

interface SchemaFeature extends BaseSchema {
  type: 'schema';
  schema: DynamicFormSchema;
}

/**
 * 'collection' is a list of objects. e.g. '/spec/containers' with type `collection` will result in '/spec/containers/{containerId}'
 * 'feature' is a single named feature e.g. 'humanitec/labels'.
 *
 * The following two examples are the same with the exception of the `type`.
 *
 * Example #1
 * The variables feature will generate a delta path of `/spec/containers/{id}/variables`.
 * ```
 * {
 *   features: {
 *     containers: {
 *       type: 'collection',
 *       featureName: 'humanitec/containers'
 *       features: {
 *         variables: {
 *           type: 'feature',
 *           featureName: 'humanitec/variables'
 *         }
 *       }
 *     }
 *   }
 * }
 * ```
 *
 * Example #2
 * The variables feature will generate a delta path of `/spec/containers/variables`.
 * ```
 * {
 *   features: {
 *     containers: {
 *       type: 'feature',
 *       featureName: 'humanitec/containers'
 *       features: {
 *         variables: {
 *           type: 'feature',
 *           featureName: 'humanitec/variables'
 *         }
 *       }
 *     }
 *   }
 * }
 * ```
 */
export interface CollectionFeature extends BaseSchema {
  type: 'collection' | 'feature';
  version: string;
}

export const isCollectionFeature = (
  value?: CollectionFeature | SchemaFeature
): value is CollectionFeature => {
  return (
    (value as CollectionFeature)?.type === 'collection' ||
    (value as CollectionFeature)?.type === 'feature' ||
    false
  );
};

export const isSchemaFeature = (value: CollectionFeature | SchemaFeature): value is SchemaFeature =>
  (value as SchemaFeature)?.type === 'schema';
