import { DiffViewer, Spinner, WalLabel, WalModal } from '@humanitec/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import usePipelineUpdateMutation from '@src/hooks/react-query/pipelines/mutation/usePipelineUpdateMutation';
import usePipelineSchemaQuery from '@src/hooks/react-query/pipelines/queries/usePipelineSchemaQuery';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { Pipeline, PipelineVersion } from '@src/models/pipeline';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

interface PipelineRevertModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  selectedPipelineVersion: PipelineVersion;
  pipeline: Pipeline;
}
const PipelineRevertModal = ({
  openState,
  selectedPipelineVersion,
  pipeline,
}: PipelineRevertModalProps) => {
  // i18n
  const { t: tViewApplication } = useTranslation('viewApplication');
  const translations = tViewApplication('PIPELINES');

  // state
  const [open, setOpen] = openState;

  // react query
  const { data: currentPipelineVersionSchema, isFetching: isCurrentPipelineVersionSchemaFetching } =
    usePipelineSchemaQuery(pipeline?.version);
  const {
    data: selectedPipelineVersionSchema,
    isFetching: isSelectedPipelineVersionSchemaFetching,
  } = usePipelineSchemaQuery(selectedPipelineVersion?.id);
  const { data: users } = useOrgRolesQuery();
  const { mutate: updatePipeline } = usePipelineUpdateMutation();

  const revertPipelineVersion = () => {
    if (selectedPipelineVersionSchema) {
      updatePipeline({
        yamlData: selectedPipelineVersionSchema,
        etag: pipeline.etag,
      });
      setOpen(false);
    }
  };

  return (
    <WalModal
      size={'large'}
      openState={[open, setOpen]}
      title={translations.REVERT_MODAL_TITLE}
      content={
        <>
          <div className={'flex-centered mt-lg mb-md'}>
            <div className={'flex-column mr-lg'}>
              <WalLabel>{translations.EDIT_DATE}</WalLabel>
              <span>
                {formatDate(
                  selectedPipelineVersion?.created_at,
                  DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
                )}
              </span>
            </div>
            <div className={'flex-column'}>
              <WalLabel>{translations.EDIT_BY}</WalLabel>
              <span>
                {users?.find((user) => selectedPipelineVersion?.created_by === user.id)?.name ||
                  '-'}
              </span>
            </div>
          </div>
          {isSelectedPipelineVersionSchemaFetching || isCurrentPipelineVersionSchemaFetching ? (
            <Spinner size={'small'} />
          ) : (
            <DiffViewer
              rounded
              oldValue={currentPipelineVersionSchema}
              newValue={selectedPipelineVersionSchema}
            />
          )}
        </>
      }
      actions={{
        main: {
          text: translations.REVERT,
          props: {
            onClick: revertPipelineVersion,
          },
        },
        cancel: {},
      }}
    />
  );
};

export default PipelineRevertModal;
