import { Button, ButtonVariant } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useDeploymentDeltaCreateMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaCreateMutation';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';

interface CreateDraftDeltaButtonProps {
  className?: string;
  buttonVariant?: ButtonVariant;
}

export const CreateDraftDeltaButton = ({
  className,
  buttonVariant = 'primary',
}: CreateDraftDeltaButtonProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Router hooks
  const { envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { mutate: createDelta } = useDeploymentDeltaCreateMutation();

  // RBAC
  const canAccessDraftURL = useRBAC('accessDraftURL');

  return canAccessDraftURL ? (
    <Button
      className={className}
      variant={buttonVariant}
      iconLeft={'edit'}
      onClick={() => createDelta({ envId, navigateAfterCreation: 'existing-path' })}>
      {uiTranslations.CREATE_NEW_DELTA}
    </Button>
  ) : (
    <></>
  );
};
