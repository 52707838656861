import { WalInput } from '@humanitec/ui-components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { convertToId } from '@src/utilities/string-utility';

interface ContainerNameStepProps {
  currentContainerIds: string[];
  selectedImage?: string;
}

const ContainerNameStep = ({ currentContainerIds, selectedImage }: ContainerNameStepProps) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const { setValue } = useFormContext();

  useEffect(() => {
    if (selectedImage) {
      const imageNameSegments = selectedImage.split('/');

      const lastSegment = imageNameSegments[imageNameSegments?.length - 1];
      if (lastSegment) {
        const imageName = lastSegment?.split(':')[0];
        if (imageName) {
          setValue('containerName', convertToId(imageName));
        }
      }
    }
  }, [selectedImage, setValue]);

  return (
    <WalInput
      name={'containerName'}
      required
      maxLength={63}
      standardValidation={[
        { type: 'idStartWithAlphabetic' },
        { type: 'existingId', ids: currentContainerIds },
      ]}
      label={imageTranslations.CONTAINER_NAME}
    />
  );
};

export default ContainerNameStep;
