import { ResourceClass, ResourceDefinition } from '@src/models/resources';

export const getResourceDefinitionsWithClassInCriteria = (
  resourceDefinitions: ResourceDefinition[],
  resourceClass: ResourceClass
) => {
  return resourceDefinitions.filter((def) =>
    def.criteria?.some(
      (criteria) => criteria.class === resourceClass.id && def.type === resourceClass.resource_type
    )
  );
};
