import { Accordion } from '@humanitec/ui-components';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { groupSharedResourcesUpdatesArray } from '@src/components/shared/CloneModal/components/ReviewCloneDetails/clone-draft-utils';
import AddWorkloadsAccordionContent from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/AddWorkloadsAccordionContent';
import RemoveWorkloadsAccordionContent from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/RemoveWorkloadsAccordionContent';
import UpdateWorkloadsAccordionContent from '@src/components/shared/CloneModal/components/ReviewCloneDetails/components/UpdateWorkloadsAccordionContent';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import { diffDeploymentSets } from '@src/utilities/deployment-delta-utils';

const AccordionHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ReviewCloneDetailsProps {
  cloneDraftContentState: [
    Partial<DeploymentDelta>,
    Dispatch<SetStateAction<Partial<DeploymentDelta>>>,
  ];
  targetEnv?: Environment;
  fromDeployment?: DeploymentObject;
}
const ReviewCloneDetails = ({
  targetEnv,
  fromDeployment,
  cloneDraftContentState,
}: ReviewCloneDetailsProps) => {
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;
  const { appId, orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: targetDeploymentSet } = useDeploymentSetQuery({
    setId: targetEnv?.last_deploy?.set_id,
  });

  // State
  const [cloneDraftContent, setCloneDraftContent] = cloneDraftContentState;
  const [defaultCloneDraftContent, setDefaultCloneDraftContent] = useState<
    Partial<DeploymentDelta>
  >({});
  // this draft is only used for displaying the resource updates grouped in the UI. This is not the actual delta that gets modified and sent to the API.
  const [
    defaultCloneDraftWithGroupedResourceUpdates,
    setDefaultCloneDraftWithGroupedResourceUpdates,
  ] = useState<Partial<DeploymentDelta>>({});

  useEffect(() => {
    if (fromDeployment?.set_id) {
      diffDeploymentSets(
        fromDeployment?.set_id,
        targetEnv?.last_deploy?.set_id || '0',
        appId,
        orgId
      ).then((draft) => {
        const draftWithGroupedResourceUpdates = cloneDeep(draft);
        draftWithGroupedResourceUpdates.shared = groupSharedResourcesUpdatesArray(
          draft.shared || []
        );
        setDefaultCloneDraftWithGroupedResourceUpdates(draftWithGroupedResourceUpdates);
        setCloneDraftContent(cloneDeep(draft));
        setDefaultCloneDraftContent(cloneDeep(draft));
      });
    }
  }, [
    fromDeployment,
    targetEnv?.last_deploy?.set_id,
    appId,
    orgId,
    setCloneDraftContent,
    targetEnv,
  ]);

  const addResourceChangeInCloneDraft = (resourceId: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    if (newCloneDraftContent) {
      const resourceChanges = defaultCloneDraftContent.shared?.filter((change) =>
        change.path.includes(resourceId)
      );
      if (resourceChanges && newCloneDraftContent.shared) {
        newCloneDraftContent.shared = [...newCloneDraftContent.shared, ...resourceChanges];
      }
      setCloneDraftContent(newCloneDraftContent);
    }
  };

  const removeResourceChangeFromCloneDraft = (resourceId: string) => {
    const newCloneDraftContent = { ...cloneDraftContent };
    // remove changes related to the resource id
    if (newCloneDraftContent.shared) {
      newCloneDraftContent.shared = newCloneDraftContent.shared?.filter(
        (change) => !change.path.includes(resourceId)
      );
    }
    setCloneDraftContent(newCloneDraftContent);
  };

  const removedResourcesCount =
    defaultCloneDraftWithGroupedResourceUpdates.shared?.filter((change) => change.op === 'remove')
      .length || 0;
  const removedWorkloadsCount =
    defaultCloneDraftWithGroupedResourceUpdates.modules?.remove?.length || 0;
  const addedWorkloadsCount = defaultCloneDraftWithGroupedResourceUpdates.modules?.add
    ? Object.keys(defaultCloneDraftWithGroupedResourceUpdates.modules?.add).length
    : 0;
  const addedResourcesCount =
    defaultCloneDraftWithGroupedResourceUpdates.shared?.filter((change) => change.op === 'add')
      .length || 0;
  const updatedWorkloadsCount = defaultCloneDraftWithGroupedResourceUpdates.modules?.update
    ? Object.keys(defaultCloneDraftWithGroupedResourceUpdates.modules?.update).length
    : 0;
  const updateResourcesCount =
    defaultCloneDraftWithGroupedResourceUpdates.shared?.filter((change) => change.op === 'replace')
      .length || 0;

  return (
    <Accordion
      items={[
        {
          id: 'remove-workloads',
          expandedByDefault: true,
          headerContent: (
            <AccordionHeaderContent>
              {modalTranslations.REMOVE_WORKLOADS_AND_SHARED_RESOURCES}
              <span className={'txt-sm txt-translucent'}>
                <span className={'mr-sm'}>
                  {removedWorkloadsCount + removedResourcesCount}{' '}
                  {modalTranslations.AVAILABLE_TO_REMOVE}
                </span>
              </span>
            </AccordionHeaderContent>
          ),
          content: (
            <RemoveWorkloadsAccordionContent
              targetEnv={targetEnv}
              fromDeployment={fromDeployment}
              cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
              defaultCloneDraftContent={defaultCloneDraftContent}
              defaultCloneDraftWithGroupedResourceUpdates={
                defaultCloneDraftWithGroupedResourceUpdates
              }
              removeResourceChangeFromCloneDraft={removeResourceChangeFromCloneDraft}
              addResourceChangeInCloneDraft={addResourceChangeInCloneDraft}
              targetDeploymentSet={targetDeploymentSet}
            />
          ),
          sortIndex: defaultCloneDraftWithGroupedResourceUpdates.modules?.remove?.length ? 1 : -1,
          disableExpansion: Boolean(
            defaultCloneDraftWithGroupedResourceUpdates.modules?.remove?.length === 0 &&
              (defaultCloneDraftWithGroupedResourceUpdates.shared
                ? defaultCloneDraftWithGroupedResourceUpdates.shared?.filter(
                    (change) => change.op === 'remove'
                  ).length === 0
                : true)
          ),
        },
        {
          id: 'add-workloads',
          expandedByDefault: true,
          headerContent: (
            <AccordionHeaderContent>
              {modalTranslations.ADD_WORKLOADS_AND_SHARED_RESOURCES}
              <span className={'txt-sm txt-translucent'}>
                <span className={'mr-sm'}>
                  {addedWorkloadsCount + addedResourcesCount} {modalTranslations.AVAILABLE_TO_ADD}
                </span>
              </span>
            </AccordionHeaderContent>
          ),
          content: (
            <AddWorkloadsAccordionContent
              targetEnv={targetEnv}
              fromDeployment={fromDeployment}
              cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
              defaultCloneDraftContent={defaultCloneDraftContent}
              defaultCloneDraftWithGroupedResourceUpdates={
                defaultCloneDraftWithGroupedResourceUpdates
              }
              addResourceChangeInCloneDraft={addResourceChangeInCloneDraft}
              removeResourceChangeFromCloneDraft={removeResourceChangeFromCloneDraft}
            />
          ),
          sortIndex:
            defaultCloneDraftContent.modules?.add &&
            Object.keys(defaultCloneDraftContent.modules?.add).length
              ? 0
              : -1,
          disableExpansion: Boolean(
            (defaultCloneDraftContent.modules?.add
              ? Object.keys(defaultCloneDraftContent.modules?.add).length === 0
              : true) &&
              (defaultCloneDraftContent.shared
                ? defaultCloneDraftContent.shared?.filter(
                    (resourceChange) => resourceChange.op === 'add'
                  ).length === 0
                : true)
          ),
        },
        {
          id: 'update-workloads',
          expandedByDefault: true,
          headerContent: (
            <AccordionHeaderContent>
              {modalTranslations.UPDATE_WORKLOADS_AND_SHARED_RESOURCES}
              <span className={'txt-sm txt-translucent'}>
                <span className={'mr-sm'}>
                  {updatedWorkloadsCount + updateResourcesCount}{' '}
                  {modalTranslations.AVAILABLE_TO_UPDATE}
                </span>
              </span>
            </AccordionHeaderContent>
          ),
          content: (
            <UpdateWorkloadsAccordionContent
              targetEnv={targetEnv}
              fromDeployment={fromDeployment}
              cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
              defaultCloneDraftContent={defaultCloneDraftContent}
              defaultCloneDraftWithGroupedResourceUpdates={
                defaultCloneDraftWithGroupedResourceUpdates
              }
              targetDeploymentSet={targetDeploymentSet}
              addResourceChangeInCloneDraft={addResourceChangeInCloneDraft}
              removeResourceChangeFromCloneDraft={removeResourceChangeFromCloneDraft}
            />
          ),
          sortIndex: Number(
            defaultCloneDraftWithGroupedResourceUpdates.modules?.update &&
              Object.keys(defaultCloneDraftWithGroupedResourceUpdates.modules?.update).length
          )
            ? 0
            : -1,
          disableExpansion: Boolean(
            (defaultCloneDraftWithGroupedResourceUpdates.modules?.update
              ? Object.keys(defaultCloneDraftWithGroupedResourceUpdates.modules?.update).length ===
                0
              : true) &&
              (defaultCloneDraftWithGroupedResourceUpdates.shared
                ? defaultCloneDraftWithGroupedResourceUpdates.shared?.filter(
                    (change) => change.op === 'replace'
                  )?.length === 0
                : true)
          ),
        },
      ]}
    />
  );
};

export default ReviewCloneDetails;
