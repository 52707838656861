import { Placement } from '@floating-ui/react';
import { Icon, MenuItem, WalMenu } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useWalhallContext } from '@src/context/walhallContext';
import useGetSpecificOrgQuery from '@src/hooks/react-query/organisations/queries/useGetSpecificOrgQuery';
import { Org } from '@src/models/user';
import { removeLastVisitedApp } from '@src/utilities/local-storage';
import { generateAppURL } from '@src/utilities/navigation';

import NavigationListItem from '../NavigationListItem/NavigationListItem';

interface OrgMenuProps {
  orgs: Org[];
  placement?: Placement;
  /* Pass disabled prop to manu component */
  disableMenu?: boolean;
  minimized: boolean;
  orgId: string;
}

const OrgMenu = ({ orgs, placement, disableMenu, minimized, orgId }: OrgMenuProps) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('NAVIGATION');

  const { data: currentOrg, isLoading: isCurrentOrgLoading } = useGetSpecificOrgQuery({ orgId });
  const { nextRouteState, shouldConfirmOnNavigateState, navigateDialogState } = useWalhallContext();
  const [shouldConfirmOnNavigate] = shouldConfirmOnNavigateState;
  const [, setNextRoute] = nextRouteState;
  const [, setShowNavigateDialog] = navigateDialogState;

  const menuOptions: MenuItem<string>[] = useMemo(
    () =>
      orgs.length > 1
        ? orgs.map((org) => ({
            label: org.name,
            id: org.id,
            value: org.id,
            link: { to: generateAppURL(org.id) },
          }))
        : [],
    [orgs]
  );

  return (
    <WalMenu
      items={menuOptions}
      onItemClick={(item, event) => {
        // removes last visited app from localstorage when switching orgs
        removeLastVisitedApp();
        if (shouldConfirmOnNavigate) {
          event.preventDefault();
          setShowNavigateDialog(true);
          setNextRoute(item.link.to);
        }
      }}
      panel={{ placement }}
      disabled={disableMenu}
      toggle={
        <NavigationListItem
          minimized={minimized}
          label={currentOrg?.name || (isCurrentOrgLoading ? '' : translations.ORGANIZATION)}
          ariaLabel={translations.SWITCH_ORGANIZATION}
          icon={'organization'}
          labelWidth={120} // TODO: Make this work without fixed width WAL-5412
          disablePointer={orgs.length <= 1}
          customElementRight={
            !minimized ? (
              orgs.length > 1 ? (
                <Icon marginLeft={'sm'} name={'arrow-down'} size={12} />
              ) : undefined
            ) : undefined
          }
        />
      }
    />
  );
};

export default OrgMenu;
