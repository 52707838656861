import { CopyText, Icon, MenuItem, Tooltip, WalCard, WalMenu } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentResourcesQuery from '@src/hooks/react-query/environments/queries/useEnvironmentResourcesQuery';
import { IngressRule } from '@src/models/deployment-set';
import { ActiveResource } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

const IngressEntryTop = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${units.padding.sm};
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${units.margin.md};
`;

const IngressEntryTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: ${units.fontSize.sm};
`;

const IngressEntryCard = styled(WalCard)`
  margin-bottom: ${units.margin.xs};
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: ${rem(25)} auto;
`;

const SubMenuContainer = styled.div`
  position: absolute;
  right: ${rem(5)};
  top: ${rem(5)};
`;

const PathList = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

interface IngressEntryProps {
  ruleId: string;
  rule: IngressRule;
  onIngressMenuClick: (item: MenuItem<'edit' | 'delete'>, resourceId: string) => void;
}

const IngressEntry = ({ ruleId, rule, onIngressMenuClick }: IngressEntryProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const pathText = ruleId.split('.')[1];

  // Context
  const { draftModeActive, onRunningDeployment } = useDeploymentOrDeltaContext();

  // React Query
  const { data: environmentResources } = useEnvironmentResourcesQuery();

  // Router hooks
  const { moduleId } = useParams<keyof MatchParams>() as MatchParams;

  const endpoint = useMemo(() => {
    const searchRes = (r: ActiveResource) =>
      r.res_id === `modules.${moduleId}.externals.${ruleId.split('.')[1]}` ||
      r.res_id === `shared.${ruleId.split('.')[1]}`;
    return environmentResources?.find((resource) => resource.type === 'dns' && searchRes(resource))
      ?.resource?.host as string;
  }, [environmentResources, moduleId, ruleId]);

  return (
    <IngressEntryCard padding={'medium'} cardStyle={'base'}>
      <CardGrid>
        <Icon name={'dns'} />
        <IngressEntryTop>
          <IngressEntryTitle>{pathText}</IngressEntryTitle>
          {onRunningDeployment && endpoint && (
            <Tooltip
              triggerComponent={
                <CopyText showText textClassName={'source-code txt-xs'} text={endpoint} />
              }
              text={<span className={'source-code'}>{endpoint}</span>}
              position={'bottom'}
            />
          )}
          <SubMenuContainer>
            {draftModeActive && (
              <WalMenu
                panel={{ placement: 'bottom-end' }}
                items={[
                  { label: uiTranslations.EDIT, value: 'edit' },
                  { label: uiTranslations.DELETE, value: 'delete' },
                ]}
                onItemClick={(item) => onIngressMenuClick(item, ruleId)}
                standardToggle={{
                  type: 'dots',
                  objectType: 'ingress',
                  objectName: pathText || '',
                }}
              />
            )}
          </SubMenuContainer>
        </IngressEntryTop>
      </CardGrid>

      <CardGrid>
        <div />
        <PathList>
          {Object.entries(rule.http).map(([path, value]) => (
            <div key={path}>
              {path}: {value.port}
            </div>
          ))}
        </PathList>
      </CardGrid>
    </IngressEntryCard>
  );
};

export default IngressEntry;
