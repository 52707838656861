import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import { Dot } from '@src/components/shared/Dot';
import { ellipsisStyle } from '@src/styles/mixins';

import { CardStyles } from '../../../base/Card/Card';
import InfoPopup from '../../../base/InfoPopup/InfoPopup';
import Tooltip from '../../../base/Tooltip/Tooltip';
import { WalTableColumn, WalTableRow } from '../Table';
import { TableCell } from './TableRow';

const StyledTooltip = styled(Tooltip)<{ $maxWidth?: number; invertEllipsis?: boolean }>`
  ${({ $maxWidth, invertEllipsis }) => css`
    .js-tooltip-trigger p,
    b,
    i,
    small,
    span:not(.js-tooltip-text):not(.js-tooltip-trigger) {
      ${ellipsisStyle($maxWidth, invertEllipsis)}
    }
  `}
`;

interface TableCellWrapperProps {
  $justifyContent?: 'flex-start' | 'center' | 'flex-end';
  $alignIconAndText?: boolean;
  $last?: boolean;
  $fullWidth?: boolean;
}

const TableCellWrapper = styled.div<TableCellWrapperProps>`
  color: ${({ theme }) => theme.color.text};
  display: flex;
  align-items: center;
  ${({ $last }) =>
    $last &&
    css`
      justify-content: flex-end;
    `}

  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      justify-content: ${$justifyContent};
    `}

  ${({ $alignIconAndText }) =>
    $alignIconAndText &&
    css`
      width: 100%;
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      flex: 1;
    `}
`;

interface TableColumnCellProps {
  column: WalTableColumn;
  $rowIndex: number;
  $colIndex: number;
  row: WalTableRow;
  filteredColumns: WalTableColumn[];
  tablePadding?: 'small' | 'medium';
  tableRowStyle?: CardStyles;
  verticalAlignTop?: boolean;
}

const TableColumnCell = ({
  column,
  $rowIndex,
  $colIndex,
  row,
  filteredColumns,
  tablePadding,
  tableRowStyle,
  verticalAlignTop,
}: TableColumnCellProps) => {
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const getCellTemplate = () => {
    if (column.template) {
      return column.template(row, $rowIndex);
    } else if (column.prop) {
      return <p>{row.data[column.prop]}</p>;
    }
  };

  return (
    <TableCell
      tableRowStyle={tableRowStyle}
      tablePadding={tablePadding}
      key={column.prop}
      style={column.customStyle}
      width={column.fixedWidth}
      verticalAlignTop={column.verticalAlignTop || verticalAlignTop}>
      <TableCellWrapper
        tabIndex={column.tabIndex}
        $fullWidth={column.fullWidth}
        $justifyContent={column.justifyContent}
        $alignIconAndText={column.alignIconAndText}
        $last={!column.justifyContent && $colIndex === filteredColumns.length - 1}>
        {$colIndex === 0 && row.isNew && <Dot color={'blue'} />}
        {column.ellipsisTooltip ? (
          <StyledTooltip
            $maxWidth={column.ellipsisTooltip.maxWidth}
            text={
              column.ellipsisTooltip.showGenericText
                ? uiTranslations.CLICK_FOR_MORE_DETAILS
                : column.ellipsisTooltip.text(row)
            }
            triggerComponent={getCellTemplate()}
            invertEllipsis={column.ellipsisTooltip.invertEllipsis}
            disableTooltip={
              (column.ellipsisTooltip.text(row) || '').length <=
              (column.ellipsisTooltip.maxCharacters ?? 10)
            }
            position={column.ellipsisTooltip.position}
          />
        ) : (
          getCellTemplate()
        )}
        {column.infoPopupText && typeof column.infoPopupText(row) === 'string' && (
          <InfoPopup text={column.infoPopupText(row)} />
        )}
      </TableCellWrapper>
    </TableCell>
  );
};

export default TableColumnCell;
