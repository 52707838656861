import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { ResourceForm } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import { EditResourceDefinitionForm } from '../../../ResourceDefinitionForm/EditResourceDefinitionForm';

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.lg};
`;

const ResourceDefinitionConfiguration = () => {
  // State
  const [editMode, setEditMode] = useState<boolean>(false);

  // Form
  const formMethods = useWalhallForm<ResourceForm>();

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  return (
    <Wrapper data-testid={'resource-definition-configuration'}>
      <FormProvider {...formMethods}>
        <LeftContent>
          <Text>{resourcesTranslations.CHANGES_TO_THIS_DEFINITION}</Text>
          <EditResourceDefinitionForm editState={[editMode, setEditMode]} />
        </LeftContent>
      </FormProvider>
      {!editMode && (
        <div>
          <Button variant={'secondary'} iconLeft={'edit'} onClick={() => setEditMode(true)}>
            {resourcesTranslations.EDIT_CONFIGURATION}
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default ResourceDefinitionConfiguration;
