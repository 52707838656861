import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { OrgRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { generateRolesURL, rolesQueryKeys } from '../rolesQueryKeys';

const useOrgRolesQuery = (
  enabled = true
): QueryResponse<Role<OrgRoles>[], Role<OrgRoles | 'artefactContributor'>[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: rolesQueryKeys.orgRoles(orgId),
    queryFn: () =>
      makeRequest<Role<OrgRoles | 'artefactContributor'>[]>('GET', generateRolesURL(orgId)),
    enabled: Boolean(orgId) && enabled,
  });
  const transformedRoles = useMemo(() => {
    return data?.data.map((r) => {
      return r.role === 'artefactContributor' ? { ...r, role: 'artefactContributor' } : r;
    }) as Role<OrgRoles>[];
  }, [data?.data]);

  return {
    ...queryResult,
    data: transformedRoles,
    responseData: transformedRoles,
  };
};

export default useOrgRolesQuery;
