import { Button, MultistepModal } from '@humanitec/ui-components';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddResourceDependencyForm } from '@src/components/shared/AddResourceDependency/components/AddResourceDependencyForm';
import { ResourceTypeList } from '@src/components/shared/AddResourceDependency/components/ResourceTypeList';
import { getResourceTypeItems } from '@src/components/shared/AddResourceDependency/utils';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useResourceTypesQuery from '@src/hooks/react-query/resources/queries/useResourceTypesQuery';
import { ResourceCategory, ResourceTypes } from '@src/models/resources';
import { DynamicFormSchema } from '@src/types/dynamic-form';

export interface ResourceDependency {
  id: string;
  type: ResourceTypes;
  /**
   * If class is undefined, it defaults to 'default'.
   */
  class?: string;
  typeName: string;
  externalOrShared?: 'externals' | 'shared';
  category?: ResourceCategory;
  param_schema?: DynamicFormSchema;
}

export interface ResourceTypeItem {
  id: string;
  value: string | ResourceDependency;
  label: string;
  hideFromList?: boolean;
  separatorItem?: boolean;
  component?: ReactNode;
}

interface AddResourceDependencyProps {
  scope: 'private' | 'shared';
}

const AddResourceDependency = ({ scope }: AddResourceDependencyProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  const uiTranslations = t('UI');
  const resourcesTranslations = t('VIEW_MODULE').EXTERNAL_RESOURCES;

  // state
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: resourceTypes } = useResourceTypesQuery();
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();

  const [addingResource, setAddingResource] = useState<ResourceDependency>();
  const [currentStep, setCurrentStep] = useState(0);

  const [externalResourceOptions, setExternalResourceOptions] = useState<ResourceDependency[]>([]);

  const items: ResourceTypeItem[] = useMemo(() => {
    return getResourceTypeItems(externalResourceOptions, resourcesTranslations);
  }, [externalResourceOptions, resourcesTranslations]);

  useEffect(() => {
    if (!isModalOpen) {
      setCurrentStep(0);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setExternalResourceOptions(
      resourceTypes
        ?.filter(
          (resourceType) =>
            resourceDefinitions?.map((d) => d.type).includes(resourceType.type) &&
            resourceType.use === 'direct'
        )
        .map((resourceType) => ({
          id: resourceType.type,
          type: resourceType.type,
          typeName: resourceType.name,
          category: resourceType.category,
          param_schema: resourceType.inputs_schema || {},
        })) ?? []
    );
  }, [resourceTypes, resourceDefinitions]);

  return (
    <div>
      <Button variant={'secondary'} iconLeft={'plus'} onClick={() => setIsModalOpen(true)}>
        {sectionsTranslations.ADD_RESOURCE_DEPENDENCY}
      </Button>
      {isModalOpen && (
        <MultistepModal
          steps={[
            {
              title: sectionsTranslations.ADD_RESOURCE_DEPENDENCY,
              actions: [
                {
                  label: uiTranslations.CANCEL,
                },
              ],
              content: (
                <ResourceTypeList
                  items={items}
                  onClick={(item: ResourceTypeItem) => {
                    if (typeof item.value === 'string') {
                      setAddingResource(externalResourceOptions.find(({ id }) => id === item.id));
                    } else {
                      setAddingResource({ ...item, ...item.value });
                    }

                    setCurrentStep(currentStep + 1);
                  }}
                />
              ),
            },
            {
              title: addingResource?.typeName || '',
              actions: [],
              content: addingResource && (
                <AddResourceDependencyForm
                  goBack={() => setCurrentStep(currentStep - 1)}
                  resource={addingResource}
                  scope={scope}
                  finish={() => {
                    setIsModalOpen(false);
                    setCurrentStep(0);
                  }}
                />
              ),
            },
          ]}
          openState={[isModalOpen, setIsModalOpen]}
          currentStepState={[currentStep, setCurrentStep]}
        />
      )}
    </div>
  );
};

export default AddResourceDependency;
