import {
  Button,
  Checkbox,
  SearchInput,
  WalModal,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import VersionDetails from '@src/components/shared/ImageVersionsModal/VersionDetails';
import useArtefactVersionArchiveMutation from '@src/hooks/react-query/artefacts/mutations/useArtefactVersionArchiveMutation';
import useArtefactVersionsQuery from '@src/hooks/react-query/artefacts/queries/useArtefactVersionsQuery';
import useClientSidePagination from '@src/hooks/useClientSidePagination';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { ArtefactVersion } from '@src/models/artefact';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import RightArrowSVG from '../svg/RightArrowSVG';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GreyedOutText = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${units.margin.xl} 0;
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const ConfirmationText = styled.div`
  margin-top: ${units.margin.sm};
  margin-bottom: ${units.margin.sm};
`;
const TitleWrapper = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

const RightArrowSVGWrapper = styled(RightArrowSVG)`
  color: ${({ theme }) => theme.color.white};
  margin: 0 ${units.margin.md};
`;
interface SelectVersionButtonProps {
  deltaPath: string;
  artefactVersion: ArtefactVersion;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SelectVersionButton = ({ deltaPath, setOpen, artefactVersion }: SelectVersionButtonProps) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;

  const { updateWorkload, data: existingImageName } = useDeltaUtils(deltaPath);

  const selectVersion = (e: MouseEvent) => {
    e.stopPropagation();

    if (existingImageName) {
      updateWorkload([
        {
          op: 'replace',
          value: `${artefactVersion.name}${artefactVersion.version !== '' ? ':' : ''}${
            artefactVersion.version
          }`,
        },
      ]);

      setOpen(false);
    }
  };
  return (
    <Button onClick={selectVersion} size={'small'}>
      {imageTranslations.SELECT_VERSION}
    </Button>
  );
};

interface ChangeImageVersionModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  /* whether the modal is for listing the version of for selecting/changing a version */
  mode: 'list-versions' | 'select-version';
  title: string;
  artefactId: string;
  deltaPath?: string;
  currentArtefactVersionId?: string;
}

const ImageVersionsModal = ({
  openState,
  title,
  mode,
  artefactId,
  currentArtefactVersionId,
  deltaPath,
}: ChangeImageVersionModalProps) => {
  // i18n
  const { t } = useTranslation();
  const imageTranslations = t('VIEW_MODULE').IMAGE;
  const uiTranslations = t('UI');

  // Form
  const formMethods = useWalhallForm();

  const [filters, setFilters] = useState<{ text: string; archived: boolean }>({
    text: '',
    archived: false,
  });
  const [rowDataToArchive, setRowDataToArchive] = useState<ArtefactVersion | undefined>();
  const [archivedVersionsFetched, setArchivedVersionsFetched] = useState(false);

  // react query
  const { data: artefactVersions } = useArtefactVersionsQuery(artefactId, archivedVersionsFetched);

  // Mutations
  const { mutate: archiveVersion } = useArtefactVersionArchiveMutation(artefactId);

  const [filteredArtefactVersions, setFilteredArtefactVersions] = useState<
    ArtefactVersion[] | undefined
  >(artefactVersions);
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const {
    updateData: updatePaginationData,
    paginatedData,
    canShowNextPage,
    showNextPageData,
  } = useClientSidePagination(filteredArtefactVersions);

  useEffect(() => {
    if (filters.archived) {
      setArchivedVersionsFetched(true);
    }
  }, [artefactId, orgId, filters.archived]);

  useEffect(() => {
    if (artefactVersions) {
      const newArtefactVersions = [...artefactVersions].filter(
        (artefactVersion: ArtefactVersion) =>
          (artefactVersion.ref?.includes(filters.text) ||
            artefactVersion.commit?.includes(filters.text) ||
            artefactVersion.version?.includes(filters.text)) &&
          (!filters.archived ? !artefactVersion.archived : true)
      );
      setFilteredArtefactVersions(newArtefactVersions);
      updatePaginationData(newArtefactVersions); // reset pagination data when filtered data is updated
    }
  }, [artefactVersions, filters, updatePaginationData]);

  const closeModal = () => {
    openState[1](false);
  };

  const filterImageVersions = (value: string) => {
    setFilters({
      ...filters,
      text: value,
    });
  };

  const filterArchived = (event: MouseEvent) => {
    setFilters({
      ...filters,
      archived: (event.target as HTMLInputElement).checked,
    });
  };

  /**
   * checks if a version is overriden
   *
   * @param artefactVersion
   */
  const isOverriden = (artefactVersion: ArtefactVersion) => {
    return (
      artefactVersion?.id !== artefactVersions?.[0]?.id &&
      artefactVersion?.version === artefactVersions?.[0]?.version
    );
  };

  const handleArchiveOrUnarchiveButtonClick = (data: ArtefactVersion) => {
    if (data.archived) {
      archiveVersion(data);
    } else {
      setRowDataToArchive(data);
    }
  };

  const handleBackButtonClick = () => {
    setRowDataToArchive(undefined);
  };

  const handleConfirmationButtonClick = (data: ArtefactVersion) => {
    archiveVersion(data);
    closeModal();
    setRowDataToArchive(undefined);
  };

  const tableColumns: WalTableColumn[] = [
    {
      label: imageTranslations.COMMIT_ID,
      prop: 'commit',
      ellipsisTooltip: {
        maxWidth: 100,
        maxCharacters: 10,
        text: (row) => row.data.commit,
      },
      template: (row: WalTableRow<ArtefactVersion>) => {
        return isOverriden(row.data) || row.data.archived ? (
          <GreyedOutText>{row.data.commit}</GreyedOutText>
        ) : (
          <span>{row.data.commit}</span>
        );
      },
    },
    {
      label: imageTranslations.REFERENCE,
      prop: 'ref',
      ellipsisTooltip: {
        maxWidth: 100,
        text: (row) => row.data.ref,
        invertEllipsis: true,
      },
      template: (row: WalTableRow<ArtefactVersion>) => {
        return isOverriden(row.data) || row.data.archived ? (
          <GreyedOutText>{row.data.ref}</GreyedOutText>
        ) : (
          <span>{row.data.ref}</span>
        );
      },
    },
    {
      label: imageTranslations.IMAGE_TAG,
      prop: 'version',
      ellipsisTooltip: {
        maxWidth: 100,
        text: (row) => row.data.version,
      },
      template: (row: WalTableRow<ArtefactVersion>) => {
        return isOverriden(row.data) || row.data.archived ? (
          <GreyedOutText>{row.data.version}</GreyedOutText>
        ) : (
          <span>{row.data.version}</span>
        );
      },
    },
    {
      prop: 'actions',
      template: (row: WalTableRow<ArtefactVersion>) =>
        !rowDataToArchive ? (
          mode === 'select-version' ? (
            currentArtefactVersionId === row.data.id ? (
              <span>{imageTranslations.SELECTED_VERSION}</span>
            ) : isOverriden(row.data) ? (
              <GreyedOutText>{imageTranslations.OVERRIDEN}</GreyedOutText>
            ) : row.data.archived ? (
              <GreyedOutText>{imageTranslations.ARCHIVED}</GreyedOutText>
            ) : (
              deltaPath && (
                <SelectVersionButton
                  deltaPath={deltaPath}
                  setOpen={openState[1]}
                  artefactVersion={row.data}
                />
              )
            )
          ) : (
            <Button
              variant={'secondary'}
              size={'small'}
              onClick={() => handleArchiveOrUnarchiveButtonClick(row.data)}>
              {row.data.archived ? imageTranslations.UNARCHIVE : imageTranslations.ARCHIVE}
            </Button>
          )
        ) : (
          <div />
        ),
    },
  ];

  const renderTitle = () => {
    return rowDataToArchive ? (
      <>
        <TitleWrapper>{title}</TitleWrapper> <RightArrowSVGWrapper size={'small'} />
        {imageTranslations.ARCHIVE_VERSION}
      </>
    ) : (
      title
    );
  };

  const renderContent = () => {
    return !rowDataToArchive ? (
      <>
        <FormProvider {...formMethods}>
          <FilterWrapper>
            <SearchInput
              className={'my-md'}
              name={'filter-versions'}
              placeholder={imageTranslations.IMAGE_VERSIONS_SEARCH_PLACEHOLDER}
              onChange={filterImageVersions}
            />
            <Checkbox
              name={'show-archived'}
              label={imageTranslations.SHOW_ARCHIVED}
              handleChange={filterArchived}
            />
          </FilterWrapper>
        </FormProvider>
        {filteredArtefactVersions?.length === 0 && (
          <NoResultsWrapper>
            <span className={'txt-lg'}>{imageTranslations.NO_RESULTS_FOUND}</span>
            <span className={'txt-sm'}>
              {imageTranslations.NO_RESULTS} {`"${filters.text}"`}
            </span>
          </NoResultsWrapper>
        )}
        {filteredArtefactVersions && (
          <>
            <WalTable
              caption={imageTranslations.IMAGE_VERSIONS_TABLE_CAPTION}
              disableScrolling
              tableRowStyle={'transparent'}
              tableStyle={mode === 'select-version' ? 'expandable' : 'regular'}
              rows={paginatedData.map((imageVersion) => ({
                data: imageVersion,
                expandableContent: (
                  <VersionDetails
                    version={imageVersion}
                    isOverriden={isOverriden(imageVersion)}
                    onArchiveButtonClick={() => handleArchiveOrUnarchiveButtonClick(imageVersion)}
                    hideArchiveButton={currentArtefactVersionId === imageVersion.id}
                  />
                ),
              }))}
              columns={tableColumns}
            />
            {canShowNextPage && (
              <div className={'flex justify-center'}>
                <Button variant={'secondary'} onClick={showNextPageData}>
                  {t('LOAD_XX_MORE', { pageSize: 10 })}
                </Button>
              </div>
            )}
          </>
        )}
      </>
    ) : (
      <>
        <ConfirmationText>{imageTranslations.ARCHIVE_CONFIRMATION_TEXT}</ConfirmationText>
        <WalTable
          caption={imageTranslations.IMAGE_VERSIONS_TABLE_CAPTION}
          disableScrolling
          tableStyle={mode === 'select-version' ? 'expandable' : 'regular'}
          tableRowStyle={'transparent'}
          rows={[
            {
              data: rowDataToArchive,
              expandableContent: (
                <VersionDetails
                  version={rowDataToArchive}
                  isOverriden={rowDataToArchive && isOverriden(rowDataToArchive)}
                  onArchiveButtonClick={() => archiveVersion(rowDataToArchive)}
                  hideArchiveButton
                />
              ),
            },
          ]}
          columns={tableColumns}
        />
      </>
    );
  };

  return (
    <WalModal
      title={renderTitle()}
      openState={openState}
      size={'large'}
      content={renderContent()}
      actions={
        rowDataToArchive
          ? {
              main: {
                text: imageTranslations.ARCHIVE,
                props: {
                  variant: 'danger',
                  onClick: () => handleConfirmationButtonClick(rowDataToArchive),
                },
              },
              cancel: {
                text: uiTranslations.BACK,
                props: {
                  onClick: handleBackButtonClick,
                },
              },
              justifyContentSpaceBetween: true,
            }
          : {
              cancel: {
                text: uiTranslations.CLOSE,
              },
            }
      }
    />
  );
};

export default ImageVersionsModal;
