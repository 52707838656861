import { Button, WalCard } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import fiveMinIDPImg from '@src/assets/images/5-min-idp.webp';
import existingClusterImg from '@src/assets/images/existing-cluster.png';
import referenceArchitectureImg from '@src/assets/images/reference-architecture.png';
import { units } from '@src/styles/variables';
import { getGetStartedHidden, setGetStartedHidden } from '@src/utilities/local-storage';

const CardEntryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${units.padding.xl};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: ${units.fontSize.lg};
`;

const Image = styled.img`
  width: 100%;
  margin: ${units.margin.md} 0;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: ${units.padding.md};
`;

const StyledCard = styled(WalCard)`
  justify-content: space-between;
`;

interface CardEntryProps {
  title: string;
  image: {
    src: string;
    alt: string;
  };
  descriptions: string[];
  duration: string;
  link: { text: string; href: string };
}

const CardEntry = ({ title, image, descriptions, duration, link }: CardEntryProps) => {
  return (
    <StyledCard cardStyle={'base'}>
      <div className={'mb-lg'}>
        <Title className={'bold'}>{title}</Title>
        <Link to={link.href} target={'_blank'}>
          <Image src={image.src} alt={image.alt} />
        </Link>
        {descriptions.map((description, index) => (
          <div
            key={description}
            className={index !== descriptions.length - 1 ? 'mb-md' : undefined}>
            {description}
          </div>
        ))}
      </div>
      <div>
        <div className={'txt-translucent pb-xs'}>Duration</div>
        <div className={'txt-lg mb-md'}>{duration}</div>
        <Button size={'small'} variant={'secondary'} link={{ href: link.href }} target={'_blank'}>
          {link.text}
        </Button>
      </div>
    </StyledCard>
  );
};

export const GetStarted = () => {
  // i18n
  const { t } = useTranslation('viewApplicationList');
  const translations = t('GET_STARTED');
  const fiveMinIDP = t('GET_STARTED').FIVE_MIN_IDP;
  const existingCluster = t('GET_STARTED').EXISTING_CLUSTER;
  const referenceArchitecture = t('GET_STARTED').REFERENCE_ARCHITECTURE;

  const [hideGetStarted, setHideGetStarted] = useState(getGetStartedHidden());

  const entries: CardEntryProps[] = [
    {
      title: fiveMinIDP.TITLE,
      image: {
        src: fiveMinIDPImg,
        alt: fiveMinIDP.TITLE,
      },
      descriptions: [fiveMinIDP.DESC_1, fiveMinIDP.DESC_2],
      duration: fiveMinIDP.DURATION,
      link: {
        text: fiveMinIDP.LINK_TEXT,
        href: fiveMinIDP.LINK,
      },
    },
    {
      title: existingCluster.TITLE,
      image: {
        src: existingClusterImg,
        alt: existingCluster.TITLE,
      },
      descriptions: [existingCluster.DESC_1, existingCluster.DESC_2],
      duration: existingCluster.DURATION,
      link: {
        text: existingCluster.LINK_TEXT,
        href: existingCluster.LINK,
      },
    },
    {
      title: referenceArchitecture.TITLE,
      image: {
        src: referenceArchitectureImg,
        alt: referenceArchitecture.TITLE,
      },
      descriptions: [referenceArchitecture.DESC_1, referenceArchitecture.DESC_2],
      duration: referenceArchitecture.DURATION,
      link: {
        text: referenceArchitecture.LINK_TEXT,
        href: referenceArchitecture.LINK,
      },
    },
  ];

  return (
    !hideGetStarted && (
      <WalCard className={'mt-xl'}>
        <TitleWrapper>
          <h2 className={'mt-md mb-lg'}>{translations.TITLE}</h2>
          <Button
            variant={'borderless-blue'}
            className={'mt-md'}
            onClick={() => {
              setHideGetStarted(true);
              setGetStartedHidden();
            }}>
            {translations.DISMISS}
          </Button>
        </TitleWrapper>
        <CardEntryWrapper>
          {entries.map((entry) => (
            <CardEntry key={entry.title} {...entry} />
          ))}
        </CardEntryWrapper>
      </WalCard>
    )
  );
};
