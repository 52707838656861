import { Button, WalInput } from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { rem } from 'polished';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useOrgCreateMutation from '@src/hooks/react-query/organisations/mutations/useOrgCreateMutation';
import { orgQueryKeys } from '@src/hooks/react-query/organisations/orgQueryKeys';
import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/useGetCurrentUserQuery';
import useModifyUserMutation from '@src/hooks/react-query/user/useModifyUserMutation';
import { cl } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { generateAppURL } from '@src/utilities/navigation';

import OrganizationNameInput from '../../components/OrganizationNameInput';

const SubmitButton = styled(Button)`
  margin-top: ${units.margin.md};
  .inner-button-text {
    justify-content: center;
  }
`;

const Label = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: ${rem(300)};
`;

interface FormProps {
  name: string;
  organization_name: string;
  email: string;
}

const AccountDetails = () => {
  const queryClient = useQueryClient();

  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  // Router hooks
  const navigate = useNavigate();

  // React Query
  const { data: user, refetch } = useGetCurrentUserQuery();

  // Form
  const methods = useWalhallForm<FormProps>({
    defaultValues: {
      name: user?.name,
      organization_name: '',
      email: '',
    },
  });
  const { formState, handleSubmit, setError } = methods;

  // React Query
  const { mutate: modifyUser, isPending: isModifyingUser } = useModifyUserMutation();
  const {
    mutate: createOrganization,
    isPending: creatingOrg,
    data: createdOrg,
  } = useOrgCreateMutation({
    onError: (error) => {
      if (error.response?.status === 409) {
        setError('organization_name', {
          type: 'validate',
          message: authTranslations.ORGANIZATION_NAME_IS_TAKEN,
        });
      }
    },
  });

  const { data: orgs = [] } = useGetOrgsQuery();

  useEffect(() => {
    if (createdOrg) {
      refetch();
      navigate(generateAppURL(createdOrg.id));
      queryClient.invalidateQueries({ queryKey: orgQueryKeys.list() });
    }
  }, [createdOrg, navigate, queryClient, refetch]);

  const registerOrg = async (formValues: FormProps) => {
    await createOrganizationClick(formValues);
  };

  useEffect(() => {
    const firstOrgId = orgs[0]?.id;
    if (user?.name && firstOrgId) {
      navigate(generateAppURL(firstOrgId));
    }
  }, [navigate, orgs, user?.name]);

  const createOrganizationClick = async (formValues: FormProps) => {
    const userModifyPayload: { name?: string; email?: string } = {};
    if (formValues.name && user?.name !== formValues.name) {
      userModifyPayload.name = formValues.name;
    }
    if (formValues.email) userModifyPayload.email = formValues.email;

    // If user has modified email or name, send modifyuser action.
    if (Object.keys(userModifyPayload).length) {
      modifyUser({
        user: userModifyPayload,
        skipInvalidation: Boolean(formValues.organization_name),
      });
    }

    if (formValues.organization_name) {
      createOrganization(formValues.organization_name);
    }
  };

  return (
    <FormProvider {...methods}>
      <h1 {...cl('mb-xl', 'txt-center')}>{authTranslations.ACCOUNT_DETAILS}</h1>
      <FormWrapper onSubmit={handleSubmit(registerOrg)}>
        {user && (
          <>
            {user.email ? (
              <>
                <Label>{authTranslations.EMAIL}</Label>
                <div>{user?.email}</div>
              </>
            ) : (
              <WalInput label={'Email'} name={'email'} />
            )}
            <WalInput label={'Full Name'} name={'name'} required />
          </>
        )}
        {!orgs.length && <OrganizationNameInput />}
        <SubmitButton
          loading={creatingOrg || isModifyingUser}
          type={'submit'}
          variant={'primary'}
          disabled={formState.isSubmitting || creatingOrg || isModifyingUser}>
          {authTranslations.NEXT}
        </SubmitButton>
      </FormWrapper>
    </FormProvider>
  );
};

export default AccountDetails;
