import { rem } from 'polished';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { BreadcrumbItem } from '@src/hooks/useBreadcrumbs';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

const BreadcrumbListItem = styled.li`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  font-size: ${units.fontSize.sm};
  max-width: ${rem(250)};
  gap: ${units.margin.sm};
`;

const Name = styled.span`
  ${ellipsisStyle()};
  font-size: ${units.fontSize.sm};
`;

const LinkStyled = styled(Link)`
  font-size: unset;
  ${ellipsisStyle()};
  &:hover {
    color: ${({ theme }) => theme.color.main};
    text-decoration: underline;
  }
`;
const Label = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  white-space: nowrap;
`;

const Separator = styled.div`
  padding: 0 ${units.padding.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

interface BreadcrumbSectionProps extends BreadcrumbItem {
  isLastItem?: boolean;
  onClick?: MouseEventHandler;
}

const BreadcrumbSection = ({
  name,
  isLastItem,
  pathname,
  label,
  onClick,
}: BreadcrumbSectionProps) => (
  <BreadcrumbListItem>
    <Wrapper>
      <Label>{label}</Label>
      {pathname && !isLastItem ? (
        <LinkStyled className={'title-link'} to={pathname} onClick={onClick}>
          {name}
        </LinkStyled>
      ) : (
        <Name>{name}</Name>
      )}
    </Wrapper>
    {!isLastItem && <Separator>/</Separator>}
  </BreadcrumbListItem>
);

export default BreadcrumbSection;
