import { useEffect, useState } from 'react';

import { useDeltaUtils } from './useDeltaUtils/useDeltaUtils';

const MANAGED_BY = 'humanitec.io/managed-by';
const WORKLOAD_SOURCE = 'humanitec.io/workload-source';
const PLATFORM_ORCHESTRATOR = 'platform-orchestrator';

/**
 * Hook to determine if score warning need to be shown
 */
const useShowWarning = () => {
  const [showScoreWarning, setShowScoreWarning] = useState(false);
  const [managedBy, setManagedBy] = useState<string | null>(null);
  const [managedByLink, setManagedByLink] = useState<string | null>(null);

  const { data: annotations } = useDeltaUtils<Record<string, string>>('spec/annotations');

  useEffect(() => {
    const showWarning = Boolean(
      annotations && annotations[MANAGED_BY] && annotations[MANAGED_BY] !== PLATFORM_ORCHESTRATOR
    );
    setShowScoreWarning(showWarning);
    if (showWarning && annotations?.[MANAGED_BY]) {
      setManagedBy(annotations?.[MANAGED_BY]);
    }
    if (showWarning && annotations && annotations[WORKLOAD_SOURCE]) {
      setManagedByLink(annotations[WORKLOAD_SOURCE]);
    }
  }, [annotations, setManagedBy, setManagedByLink]);

  return { showScoreWarning, managedBy, managedByLink };
};

export default useShowWarning;
