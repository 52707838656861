import { UserPreferences } from '@src/hooks/zustand/useUserPreferencesStore';

import {
  LS_DISABLE_FREE_TRIAL,
  LS_DISMISS_GET_STARTED,
  LS_INVITE_TOKEN,
  LS_LAST_VISITED_APP,
  LS_LAST_VISITED_URL,
  LS_NAVIGATION_MINIMIZED,
  LS_SELECTED_ORGANIZATION,
  LS_USER_PREFERENCES,
} from './variables';

/**
 * Returns the selected organization.
 */
export const getSelectedOrganization = (): string | null => {
  return localStorage.getItem(LS_SELECTED_ORGANIZATION);
};

export const getUserPreferencesFromLS = (): UserPreferences | null => {
  const preferences = localStorage.getItem(LS_USER_PREFERENCES);
  return preferences && JSON.parse(preferences);
};

export const setUserPreferencereInLS = (userPreferences: UserPreferences): void => {
  localStorage.setItem(LS_USER_PREFERENCES, JSON.stringify(userPreferences));
};

/**
 * Sets the selected organization.
 */
export const setSelectedOrganization = (organizationUuid: string): void => {
  localStorage.setItem(LS_SELECTED_ORGANIZATION, organizationUuid);
};

/**
 * Returns InviteToken.
 */
export const getInviteToken = (): string | null => {
  return localStorage.getItem(LS_INVITE_TOKEN);
};

/**
 * Gets lastVisitedApp.
 */
export const getLastVisitedApp = (): string | null => {
  return localStorage.getItem(LS_LAST_VISITED_APP);
};

/**
 * Sets lastVisitedApp.
 */
export const setLastVisitedApp = (appId: string): void => {
  localStorage.setItem(LS_LAST_VISITED_APP, appId);
};

/**
 * Removes lastVisitedApp.
 */
export const removeLastVisitedApp = (): void => {
  localStorage.removeItem(LS_LAST_VISITED_APP);
};

/**
 * Gets lastVisitedURL.
 */
export const getLastVisitedURL = (): string | null => {
  return localStorage.getItem(LS_LAST_VISITED_URL);
};

/**
 * Sets lastVisitedURL.
 */
export const setLastVisitedURL = (url: string): void => {
  localStorage.setItem(LS_LAST_VISITED_URL, url);
};

/**
 * Sets InviteToken.
 */
export const setInviteToken = (inviteToken: string): void => {
  localStorage.setItem(LS_INVITE_TOKEN, inviteToken);
};

/**
 * Removes InviteToken.
 */
export const removeInviteToken = (): void => {
  localStorage.removeItem(LS_INVITE_TOKEN);
};

/**
 * Returns if the navigation menu is minimized.
 */
export const getNavigationMenuMinimized = (): boolean => {
  return Boolean(localStorage.getItem(LS_NAVIGATION_MINIMIZED) === 'true');
};

/**
 * Sets if the navigation menu is minimized.
 */
export const setNavigationMenuMinimized = (minimized: boolean): void => {
  localStorage.setItem(LS_NAVIGATION_MINIMIZED, minimized ? 'true' : 'false');
};

/**
 * Returns if the free trial should be disabled.
 */
export const getFreeTrialDisabled = (): boolean => {
  return Boolean(localStorage.getItem(LS_DISABLE_FREE_TRIAL) === 'true');
};

/**
 * Sets if the free trial should be disabled.
 */
export const setFreeTrialDisabled = (disableFreeTrial: boolean): void => {
  localStorage.setItem(LS_DISABLE_FREE_TRIAL, disableFreeTrial ? 'true' : 'false');
};

/**
 * Removes disableFreeTrial.
 */
export const removeFreeTrialDisabled = (): void => {
  localStorage.removeItem(LS_DISABLE_FREE_TRIAL);
};

/**
 * Returns if get started is hidden.
 */
export const getGetStartedHidden = (): boolean => {
  return Boolean(localStorage.getItem(LS_DISMISS_GET_STARTED) === 'true');
};

/**
 * Sets if get started is hidden.
 */
export const setGetStartedHidden = (): void => {
  localStorage.setItem(LS_DISMISS_GET_STARTED, 'true');
};
