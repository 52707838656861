import {
  ErrorWarning,
  Text,
  WalInput,
  WalLabel,
  WalModal,
  WarningSection,
} from '@humanitec/ui-components';
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const InputWrapper = styled.div`
  margin-top: ${units.margin.sm};
`;

const CustomWalLabel = styled(WalLabel)`
  display: inline-block;
  word-break: break-all;
`;

export type InputConfirmationType = {
  /** the correct value to be validated against */
  correctValue: string;
  /** a string that describes the entity to be deleted e.g 'application' */
  objectName: string;
  /** the property that the user must type to confirm deletion */
  propertyToType?: string;
  /** for form to validate when input is changed */
  validateOnChange?: boolean;
  /** optional placeholder for input */
  placeholder?: string;
};
export interface ConfirmDeleteModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  deleteConfirmedCallback: () => void;
  confirmMessage?: string;
  title?: string;
  deleteButtonText?: string;
  customContentComponent?: ReactNode;
  customWarningComponent?: ReactNode;
  modalSize?: 'medium' | 'large';
  /* Shows "This action cannot be undone" text if set to true. This text by default would always show when inputConfirmation is passed */
  showWarningText?: boolean;
  /** if set to true the user needs to fill an input to confirm the deletion */
  inputConfirmation?: InputConfirmationType;
  /** if the deleting request is pending, this usually comes from React query */
  isDeleting?: boolean;
  /** code and message of the API error */
  deleteError?: {
    code?: string;
    message?: string;
  };
  disableConfirmDeleteButton?: boolean;
  /** function to be called when cancel is clicked */
  onCancel?: () => void;
}

const ConfirmDeleteModal = ({
  state,
  deleteConfirmedCallback,
  title,
  confirmMessage,
  deleteButtonText,
  inputConfirmation,
  showWarningText,
  customContentComponent,
  customWarningComponent,
  onCancel,
  isDeleting,
  deleteError,
  disableConfirmDeleteButton,
  modalSize,
}: ConfirmDeleteModalProps) => {
  const [open, setOpen] = state;

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI', {
    objectName: inputConfirmation?.objectName,
    returnObjects: true,
  });
  const enterNameTranslation = t('UI.DELETE_ENTER_PROPERTY', {
    objectName: inputConfirmation?.objectName,
    propertyToType: inputConfirmation?.propertyToType || 'name',
  });

  // Form
  const methods = useWalhallForm({
    mode: inputConfirmation?.validateOnChange ? 'onChange' : 'onSubmit',
  });
  const {
    reset,
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const cancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <WalModal
        openState={state}
        handleFormSubmit={() => deleteConfirmedCallback()}
        title={title || uiTranslations.DELETE}
        size={modalSize}
        content={
          <>
            {deleteError && (
              <ErrorWarning
                code={deleteError?.code}
                message={deleteError?.message}
                className={'mt-md'}
              />
            )}
            <div className={'mb-sm'}>
              {' '}
              {customContentComponent || confirmMessage || uiTranslations.ARE_YOU_SURE}
            </div>
            {(inputConfirmation || showWarningText) && !customWarningComponent && (
              <WarningSection smallIcon showIcon>
                {uiTranslations.ACTION_CANNOT_BE_UNDONE}
              </WarningSection>
            )}
            {inputConfirmation && (
              <div className={'mb-sm'}>
                <CustomWalLabel>
                  <Trans
                    defaults={uiTranslations.DELETE_TYPE_NAME}
                    values={{
                      objectName: inputConfirmation.objectName,
                      propertyToType: inputConfirmation.propertyToType || 'name',
                    }}>
                    <Text className={'mx-xs'} />
                  </Trans>
                </CustomWalLabel>
                <InputWrapper>
                  <WalInput
                    name={'name'}
                    hideLabel
                    placeholder={inputConfirmation.placeholder || enterNameTranslation}
                    required
                    validate={{
                      validateName: (value) =>
                        value === inputConfirmation.correctValue || enterNameTranslation,
                    }}
                  />
                </InputWrapper>
              </div>
            )}
            {customWarningComponent}
          </>
        }
        actions={{
          main: {
            props: {
              variant: 'danger',
              type: 'submit',
              disabled:
                disableConfirmDeleteButton ||
                Object.keys(errors).length > 0 ||
                (!isDirty && inputConfirmation?.validateOnChange),
              loading: isDeleting,
              iconLeft: 'delete',
            },
            text: deleteButtonText ? deleteButtonText : uiTranslations.DELETE,
          },
          cancel: {
            props: {
              onClick: cancel,
            },
          },
        }}
      />
    </FormProvider>
  );
};

export default ConfirmDeleteModal;
