import { ActiveResource } from '@humanitec/resource-graph';
import { Icon, Tooltip } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { useResourceDefinitionVersionQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersionQuery';

import { VersionLink } from '../VersionLink';

interface PinnedToColumnProps {
  activeResource: ActiveResource | undefined;
}

export const PinnedToColumn = ({ activeResource }: PinnedToColumnProps) => {
  // React Query
  const { data: pinnedVersion } = useResourceDefinitionVersionQuery({
    defVersionId: activeResource?.target_def_version_id,
  });

  // i18n
  const { t } = useTranslation();
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.ACTIVE_RESOURCES;

  const showWarning = activeResource?.target_def_version_id !== activeResource?.def_version_id;

  return pinnedVersion ? (
    showWarning ? (
      <Tooltip
        triggerComponent={
          <span className={'flex'}>
            <Icon name={'warning'} overrideColor={'yellow'} marginRight={'sm'} />
            <VersionLink resourceDefinitionVersion={pinnedVersion} />
          </span>
        }
        text={translations.PINNED_PROVISIONED_NEXT_DEPLOYMENT}
      />
    ) : (
      <VersionLink resourceDefinitionVersion={pinnedVersion} />
    )
  ) : (
    translations.NOT_PINNED
  );
};
