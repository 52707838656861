import {
  Button,
  ComboSelectMenuItem,
  DropdownItem,
  EmptyStateCard,
  ErrorWarning,
  WalTable,
  WalTableColumn,
  WarningSection,
} from '@humanitec/ui-components';
import { AxiosError } from 'axios';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AddNewCriteria, {
  CriteriaFormField,
} from '@src/components/shared/MatchingCriteria/AddNewCriteria/AddNewCriteria';
import ConfirmDeleteMatchingCriteriaModal from '@src/components/shared/MatchingCriteria/ConfirmDeleteMatchingCriteriaModal/ConfirmDeleteMatchingCriteriaModal';
import ToggleDefaultResource from '@src/components/shared/MatchingCriteria/ToggleDefaultResourceDefinition/ToggleDefaultResourceDefinition';
import GetInactivityStatus from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionMatchingCriteria/components/GetInactivityStatus';
import ShowMatchingCriteriaInfo from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ShowMatchingCriteriaInfo';
import { useRBAC } from '@src/hooks/useRBAC';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { PipelineCriteria } from '@src/models/pipeline';
import { ResourceCriteria, ResourceDefinition } from '@src/models/resources';
import { units } from '@src/styles/variables';

const HeadingText = styled.div`
  margin-bottom: ${units.margin.md};
  font-size: ${units.fontSize.base};
`;

export const InfoContainer = styled.div`
  max-width: 73vw;
`;

export const ShowMatchingCriteriaDetails = styled(ShowMatchingCriteriaInfo)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CriteriaRowHeadingText = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const CriteriaHeading = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-bottom: ${units.margin.sm};
  font-size: ${units.fontSize.sm};
`;

const CriteriaValue = styled.div`
  margin-bottom: ${units.margin.md};
  font-size: ${units.fontSize.sm};
`;

// Mapping for API property names against display names
const criteriaNamesMapping = {
  env_type: 'Environment type',
  app_id: 'Application ID',
  env_id: 'Environment ID',
  res_id: 'Resource ID',
  class: 'Resource class',
  deployment_type: 'Deployment type',
};

interface MatchingCriteriaProps<T> {
  /** the description on top of the component */
  description: ReactNode;
  /** callback when submitting the criteria */
  onSubmitCriteria: (criteria: Partial<T>) => void;
  /** list of the criteria from the backend  */
  criteriaList?: T[];
  /** the section where this component is shown */
  section?: 'pipelines' | 'resources';
  /** the description in adding new criteria section */
  addCriteriaDescription: string;
  /** map of combo select items per criteria */
  comboSelectItemsMap: Partial<Record<keyof T, ComboSelectMenuItem<string>[]>>;
  /** the possible dropdown options to select from */
  defaultDropdownOptions: DropdownItem<keyof T>[];
  /** the initially selected item in the dropdown when addaing a new criteria  */
  initialCriteria: CriteriaFormField<T>[];
  /** isCreating from react query */
  isCriteriaCreating: boolean;
  /** isCreated from react query */
  isCriteriaCreated: boolean;
  /** submit error from react query */
  submitCriteriaError: AxiosError<ApiErrorResponse> | null;
  /** delete error from react query */
  deleteCriteriaError: AxiosError<ApiErrorResponse> | null;
  /** isDeleting from react query */
  isDeletingCriteria: boolean;
  /** content of the confirm delete modal */
  confirmDeleteModalContent: ReactNode;
  /** state of the confirm delete modal */
  confirmDeleteModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  criteriaToDelete: T | null;
  /** callback when confirming deleting from the confirm delete modal */
  onDeleteConfirmed: () => void;
  /** callback when clicking on the delete button from the criteria list */
  onDeleteButtonClicked: (criteria: T) => void;
  /* the current resource this is only needed in the case of resource definition to show the inactivity status of the criteria */
  resourceDefinition?: ResourceDefinition;
}

const MatchingCriteria = <T extends PipelineCriteria | ResourceCriteria>({
  description,
  onSubmitCriteria,
  addCriteriaDescription,
  initialCriteria,
  submitCriteriaError,
  isCriteriaCreated,
  isCriteriaCreating,
  comboSelectItemsMap,
  defaultDropdownOptions,
  criteriaList,
  confirmDeleteModalContent,
  confirmDeleteModalState,
  deleteCriteriaError,
  isDeletingCriteria,
  onDeleteConfirmed,
  resourceDefinition,
  criteriaToDelete,
  onDeleteButtonClicked,
  section,
}: MatchingCriteriaProps<T>) => {
  // State
  const [showAddForm, setShowAddForm] = useState(false);
  const [deleteConflictsModalOpen] = confirmDeleteModalState;
  const canEditApp = useRBAC('editApplication');

  // i18n
  const { t } = useTranslation();
  const translations = t('MATCHING_CRITERIA');
  const uiTranslations = t('UI');

  useEffect(() => {
    setShowAddForm(false);
  }, [isCriteriaCreated]);

  const handleDeleteIconClick = (e: any, criteria: T) => {
    e.stopPropagation();
    onDeleteButtonClicked(criteria);
  };

  const handleSubmitCriteria = (formValues: { criteria: CriteriaFormField<T>[] }) => {
    const criteria = formValues.criteria?.reduce((acc: Partial<T>, criteriaFormField) => {
      (acc[criteriaFormField.key] as string) = criteriaFormField.value;
      return acc;
    }, {});
    onSubmitCriteria(criteria);
  };

  const columns: WalTableColumn<T>[] = [
    {
      prop: 'id',
      template: (row, index) => {
        const criteria = { ...row.data };
        if (section === 'pipelines') {
          // remove the app_id from the criteria to be displayed if we are showing
          // pipeline criteria because the pipeline app will be added to every criteria
          delete criteria.app_id;
        }
        return (
          <InfoContainer>
            <CriteriaRowHeadingText>{`${translations.CRITERIA} ${
              criteriaList && criteriaList.length - index
            }`}</CriteriaRowHeadingText>
            <ShowMatchingCriteriaDetails criteria={criteria} />
            {section === 'resources' && (
              <GetInactivityStatus
                criteria={criteria as ResourceCriteria}
                resource={resourceDefinition}
              />
            )}
          </InfoContainer>
        );
      },
      justifyContent: 'flex-start',
    },
    {
      prop: 'actions',
      tabIndex: -1,
      hide: !canEditApp,
      template: (row) => (
        <Button
          ariaLabel={uiTranslations.DELETE}
          iconLeft={{ name: 'delete' }}
          loading={
            isDeletingCriteria && !deleteConflictsModalOpen && criteriaToDelete?.id === row.data.id
          }
          key={row.data.id}
          variant={'secondary'}
          size={'small'}
          onClick={(e) => handleDeleteIconClick(e, row.data)}
        />
      ),
    },
  ];

  const renderRowDetails = (criteria: ResourceCriteria) => {
    const criteriaProperties = Object.entries(criteria).filter(
      ([key, _]) =>
        key === 'app_id' ||
        key === 'env_type' ||
        key === 'env_id' ||
        key === 'res_id' ||
        key === 'deployment_type' ||
        key === 'class'
    );

    // For cases when all conditions match all values
    if (criteriaProperties.length === 0) {
      const criteriaArray = Object.values(criteriaNamesMapping);
      return criteriaArray.map((value) => (
        <>
          <CriteriaHeading>{value}</CriteriaHeading>
          <CriteriaValue>{translations.ALL_VALUES}</CriteriaValue>
        </>
      ));
    }

    return criteriaProperties.map(([key, value]) => (
      <>
        <CriteriaHeading>
          {criteriaNamesMapping[key as keyof typeof criteriaNamesMapping]}
        </CriteriaHeading>
        <CriteriaValue>{value}</CriteriaValue>
      </>
    ));
  };

  return (
    <div className={'flex-column'}>
      {description && <HeadingText>{description}</HeadingText>}
      {section === 'resources' &&
        deleteCriteriaError &&
        deleteCriteriaError.response?.status !== 409 && (
          <ErrorWarning
            code={(deleteCriteriaError.response?.data as ApiErrorResponse).error}
            message={(deleteCriteriaError.response?.data as ApiErrorResponse).message}
          />
        )}
      {resourceDefinition?.is_default ? (
        <ToggleDefaultResource resourceDefinition={resourceDefinition} />
      ) : (
        <>
          <span className={'mt-lg mb-lg'}>
            {showAddForm ? (
              <AddNewCriteria<T>
                onCancel={() => setShowAddForm(false)}
                submitCriteria={handleSubmitCriteria}
                description={addCriteriaDescription}
                initialSelectedCriteria={initialCriteria}
                isCriteriaCreating={isCriteriaCreating}
                section={section}
                errorText={
                  submitCriteriaError?.response?.data?.message && (
                    <WarningSection mode={'alert'}>
                      {submitCriteriaError?.response?.data?.message}
                    </WarningSection>
                  )
                }
                itemsMap={comboSelectItemsMap}
                defaultDropdownOptions={defaultDropdownOptions}
              />
            ) : (
              <Button iconLeft={'plus'} onClick={() => setShowAddForm(true)} disabled={!canEditApp}>
                {translations.ADD_NEW}
              </Button>
            )}
          </span>
          {criteriaList?.length ? (
            <WalTable
              tableStyle={'expandable'}
              disableScrolling
              caption={translations.TITLE}
              columns={columns}
              rows={criteriaList.map((criteria) => ({
                data: criteria,
                expandableContent: renderRowDetails(criteria),
              }))}
            />
          ) : (
            <EmptyStateCard>{translations.NO_CRITERIA}</EmptyStateCard>
          )}
          {criteriaToDelete && (
            <ConfirmDeleteMatchingCriteriaModal
              openState={confirmDeleteModalState}
              onDeleteConfirmed={onDeleteConfirmed}
              deleteCriteriaError={section === 'pipelines' ? deleteCriteriaError : undefined}
              isDeletingCriteria={isDeletingCriteria}
              title={translations.DELETE_MATCHING_CRITERIA}
              content={confirmDeleteModalContent}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MatchingCriteria;
