import { ActiveResource } from '@humanitec/resource-graph';
import { Icon, Tooltip } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { useResourceDefinitionVersionQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersionQuery';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';

import { getVersionStatus } from '../../ResourceDefinitionVersions/resource-definition-versions.utils';
import { VersionLink } from './VersionLink';

interface ProvisionedVersionColumnProps {
  activeResource: ActiveResource;
}

export const ProvisionedVersionColumn = ({ activeResource }: ProvisionedVersionColumnProps) => {
  // React Query
  const { data: provisionedVersion } = useResourceDefinitionVersionQuery({
    defVersionId: activeResource.def_version_id,
  });

  // i18n
  const { t } = useTranslation();
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.ACTIVE_RESOURCES;

  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();
  const latestResourceDefinitionVersion = resourceDefinitionVersions?.[0];

  const versionStatus = provisionedVersion
    ? getVersionStatus(
        provisionedVersion,
        latestResourceDefinitionVersion?.id === activeResource.def_version_id
      )
    : '';

  const link = () => (
    <>
      {versionStatus}
      {' ('}
      <VersionLink resourceDefinitionVersion={provisionedVersion} />
      {')'}
    </>
  );

  const showWarning = versionStatus === 'Outdated' && !activeResource.target_def_version_id;

  return (
    <span className={'flex'}>
      {showWarning ? (
        <Tooltip
          text={translations.ACTIVE_PROVISIONED_NEXT_DEPLOYMENT}
          triggerComponent={
            <span className={'flex'}>
              <Icon name={'warning'} overrideColor={'yellow'} marginRight={'sm'} />
              {link()}
            </span>
          }
        />
      ) : (
        link()
      )}
    </span>
  );
};
