import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AppRoles, OrgRoles } from '@src/models/role';

import useEnvironmentQuery from './react-query/environments/queries/useEnvironmentQuery';
import useGetCurrentUserQuery from './react-query/user/useGetCurrentUserQuery';

/**
 * Custom React hook to retrieve user roles for organization, app, and environment type.
 *
 * @returns {{
 * orgRole: OrgRoles | undefined,
 * appRole: AppRoles | undefined,
 * envTypeRole: EnvTypeRoles | undefined
 * }}
 */

export const useGetUserRoles = (customParams?: { orgId?: string }) => {
  const { orgId: routerOrgId, appId } = useParams();
  const { data: user } = useGetCurrentUserQuery();
  const { data: environment } = useEnvironmentQuery();
  const orgId = routerOrgId || customParams?.orgId;

  const orgRole = useMemo(() => {
    return user?.roles?.[`/orgs/${orgId}`] as OrgRoles;
  }, [user, orgId]);

  const appRole = useMemo(() => {
    return user?.roles?.[`/orgs/${orgId}/apps/${appId}`] as AppRoles;
  }, [user, orgId, appId]);

  const envTypeRole = useMemo(() => {
    if (environment?.type) {
      return user?.roles?.[`/orgs/${orgId}/env-types/${environment?.type}`];
    }
  }, [user, environment?.type, orgId]);

  return {
    orgRole,
    appRole,
    envTypeRole,
  };
};
