import { Spinner } from '@humanitec/ui-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useAcceptInviteMutation, {
  AcceptInvitePayload,
} from '@src/hooks/react-query/user/useAcceptInviteMutation';
import useAuthenticateUserMutation from '@src/hooks/react-query/user/useAuthenticateUserMutation';
import useLoadInviteQuery from '@src/hooks/react-query/user/useLoadInviteQuery';
import { AuthenticationTypes } from '@src/hooks/zustand/useCurrentUserStore';
import { units } from '@src/styles/variables';
import { getInviteToken } from '@src/utilities/local-storage';
import { useGetUrlParam } from '@src/utilities/url-params';

import { AuthError } from './AuthError';

const GithubAuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  margin-right: ${units.margin.md};
`;

const GithubAuthTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GithubAuthText = styled.p`
  font-weight: 700;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${units.margin.lg};
`;

interface GithubAuthProps {
  authType: AuthenticationTypes;
  isInvite: boolean;
  disableFreeTrial?: boolean;
}

const GithubAuth = ({ authType, isInvite, disableFreeTrial = false }: GithubAuthProps) => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  // Router hooks
  const code = useGetUrlParam('code');

  // Selectors
  const inviteToken = getInviteToken();

  const { data: invite } = useLoadInviteQuery(inviteToken);

  const { isPending: isAuthenticating, mutate: authenticateUser } = useAuthenticateUserMutation({
    authenticationType: authType,
    provider: 'github',
    disableFreeTrial,
  });

  const { mutate: acceptInvite, isPending: isAccepting } = useAcceptInviteMutation();

  useEffect(() => {
    if (!code) return;

    const payload: AcceptInvitePayload = {
      provider: 'github',
      token: code,
    };

    if (authType === 'register') {
      payload.privacy_policy_accepted = true;
    }

    if (isInvite && invite) {
      acceptInvite({
        invite,
        payload,
      });
    } else if (!isInvite) {
      authenticateUser(code);
    }
  }, [invite, acceptInvite, code, isInvite, authenticateUser, authType]);

  return (
    <GithubAuthWrapper>
      <Body>
        {isAuthenticating || isAccepting ? (
          <>
            <SpinnerContainer>
              <Spinner color={'main'} />
            </SpinnerContainer>
            <GithubAuthTextContainer>
              <GithubAuthText>{authTranslations.AUTHENTICATING}</GithubAuthText>
              <span>{authTranslations.PLEASE_WAIT}</span>
            </GithubAuthTextContainer>
          </>
        ) : (
          <AuthError />
        )}
      </Body>
    </GithubAuthWrapper>
  );
};

export default GithubAuth;
